<div class="row">
    <select id="edit_step_name{{type}}"
            class="col-md-6" style="padding: 0"
            hidden
            data-style="select-btn select-btn-grey"
            title="Select Macro Step"
            data-size="7"
            [(ngModel)]="selectedMacroStep">
        <option [ngValue]="macroStep" *ngFor="let macroStep of macroSteps">
            {{macroStep.description}}
        </option>
    </select>

    <button type="button"
            class="btn btn-link"
            (click)="addMacroStep()">
        <i class="fa fa-plus"></i>
    </button>

    <button type="button" class="btn btn-primary pull-right"
            (click)="createStep()">
        Create step
    </button>
</div>

<div class="card-content table-responsive table-full-width" style="margin: 1px">
    <table class="table table-hover">
        <thead>
        <tr>
            <th>#</th>
            <th>Priority</th>
            <th>Step description</th>
            <th>Optional</th>
            <th>Wait</th>
            <th>Order ready</th>
            <th>Depends on</th>
            <th style="width:35px"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let step of getSteps(); let first = first; let last = last; let i = index">
            <td>{{step.priorityNum}}</td>
            <td>
                <button [disabled]="first" type="button" class="btn btn-simple btn-sm right" style="padding: 0"
                        (click)="incPriority(step)"><i class="fa fa-arrow-up"></i>
                </button>
                <button [disabled]="last" type="button" class="btn btn-simple btn-sm right" style="padding: 0"
                        (click)="decPriority(step)"><i class="fa fa-arrow-down"></i>
                </button>
            </td>
            <td>
                <button type="button" class="btn btn-simple" [disabled]="!step.step"
                        (click)="editStep(step)">{{getStepDescription(step)}}
                </button>
            </td>
            <td><input type="checkbox" disabled
                       [checked]="step.step ? step.step.optional : false"/></td>
            <td><input type="checkbox" disabled
                       [checked]="step.step ? step.step.wait : false"/></td>
            <td><input type="checkbox" disabled
                       [checked]="step.step ? step.step.orderReady : false"/></td>
            <td>{{getDependsOnString(step)}}
                <button *ngIf="i > 0" type="button" class="btn btn-simple btn-sm right"
                        (click)="editDependsOn(step)"><i class="fa fa-edit"></i>
                </button>
            </td>
            <td>
                <button type="button" class="btn btn-link btn-sm mt-0 black"
                        (click)="removeStep(step)"><i class="fa fa-trash"></i>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>
