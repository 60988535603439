import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {
    IngredientWeightStatsBean,
    KioskBean, MessageToUserBean,
    OrderBean,
    OrderIngredientStatusBean,
    OrderState,
    PageBean,
    RecipeOrdersCountBean,
    ResponseBean,
    ResponseItemBean, SMSBean,
    StepStateBean
} from '../model/model';

@Injectable()
export class OrderService extends BaseRequestService {

    getOrder(id: number): Observable<ResponseItemBean<OrderBean>> {
        return this.get('admin/order/' + id);
    }

    getOrders(state: OrderState, filter: string, mealplanMobile: boolean, startTime: number, endTime: number,
        kioskId?: number, limit?: number, offset?: number): Observable<PageBean<OrderBean>> {

        const p = BaseRequestService.buildOffsetParams(limit, offset);
        if (kioskId != null) {
            p['kiosk'] = kioskId;
        }
        if (state != null) {
            p['state'] = state;
        }
        if (filter != null) {
            p['filter'] = filter;
        }
        p['start_date'] = startTime;
        p['end_date'] = endTime;
        p['mealplan_mobile'] = mealplanMobile;

        return this.get('admin/order/list', p);
    }

    cancelOrders(ids: number[]): Observable<ResponseBean> {
        const p = {};
        p['id'] = ids;
        return this.del('admin/order/cancel', p);
    }

    getCountLastWeek(recipes: number[]): Observable<ResponseItemBean<RecipeOrdersCountBean>> {
        const p = {};
        for (const id of recipes) {
            p['recipe'] = id;
        }
        return this.get('admin/order/count_last_week', p);
    }

    getKioskStepsHistory(kiosk: KioskBean, startTime?: number, limit?: number, offset?: number): Observable<PageBean<StepStateBean>> {
        const p = BaseRequestService.buildOffsetParams(limit, offset);
        if (startTime != null) {
            p['startTime'] = startTime;
        }
        return this.get('admin/order/kiosk/' + kiosk.id + '/location/' + kiosk.operatorId + '/steps_history', p);
    }

    getIngredientWeightStats(kiosk: KioskBean, startTime: number, endTime: number, recipeId: number):
        Observable<ResponseItemBean<IngredientWeightStatsBean>> {
        const p = {};
        if (startTime != null) {
            p['start_time'] = startTime;
            p['end_time'] = endTime;
            p['recipe_id'] = recipeId;
        }

        return this.get('admin/order/kiosk/' + kiosk.id + '/ingredient_weight_stats', p);
    }

    getIngredientWeightStatsCSV(kiosk: KioskBean, startTime: number, endTime: number, recipeId: number): Observable<Blob> {
        const p = {};
        if (startTime != null) {
            p['start_time'] = startTime;
            p['end_time'] = endTime;
            p['recipe_id'] = recipeId;
        }

        return this.blob('admin/order/kiosk/' + kiosk.id + '/ingredient_weight_stats_csv', p);
    }

    getOrdersChartsCSV(kiosk: KioskBean, startTime: number, endTime: number): Observable<Blob> {
        const p = {};
        if (startTime != null) {
            p['start_time'] = startTime;
            p['end_time'] = endTime;
        }

        return this.blob('admin/order/kiosk/' + kiosk.id + '/report', p);
    }

    getOrderStepsHistory(orderId: number): Observable<ResponseItemBean<StepStateBean[]>> {
        return this.get('admin/order/' + orderId + '/steps_history');
    }

    getDispensedQuantities(orderId: number): Observable<ResponseItemBean<OrderIngredientStatusBean[]>> {
        const p = {order_id: orderId};
        return this.get('orders/dispensed_quantities', p);
    }

    setOrderNote(orderId: number, note: string): Observable<ResponseBean> {
        return this.post('admin/order/' + orderId + '/set_note', note);
    }

    refund(orderId: number): Observable<ResponseBean> {
        const p = {id: orderId};
        return this.get('admin/order/refund', p);
    }

    pickup(orderId: number): Observable<ResponseBean> {
        return this.get('orders/' + orderId + '/pick_up');
    }

    getSMS(userId: number, orderId: number, startDate: number, endDate: number): Observable<ResponseItemBean<SMSBean[]>> {
        const p = {user_id: userId};
        if (orderId != null) {
            p['order_id'] = orderId;
        }
        if (startDate != null) {
            p['start_date'] = startDate;
        }
        if (endDate != null) {
            p['end_date'] = endDate;
        }
        return this.get(`sms`, p);
    }

    sendSMS(message: MessageToUserBean): Observable<ResponseBean> {
        return this.post('sms', message)
    }

    sendMessageToJumbotron(kioskId: number, message: MessageToUserBean, voice: boolean): Observable<ResponseBean> {
        return this.post(`admin/kiosk/${kioskId}/send_message_to_jumbotron?voice=${voice}`, message)
    }

    sendCoupon(userId: number, kioskId: number, discount: number, endDate: number, message: string): Observable<ResponseItemBean<SMSBean[]>> {
        let url = `coupon/send?user_id=${userId}&discount=${discount}&kiosk_id=${kioskId}&message=${message}`;
        if (endDate) {
            url += `&end_date=${endDate}`;
        }
        return this.get(url);
    }

}
