import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {EntityNameBean, UserBean} from '../../../../model/model';
import {Helper} from '../../../../common/helper';
import {TableComponent} from '../../../../components/table.component';
import {Column} from '../../../../common/column';
import {UserMessagesEditComponent} from './user-messages.edit.component';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'user-messages-component',
    templateUrl: 'user-messages.component.html'
})
export class UserMessagesComponent implements AfterViewInit {

    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(UserMessagesEditComponent, {static: false}) modal: UserMessagesEditComponent;

    fields: Column[] = [
        new Column('name', 'Message'),
    ];
    messages: string[];
    kioskId: number;

    constructor(private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;
        this.kioskManageService.getUserMessages(kioskId).subscribe(response => {
            if (response.success) {
                this.messages = response.value;
                this.updateTable();
            }
        });
    }

    onEdit(items: EntityNameBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Message');
        }
    }

    onRemove(items) {
        for (const step of items) {
            const index = this.messages.indexOf(step.name);
            this.messages.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Message');
    }

    addMessage(initMessage: EntityNameBean, message: EntityNameBean) {

        if (this.messages == null) {
            this.messages = [];
        }
        if (initMessage.name == null) {
            this.messages.push(message.name);

        } else {

            let ind = -1;
            let i = 0;
            for (const name of this.messages) {
                if (name == initMessage.name) {
                    ind = i;
                }
                i++;
            }

            if (ind >= 0) {
                this.messages.splice(ind, 1);
                this.messages.splice(ind, 0, message.name);
            }
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {

            const users: EntityNameBean[] = [];
            if (this.messages) {
                for (const msg of this.messages) {
                    const message: EntityNameBean = {} as EntityNameBean;
                    message.name = msg;
                    users.push(message);
                }
            }

            this.table.setData(users, this.fields);
        }
    }

    save() {

        this.kioskManageService.setUserMessages(this.kioskId, this.messages).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
