import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ServiceEventsAnalyticsComponent} from './service-events-analytics.component';
import {EntityNameBean, KioskBean, ServiceEventBean} from '../../model/model';
import {Helper} from '../../common/helper';
import * as moment from 'moment-timezone';
import {Location} from '@angular/common';
import {KioskService} from '../../services/kiosk.service';
import {ActivatedRoute} from '@angular/router';
import {json2csv} from 'json-2-csv';
import * as FileSaver from 'file-saver';
import {Column} from '../../common/column';
import {ServiceEventsEditComponent} from './service-events.edit.component';
import {TableComponent} from '../../components/table.component';

@Component({
    selector: 'service-events-component',
    templateUrl: 'service-events.component.html',
})

export class ServiceEventsComponent implements AfterViewInit {

    @ViewChild(ServiceEventsAnalyticsComponent, {static: false}) serviceEventsAnalyticsComponent: ServiceEventsAnalyticsComponent;
    @ViewChild(ServiceEventsEditComponent, {static: false}) serviceEventsEditComponent: ServiceEventsEditComponent;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    kiosks: EntityNameBean[] = [];
    selectedKiosk: EntityNameBean;
    selectedKioskId: number;
    compareBeans = Helper.compareBeans;

    selectedRange = [
        moment().startOf('day').subtract(1, 'day').toDate(),
        moment().endOf('day').toDate()
    ];
    selectedCategory: string = 'all';
    tabMode: boolean;
    serviceEvents: ServiceEventBean[];

    columns: Column[] = [
        new Column('id', 'Id').hide(),
        new Column('startDate', 'Start Date').date(),
        new Column('endDate', 'End Date').date(),
        new Column('originalCategory', 'Original Category'),
        new Column('category', 'Actual Category'),
        new Column('durationSeconds', 'Duration').duration(),
        new Column('component1', 'Component 1'),
        new Column('component2', 'Component 2'),
        new Column('analysis', 'Analysis').hide()
    ];

    constructor(private kioskService: KioskService,
        private route: ActivatedRoute,
        private location: Location) {
    }

    ngAfterViewInit(): void {

        Helper.selectPicker('select_kiosk', 'select_category');

        this.route.queryParams.subscribe(params => {
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;
            this.selectedCategory = params['category'] != null ? params['category'] : 'all';

            this.loadKiosks();
        });
    }

    loadKiosks() {

        this.kioskService.getActiveKiosksNames(true).subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks: EntityNameBean[]) {

        this.kiosks = kiosks;
        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKioskId) {
            this.selectedKiosk = this.kiosks[0];
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.id == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }

        Helper.selectPicker('select_kiosk');
        this.loadEvents();
    }

    onKioskChange() {
        this.selectedCategory = 'all';
        Helper.selectPicker('select_category');

        this.loadEvents();
        this.updateLocation();
    }

    onCategoryChange() {
        this.loadEvents();
        this.updateLocation();
    }

    updateLocation() {
        let url = '/service-events';
        if (this.selectedKiosk.id) {
            url += ('?kiosk=' + this.selectedKiosk.id);
            if (this.selectedCategory != 'all') {
                url += ('&category=' + this.selectedCategory);
            }
        } else {
            if (this.selectedCategory != 'all') {
                url += ('?category=' + this.selectedCategory);
            }
        }

        this.location.replaceState(url);
    }

    loadEvents() {
        if (!this.selectedKiosk) {
            return;
        }

        this.serviceEventsAnalyticsComponent.onShow(this.selectedKiosk, this.selectedCategory);

        // this.kioskService.getServiceEventOptions(this.selectedKiosk.id).subscribe(response => {
        //     if (response.success) {
        //         this.options = response.value;
        //         Helper.selectPicker('select_category');
        //
        //         let start = Helper.startOf(this.selectedRange[0]);
        //         let end = Helper.endOf(this.selectedRange[1]);
        //
        //         this.kioskService.getServiceEvents(this.selectedKiosk.id, start, end,
        //             this.selectedCategory == 'all' ? null : this.selectedCategory)
        //
        //             .subscribe(response => {
        //                 this.serviceEvents = response.value;
        //                 this.table.setData(this.serviceEvents, this.columns);
        //             });
        //     }
        // });
    }

    downloadMaintenance() {
        if (this.serviceEvents) {
            json2csv(this.serviceEvents, (err, csv) => {
                let blob = new Blob([csv], {
                    type: 'text/plain'
                });
                FileSaver.saveAs(blob, 'ServiceEvents.csv');
            }, {delimiter: {field: ';'}});
        }
    }

    onEdit(items: ServiceEventBean[]) {
        if (items && items.length > 0) {
            this.serviceEventsEditComponent.open(items[0], this.selectedKiosk.id, 'Edit Event');
        } else {
            this.serviceEventsEditComponent.open(null, this.selectedKiosk.id, 'Add Event');
        }
    }

    onShow(kiosk: KioskBean = null, tabMode: boolean = false) {
        this.tabMode = tabMode;
        if (kiosk) {
            this.selectedKiosk = {id: kiosk.id, name: kiosk.name} as EntityNameBean;
        }
    }
}
