<div class="animated fadeIn">

    <div class="row">
        <div class="col col-md-9" *ngIf="userType=='CUSTOMER'">

            <div class="radio">
                <input type="radio" name="radio1" id="radio1" value="option1" checked="" [value]="true"
                       (change)="sortChange($event)"
                       [(ngModel)]="isByMemberSinceDate">
                <label for="radio1">
                    By Member Since Date
                </label>
            </div>
            <div class="radio">
                <input type="radio" name="radio1" id="radio2" value="option2" [value]="false"
                       [(ngModel)]="isByMemberSinceDate" (change)="sortChange($event)">
                <label for="radio2">
                    By Order Count
                </label>
            </div>

        </div>

        <div class="col col-md-3">

            <button type="button" class="btn btn-outline-primary" style="float: right"
                    (click)="download()">Download CSV
            </button>&nbsp;

        </div>

    </div>

    <div class="col-md-12">
        <div class="card">
            <div class="card-content">
                <table-component (onEdit)="onEdit($event)"
                                 (onRemove)="onRemove($event)"
                                 (onExtra)="onExtra($event)"
                                 (onView)="onView($event)"
                                 extraText="Send Notification"
                                 (onAdd)="onAdd()"
                                 (dataFunction)="getData($event)"></table-component>
            </div>
        </div>
    </div>
</div>
<profiles-edit-component></profiles-edit-component>
<profiles-notification-component></profiles-notification-component>
<customer-edit-component></customer-edit-component>
