import {Component, forwardRef, Inject, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {RecipeService} from '../../services/recipe.service';
import {RecipesComponent} from './recipes.component';
import {AppComponent} from '../../app.component';
import {FileUploaderComponent} from '../../file-uploader/file-uploader.component';
import {StepsListComponent} from '../../components/steps-list.component';
import {KioskDataService} from '../../services/kiosk-data.service';
import {IngredientBean, RecipeBean, RecipeStepBean} from '../../model/model';
import {Helper} from '../../common/helper';
import {Column} from '../../common/column';

@Component({
    selector: 'recipes-edit-component',
    templateUrl: 'recipes.edit.component.html'
})

export class RecipesEditComponent {

    recipe: RecipeBean = {} as RecipeBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild('fieldsListComponentDesc', {static: false}) fieldsListComponentDesc: FieldsListComponent;
    @ViewChild('fieldsListComponentImages', {static: false}) fieldsListComponentImages: FieldsListComponent;

    @ViewChildren(FileUploaderComponent) fileUploaderComponents: QueryList<FileUploaderComponent>;
    @ViewChildren(StepsListComponent) stepsListComponents: QueryList<StepsListComponent>;

    fields: Field[] = [
        new Field('id', 'Recipe ID').disable(),
        new Field('title', 'Title').require(),
        new Field('colorPrimary', 'Primary Color', FieldType.COLOR),
        new Field('colorDark', 'Dark Color', FieldType.COLOR),
        new Field('colorChart', 'Chart Color', FieldType.COLOR),
        new Field('blendTimeSec', 'Blend Time'),
        new Field('description', 'Description', FieldType.TEXT_AREA),
        new Field('featured', 'Featured', FieldType.CHECKBOX),
        new Field('vegan', 'Vegan', FieldType.CHECKBOX),
        new Field('vendingMachineProduct', 'Vending Machine Product', FieldType.CHECKBOX),
        new Field('expirationTime', 'Expiration Time', FieldType.NUMBER)
    ];

    fieldsImages: Field[] = [
        new Field('bannerUrl', 'Jumboton Menu Banner', FieldType.FILE),
        new Field('backgroundUrl', 'Custom Recipe Background', FieldType.FILE),
        new Field('imageUrl', 'App Image', FieldType.FILE),
        new Field('soloImageUrl', 'Jumboton Menu Hero Item', FieldType.FILE),
    ];

    smallSizeEnabled: boolean;
    mediumSizeEnabled: boolean;
    largeSizeEnabled: boolean;

    selectedIngredient: IngredientBean;
    ingredients: IngredientBean[];
    selectedBoost: IngredientBean;

    volumeSmall: number;
    volumeMedium: number;
    volumeLarge: number;
    steps: RecipeStepBean[];
    selectedStep: RecipeStepBean;

    isValid() {
        return () => {
            return this.fieldsListComponentDesc && this.fieldsListComponentDesc.valid();
        };
    }

    constructor(private recipeService: RecipeService,
        private kioskDataService: KioskDataService,
        @Inject(forwardRef(() => RecipesComponent)) private parent: RecipesComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(recipe: RecipeBean, categoryId: number, title: string) {

        if (recipe) {
            this.recipe = Helper.copyObject(recipe);

        } else {
            this.recipe = {} as RecipeBean;
            this.recipe.id = 0;
            this.recipe.price = {};
            this.recipe.ingredients = [];
            this.recipe.addons = [];

            this.recipe.categoryId = categoryId;
        }

        this.stepsListComponents.forEach((item) => {
            item.init();
        });

        // this.fileImageAdd.imageSrc = '';
        this.base.show(title);

        this.kioskDataService.getData().subscribe(kioskData => {
            this.ingredients = kioskData.value.ingredients;
            Helper.selectPicker('edit_ingredient_name', 'edit_boost_name');
        });

        this.smallSizeEnabled = !!this.recipe.price['SMALL'];
        this.mediumSizeEnabled = !!this.recipe.price['MEDIUM'];
        this.largeSizeEnabled = !!this.recipe.price['LARGE'];

        this.updateVolumes();
    }

    onSmallSizeChanged(ev) {
        this.smallSizeEnabled = ev.target.checked;
        if (!this.smallSizeEnabled) {
            delete this.recipe.price['SMALL'];
        }
    }

    onMediumSizeChanged(ev) {
        this.mediumSizeEnabled = ev.target.checked;
        if (!this.mediumSizeEnabled) {
            delete this.recipe.price['MEDIUM'];
        }
    }

    onLargeSizeChanged(ev) {
        this.largeSizeEnabled = ev.target.checked;
        if (!this.largeSizeEnabled) {
            delete this.recipe.price['LARGE'];
        }
    }

    addIngredient() {

        if (this.selectedIngredient) {
            this.selectedIngredient.minQuantity = {};
            this.selectedIngredient.maxQuantity = {};
            this.selectedIngredient.normalQuantity = {};
            this.selectedIngredient.normalQuantitySeconds = {};
            this.recipe.ingredients.push(this.selectedIngredient);
        }
    }

    removeIngredient(ingredient: IngredientBean) {
        const index = this.recipe.ingredients.indexOf(ingredient, 0);
        if (index > -1) {
            this.recipe.ingredients.splice(index, 1);
        }
    }

    addBoost() {
        if (this.selectedBoost) {
            this.recipe.addons.push(this.selectedBoost);
        }
    }

    removeBoost(boosts: IngredientBean) {
        const index = this.recipe.addons.indexOf(boosts, 0);
        if (index > -1) {
            this.recipe.addons.splice(index, 1);
        }
    }

    editDependsOn(ev) {
        this.parent.openDependsOnComponent(ev.recipeSteps, ev.step);
    }

    createStep(ev) {
        this.steps = ev.recipeSteps;
        this.parent.openCreateStepModal(ev.step);

        this.selectedStep = ev.step;
    }

    addProcess(ev, steps) {

        const step: RecipeStepBean = this.selectedStep ? Helper.copyObject(this.selectedStep) : {} as RecipeStepBean;
        step.step = ev.process;

        console.log(ev);
        console.log(step);

        if (!ev.origProcess) {
            if (this.steps.length > 0) {
                step.dependsOn = [this.steps.length - 1];
            }
            this.steps.push(step);

        } else {

            let index = -1;

            for (let i = 0; i < this.steps.length; i++) {
                if (this.steps[i].step && this.steps[i].step == ev.origProcess) {

                    step.dependsOn = this.steps[i].dependsOn;
                    index = i;
                    break;
                }
            }

            if (index >= 0) {
                this.steps.splice(index, 1);
                this.steps.splice(index, 0, step);
            }
        }
    }

    onHidden() {
        this.fileUploaderComponents.forEach((item) => {
            item.cancel();
        });
    }

    save() {

        this.recipe.state = this.recipe['stateCopy'];

        if (this.recipe.stepPhases) {
            let ind;
            ind = 0;
            for (const step of this.recipe.stepPhases.preparationSteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            for (const step of this.recipe.stepPhases.cookingSteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            for (const step of this.recipe.stepPhases.servingSteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            for (const step of this.recipe.stepPhases.deliverySteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            if (this.recipe.stepPhases.cleaningSteps) {
                for (const step of this.recipe.stepPhases.cleaningSteps) {
                    step.priorityNum = ind++;
                    delete step['orderReady'];
                }
            }
            ind = 0;
            if (this.recipe.stepPhases.wrapupSteps) {
                for (const step of this.recipe.stepPhases.wrapupSteps) {
                    step.priorityNum = ind++;
                    delete step['orderReady'];
                }
            }
        }
        if (this.recipe.id == 0) {

            this.recipeService.addRecipe(this.recipe).subscribe(response => {
                if (response.success) {
                    this.parent.loadRecipes();
                } else {
                    this.app.showError('Can\'t create recipe');
                }
            }, error => {
                this.app.showError('Can\'t create recipe');
            });

        } else {

            this.recipeService.updateRecipe(this.recipe).subscribe(response => {
                if (response.success) {
                    this.parent.loadRecipes();
                } else {
                    this.app.showError('Can\'t update recipe');
                }
            }, error => {
                this.app.showError('Can\'t update recipe');
            });
        }
    }

    incPriority(ingredient: IngredientBean) {
        const index = this.recipe.ingredients.indexOf(ingredient, 0);
        if (index > -1) {
            const ingredients = this.recipe.ingredients;
            ingredients.splice(index - 1, 0, ingredients.splice(index, 1)[0]);
        }
    }

    decPriority(ingredient: IngredientBean) {
        const index = this.recipe.ingredients.indexOf(ingredient, 0);
        if (index > -1) {
            const ingredients = this.recipe.ingredients;
            ingredients.splice(index + 1, 0, ingredients.splice(index, 1)[0]);
        }
    }

    updateVolumes() {
        this.volumeSmall = this.volumeMedium = this.volumeLarge = 0;
        for (let ing of this.recipe.ingredients) {

            if (ing.density > 0) {

                this.volumeSmall += ing.normalQuantity['SMALL'] / ing.density;
                this.volumeMedium += ing.normalQuantity['MEDIUM'] / ing.density;
                this.volumeLarge += ing.normalQuantity['LARGE'] / ing.density;

                // console.log("add " + ing.name + " " + ing.density + " " +  ing.normalQuantity['SMALL'] + " " + this.volumeSmall);
            }
        }
        this.volumeSmall *= 0.0338;
        this.volumeMedium *= 0.0338;
        this.volumeLarge *= 0.0338;
    }
}
