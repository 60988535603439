import {AfterViewInit, Component} from '@angular/core';
import {Location} from '@angular/common';
import {DeviceMessageBean} from '../../model/model';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements AfterViewInit {

    listTitles: any[] = [];
    lastTitle: string;

    customTitle: string;
    messages: DeviceMessageBean[] = [];

    constructor(private location: Location) {
    }

    ngAfterViewInit() {
        this.listTitles = [
            {path: 'vision', title: 'Vision'},
            {path: 'home', title: 'Dashboard'},
            {path: 'analytics', title: 'Analytics'},
            {path: 'business-ops', title: 'Business Ops'},
            {path: 'operators', title: 'Operators'},
            {path: 'kiosks', title: 'Locations'},
            {path: 'kiosk-templates', title: 'Kiosk Templates'},
            {path: 'device-provisioning', title: 'Device Provisioning'},
            {path: 'kiosk-models', title: 'Kiosk Models'},
            {path: 'kiosk-model-details', title: 'Model Details'},
            {path: 'lists', title: 'Recipes Lists'},
            {path: 'categories', title: 'Categories'},
            {path: 'recipes', title: 'Recipes'},
            {path: 'ingredients', title: 'Ingredients'},
            {path: 'custom-recipes', title: 'Custom Recipes'},
            {path: 'orders', title: 'Orders'},
            {path: 'orders-charts', title: 'Financial Statistics'},
            {path: 'reviews', title: 'Reviews'},
            {path: 'profiles/admins', title: 'Super Admins'},
            {path: 'profiles/users', title: 'Users'},
            {path: 'profiles/customers', title: 'Customers'},
            {path: 'companies', title: 'Companies'},
            {path: 'devices', title: 'Abstract Devices'},
            {path: 'device-templates', title: 'Device Templates'},
            {path: 'macro-steps', title: 'Macro Steps'},
            {path: 'event-log', title: 'Event Log'},
            {path: 'payment-credentials', title: 'Payment Credentials'},
            {path: 'app-configuration', title: 'App Configuration'},
            {path: 'notifications', title: 'App Notifications'},
            {path: 'documentation', title: 'Documentation'},
            {path: 'image-manager', title: 'Image Manager'},
            {path: 'mealplans', title: 'Meal Plans'},
            {path: 'system-configuration', title: 'System Configuration'},
            {path: 'service-events', title: 'Service Events'},
            {path: 'promotions', title: 'Promotions'},

        ];
    }

    getTitle() {

        let title = this.location.prepareExternalUrl(this.location.path());
        if (title.charAt(0) == '/') {
            title = title.slice(1);
        }

        const queryIndex = title.indexOf('?');
        if (queryIndex > 0) {
            title = title.slice(0, queryIndex);
        }

        if (title != this.lastTitle) {
            this.customTitle = null;
        }
        this.lastTitle = title;

        if (this.customTitle) {
            return this.customTitle;
        }

        for (const item of this.listTitles) {
            if (item.path == title) {
                return item.title;
            }
        }

        return '';
    }

    public setTitle(title: string) {
        this.customTitle = title;
    }

    public setMessages(messages: DeviceMessageBean[]): void {
        this.messages = messages;
    }
}
