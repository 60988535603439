<div #toolbar>
    <button #add class="btn btn-info" (click)="addItem()">
        <i class="glyphicon glyphicon-add"></i> Add
    </button>&nbsp;
    <button #remove class="btn btn-danger ml5" disabled (click)="deleteSelectedItems()">
        <i class="glyphicon"></i> {{removeText}}
    </button>
    <button #extra class="btn btn-warning ml5" [disabled]="extraAutoDisabled" (click)="extraMultipleEvent()">
        <i class="glyphicon"></i> {{extraText}}
    </button>
</div>
<table #table></table>
