import {Component, ViewChild} from '@angular/core';
import {BaseModalComponent} from './base.modal.component';

@Component({
    selector: 'image-modal-component',
    templateUrl: 'image.modal.component.html'
})

export class ImageModalComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    src: string;
    rotated: boolean;

    constructor() {
    }

    open(src: string, rotated: boolean) {
        this.src = src;
        this.rotated = rotated;
        this.base.setFooterVisibility(false);
        this.base.setHeaderVisibility(false);
        this.base.show('');
    }

}
