<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <fields-list-component #fieldsListComponent [fields]="fields" name="robot_command_edit" [value]="robotCommand"></fields-list-component>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">

                <label for="select_device" class="col-md-3 control-label">Robot Device</label>
                <div class="col-md-9">

                    <select class="form-control form-control-low selectx" id="select_device"
                            [compareWith]="compareBeans"
                            [(ngModel)]="selectedRobotDevice">
                        <option *ngFor="let device of robotDevices" [ngValue]="device">
                            {{device.deviceName}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label for="edit_3" class="col-md-3 control-label">Process</label>
                <div class="col-md-9">
                    <select
                            id="edit_3"
                            name="edit_3"
                            class="form-control form-control-low selectx"
                            size="1"
                            (change)="createParameters()"
                            [compareWith]="compareBeans"
                            [(ngModel)]="robotCommand.process">
                        <option [ngValue]="p"
                                *ngFor="let p of (selectedRobotDevice ? selectedRobotDevice.deviceTemplate.abstractDevice.processes : [])">
                            {{p.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div *ngIf="haveParameters()">
                <div class="form-group">
                    <label class="col-md-3 control-label">Parameters</label>

                    <div class="col-md-9" style="padding-top: 12px">
                        <div class="row"
                             *ngFor="let parameter of robotCommand.process.parameters; let i = index;">
                            <div class="col-md-2">
                                <label class="col-form-label">{{parameter}}</label>
                            </div>
                            <div class="col-md-5">
                                <label for="edit_type_{{i}}" class="col-form-label">Value Type
                                    <select
                                            id="edit_type_{{i}}"
                                            name="edit_type_{{i}}"
                                            class="form-control form-control-low selectx"
                                            [(ngModel)]="robotCommand.parameters[parameter].type"
                                            size="1">

                                        <option [ngValue]="'NUMBER'">NUMBER</option>
                                        <option [ngValue]="'DEVICE'">DEVICE</option>
                                        <option [ngValue]="'DEVICE_PLACE'">DEVICE_PLACE</option>
                                        <option [ngValue]="'DELIVERY_PLACE'">DELIVERY_PLACE</option>

                                    </select>
                                </label>
                            </div>
                            <div class="col-md-5" [hidden]="!isNumberType(robotCommand.parameters[parameter].type)">
                                <label for="edit_value_{{i}}" class="col-form-label">Value
                                    <input type="text"
                                           [(ngModel)]="robotCommand.parameters[parameter].value"
                                           class="form-control form-control-low "
                                           id="edit_value_{{i}}"
                                           name="edit_value_{{i}}"/>
                                </label>
                            </div>
                            <div class="col-md-5" [hidden]="!isDeviceOrPlace(robotCommand.parameters[parameter].type)">
                                <label for="edit_device_{{i}}" class="col-form-label">Value
                                    <select
                                            id="edit_device_{{i}}"
                                            name="edit_device_{{i}}"
                                            class="form-control form-control-low selectx"
                                            [(ngModel)]="robotCommand.parameters[parameter].value"
                                            size="1">
                                        <option [ngValue]="device.id" *ngFor="let device of kioskModel.devices">
                                            {{device.deviceName}}
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


</base-modal-component>
