import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderState, ProgressLoaderService} from '../services/loader.service';

@Component({
    selector: 'progress-loader',
    templateUrl: 'progress-loader.component.html',
    styleUrls: ['progress-loader.component.css']
})
export class ProgressLoaderComponent implements AfterViewInit, OnDestroy {

    show = false;
    subscription: Subscription;

    constructor(loaderService: ProgressLoaderService) {
        this.subscription = loaderService.loaderState
            .subscribe((state: LoaderState) => {
                this.show = state.show;
            });
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
