import {AfterViewInit, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {Helper} from '../common/helper';
import {BaseModalComponent} from './base.modal.component';
import {KioskDataService} from '../services/kiosk-data.service';
import {AbstractDeviceBean, ProcessParameterValueBean, StepBean} from '../model/model';

@Component({
    selector: 'process-edit-component',
    templateUrl: 'process.edit.component.html'
})

export class ProcessEditComponent implements AfterViewInit {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;

    process: StepBean = {} as StepBean;
    devices: AbstractDeviceBean[];
    devicesWithProcesses: AbstractDeviceBean[];
    origProcess: StepBean;
    @Output() onResult = new EventEmitter();

    compareBeans = Helper.compareBeans;

    constructor(private kioskDataService: KioskDataService) {
    }

    ngAfterViewInit(): void {
    }

    open(process: StepBean) {

        this.kioskDataService.getData().subscribe(kioskData => {
            this.devices = kioskData.value.devices;
            this.devicesWithProcesses = [];
            for (const device of kioskData.value.devices) {
                if (device.processes && device.processes.length > 0) {
                    this.devicesWithProcesses.push(device);
                }
            }
        });

        this.origProcess = process;

        if (process) {
            this.process = Helper.copyObject(process);
        } else {
            this.process = {} as StepBean;
            this.process.device = this.devices[0];
            this.onDeviceChange(null);
        }
        this.base.show(process == null ? 'Create New Process' : 'Edit Process');
    }

    onDeviceChange(value) {
        this.process.process = this.process.device.processes[0];
        this.onProcessChange(null);
    }

    onProcessChange(value) {
        this.process.parameters = {};
        if (this.process.process) {
            for (const param of this.process.process.parameters) {
                this.process.parameters[param] = {} as ProcessParameterValueBean;
                this.process.parameters[param].type = 'NUMBER';
            }
        }
    }

    isTextType(value, key) {
        return value == 'TEXT';
    }

    isNumberType(value, key) {
        return value == 'NUMBER';
    }

    isDeviceOrPlace(value, key) {
        return value == 'DEVICE' || value == 'DEVICE_PLACE';
    }

    haveParameters() {
        if (!this.process.parameters || !this.process.process) {
            return false;
        }
        for (const param of this.process.process.parameters) {
            if (param.length > 0) {
                return true;
            }
        }
        return false;
    }

    save() {
        this.onResult.emit({process: this.process, origProcess: this.origProcess});
    }

}
