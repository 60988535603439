import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {KioskDevicesEditComponent} from './kiosk-devices.edit.component';
import {AppComponent} from '../../../app.component';
import {Column} from '../../../common/column';
import {TableComponent} from '../../../components/table.component';
import {DeviceBean, VisionObjectAreaBean} from '../../../model/model';
import {Helper} from '../../../common/helper';
import {VisionService} from '../../../services/vision.service';
import {RectSelectComponent} from './rect.select.component';
import {KioskDevicesViewComponent} from './kiosk-devices.view.component';

@Component({
    selector: 'kiosk-devices-component',
    templateUrl: 'kiosk-devices.component.html',
})
export class KioskDevicesComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskDevicesEditComponent, {static: false}) kioskDevicesSelectComponent: KioskDevicesEditComponent;
    @ViewChild(KioskDevicesViewComponent, {static: false}) kioskDevicesViewComponent: KioskDevicesViewComponent;
    @ViewChild(RectSelectComponent, {static: false}) rectSelectComponent: RectSelectComponent;

    fields: Column[] = [
        new Column('name', 'Name'),
        new Column('abstractDeviceName', 'Abstract Device'),
        new Column('ipAddress', 'IP'),
        // new Column('hwVersion', 'Hardware Version'),
        new Column('stateCopy', 'State'),
        new Column('activeSince', 'Active Since').date(),
        new Column('disabled', 'Disabled').checkbox(),
        new Column('operationType', 'Operation Type'),
        // new Column('visionObjectNameUrl', 'Object').data(this, this.getObjectLink).thumbnail(50, false),
        // new Column('visionIngredientNameUrl', 'Ingredient').data(this, this.getIngredientLink).thumbnail(50, false),
        new Column('', 'Ingredients').data(this, this.getIngredients),
        new Column('errorCode', 'Error').concat('errorMessage').button(this, this.refill).hide(),
    ];
    private devices: DeviceBean[];
    private tags: string[];
    private kioskId: number;
    private operatorId: number;

    constructor(private kioskService: KioskService,
        private visionService: VisionService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.operatorId = operatorId;
        this.kioskId = kioskId;
        this.loadDevices();
    }

    public loadDevices() {
        this.kioskService.getDevices(this.kioskId).subscribe(devices => {

            console.log(devices.list);

            this.devices = [];
            for (const device of devices.list) {
                const deviceCopy: DeviceBean = Helper.copyObject(device);
                deviceCopy['stateCopy'] = device.state;
                this.devices.push(deviceCopy);
            }

            this.table.setData(this.devices, this.fields);

            this.visionService.getTags().subscribe(result => {
                this.tags = result.value;

            });
            // this.visionService.getDetectionResultsForKiosk(this.kiosk.id).subscribe(result => {
            //     console.log(result);
            //
            //     for (const device of this.devices) {
            //         for (const vision of result) {
            //             if (device.visionObjectName == vision.object_name) {
            //                 device['visionObjectNameUrl'] = vision.result_image;
            //                 device['visionInfo'] = vision.result;
            //
            //             } else if (device.visionIngredientName == vision.object_name) {
            //                 device['visionIngredientNameUrl'] = vision.result_image;
            //             }
            //         }
            //     }
            //
            //     this.table.setData(this.devices, this.fields);
            //
            // }, error => {
            //     this.table.setData(this.devices, this.fields);
            // })
        });

    }

    refill(device: DeviceBean) {
        if (device.errorCode == 101) {
            this.kioskService.refillIngredient(this.kioskId, this.operatorId, device.id).subscribe(response => {
                if (response.success) {
                    this.app.showNotification('Refilled');
                    this.loadDevices();

                } else {
                    this.app.showError('Can\'t refill');
                }
            }, error => {
                this.app.showError('Can\'t refill');
            });
        }
    }

    getIngredientLink(device: DeviceBean) {
        // if (!device.visionIngredientName) return '';
        // return '<br /><a href='/vision?filter=' + device.visionIngredientName + ''>View All</a>';
    }

    getObjectLink(device: DeviceBean) {
        // if (!device.visionObjectName) return '';
        // return '<br /><a href='/vision?filter=' + device.visionObjectName + ''>View All</a>';
    }

    getIngredients(device: DeviceBean) {

        if (device.dispenserType == 'INGREDIENT') {
            let result: string = '';
            for (const key in device.canDispenseIngredients) {
                result += (device.canDispenseIngredients[key].name + '; ');
            }
            return result.slice(0, -2);
        }

        return '';
    }

    // getVision(device: DeviceBean) {
    //     if (this.visionResult) {
    //         for (const result of this.visionResult) {
    //             if (result.object_name == device.visionObjectName) {
    //                 return '<img class='img-thumbnail' width='50px' height='50px' src='' + result.result_image + ''/>';
    //             }
    //         }
    //     }
    //     return '';
    // }

    onEdit(items: DeviceBean[]) {
        // if (this.kiosk.state == 'OPERATIONAL') { //fixme!!!!
        //     this.app.showError('This action can not be performed when operational state');
        //     return;
        // }

        if (items && items.length > 0) {
            if (items[0].id != 0) {
                this.kioskDevicesSelectComponent.open(this.kioskId, this.operatorId, items[0], this.devices);
            }
        }
    }

    onView(items: DeviceBean[]) {
        if (items && items.length > 0) {
            if (items[0].id != 0) {
                this.kioskDevicesViewComponent.open(this.kioskId, this.operatorId, items[0]);
            }
        }
    }

    setNormalState() {
        this.kioskService.changeDevicesOperationType(this.kioskId, 'NORMAL').subscribe(response => {
            if (response.success) {
                this.app.showNotification('Done');
                this.loadDevices();
            } else {
                this.app.showError('Can\'t change operation type');
            }
        });
    }

    setPseudoSimState() {
        this.kioskService.changeDevicesOperationType(this.kioskId, 'PSEUDO_SIM').subscribe(response => {
            if (response.success) {
                this.app.showNotification('Done');
                this.loadDevices();
            } else {
                this.app.showError('Can\'t change operation type');
            }
        });
    }

    public openRectEditDialog(device: DeviceBean, rect: VisionObjectAreaBean) {
        this.rectSelectComponent.open(this.tags, device, this.devices, rect);
    }

    addRect(device: DeviceBean, rect: VisionObjectAreaBean) {
        device.visionObjectAreas.push(rect);
    }

    editRect(device: DeviceBean, rect: VisionObjectAreaBean) {
    }
}
