import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {KioskService} from '../../services/kiosk.service';
import {KiosksComponent} from './kiosks.component';
import {AppComponent} from '../../app.component';
import {KioskModelService} from '../../services/kiosk-model.service';
import {KioskBean} from '../../model/model';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'kiosks-quickview-component',
    templateUrl: 'kiosks.quickview.component.html'
})

export class KiosksQuickviewComponent {

    kiosk: KioskBean = {} as KioskBean;
    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields = [
        new Field('name', 'Name').disable(),
        new Field('addressLine1', 'Address Line #1').disable(),
        new Field('addressLine2', 'Address Line #2').disable(),
        new Field('country', 'Country').disable(),
        new Field('countryCode', 'Country Code').disable(),
        new Field('city', 'City').require().disable(),
        new Field('contactName', 'Contact Name').disable(),
        new Field('contactEmail', 'Contact Email').disable(),
        new Field('contactPhone', 'Contact Phone', FieldType.TEL).disable(),
        new Field('latitude', 'Latitude', FieldType.NUMBER).disable(),
        new Field('longitude', 'Longitude', FieldType.NUMBER).disable(),
    ];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private kioskService: KioskService,
        private kioskModelService: KioskModelService,
        private http: HttpClient,
        @Inject(forwardRef(() => KiosksComponent)) private parent: KiosksComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(kioskId: number, operatorId: number, title: string) {

        // if (kiosk) {
        //     this.kiosk = Helper.copyObject(kiosk);
        // } else {
        //     this.kiosk = {} as KioskBean;
        //     this.kiosk.operatorId = operatorId;
        // }

        this.kiosk = {} as KioskBean;
        this.kioskService.getKiosk(operatorId, kioskId).subscribe(response => {
            if (response.success) {
                this.kiosk = response.value;
            } else {
                this.app.showError('Access Denied');
            }
        });

        this.base.show(title);
    }

    edit() {
        // this.parent.editKiosk(this.kiosk);
    }

}
