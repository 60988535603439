import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {HardwareInfoBean, KioskBean, KioskInfoBean, ResponseBean, ResponseItemBean} from '../model/model';
import {DeviceSnapshot} from '../model/custom-model';

@Injectable()
export class StoreManagerService extends BaseRequestService {

    setKioskMaintenance(kioskId: number, on: boolean): Observable<ResponseBean> {
        const p = {};
        p['on'] = on;
        return this.get('store_manager/kiosk/' + kioskId + '/maintenance', p);
    }

    setDeviceDisabled(kioskId: number, deviceId: number, disabled: boolean): Observable<ResponseBean> {
        const p = {};
        p['value'] = disabled;
        return this.get('store_manager/kiosk/' + kioskId + '/device/' + deviceId + '/disabled', p);
    }

    refillIngredient(kioskId: number, deviceId: number): Observable<ResponseBean> {
        return this.get('store_manager/kiosk/' + kioskId + '/device/' + deviceId + '/refill_ingredient');
    }

    cancelOrder(orderId: number): Observable<ResponseBean> {
        return this.get('store_manager/order/' + orderId + '/cancel');
    }

    startNow(orderId: number): Observable<ResponseBean> {
        return this.get('store_manager/order/' + orderId + '/start_now');
    }

    getKiosks(): Observable<ResponseItemBean<KioskBean[]>> {
        return this.get('store_manager/kiosk/list');
    }

    getKiosksInfo(): Observable<ResponseItemBean<KioskInfoBean[]>> {
        return this.get('store_manager/kiosk/list_info');
    }

    getKiosk(id: number): Observable<ResponseItemBean<KioskBean>> {
        return this.get('store_manager/kiosk/' + id);
    }

    unlockJars(kiosk: KioskBean): Observable<ResponseBean> {
        return this.get('store_manager/kiosk/' + kiosk.id + '/unlock_jars');
    }

    getNotConnectedDevicesForKiosk(kioskId: number): Observable<ResponseItemBean<HardwareInfoBean[]>> {
        return this.get('store_manager/kiosk/' + kioskId + '/hardware/not_connected');
    }

}
