import {ProgressService} from './progress.service';
import {ɵangular_packages_common_http_http_d as ImportedXhr} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomBrowserXhr extends ImportedXhr {

    constructor(private service: ProgressService) {
        super();
    }

    build(): any {
        const xhr = super.build();
        xhr.upload.onprogress = (event) => {
            this.service.progressEventObservable.next(event);
        };
        return xhr;
    }
}
