<div class="container">
    <div class="row">
        <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form role="form" (submit)="resetPassword(  password1.value, password2.value)">
                <div class="card" data-background="color" data-color="blue">
                    <div class="card-header">
                        <h3 class="card-title">Reset Password</h3>
                    </div>
                    <div class="card-content">
                        <div class="form-group">
                            <label>Password</label>
                            <input #password1 type="password" placeholder="Password"
                                   class="form-control input-no-border">
                        </div>
                        <div class="form-group">
                            <label>Confirm Password</label>
                            <input #password2 type="password" placeholder="Password"
                                   class="form-control input-no-border">
                        </div>
                    </div>
                    <div class="card-footer text-center">
                        <button type="submit" class="btn btn-fill btn-wd ">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

