import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {ManageKioskMaintenanceOptionsBean} from '../../../../model/model';
import {Helper} from '../../../../common/helper';
import {TableComponent} from '../../../../components/table.component';
import {Column} from '../../../../common/column';
import {KioskMaintenanceOptionsEditComponent} from './kiosk-maintenance-options.edit.component';
import {MaintenanceTypeBean, StringBean} from '../../../../model/custom-model';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-maintenance-options-component',
    templateUrl: 'kiosk-maintenance-options.component.html'
})
export class KioskMaintenanceOptionsComponent implements AfterViewInit {

    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskMaintenanceOptionsEditComponent, {static: false}) modal: KioskMaintenanceOptionsEditComponent;

    fields: Column[] = [
        new Column('value', 'Text'),
        new Column('type', 'Type'),
    ];
    maintenanceOptions: ManageKioskMaintenanceOptionsBean;
    kioskId: number;

    constructor(private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {

        this.kioskId = kioskId;
        this.kioskManageService.getMaintenanceOptions(kioskId).subscribe(response => {
            if (response.success) {
                this.maintenanceOptions = response.value;
                this.updateTable();
            }
        });
    }

    onEdit(items: StringBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Maintenance Option');
        }
    }

    onRemove(value: MaintenanceTypeBean[]) {
        for (const option of value) {
            delete this.maintenanceOptions.maintenanceOptionsWithType[option.value];
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Maintenance Option');
    }

    addOption(initValue: MaintenanceTypeBean, value: MaintenanceTypeBean) {

        if (initValue.value == null) {
            this.maintenanceOptions.maintenanceOptionsWithType[value.value] = value.type;

        } else {

            if (initValue.value != value.value) {
                delete this.maintenanceOptions.maintenanceOptionsWithType[initValue.value];
            }
            this.maintenanceOptions.maintenanceOptionsWithType[value.value] = value.type;
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {

            const values = [];
            for (const key in this.maintenanceOptions.maintenanceOptionsWithType) {
                values.push({value: key, type: this.maintenanceOptions.maintenanceOptionsWithType[key]} as MaintenanceTypeBean);
            }

            this.table.setData(values, this.fields);
        }
    }

    save() {

        this.kioskManageService.setMaintenanceOptions(this.kioskId, this.maintenanceOptions).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
