import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../../components/base.modal.component';
import {FieldsListComponent} from '../../../../components/fields-list.component';
import {Field} from '../../../../common/field';
import {EntityNameBean, UserBean} from '../../../../model/model';
import {UserMessagesComponent} from './user-messages.component';
import {AppComponent} from '../../../../app.component';
import {Helper} from '../../../../common/helper';

@Component({
    selector: 'user-messages-edit-component',
    templateUrl: 'user-messages.edit.component.html'
})

export class UserMessagesEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    message: EntityNameBean = {} as EntityNameBean;

    fields: Field[] = [
        new Field('name', 'Message').require(),
    ];
    initMessage: EntityNameBean;

    constructor(@Inject(forwardRef(() => UserMessagesComponent)) private parent: UserMessagesComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    open(message: EntityNameBean, title: string) {
        this.base.show(title);
        this.message = message ? Helper.copyObject(message) : {} as UserBean;
        this.initMessage = message ? Helper.copyObject(message) : {} as UserBean;
    }

    save() {
        this.parent.addMessage(this.initMessage, this.message);
    }

}
