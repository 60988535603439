import {Component, forwardRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {AppComponent} from '../../app.component';
import {ProfileService} from '../../services/profile.service';
import {Helper} from '../../common/helper';
import {ForgotPasswordComponent} from './forgot-password.component';
import {GoogleLoginProvider, SocialAuthService} from 'angularx-social-login';
import {TokenLoginBean, UserInfoBean} from '../../model/model';
import {Rights} from '../../common/rights';

@Component({
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {

    @ViewChild(ForgotPasswordComponent, {static: false}) forgotPasswordComponent: ForgotPasswordComponent;

    constructor(public router: Router,
        private userService: ProfileService,
        private dataService: DataService,
        private authService: SocialAuthService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    login(event, username, password) {
        event.preventDefault();

        this.userService.login(username, Helper.encodePassword(password))
            .subscribe(response => {

                console.log(response);
                if (response.success) {

                    this.dataService.setUserId(response.userId);
                    this.router.navigate(['/confirmation']);

                } else {

                    this.app.showError('Can\'t login: ' + response.errorDescription);
                }
            }, error => {
                console.log(error);
                this.app.showError('Can\'t login');
            });
    }

    forgotPassword() {
        this.forgotPasswordComponent.open();
    }

    ngOnInit() {
        this.authService.authState.subscribe((user) => {
            console.log(user);
            if (user) {

                const body: TokenLoginBean = {
                    token: user.idToken,
                    provider: user.provider,
                    lastName: user.lastName,
                    firstName: user.firstName,
                    email: user.email
                };
                this.userService.loginWithToken(body).subscribe(response => {

                    console.log(response);
                    if (response.success) {
                        const userInfo = {token: response.token} as UserInfoBean;
                        this.dataService.setUserInfo(userInfo);

                        this.userService.getUserInfo().subscribe(userInfo => {

                            if (userInfo.success) {
                                if (userInfo.value.user.rights && Rights.MANAGER == Rights.MANAGER) {
                                    this.dataService.setUserId(response.userId);
                                    this.dataService.setUserInfo(userInfo.value);
                                    this.router.navigate(['/']);
                                } else {
                                    this.app.showError('Can\'t login: wrong rights');
                                }
                            } else {
                                this.app.showError('Can\'t login: ' + response.errorDescription);
                            }
                        });

                    } else {

                        this.app.showError('Can\'t login: ' + response.errorDescription);
                    }
                }, error => {
                    console.log(error);
                    this.app.showError('Can\'t login');
                });

            }
        });
    }

    signInWithGoogle() {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }
}
