<div class="animated fadeIn">
    <div class="row">

        <div class="col-md-2">
            <label class="col-form-label" for="input_min">Inactive Days:</label>
            <input
                    type="number" id="input_min"
                    class="btn btn-block" [(ngModel)]="inactiveDays"
            />
        </div>
        <div class="col-md-6">
            <label class="col-form-label" for="input_message">Message:</label>
            <input
                    type="text" id="input_message"
                    class="btn btn-block" [(ngModel)]="message"
            />
        </div>

        <div class="col-md-2">
            <label class="col-form-label">&nbsp;</label>
            <button type="button" class="btn btn-primary" style="width: 100%"
                    (click)="send()">Send
            </button>

        </div>

    </div>
</div>
