import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {KioskService} from '../../services/kiosk.service';
import {KioskDevicesComponent} from './devices/kiosk-devices.component';
import {KioskSimulatorComponent} from './simulator/kiosk-simulator.component';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {KioskDiagnosticsComponent} from './diagnostics/kiosk-diagnostics.component';
import {KioskInfoComponent} from './description/info/kiosk-info.component';
import {KioskTemplateService} from '../../services/kiosk-template.service';
import {KioskDataService} from '../../services/kiosk-data.service';
import {KioskTestsComponent} from './tests/kiosk-tests.component';
import {DeviceLogsComponent} from './device-logs/device-logs.component';
import {AppComponent} from '../../app.component';
import {KioskActionsComponent} from './actions/kiosk-actions.component';
import {KioskRecipesListsComponent} from './description/recipes-lists/kiosk-recipes-lists.component';
import {KioskRecipesComponent} from './description/recipes/kiosk-recipes.component';
import {KioskIngredientsComponent} from './description/ingredients/kiosk-ingredients.component';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {KioskManagersComponent} from './description/managers/kiosk-managers.component';
import {KioskHoursComponent} from './description/hours/kiosk-hours.component';
import {KioskCouponsComponent} from './description/coupons/kiosk-coupons.component';
import {KioskTestersComponent} from './description/testers/kiosk-testers.component';
import {KioskLogsComponent} from './kiosk-logs/kiosk-logs.component';
import {KioskMaintenanceOptionsComponent} from './description/maintenance-options/kiosk-maintenance-options.component';
import {KioskCustomRecipesComponent} from './description/custom-recipes/kiosk-custom-recipes.component';
import {KioskRewardsComponent} from './description/rewards/kiosk-rewards.component';
import {KioskBlacklistComponent} from './description/blacklist/kiosk-blacklist.component';
import {RightsGuard} from '../../common/auth.guard';
import {Rights} from '../../common/rights';
import {KioskNotificationEmailsComponent} from './description/notification-emails/kiosk-notification-emails.component';
import {KioskManageService} from '../../services/kiosk-manage.service';
import {ManageKioskDescriptionBean} from '../../model/model';
import {UserMessagesComponent} from './description/user-messages/user-messages.component';
import {KioskTabletsComponent} from './description/tablets/kiosk-tablets.component';

@Component({
    templateUrl: 'kiosk-details.component.html'
})

export class KioskDetailsComponent implements AfterViewInit {

    kioskId: number;
    operatorId: number;
    kioskLoaded: boolean;
    tab: string;

    @ViewChild(KioskInfoComponent, {static: false}) kioskDescriptionComponent: KioskInfoComponent;
    @ViewChild(KioskDevicesComponent, {static: false}) kioskDevicesComponent: KioskDevicesComponent;
    @ViewChild(KioskSimulatorComponent, {static: false}) kioskSimulatorComponent: KioskSimulatorComponent;
    @ViewChild(KioskDiagnosticsComponent, {static: false}) kioskDiagnosticsComponent: KioskDiagnosticsComponent;
    @ViewChild(KioskTestsComponent, {static: false}) kioskTestsComponent: KioskTestsComponent;
    @ViewChild(DeviceLogsComponent, {static: false}) deviceLogsComponent: DeviceLogsComponent;
    @ViewChild(KioskLogsComponent, {static: false}) kioskLogsComponent: KioskLogsComponent;
    @ViewChild(KioskRecipesListsComponent, {static: false}) kioskRecipesListsComponent: KioskRecipesListsComponent;
    @ViewChild(KioskRecipesComponent, {static: false}) kioskRecipesComponent: KioskRecipesComponent;
    @ViewChild(KioskCustomRecipesComponent, {static: false}) kioskCustomRecipesComponent: KioskCustomRecipesComponent;

    @ViewChild(KioskIngredientsComponent, {static: false}) kioskIngredientsComponent: KioskIngredientsComponent;
    @ViewChild(KioskActionsComponent, {static: false}) kioskActionsComponent: KioskActionsComponent;
    @ViewChild(KioskManagersComponent, {static: false}) kioskManagersComponent: KioskManagersComponent;
    @ViewChild(KioskTestersComponent, {static: false}) kioskTestersComponent: KioskTestersComponent;
    @ViewChild(KioskBlacklistComponent, {static: false}) kioskBlacklistComponent: KioskBlacklistComponent;

    @ViewChild(KioskMaintenanceOptionsComponent, {static: false}) kioskMaintenanceOptionsComponent: KioskMaintenanceOptionsComponent;
    @ViewChild(KioskHoursComponent, {static: false}) kioskHoursComponent: KioskHoursComponent;
    @ViewChild(KioskCouponsComponent, {static: false}) kioskCouponsComponent: KioskCouponsComponent;
    @ViewChild(KioskRewardsComponent, {static: false}) kioskRewardsComponent: KioskRewardsComponent;
    @ViewChild(KioskTabletsComponent, {static: false}) kioskTabletsComponent: KioskTabletsComponent;
    @ViewChild(KioskNotificationEmailsComponent, {static: false}) notificationEmailsComponent: KioskNotificationEmailsComponent;
    @ViewChild(UserMessagesComponent, {static: false}) userMessagesComponent: UserMessagesComponent;

    isAdmin = RightsGuard.isAdmin() || RightsGuard.isSuperAdmin();
    kioskDescription: ManageKioskDescriptionBean;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private kioskDataService: KioskDataService,
        private kioskTemplateService: KioskTemplateService,
        private kioskManageService: KioskManageService,
        private kioskService: KioskService,
        @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.kioskLoaded = false;
        this.route.queryParams.subscribe(params => {
            this.kioskId = params['kioskId'] ? params['kioskId'] : 0;
            this.operatorId = params['operatorId'] ? params['operatorId'] : 0;
            this.tab = params['tab'] ? params['tab'] : null;
            this.getKiosk();
        });
    }

    getKiosk() {
        if (this.kioskId != 0) {
            this.kioskManageService.getDescription(this.kioskId).subscribe(response => {
                if (response.success) {
                    this.kioskDescription = response.value;

                    if (this.tab == 'devices') {
                        this.onDevicesTabSelected(null);
                    } else {
                        this.onDescriptionTabSelected(null);
                    }

                    if (this.tab == 'ingredients') {
                        this.onIngredientsTabSelected(null);
                    }

                    this.kioskLoaded = true;

                    setTimeout(() => {
                        this.layout.navbarComponent.setTitle(this.kioskDescription.name);
                    }, 100);
                }
            });
        } else {
            this.kioskDescription = {} as ManageKioskDescriptionBean;
            this.onDescriptionTabSelected(null);
        }
    }

    onDevicesTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskDevicesComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onDiagnosticsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            if (this.kioskDescription != null) {
                this.kioskDiagnosticsComponent.onShow(this.kioskId, this.operatorId, this.kioskDescription.kioskModelId);
            }
        }
    }

    onSimulatorTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskSimulatorComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onTestsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            if (this.kioskDescription != null) {
                this.kioskTestsComponent.onShow(this.kioskId, this.operatorId, this.kioskDescription.kioskModelId);
            }
        }
    }

    onLogsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.deviceLogsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onKioskLogsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskLogsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onDescriptionTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskDescriptionComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onRecipesListsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskRecipesListsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onRecipesTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskRecipesComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onCustomRecipesTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskCustomRecipesComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onIngredientsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskIngredientsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onMangersTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskManagersComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onTestersTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskTestersComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onBlacklistTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskBlacklistComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onHoursTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskHoursComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onCouponsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskCouponsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onActionsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskActionsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onMaintenanceOptionsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskMaintenanceOptionsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onRewardsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskRewardsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onTabletsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskTabletsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onNotificationEmailsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.notificationEmailsComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onUserMessagesTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.userMessagesComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    viewKiosk() {
        this.router.navigate(['/kiosk-view/'], {
            queryParams: {
                kioskId: this.kioskId,
                operatorId: this.operatorId
            }
        });
    }

    save() {

        // console.log(this.kiosk);
        // if (this.kiosk.shortName == '') this.kiosk.shortName = null;
        //
        // if (!this.kioskDescriptionComponent.fieldsListComponent.valid()) {
        //
        //     this.app.showError('Please check all required fields');
        //     return;
        // }
        //
        // if (this.kiosk.id == null) {
        //
        //     this.kioskService.addKiosk(this.kiosk).subscribe(response => {
        //         if (response.success) {
        //             this.app.showNotification('Created');
        //
        //         } else {
        //             this.app.showError('Can\'t create kiosk');
        //         }
        //     }, error => {
        //         this.app.showError('Can\'t create kiosk');
        //     });
        //
        // } else {
        //
        //     this.kioskService.updateKiosk(this.kiosk).subscribe(response => {
        //         if (response.success) {
        //             this.app.showNotification('Updated');
        //
        //         } else {
        //             this.app.showError('Can\'t update kiosk');
        //         }
        //     }, error => {
        //         this.app.showError('Can\'t update kiosk');
        //     });
        // }
    }

    manageKiosksRights() {
        return RightsGuard.userAccess([Rights.MANAGE_KIOSKS]);
    };

    isBusinessOps() {
        return RightsGuard.isBusinessOps();
    }

}
