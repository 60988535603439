<nav class="navbar navbar-default navbar-fixed">
    <div class="container-fluid">
        <!--<div class="navbar-minimize">-->
        <!--<button id="minimizeSidebar" class="btn btn-fill btn-icon"><i class="ti-more-alt"></i></button>-->
        <!--</div>-->
        <div class="navbar-header">
            <button type="button" class="navbar-toggle">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar bar1"></span>
                <span class="icon-bar bar2"></span>
                <span class="icon-bar bar3"></span>
            </button>
            <a class="navbar-brand" href="#">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <!--<form class="navbar-form navbar-left navbar-search-form" role="search">-->
                <!--<div class="input-group">-->
                    <!--<span class="input-group-addon"><i class="fa fa-search"></i></span>-->
                    <!--<input type="text" value="" class="form-control" placeholder="Search...">-->
                <!--</div>-->
            <!--</form>-->
            <ul class="nav navbar-nav navbar-right">
                <li class="dropdown">
                    <a href="#notifications" class="dropdown-toggle btn-rotate" data-toggle="dropdown">
                        <i class="ti-bell"></i>
                        <span class="notification">{{messages.length}}</span>
                        <p class="hidden-md hidden-lg">
                            Notifications
                            <b class="caret"></b>
                        </p>
                    </a>
                    <ul class="dropdown-menu">

                        <li *ngFor="let message of messages">
                            <a href="#not1">
                                <h6>{{message.location}}: {{message.kiosk}}</h6>
                                <h6>{{message.device}}: {{message.errorMessage}} ({{message.errorCode}})</h6>
                                <small>{{message.date | date: 'MM/dd/yyyy hh:mm a'}}</small>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
