import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {DeviceProvisioningEditComponent} from './device-provisioning.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {DeviceService} from '../../services/device.service';
import {Helper} from '../../common/helper';
import {ActivatedRoute, Router} from '@angular/router';
import {OperatorsService} from '../../services/operators.service';
import {KioskService} from '../../services/kiosk.service';
import {Location} from '@angular/common';
import {EntityNameBean, HardwareWithProvisioningBean} from '../../model/model';
import {DeviceProvisioningTestComponent} from './device-provisioning.test.component';
import {Rights} from '../../common/rights';
import {DataService} from '../../services/data.service';

@Component({
    templateUrl: 'device-provisioning.component.html',
})

export class DeviceProvisioningComponent implements AfterViewInit {

    @ViewChild('allTableComponent', {static: false}) allTable: TableComponent;
    @ViewChild('notConnectedTableComponent', {static: false}) notConnectedTable: TableComponent;

    @ViewChild(forwardRef(() => DeviceProvisioningEditComponent), {static: false}) deviceProvisioningEditComponent: DeviceProvisioningEditComponent;
    @ViewChild(forwardRef(() => DeviceProvisioningTestComponent), {static: false}) deviceProvisioningTestComponent: DeviceProvisioningTestComponent;

    // operators: OperatorBean[] = [];
    // allOperators: OperatorBean = {} as OperatorBean;
    // selectedOperator: OperatorBean = {} as OperatorBean;
    // selectedOperatorId: number;

    kiosks: EntityNameBean[] = [];
    allKiosks: EntityNameBean = {id: 0} as EntityNameBean;
    selectedKiosk: EntityNameBean = {id: 0} as EntityNameBean;
    selectedKioskId: number;
    compareBeans = Helper.compareBeans;

    fieldsNotConnected: Column[] = [
        new Column('name', 'Name'),
        new Column('mac', 'Mac').hide(),
        new Column('ip', 'Ip'),
        new Column('softwareVersion', 'Version').hide(),
        new Column('locationName', 'Operator').hide(),
        new Column('kioskName', 'Kiosk').hide(),
        new Column('lastSeen', 'Last Seen').date(),
        new Column('alive', 'Alive').checkbox(),
        new Column('kioskService', 'Kiosk Service').checkbox(),
    ];

    fields: Column[] = [
        new Column('name', 'Name'),
        new Column('mac', 'Mac'),
        new Column('ip', 'Ip'),
        new Column('softwareVersion', 'Version'),
        new Column('locationName', 'Operator'),
        new Column('kioskName', 'Kiosk'),
        new Column('lastSeen', 'Last Seen').date(),
        new Column('devices', 'Devices').data(this, this.getDevices),
    ];

    private allTabSelected: boolean;

    constructor(private deviceService: DeviceService,
        private operatorsService: OperatorsService,
        private kioskService: KioskService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private dataService: DataService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.allTabSelected = true;
        this.route.queryParams.subscribe(params => {
            // this.selectedOperatorId = params['location'] != null ? params['location'] : 0;
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;
            this.onNotConnectedTabSelected(null);
        });
    }

    onOperatorChange() {
        this.loadKiosks();
        this.updateUrl();
    }

    loadKiosks() {

        this.kioskService.getKiosksNames().subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks) {

        this.kiosks = kiosks;

        if (this.selectedKioskId == 0) {
            this.selectedKiosk = this.allKiosks;
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.id == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }

        Helper.selectPicker('select_kiosk');
        this.updateUrl();
        this.loadAllDevices();
    }

    onKioskChange() {
        this.loadAllDevices();
        this.updateUrl();
    }

    getDevices(dh: HardwareWithProvisioningBean) {

        if (dh.kioskService) {
            return 'Kiosk Service';
        }
        let result: string = '';
        if (dh.devices) {
            for (const device of dh.devices) {
                result += (device.name + ', ');
            }
        }
        if (result.length > 2) {
            result = result.slice(0, -2);
        }
        return result;
    }

    loadAllDevices() {
        // let locationId = (this.selectedOperator == this.allOperators ? 0 : this.selectedOperator.id);
        const kioskId = (this.selectedKiosk == this.allKiosks ? 0 : this.selectedKiosk.id);

        this.deviceService.getDevicesHardware(kioskId).subscribe(devices => {
            console.log(devices);
            if (devices) {
                this.allTable.setData(devices.list, this.fields);
            }
        });
    }

    loadNotConnectedDevices() {
        this.deviceService.getNotConnectedDevicesHardware().subscribe(devices => {
            if (this.notConnectedTable && devices) {
                this.notConnectedTable.setData(devices.list, this.fieldsNotConnected);
            }
        });
    }

    canEditDevices() {
        return this.dataService.getUserInfo().user.rights != Rights.MANAGE_HW_TESTS;
    }

    onEdit(items: HardwareWithProvisioningBean[]) {

        if (this.canEditDevices()) {

            if (items && items.length > 0) {
                this.deviceProvisioningEditComponent.open(items[0], 'Edit ' + items[0].name);
            }
        } else {
            this.app.showError('Permission denied');
        }
    }

    onView(items: HardwareWithProvisioningBean[]) {
        if (items && items.length > 0) {
            this.deviceProvisioningTestComponent.open(items[0], 'Test ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const deviceTemplate of items) {

            this.deviceService.deleteDeviceHardware(deviceTemplate).subscribe(response => {
                if (response.success) {
                    if (deviceTemplate == items.pop()) {
                        this.reload();
                    }

                } else {
                    this.app.showError('Can\'t delete Device Hardware');
                }
            }, error => {
                this.app.showError('Can\'t delete Device Hardware');
            });
        }
    }

    onAdd() {
        this.deviceProvisioningEditComponent.open(null, 'New Device Hardware');
    }

    reload() {
        if (this.allTabSelected) {
            this.loadAllDevices();
        } else {
            this.loadNotConnectedDevices();
        }
    }

    onAllTabSelected(event) {
        if (!this.allTabSelected) {
            this.allTabSelected = true;
            this.loadKiosks();
        }
    }

    onNotConnectedTabSelected(event) {
        if (this.allTabSelected) {
            this.allTabSelected = false;
            this.loadNotConnectedDevices();
        }
    }

    private updateUrl() {
        let path = '/device-provisioning';

        if (this.selectedKiosk != this.allKiosks) {
            path += '?kiosk=' + this.selectedKiosk.id;
        }

        this.location.replaceState('' + path);
    }

}
