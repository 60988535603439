<div class="animated fadeIn">
    <div class="col-md-12">

        <div *ngIf="paymentCredentials">

            <fields-list-component #paymentCredentialsFieldsListComponent [fields]="fields" name="kiosk_description" [value]="paymentCredentials"></fields-list-component>

            <button type="button" class="btn btn-outline-primary  pull-right"
                    (click)="save()">Save
            </button>
        </div>
    </div>
</div>