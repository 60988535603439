<base-modal-component class="base-modal">

    <table class="table table-striped">
        <thead>
        <tr>
            <th>Total Cycles</th>
            <th>Success</th>
            <th>Failure</th>
            <th>Min Cycle Time</th>
            <th>Max Cycle Time</th>
            <th>Avg Cycle Time</th>
            <th>Min Dispense Variance</th>
            <th>Max Dispense Variance</th>
            <th>Avg Dispense Variance</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>{{test.totalCycles}}</td>
            <td>{{test.successNumber}}</td>
            <td>{{test.failuresNumber}}</td>
            <td>{{test.minCycleTimeMs}}</td>
            <td>{{test.maxCycleTimeMs}}</td>
            <td>{{test.avgCycleTimeMs}}</td>
            <td>{{test.minDispenseVariance}}</td>
            <td>{{test.maxDispenseVariance}}</td>
            <td>{{test.avgDispenseVariance}}</td>
        </tr>
        </tbody>
    </table>

    <table class="table table-striped">
        <thead>
        <tr>
            <th>Cycle</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Dispense Requsted</th>
            <th>Dispense Amounts</th>
            <th>Dispense Time</th>
            <th>Success</th>
            <th>Details</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let result of results">
            <td>{{result.cycle}}</td>
            <td>{{result.startDate | los_angeles: 'MM/dd/yyyy hh:mm a'}}</td>
            <td>{{result.endDate | los_angeles: 'MM/dd/yyyy hh:mm a'}}</td>
            <td>{{result.dispenseAmountsRequested}}</td>
            <td>{{result.dispensedAmounts}}</td>
            <td>{{result.timeToDispenseMs}}</td>
            <td><input disabled type="checkbox" [checked]="result.success"/></td>
            <td>
                <div class="table-icons"><a rel="tooltip" title="View" class="btn btn-simple btn-icon table-action btn-info view"
                                            (click)="openCycleDetails(result)"><i class="ti-image"></i></a></div>
            </td>
        </tr>
        </tbody>
    </table>


</base-modal-component>
