import {AfterViewInit, Component} from '@angular/core';
import '../../../common/fabric';
import {DeviceModelBean, KioskModelBean, KioskTemplateBean} from '../../../model/model';

@Component({
    selector: 'model-presentation-component',
    templateUrl: 'model-presentation.component.html'
})
export class ModelPresentationComponent implements AfterViewInit {

    canvas: fabric.Canvas;
    model: KioskModelBean;
    template: KioskTemplateBean;

    scale: number = 768.0 / 698.0;
    width: number;
    height: number;

    constructor() {
    }

    ngAfterViewInit(): void {
        this.canvas = new fabric.Canvas('kioskCanvas', {
            hoverCursor: 'pointer',
            selection: false,
        });
    }

    onShow(model: KioskModelBean, template: KioskTemplateBean) {

        this.model = model;
        this.template = template;
        this.initializePresentation();
    }

    initializePresentation() {

        this.canvas.clear();

        const img = new Image();
        img.onload = () => {

            this.width = img.width;
            this.height = img.height;

            const rect = {width: img.width * this.scale, height: img.height * this.scale};
            this.canvas.setBackgroundImage(img.src, this.canvas.renderAll.bind(this.canvas), rect);
            this.canvas.setDimensions(rect);

            for (const device of this.model.devices) {
                this.addDevice(device);
            }

            this.startDevicesWaiter();
        };
        img.src = this.template.bgImage;

    }

    addDevice(device: DeviceModelBean) {

        fabric.Image.fromURL(device.deviceTemplate.image1, img => {

            const dx = (this.width * this.scale - this.width) / 2;
            const dy = (this.height * this.scale - this.height) / 2;

            const x = this.width / 2 + device.x - this.template.x0 + dx;
            const y = this.height / 2 - device.y - this.template.y0 + dy;

            img.set({
                hasControls: false,
                evented: false,
                originY: 'center',
                originX: 'center',
                left: x,
                top: y,
            });

            device['view'] = img;
        });
    }

    startDevicesWaiter() {
        const waiter = setInterval(() => {

            for (const device of this.model.devices) {
                if (!device['view']) {
                    return;
                }
            }

            this.model.devices.sort((a: DeviceModelBean, b: DeviceModelBean) => {
                return b['view'].left - a['view'].left;
            });

            this.model.devices.forEach(item => {
                this.canvas.add(item['view']);
            });

            clearInterval(waiter);
        }, 100
        );
    }

}
