import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {ClientConfigBean, DocsBean, ResponseBean, ResponseItemBean, SystemConfigurationBean} from '../model/model';

@Injectable()
export class ConfigurationService extends BaseRequestService {

    getClientConfig(): Observable<ResponseItemBean<ClientConfigBean>> {
        return this.get('admin/client/config');
    }

    updateClientConfig(config: ClientConfigBean): Observable<ResponseBean> {
        return this.put('admin/client/config/update', config);
    }

    getSystemConfig(): Observable<ResponseItemBean<SystemConfigurationBean>> {
        return this.get('admin/system/config');
    }

    updateSystemConfig(config: SystemConfigurationBean): Observable<ResponseBean> {
        return this.put('admin/system/config/update', config);
    }

    getDocumentation(): Observable<ResponseItemBean<DocsBean>> {
        return this.get('admin/docs');
    }

    updateDocumentation(docs: DocsBean): Observable<ResponseBean> {
        return this.put('admin/docs', docs);
    }

}
