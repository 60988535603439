<base-modal-component (onConfirm)="save()"  [extraLarge]="true" [validation]="isValid()" class="base-modal">
    <tabset>
        <tab heading="Description">
            <fields-list-component [fields]="fields" name="categories_edit" [value]="category"></fields-list-component>
        </tab>
        <tab heading="Steps">
            <tabset>
                <tab heading='Preparation'>
                    <steps-list-component [type]="'PREPARATION'" #steps1
                                          [stepPhases]="category.stepPhases"
                                          (onCreateStepClicked)="createStep($event)"
                                          (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                </tab>
                <tab heading='Cooking'>
                    <steps-list-component [type]="'COOKING'" #steps2
                                          [stepPhases]="category.stepPhases"
                                          (onCreateStepClicked)="createStep($event)"
                                          (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                </tab>
                <tab heading='Serving'>
                    <steps-list-component [type]="'SERVING'" #steps3
                                          [stepPhases]="category.stepPhases"
                                          (onCreateStepClicked)="createStep($event)"
                                          (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                </tab>
            </tabset>
        </tab>
        <tab heading="Notification Emails"   (selectTab)="onChangeTabSelected($event)" >
            <category-notification-emails-component></category-notification-emails-component>
        </tab>
    </tabset>
</base-modal-component>
