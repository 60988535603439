<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <fields-list-component #fieldsListComponent [fields]="fields" name="diag_step_seq_edit" [value]="stepsSequence"></fields-list-component>

    <table-component #diagnosticsStepsTable
                     [buttons]="priorityButtons"
                     (onEdit)="onDiagnosticStepEdit($event)"
                     (onRemove)="onDiagnosticStepRemove($event)"
                     (onAdd)="onDiagnosticStepAdd()"></table-component>

</base-modal-component>
