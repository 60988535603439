<base-modal-component (onConfirm)="edit()" [confirmButtonHidden]="true" [validation]="isValid()" class="base-modal">

    <fields-list-component [fields]="fields" name="kiosks_quickview"
                           [value]="kiosk"></fields-list-component>

    <div *ngIf="kiosk.id" class="row">

        <div class="col-lg-4 col-sm-6">
            <div class="card grayish">
                <div class="card-content">
                    <h5>Orders</h5>
                    <div *ngFor="let state of kiosk.orderStateCount | dictionaryValues">
                        <a [routerLink]="['/orders']"
                           [queryParams]="{location: kiosk.operatorId, kiosk: kiosk.id, states: state.key}">
                            {{state.key}}: {{state.value}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6">
            <div class="card grayish">
                <div class="card-content">
                    <h5>Ingredients</h5>
                    <div *ngFor="let state of kiosk.dispenserStateCount | dictionaryValues">
                        <a [routerLink]="['/kiosk-details']"
                           [queryParams]="{operatorId: kiosk.operatorId, kioskId: kiosk.id, tab: 'ingredients'}">
                            {{state.key}}: {{state.value}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6">
            <div class="card grayish">
                <div class="card-content">
                    <h5>Devices</h5>
                    <div *ngFor="let state of kiosk.deviceStateCount | dictionaryValues">
                        <a [routerLink]="['/kiosk-details']"
                           [queryParams]="{operatorId: kiosk.operatorId, kioskId: kiosk.id, tab: 'devices'}">
                            {{state.key}}: {{state.value}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</base-modal-component>
