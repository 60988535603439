import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {DeviceTemplatesComponent} from './device-templates.component';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {DeviceTemplateService} from '../../services/device-template.service';
import {AbstractDeviceBean, DeviceTemplateBean, HardwareVersionBean} from '../../model/model';

@Component({
    selector: 'device-templates-edit-component',
    templateUrl: 'device-templates.edit.component.html'
})

export class DeviceTemplatesEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    deviceTemplate: DeviceTemplateBean = {} as DeviceTemplateBean;

    fields: Field[] = [
        new Field('id', 'Id').disable(),
        new Field('name', 'Name').require(),
        new Field('image1', 'Image', FieldType.FILE),
        // new Field('image2', 'Image 2', FieldType.FILE),
        new Field('x0', 'Center X', FieldType.NUMBER),
        new Field('y0', 'Center Y', FieldType.NUMBER),
        new Field('a', 'Length', FieldType.NUMBER),
        new Field('b', 'Width', FieldType.NUMBER),
        new Field('c', 'Height', FieldType.NUMBER),

    ];
    devices: AbstractDeviceBean[];
    selectedAbstractDeviceBean: AbstractDeviceBean;
    compareBeans = Helper.compareBeans;

    hwVersion: string;
    hwDescription: string;

    isValid() {
        return () => {
            return this.fieldsListComponent && this.selectedAbstractDeviceBean && this.fieldsListComponent.valid();
        };
    }

    constructor(private deviceTemplateService: DeviceTemplateService,
        @Inject(forwardRef(() => DeviceTemplatesComponent)) private parent: DeviceTemplatesComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(deviceTemplate: DeviceTemplateBean, devices: AbstractDeviceBean[], title: string) {
        this.devices = devices;
        this.deviceTemplate = (deviceTemplate ? Helper.copyObject(deviceTemplate) : {} as DeviceTemplateBean);
        this.selectedAbstractDeviceBean = this.deviceTemplate.abstractDevice;
        this.base.show(title);
    }

    save() {

        this.deviceTemplate.abstractDevice = this.selectedAbstractDeviceBean;

        if (this.deviceTemplate.id == null) {

            this.deviceTemplateService.addDeviceTemplate(this.deviceTemplate).subscribe(response => {

                if (response.success) {
                    this.parent.loadDeviceTemplates();
                } else {
                    this.app.showError('Can\'t create Device Template');
                }
            }, error => {
                this.app.showError('Can\'t create Device Template');
            });

        } else {

            this.deviceTemplateService.updateDeviceTemplate(this.deviceTemplate).subscribe(response => {

                if (response.success) {
                    this.parent.loadDeviceTemplates();
                } else {
                    this.app.showError('Can\'t update Device Template');
                }
            }, error => {
                this.app.showError('Can\'t update Device Template');
            });
        }
    }

    addHardwareVersion() {
        if (this.hwVersion.length == 0) {
            return;
        }

        const hw = {} as HardwareVersionBean;
        hw.version = this.hwVersion;
        hw.description = this.hwDescription;
        this.deviceTemplate.hwVersions.push(hw);

        this.hwVersion = this.hwDescription = '';
    }

    removeHardwareVersion(hw: HardwareVersionBean) {
        const index = this.deviceTemplate.hwVersions.indexOf(hw);
        if (index >= 0) {
            this.deviceTemplate.hwVersions.splice(index, 1);
        }
    }

}
