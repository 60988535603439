<div class="animated fadeIn">
    <div class="col-md-12">
        <div class="card">
            <div class="card-content">

                <div class="row">
                    <div class="col-md-8">

                    </div>
                    <div class="col-md-4">

                    </div>
                </div>

                <table-component (dataFunction)="getData($event, null)" (onSearch)="getData(null, $event)" ></table-component>
            </div>
        </div>
    </div>
</div>