import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {DiagnosticsStepService} from '../../../services/diagnosics-step.service';
import {AppComponent} from '../../../app.component';
import {KioskDiagnosticsRunStepComponent} from './kiosk-diagnostics-run-step.component';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {KioskDiagnosticsOrdersComponent} from './kiosk-diagnostics-orders.component';
import {KioskManageService} from '../../../services/kiosk-manage.service';
import {KioskInfoBean, ManageKioskDiagnosticStatesBean} from '../../../model/model';

@Component({
    selector: 'kiosk-diagnostics-component',
    templateUrl: 'kiosk-diagnostics.component.html'
})
export class KioskDiagnosticsComponent implements AfterViewInit {

    @ViewChild(KioskDiagnosticsRunStepComponent, {static: false}) kioskDiagnosticsRunStepComponent: KioskDiagnosticsRunStepComponent;
    @ViewChild(KioskDiagnosticsOrdersComponent, {static: false}) kioskDiagnosticsOrdersComponent: KioskDiagnosticsOrdersComponent;
    kioskId: number;
    operatorId: number;
    diagnosticsStates: ManageKioskDiagnosticStatesBean;
    modelId: number;

    constructor(private diagnosticsStepService: DiagnosticsStepService,
        private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number, modelId: number) {
        this.kioskId = kioskId;
        this.operatorId = operatorId;
        this.modelId = modelId;
        this.onOrdersTabSelected(null);

        this.kioskManageService.getDiagnosticStates(this.kioskId).subscribe(response => {
            if (response.success) {
                this.diagnosticsStates = response.value;
            }
        });
    }

    saveDiagnosticStates() {
        this.kioskManageService.setDiagnosticStates(this.kioskId, this.diagnosticsStates).subscribe(response => {
            console.log(response);
            if (response.success) {
                this.app.showNotification('Updated');
            } else {
                this.app.showError('Can\'t update states');
            }
        }, error => {
            this.app.showError('Can\'t update states');
        });
    }

    onOrdersTabSelected(ev) {

        if (!ev || ev instanceof TabDirective) {
            this.kioskDiagnosticsOrdersComponent.onShow(this.kioskId);
        }
    }

    onRunStepTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskDiagnosticsRunStepComponent.onShow(this.kioskId, this.operatorId, this.modelId);
        }
    }
}
