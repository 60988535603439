import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {UserBean} from '../../../../model/model';
import {Helper} from '../../../../common/helper';
import {TableComponent} from '../../../../components/table.component';
import {Column} from '../../../../common/column';
import {KioskNotificationEmailsEditComponent} from './kiosk-notification-emails.edit.component';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-notification-emails-component',
    templateUrl: 'kiosk-notification-emails.component.html'
})
export class KioskNotificationEmailsComponent implements AfterViewInit {

    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskNotificationEmailsEditComponent, {static: false}) modal: KioskNotificationEmailsEditComponent;

    fields: Column[] = [
        new Column('email', 'Email'),
    ];
    notificationEmails: string[];
    kioskId: number;

    constructor(private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {

        this.kioskId = kioskId;

        if (!this.notificationEmails) {
            this.kioskManageService.getNotificationEmails(kioskId).subscribe(response => {
                if (response.success) {
                    this.notificationEmails = response.value;
                    this.updateTable();
                }
            });
        }
    }

    onEdit(items: UserBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Email');
        }
    }

    onRemove(items) {

        for (const step of items) {
            const index = this.notificationEmails.indexOf(step.email);
            this.notificationEmails.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Email');
    }

    addUser(initUser: UserBean, user: UserBean) {

        if (this.notificationEmails == null) {
            this.notificationEmails = [];
        }
        if (initUser.email == null) {
            this.notificationEmails.push(user.email);

        } else {

            let ind = -1;
            let i = 0;
            for (const email of this.notificationEmails) {
                if (email == initUser.email) {
                    ind = i;
                }
                i++;
            }

            if (ind >= 0) {
                this.notificationEmails.splice(ind, 1);
                this.notificationEmails.splice(ind, 0, user.email);
            }
        }

        this.updateTable();
    }

    updateTable() {
        const users: UserBean[] = [];
        if (this.notificationEmails) {

            for (const email of this.notificationEmails) {
                const user: UserBean = {} as UserBean;
                user.email = email;
                users.push(user);
            }
        }

        this.table.setData(users, this.fields);
    }

    save() {

        this.kioskManageService.setNotificationEmails(this.kioskId, this.notificationEmails).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
