import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseRequestService} from './base-request.service';
import {PromotionBean, ResponseBean, ResponseItemBean} from '../model/model';

@Injectable()
export class PromotionsService extends BaseRequestService {

    getCategories(): Observable<ResponseItemBean<Array<string[]>>> {
        return this.get('admin/promotions/categories');
    }

    getPromotions(kioskId: number, state: string = null): Observable<ResponseItemBean<PromotionBean[]>> {
        let p = [];
        if (kioskId != null) {
            p['kioskId'] = kioskId;
        }
        if (state != null && state != '') {
            p['state'] = state.split(",");
        }
        return this.get('admin/promotions/list', p);
    }

    addPromotion(promotionBean: PromotionBean): Observable<ResponseBean> {
        return this.post('admin/promotions', promotionBean);
    }

    updatePromotion(promotionBean: PromotionBean): Observable<ResponseBean> {
        return this.put('admin/promotions/' + promotionBean.id + '/update', promotionBean);
    }

}
