import {JarView} from './jar.view';
import {Helper} from '../common/helper';
import {DeviceView} from './device.view';
import {DeviceModelViewBean} from '../model/custom-model';

const resourcesPath = '../assets/img/presentation/';

export class CupView extends DeviceView {
    private cupContent: fabric.Image;
    private canvas: fabric.Canvas;
    orderId: number;
    group: fabric.Group;
    private deviceModel: DeviceModelViewBean;

    constructor(orderId: number, place: [number, number], canvas: fabric.Canvas, callback?: (objects: fabric.Object[]) => any) {

        super();
        this.canvas = canvas;
        this.orderId = orderId;

        fabric.Image.fromURL(resourcesPath + 'Cup.png', img => {

            img.set({
                hasControls: false,
                evented: false,
                originY: 'center',
                originX: 'center',
                left: 0,
                top: -img.height,
            });

            fabric.Image.fromURL(resourcesPath + 'Cup_Content.png', cupContent => {

                cupContent.set({
                    originY: 'bottom',
                    originX: 'center',
                    top: 20,
                    left: 0,
                    visible: false
                });

                this.cupContent = cupContent;

                this.group = new fabric.Group([img], {
                    left: place[0],
                    top: place[1],
                    originY: 'center',
                    originX: 'center',
                    hasControls: false,
                    evented: false,
                    width: img.width,
                    height: img.height
                });

                this.group.add(this.cupContent);

                canvas.add(this.group);
                callback.call(this, [this.group]);
            });

        });
    }

    dispense(animationDuration: number) {
        this.group.animate({top: this.group.get('top') + 0, left: this.group.get('left') + 300}, {
            duration: animationDuration,
            onChange: this.canvas.renderAll.bind(this.canvas)
        });
    }

    fill(jar: JarView, animationDuration) {

        this.cupContent.set('visible', true);
        this.cupContent.set('height', 0);
        this.cupContent.set('width', 27);

        if (jar.avColor) {
            this.cupContent.filters = [];
            this.cupContent.filters.push(new fabric.Image.filters.Tint({
                color: Helper.rgbToHex(Math.round(jar.avColor.r), Math.round(jar.avColor.g), Math.round(jar.avColor.b)),
                opacity: 0.9
            }));
            this.cupContent.applyFilters(this.canvas.renderAll.bind(this.canvas));
            this.cupContent.animate({height: 49, width: 38}, {
                duration: animationDuration,
                onChange: this.canvas.renderAll.bind(this.canvas)
            });

        }
    }

    setDeviceModel(cupModel: DeviceModelViewBean) {
        this.deviceModel = cupModel;
    }

    getDeviceModel() {
        return this.deviceModel;
    }

}
