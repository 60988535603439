import {AfterViewInit, Component, forwardRef, Inject, OnDestroy} from '@angular/core';
import {KioskBean, KioskState} from '../../model/model';
import {SafeResourceUrl} from '@angular/platform-browser';
import {AppSettings} from '../../app.settings';
import {DataService} from '../../services/data.service';
import {DeviceSnapshot, IngredientSnapshot, KioskNOC, OrderSnapshot, OrderStepsNocSnapshot} from '../../model/custom-model';
import {KioskService} from '../../services/kiosk.service';
import {AppComponent} from '../../app.component';
import {Helper} from '../../common/helper';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {OrderService} from '../../services/order.service';

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

@Component({
    selector: 'kiosk-noc-component',
    templateUrl: 'kiosk-noc.component.html'
})
export class KioskNocComponent implements AfterViewInit, OnDestroy {

    getIngredientPercent = KioskNocComponent.getIngredientPercent;
    url: SafeResourceUrl;
    channel: any;
    kioskNOC: KioskNOC;
    kioskState: KioskState;
    now: number;
    kioskId: number;
    operatorId: number;
    steps: OrderStepsNocSnapshot;
    lastStepsChangeTime: number;

    constructor(private dataService: DataService,
        private route: ActivatedRoute,
        private router: Router,
        private kioskService: KioskService,
        private orderService: OrderService,
        private coolDialogs: NgxCoolDialogsService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    static getIngredientPercent(ingredient: IngredientSnapshot) {
        return ingredient.current_amount <= 0 ? 0 : ingredient.current_amount / ingredient.max_amount * 100;
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.kioskId = params['kioskId'] != null ? params['kioskId'] : 0;
            this.operatorId = params['operatorId'] != null ? params['operatorId'] : 0;
            this.initFirebase();
        });
    }

    initFirebase() {
        if (!firebase.apps.length) {
            firebase.initializeApp(AppSettings.getFirebaseConfig());
        }

        firebase.auth().signInWithEmailAndPassword(AppSettings.FB_USER, AppSettings.FB_PASSWORD).then(response => {
            this.channel = firebase.database()
                .ref('company')
                .child('' + this.dataService.getCompanyId())
                .child('kiosk')
                .child('' + this.kioskId);

            this.channel.on('value', data => {

                this.kioskNOC = data.val();
                this.now = (new Date()).getTime();
                this.kioskState = this.kioskNOC.kiosk_state.value;

                if (this.kioskNOC.order_steps_noc != this.steps) {
                    this.lastStepsChangeTime = (new Date()).getTime();
                    this.steps = this.kioskNOC.order_steps_noc;
                }

                if (this.kioskNOC.devices_snapshot) {

                    this.kioskNOC.devices_snapshot.value.devices.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                }

                if (this.kioskNOC.orders) {
                    let sorted = Object.keys(this.kioskNOC.orders)
                        .sort((a, b) => {
                            if (!this.kioskNOC.orders[a].value.start_date && this.kioskNOC.orders[b].value.start_date) {
                                return 1;
                            }
                            if (!this.kioskNOC.orders[a].value.start_date && !this.kioskNOC.orders[b].value.start_date) {
                                return this.kioskNOC.orders[a].value.state_change_date - this.kioskNOC.orders[b].value.state_change_date;
                            }

                            return this.kioskNOC.orders[a].value.start_date - this.kioskNOC.orders[b].value.start_date;
                        })
                        .reduce((acc, key) => ({
                            ...acc, [key]: this.kioskNOC.orders[key]
                        }), {});
                }

            });
        }, error => {
            console.log(error);
        });
    }

    getOrderForJar(device: DeviceSnapshot) {
        if (this.kioskNOC.orders) {
            for (const key in this.kioskNOC.orders) {
                const order = this.kioskNOC.orders[key].value as OrderSnapshot;
                if (order.jar_name == device.name) {
                    return '#' + order.id + ' ' + (order.jar_place ? order.jar_place : '');
                }
            }
        }
    }

    ngOnDestroy() {
        try {
            firebase.app().delete();
        } catch (error) {
        }
    }

    getLastIngredientStep(device: DeviceSnapshot, ingredient: IngredientSnapshot) {
        if (this.kioskNOC && this.kioskNOC.order_steps_noc) {

            const orders = this.kioskNOC.order_steps_noc.value;
            for (const id in orders) {
                if (this.isIterable(orders[id].steps)) {
                    for (const step of orders[id].steps) {
                        if (step && step.device_name == device.name && step.state != 'WAITING') {
                            if (step.process.indexOf(ingredient.name) > 0) {
                                let process = step.process.replace(device.name, '').replace(' -> ', '');
                                if (step.dispensed_weight) {
                                    process += ' Response {' + step.dispensed_weight + '}';
                                }
                                return process;
                            }
                        }
                    }
                }
            }
        }
        return device.invalid ? 'INVALID' : device.state;
    }

    getLastOrderStep(order: OrderSnapshot) {
        let result;
        if (this.kioskNOC && this.kioskNOC.order_steps_noc) {

            const orders = this.kioskNOC.order_steps_noc.value;
            if (orders[order.id]) {
                if (this.isIterable(orders[order.id].steps)) {
                    for (const step of orders[order.id].steps) {
                        if (step && step.state != 'WAITING') {
                            result = step.process;
                        }
                    }
                }
            }
        }
        return result;
    }

    getRunningStep(device: DeviceSnapshot) {
        if (this.kioskNOC && this.kioskNOC.order_steps_noc) {

            const orders = this.kioskNOC.order_steps_noc.value;
            for (const id in orders) {
                if (this.isIterable(orders[id].steps)) {
                    for (const step of orders[id].steps) {
                        if (step && step.device_name == device.name && step.state == 'RUNNING') {
                            return step.process.replace(device.name, '').replace(' -> ', '');
                        }
                    }
                }
            }
        }
        return '';
    }

    isIterable(obj) {
        return obj && typeof obj[Symbol.iterator] === 'function';
    }

    refill(deviceId: number) {
        this.kioskService.refillIngredient(this.kioskId, this.operatorId, deviceId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Refilled');

            } else {
                this.app.showError('Can\'t refill');
            }
        }, error => {
            this.app.showError('Can\'t refill');
        });
    }

    unlock(deviceId: number) {
        this.kioskService.unlockDevice(this.kioskId, deviceId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Unlocked');

            } else {
                this.app.showError('Can\'t unlock');
            }
        }, error => {
            this.app.showError('Can\'t unlock');
        });
    }

    isRunning(): boolean {
        if (this.kioskNOC && this.kioskNOC.orders) {

            for (const id in this.kioskNOC.orders) {
                const now = (new Date()).getTime();
                return (now - this.lastStepsChangeTime) < 60000;
            }
        }

        return false;
    }

    setDiagnosticsMode(enabled) {

        this.kioskService.setDiagnosticsState(this.kioskId, this.operatorId, enabled).subscribe(response => {
            if (response.success) {
            } else {
                this.app.showError('Can\'t change kiosk state');
            }
        });
    }

    restart(device: DeviceSnapshot) {

        this.coolDialogs.confirm('Are you sure you want to restart ' + device.name + '?', {
            okButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        })
            .subscribe(res => {
                if (res) {
                    this.kioskService.executeShellCommand(this.kioskId, device.id, 'restart').subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('Restart scheduled');

                        } else {
                            this.app.showError('Can\'t execute command');
                        }
                    }, error => {
                        this.app.showError('Can\'t execute command');
                    });
                } else {
                }
            });

    }

    pickup(id: number) {
        this.orderService.pickup(id).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Picked up');
            } else {
                this.app.showError('Pickup failed');
            }
        }, error => {
            this.app.showError('Pickup failed');
        });
    }

    setIngredientQuantity(device: DeviceSnapshot, ingredient: IngredientSnapshot) {
        let value = this.getIngredientPercent(ingredient) ? this.getIngredientPercent(ingredient) : 0;

        this.coolDialogs.prompt('', {
            okButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            defaultText: '' + value,
            title: 'Enter new ingredient quantity value for ' + device.name + ' (' + ingredient.name + ')'
        })
            .subscribe(res => {
                if (res['result']) {

                    this.kioskService.updateIngredientPercent(this.kioskId, device.id, ingredient.id, res['value']).subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('Updated');
                        } else {
                            this.app.showError('Update failed');
                        }
                    }, error => {
                        this.app.showError('Update failed');
                    });

                } else {
                }
            });
    }
}
