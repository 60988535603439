import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../../components/base.modal.component';
import {FieldsListComponent} from '../../../../components/fields-list.component';
import {Field, FieldType} from '../../../../common/field';
import {AppComponent} from '../../../../app.component';
import {Helper} from '../../../../common/helper';
import {KioskTabletsComponent} from './kiosk-tablets.component';
import {KioskTabletBean} from '../../../../model/model';

@Component({
    selector: 'kiosk-tablets-edit-component',
    templateUrl: 'kiosk-tablets.edit.component.html'
})

export class KioskTabletsEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    value: KioskTabletBean = {} as KioskTabletBean;

    fields: Field[] = [
        new Field('name', 'Name').require(),
        new Field('allowTestOrders', 'Allow Test Orders', FieldType.CHECKBOX),
    ];
    initValue: KioskTabletBean;

    constructor(@Inject(forwardRef(() => KioskTabletsComponent)) private parent: KioskTabletsComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    open(value: KioskTabletBean, title: string) {
        this.base.show(title);
        this.value = value ? Helper.copyObject(value) : {} as KioskTabletBean;
        this.initValue = value ? Helper.copyObject(value) : {} as KioskTabletBean;
    }

    save() {
        this.parent.addOption(this.initValue, this.value);
    }

}
