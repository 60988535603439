<div class="animated fadeIn">

    <div class="col-md-12">
        <select hidden
                id="selectpicker"
                data-style="select-btn"
                title="Select Category"
                data-size="7"
                [compareWith]="compareBeans"
                [(ngModel)]="selectedCategory"
                (change)="onCategoryChange()">
            <option [ngValue]="category" selected="selected"
                    *ngFor="let category of categories">{{category.name}}
            </option>
        </select>

    </div>

    <div class="row">
        <label class="col-md-3 control-label">Filter by</label>
        <div class="col-md-9">

            <div class="radio">
                <input (change)="reviewChange($event)" [(ngModel)]="customRecipeState" [value]="'ALL'" checked=""
                       id="radio1" name="radio1"
                       type="radio"
                       value="option1">
                <label for="radio1">
                    All Custom Recipes
                </label>
            </div>

            <div class="radio">
                <input (change)="reviewChange($event)" [(ngModel)]="customRecipeState" [value]="'REVIEW'" id="radio2"
                       name="radio1"
                       type="radio" value="option2">
                <label for="radio2">
                    Review Required
                </label>
            </div>

            <div class="radio">
                <input (change)="reviewChange($event)" [(ngModel)]="customRecipeState" [value]="'PUBLISHED'" id="radio3"
                       name="radio1"
                       type="radio" value="option2">
                <label for="radio3">
                    Published
                </label>
            </div>

        </div>
    </div>


    <div class="card">
        <div class="card-content">
            <table-component (dataFunction)="getData($event)" (onEdit)="onEdit($event)"
                             (onRemove)="onRemove($event)"></table-component>
        </div>
    </div>
</div>

<custom-recipes-edit-component></custom-recipes-edit-component>
