export class Column {
    public title: string;
    public field: string;
    public subfield: string;
    public concatFields: string[] = [];
    public isThumbnail: boolean;
    public isCheckbox: boolean;
    public isDecimal: boolean;
    public isDate: boolean;
    public isDateTime: boolean;
    public isShortDate: boolean;
    public isHidden: boolean;
    public isTime: boolean;
    public isButton: boolean;
    public isShortTime: boolean;
    public isDuration: boolean;

    public enumType: {[p: number]: string};
    public dataObject: object;
    public dataFunction: (bean: any) => any;
    public thumbnailSize: number;
    public thumbnailRotated: boolean;

    public clickObject: object;
    public clickFunction: (bean: any) => any;
    public serverThumbnail: boolean;
    public decimalCount: number;
    public thumbnailCaption: string;
    public thumbnailCaptionDate: boolean;

    constructor(field: string, title: string) {
        this.field = (field ? field.split('.')[0] : null);
        this.subfield = (field ? field.split('.')[1] : null);
        this.title = title;
    }

    concat(field: string) {
        this.concatFields.push(field);
        return this;
    }

    thumbnail(size: number = 50, rotated: boolean = false, serverThumbnail = true, caption = "", captionDate = true) {
        this.isThumbnail = true;
        this.thumbnailSize = size;
        this.thumbnailRotated = rotated;
        this.thumbnailCaption = caption;
        this.thumbnailCaptionDate = captionDate;
        this.serverThumbnail = serverThumbnail;
        return this;
    }

    checkbox() {
        this.isCheckbox = true;
        return this;
    }

    button(clickObject: object, clickFunction: (bean: any) => any) {
        this.isButton = true;
        this.clickObject = clickObject;
        this.clickFunction = clickFunction;

        return this;
    }

    decimal(cnt: number = 2) {
        this.isDecimal = true;
        this.decimalCount = cnt;
        return this;
    }

    dateTime() {
        this.isDateTime = true;
        return this;
    }

    date() {
        this.isDate = true;
        return this;
    }

    shortDate() {
        this.isShortDate = true;
        return this;
    }

    hide() {
        this.isHidden = true;
        return this;
    }

    time() {
        this.isTime = true;
        return this;
    }

    shortTime() {
        this.isShortTime = true;
        return this;
    }

    duration() {
        this.isDuration = true;
        return this;
    }

    asEnum(enumType: {[s: number]: string}) {
        this.enumType = enumType;
        return this;
    }

    data(dataObject: object, dataFunction: (bean: any) => any) {
        this.dataObject = dataObject;
        this.dataFunction = dataFunction;
        return this;
    }
}
