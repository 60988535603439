import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {UserBean} from '../../../../model/model';
import {ProfileService} from '../../../../services/profile.service';
import {Rights} from '../../../../common/rights';
import {Helper} from '../../../../common/helper';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-managers-component',
    templateUrl: 'kiosk-managers.component.html'
})
export class KioskManagersComponent implements AfterViewInit {

    managers: UserBean[] = [];
    compareBeans = Helper.compareBeans;
    kioskManagers: UserBean[];
    kioskId: number;

    constructor(private profileService: ProfileService,
        private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;

        if (!this.kioskManagers) {
            this.kioskManageService.getManagers(kioskId).subscribe(response => {
                if (response.success) {
                    this.kioskManagers = response.value;
                }

                if (this.managers.length != 0) {
                    return;
                }

                this.profileService.getUsers().subscribe(response => {

                    for (const user of response.list) {
                        if ((user.rights | Rights.MANAGE_SELECTED_STORES) == user.rights) {
                            this.managers.push(user);
                        }
                    }

                    Helper.selectPicker('select_manager', 'select_state');
                });
            });
        }

    }

    save() {
        this.kioskManageService.setManagers(this.kioskId, this.kioskManagers).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
