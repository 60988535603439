import {Component, forwardRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {Helper} from '../../common/helper';
import {DeviceProvisioningComponent} from './device-provisioning.component';
import {BaseModalComponent} from '../../components/base.modal.component';
import {AppComponent} from '../../app.component';
import {DeviceService} from '../../services/device.service';
import {
    AbstractDeviceBean,
    DeviceTestDataBean,
    HardwareProvisioningHistoryBean,
    HardwareWithProvisioningBean,
    ProcessBean
} from '../../model/model';
import {AppSettings} from '../../app.settings';
import {DataService} from '../../services/data.service';

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

@Component({
    selector: 'device-provisioning-test-component',
    templateUrl: 'device-provisioning.test.component.html'
})

export class DeviceProvisioningTestComponent implements OnDestroy {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    compareBeans = Helper.compareBeans;
    deviceHardware: HardwareWithProvisioningBean;
    abstractDevices: AbstractDeviceBean[];
    selectedAbstractDevice: AbstractDeviceBean;
    selectedProcess: ProcessBean;
    selectedIngredientId: number;
    selectedIngredientType: string;
    selectedValue: string[] = [];
    selectedFunc: string;
    log: string = '';
    history: HardwareProvisioningHistoryBean[];

    constructor(private deviceService: DeviceService,
        private dataService: DataService,
        @Inject(forwardRef(() => DeviceProvisioningComponent)) private parent: DeviceProvisioningComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(deviceHardware: HardwareWithProvisioningBean, title: string) {

        this.deviceHardware = (deviceHardware ? Helper.copyObject(deviceHardware) : {} as HardwareWithProvisioningBean);
        console.log(this.deviceHardware);

        this.getAbstractDevices();
        this.openFirebaseMessaging();

        this.base.show(title);
        this.base.setFooterVisibility(false);

        this.loadHistory();
    }

    onAbstractDeviceChange() {
        this.selectedProcess = null;
        Helper.selectPicker('select_process');
    }

    openFirebaseMessaging() {
        if (!firebase.apps.length) {
            firebase.initializeApp(AppSettings.getFirebaseConfig());
        }

        firebase.auth().signInWithEmailAndPassword(AppSettings.FB_USER, AppSettings.FB_PASSWORD).then(response => {
            const channel = firebase.database()
                .ref('company')
                .child('' + this.dataService.getCompanyId())
                .child('hardware')
                .child('' + this.deviceHardware.id)
                .child('test_status');

            channel.on('child_changed', data => {
                this.log += (data.val() + '\n');
            });
        });
    }

    haveParameters() {
        if (!this.selectedProcess || !this.selectedProcess.parameters) {
            return false;
        }
        if (this.selectedAbstractDevice.dispenserType == 'INGREDIENT') {
            return true;
        }

        for (const param of this.selectedProcess.parameters) {
            if (param.length > 0) {
                return true;
            }
        }
        return false;
    }

    isBoardDevice(device: AbstractDeviceBean) {
        return device.name == 'Ingredient Dispenser' ||
            device.name == 'Blender' ||
            device.name == 'Delivery System' ||
            device.name == 'Vision';
    }

    execute() {
        const test: DeviceTestDataBean = {} as DeviceTestDataBean;
        if (this.selectedAbstractDevice.dispenserType == 'INGREDIENT') {
            test.subDispenserId = this.selectedIngredientId;
            test.ingredientType = this.selectedIngredientType;
        }
        test.process = this.selectedProcess.name;
        test.parameters = {};

        for (const param of this.selectedProcess.parameters) {
            if (param.length == 0) {
                continue;
            }
            test.parameters[param] = this.selectedValue[param];
        }

        this.deviceService.startDeviceTest(this.deviceHardware.id, this.selectedAbstractDevice.id, test).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Test scheduled');
            } else {
                this.app.showError('Test start failed');
            }
        });
    }

    executeFunc() {
        const test: DeviceTestDataBean = {} as DeviceTestDataBean;
        test.process = this.selectedFunc;
        test.parameters = {};

        for (const param of this.deviceHardware.functionalTests[this.selectedFunc]) {
            test.parameters[param] = (this.selectedValue[param] ? this.selectedValue[param] : '');
        }

        this.deviceService.startFunctionalTest(this.deviceHardware.id, test).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Test scheduled');
            } else {
                this.app.showError('Test start failed');
            }
        }, error => {
            this.app.showError('Test start failed');
        });
    }

    clearLog() {
        this.log = '';
    }

    renameDevice() {
        this.deviceService.changeHostname(this.deviceHardware.id, this.deviceHardware.name).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Rename scheduled');
            } else {
                this.app.showError('Rename failed');
            }
        }, error => {
            this.app.showError('Rename failed');
        });
    }

    updateSoftware() {
        this.deviceService.updateSoftware(this.deviceHardware.id).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Update scheduled');
            } else {
                this.app.showError('Update failed');
            }
        }, error => {
            this.app.showError('Update failed');
        });
    }

    ngOnDestroy() {

        this.parent.reload();
        try {
            firebase.app().delete();
        } catch (error) {
        }
    }

    private loadHistory() {
        this.history = null;
        this.deviceService.getDeviceHistory(this.deviceHardware.id).subscribe(response => {
            if (response.success) {
                this.history = response.value;
            } else {
                this.app.showError('Can\'t get history');
            }
        }, error => {
            this.app.showError('Can\'t get history');
        });
    }

    private getAbstractDevices() {
        this.deviceService.getDevices().subscribe(devices => {
            this.abstractDevices = devices.list;

            Helper.selectPicker('select_func', 'select_abstract_device');
        });
    }

}
