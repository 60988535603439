import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../../components/base.modal.component';
import {FieldsListComponent} from '../../../../components/fields-list.component';
import {Field, FieldType} from '../../../../common/field';
import {KioskMaintenanceOptionsComponent} from './kiosk-maintenance-options.component';
import {AppComponent} from '../../../../app.component';
import {Helper} from '../../../../common/helper';
import {MaintenanceTypeBean, StringBean} from '../../../../model/custom-model';

@Component({
    selector: 'kiosk-maintenance-options-edit-component',
    templateUrl: 'kiosk-maintenance-options.edit.component.html'
})

export class KioskMaintenanceOptionsEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    value: MaintenanceTypeBean = {} as MaintenanceTypeBean;

    fields: Field[] = [
        new Field('value', 'Text').require(),
        new Field('type', 'Type', FieldType.SELECT).setOptions(['REGULAR_MAINTENANCE', 'FIX_ERRORS']),
    ];
    initValue: MaintenanceTypeBean;

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(@Inject(forwardRef(() => KioskMaintenanceOptionsComponent)) private parent: KioskMaintenanceOptionsComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(value: StringBean, title: string) {
        this.base.show(title);
        this.value = value ? Helper.copyObject(value) : {} as StringBean;
        this.initValue = value ? Helper.copyObject(value) : {} as StringBean;
    }

    save() {
        this.parent.addOption(this.initValue, this.value);
    }

}
