<div class="row" style="padding: 3px">
    <div class="col-md-1">
        <label class="col-form-label" for="input_repeat">{{title}}</label>
    </div>

    <div class="col-md-1">
        <button type="button" class="btn btn-youtube" (mousedown)="minusDown()" (mouseup)="minusUp()"
                (mouseout)="minusUp()">-
        </button>
    </div>

    <div class="col-md-2">
        <input disabled
               id="input_repeat"
               type="number" [(ngModel)]="val"
               class="btn btn-block"
        />
    </div>

    <div class="col-md-1">
        <button type="button" class="btn btn-youtube" (mousedown)="plusDown()" (mouseup)="plusUp()"
                (mouseout)="plusUp()">+
        </button>
    </div>

</div>
