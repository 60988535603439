<div class="row">
    <div class="col-md-4">
        <label class="col-form-label">Select Sequence:</label>
        <select hidden disabled
                id="select_test_sequence"
                data-style="select-btn"
                title="Select Sequence"
                data-size="7"
                [(ngModel)]="stepTest.stepSequence">
            <option [ngValue]="seq" selected="selected"
                    *ngFor="let seq of sequences">{{seq.description}}
            </option>
        </select>
    </div>
    <div class="col-md-2">
        <label class="col-form-label" for="input_repeat">Repeat:</label>
        <input
                id="input_repeat"
                type="number"
                class="btn btn-block" [(ngModel)]="stepTest.repeatNumber"
        />
    </div>
    <div class="col-md-2">
        <label class="col-form-label" for="input_min">Min Dispense:</label>
        <input
                type="number" id="input_min"
                class="btn btn-block" [(ngModel)]="stepTest.minQuantity"
        />
    </div>
    <div class="col-md-2">
        <label class="col-form-label" for="input_max">Max Dispense:</label>
        <input
                type="number" id="input_max"
                class="btn btn-block" [(ngModel)]="stepTest.maxQuantity"
        />
    </div>

    <div class="col-md-2">
        <label class="col-form-label">&nbsp;</label>
        <button type="button" class="btn btn-outline-primary" style="width: 100%"
                (click)="runTest()">Run
        </button>

    </div>
</div>

<br/><br/>

<table-component (onRemove)="onRemove($event)" (onView)="onView($event)"></table-component>
<br/><br/>
<button type="button" class="btn btn-outline-primary pull-right"
        (click)="loadTests()">Refresh
</button>

<br/><br/>

<kiosk-test-details-component></kiosk-test-details-component>
<kiosk-test-cycle-details-component></kiosk-test-cycle-details-component>
