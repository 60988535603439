import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {AppComponent} from '../../app.component';
import {ProfileService} from '../../services/profile.service';
import {UserInfoBean} from '../../model/model';

@Component({
    templateUrl: 'confirmation.component.html'
})

export class ConfirmationComponent implements AfterViewInit {

    currentDate: Date;

    constructor(public router: Router,
        private userService: ProfileService,
        private dataService: DataService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {

        this.currentDate = new Date();
    }

    ngAfterViewInit(): void {
        if (!this.dataService.getUserId()) {
            this.router.navigate(['/']);
        }
    }

    loginConfirmation(event, code) {
        event.preventDefault();

        this.userService.loginConfirmation(this.dataService.getUserId(), code)
            .subscribe(response => {
                if (response.success) {
                    const userInfo: UserInfoBean = response.value;
                    this.dataService.setUserInfo(userInfo);
                    this.router.navigate(['/']);

                } else {

                    this.app.showError('Wrong confirmation code.');
                }
            }, error => {
                this.app.showError('Wrong confirmation code.');
            });
    }

    resendConfirmation() {

        this.userService.resendConfirmationCode(this.dataService.getUserId())
            .subscribe(response => {
                if (response.success) {
                    this.app.showError('Confirmation code sent.');

                } else {

                    this.app.showError('Can\'t send confirmation code.');
                }
            }, error => {
                this.app.showError('Can\'t send confirmation code.');
            });
    }
}
