import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../components/base.modal.component';
import {AppComponent} from '../../app.component';
import {ProfileService} from '../../services/profile.service';

@Component({
    selector: 'forgot-password-component',
    templateUrl: 'forgot-password.component.html'
})

export class ForgotPasswordComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    email: string;

    isValid() {
        return () => {
            return this.email && this.email.length;
        };
    }

    constructor(private profileService: ProfileService, @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open() {
        this.base.show('Please enter your email');
    }

    save() {
        this.profileService.requestPasswordRecovery(this.email).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Please check your email inbox.');
            } else {
                this.app.showError('Request failed. Check your credentials');
            }

        }, error => {
            console.log(error);
            this.app.showError('Request failed. Check your credentials');
        });
    }

}
