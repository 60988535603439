<div class="container">
    <div class="row">
        <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form role="form" (submit)="loginConfirmation($event, code.value)">
                <div class="card" data-background="color" data-color="blue">
                    <div class="card-header">
                        <h3 class="card-title">Confirmation</h3>
                    </div>
                    <div class="card-content">
                        <div class="form-group">
                            <label>Confirmation Code</label>
                            <input #code type="number" placeholder="Enter Code"
                                   class="form-control input-no-border">
                        </div>
                    </div>
                    <div class="card-footer text-center">
                        <button type="submit" class="btn btn-fill btn-wd ">Let's go</button>
                        <br/> <br/>
                        <div class="forgot">
                            <a (click)="resendConfirmation()">Resend code</a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>