<div class="animated fadeIn">
    <div class="col-md-12">

        <tabset>

            <tab heading='Not connected' (selectTab)="onNotConnectedTabSelected($event)">
                <div class="card">
                    <div class="card-content">
                        <table-component (onEdit)="onEdit($event)" #notConnectedTableComponent (onView)="onView($event)"
                                         sortName="lastSeen" sortOrder="desc"
                                         (onRemove)="onRemove($event)"></table-component>
                    </div>
                </div>
            </tab>

            <tab heading='All' (selectTab)="onAllTabSelected($event)" *ngIf="canEditDevices()">
<!--                <select hidden-->
<!--                        id="select_location"-->
<!--                        data-style="select-btn"-->
<!--                        title="Select Operator"-->
<!--                        data-size="7"-->
<!--                        [compareWith]="compareBeans"-->
<!--                        [(ngModel)]="selectedOperator"-->
<!--                        (change)="onOperatorChange()">-->
<!--                    <option [ngValue]="allOperators" selected="selected">All Operators</option>-->
<!--                    <option [ngValue]="location" selected="selected"-->
<!--                            *ngFor="let location of operators">{{location.name}}-->
<!--                    </option>-->
<!--                </select>-->

                <select hidden
                        id="select_kiosk"
                        data-style="select-btn"
                        title="Select Location"
                        data-size="7"
                        [disabled]="!kiosks"
                        [compareWith]="compareBeans"
                        [(ngModel)]="selectedKiosk"
                        (change)="onKioskChange()">
                    <option [ngValue]="allKiosks" selected="selected">All Locations</option>
                    <option [ngValue]="kiosk" selected="selected"
                            *ngFor="let kiosk of kiosks">{{kiosk.name}}
                    </option>
                </select>


                <div class="card">
                    <div class="card-content">
                        <table-component (onEdit)="onEdit($event)" #allTableComponent
                                         (onRemove)="onRemove($event)"></table-component>
                    </div>
                </div>

            </tab>
        </tabset>

    </div>
</div>
<device-provisioning-edit-component></device-provisioning-edit-component>
<device-provisioning-test-component></device-provisioning-test-component>
