<base-modal-component (onConfirm)="save()" (onHidden)="onHidden()" [extraLarge]="true" [validation]="isValid()" class="base-modal">

    <tabset>
        <tab heading='Description'>
            <fields-list-component #fieldsListComponentDesc [fields]="fields" name="recipe_edit_desc"
                                   [value]="recipe"></fields-list-component>
            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-3 control-label">Price</label>
                        <div class="col-md-9" *ngIf="recipe.price">
                            <div class="row">
                                <div class="col-md-3 pl0">

                                    <div class="checkbox">
                                        <input
                                                id="checkbox_small"
                                                type="checkbox"
                                                (change)="onSmallSizeChanged($event)"
                                                [checked]="smallSizeEnabled"
                                                class="custom-control-input"/>
                                        <label for="checkbox_small">SMALL</label>
                                    </div>
                                    <label>
                                        <input [(ngModel)]="recipe.price['SMALL']"
                                               [disabled]="!smallSizeEnabled"
                                               class="form-control"
                                               type="number"/>
                                    </label>
                                </div>

                                <div class="col-md-3 pl0">
                                    <div class="checkbox">
                                        <input
                                                id="checkbox_medium"
                                                type="checkbox"
                                                (change)="onMediumSizeChanged($event)"
                                                [checked]="mediumSizeEnabled"
                                                class="custom-control-input"/>
                                        <label for="checkbox_medium">MEDIUM</label>
                                    </div>
                                    <label>
                                        <input [(ngModel)]="recipe.price['MEDIUM']"
                                               [disabled]="!mediumSizeEnabled"
                                               class="form-control"
                                               type="number"/>
                                    </label>
                                </div>

                                <div class="col-md-3 pl0">
                                    <div class="checkbox">
                                        <input
                                                id="checkbox_large"
                                                type="checkbox"
                                                (change)="onLargeSizeChanged($event)"
                                                [checked]="largeSizeEnabled"
                                                class="custom-control-input"/>
                                        <label for="checkbox_large">LARGE</label>
                                    </div>
                                    <label>
                                        <input [(ngModel)]="recipe.price['LARGE']"
                                               [disabled]="!largeSizeEnabled"
                                               class="form-control"
                                               type="number"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
        <tab heading='Images'>

            <fields-list-component #fieldsListComponentImages [fields]="fieldsImages" name="recipe_edit_img"
                                   [value]="recipe"></fields-list-component>

        </tab>
        <tab heading='Ingredients'>

            <div class="pb15">
                <b>Total Volume: [SMALL: {{volumeSmall | number: '1.0-4'}};
                    MEDIUM: {{volumeMedium | number: '1.0-4'}};
                    LARGE: {{volumeLarge | number: '1.0-4'}}]
                </b>
            </div>

            <div class="row">
                <div class="col-md-8 pl0">
                    <label for="edit_ingredient_name" class="col-form-label">Name</label>

                    <select id="edit_ingredient_name"
                            data-style="select-btn select-btn-grey"
                            title="Select Ingredient"
                            data-size="7"
                            [(ngModel)]="selectedIngredient">
                        <option [ngValue]="ingredient" *ngFor="let ingredient of ingredients">{{ingredient.name}}
                        </option>
                    </select>

                </div>

                <div class="col-md-4">
                    <label for="add_ingredient" class="col-form-label">Add</label><br/>
                    <button type="button"
                            id="add_ingredient"
                            style="padding: 7px 14px"
                            class="btn btn-link btn-sm black"
                            (click)="addIngredient()">
                        <i class="fa fa-plus"></i></button>
                </div>
            </div>

            <div class="card-content table-responsive table-full-width" style="margin: 1px">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>Priority</th>
                        <th>Name</th>
                        <th>Min quantity</th>
                        <th>Normal quantity</th>
                        <th>Max quantity</th>
                        <th>Dispense seconds</th>
                        <th style="width:35px"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let ingredient of recipe.ingredients; let first = first; let last = last; let i = index">
                        <td>
                            <button [disabled]="first" type="button" class="btn btn-simple btn-sm right"
                                    style="padding: 0"
                                    (click)="incPriority(ingredient)"><i class="fa fa-arrow-up"></i>
                            </button>
                            <button [disabled]="last" type="button" class="btn btn-simple btn-sm right"
                                    style="padding: 0"
                                    (click)="decPriority(ingredient)"><i class="fa fa-arrow-down"></i>
                            </button>
                        </td>
                        <td>{{ingredient.name}}</td>
                        <td>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.minQuantity['SMALL']" [disabled]="!smallSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.minQuantity['MEDIUM']" [disabled]="!mediumSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.minQuantity['LARGE']" [disabled]="!largeSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input
                                        (change)="updateVolumes()" [(ngModel)]="ingredient.normalQuantity['SMALL']"
                                        [disabled]="!smallSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        (change)="updateVolumes()" [(ngModel)]="ingredient.normalQuantity['MEDIUM']"
                                        [disabled]="!mediumSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        (change)="updateVolumes()" [(ngModel)]="ingredient.normalQuantity['LARGE']"
                                        [disabled]="!largeSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.maxQuantity['SMALL']" [disabled]="!smallSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.maxQuantity['MEDIUM']" [disabled]="!mediumSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.maxQuantity['LARGE']" [disabled]="!largeSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.normalQuantitySeconds['SMALL']"
                                        [disabled]="!smallSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.normalQuantitySeconds['MEDIUM']"
                                        [disabled]="!mediumSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.normalQuantitySeconds['LARGE']"
                                        [disabled]="!largeSizeEnabled"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                        </td>
                        <td>
                            <button type="button" class="btn btn-link btn-sm mt-0 black"
                                    (click)="removeIngredient(ingredient)"><i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </tab>
        <tab heading='Add-ons'>

            <div class="pb15">
                <b>Total Volume: [SMALL: {{volumeSmall | number: '1.0-4'}};
                    MEDIUM: {{volumeMedium | number: '1.0-4'}};
                    LARGE: {{volumeLarge | number: '1.0-4'}}]
                </b>
            </div>

            <div class="row">
                <div class="col-md-8">
                    <label for="edit_boost_name" class="col-form-label">Name</label>

                    <select id="edit_boost_name"
                            data-style="select-btn select-btn-grey"
                            title="Select Ingredient"
                            data-size="7"
                            [(ngModel)]="selectedBoost">
                        <option [ngValue]="boost" *ngFor="let boost of ingredients">{{boost.name}}
                        </option>
                    </select>

                </div>

                <div class="col-md-4">
                    <label for="add_boost" class="col-form-label">Add</label><br/>
                    <button type="button"
                            id="add_boost"
                            class="btn btn-link btn-sm mt-0 black"
                            style="padding: 7px 14px"
                            (click)="addBoost()">
                        <i class="fa fa-plus"></i></button>
                </div>
            </div>

            <div class="card-content table-responsive table-full-width" style="margin: 1px">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Min quantity</th>
                        <th>Normal quantity</th>
                        <th>Max quantity</th>
                        <th>Dispense seconds</th>
                        <th>Price</th>
                        <th style="width:35px"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let ingredient of recipe.addons">
                        <td>{{ingredient.name}}</td>
                        <td>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.minQuantity['SMALL']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.minQuantity['MEDIUM']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.minQuantity['LARGE']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input
                                        (change)="updateVolumes()" [(ngModel)]="ingredient.normalQuantity['SMALL']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        (change)="updateVolumes()" [(ngModel)]="ingredient.normalQuantity['MEDIUM']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        (change)="updateVolumes()" [(ngModel)]="ingredient.normalQuantity['LARGE']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.maxQuantity['SMALL']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.maxQuantity['MEDIUM']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.maxQuantity['LARGE']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.normalQuantitySeconds['SMALL']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.normalQuantitySeconds['MEDIUM']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                            <label>
                                <input
                                        [(ngModel)]="ingredient.normalQuantitySeconds['LARGE']"
                                        class="form-control form-control-low"
                                        type="text"/>
                            </label>
                        </td>
                        <td style="width: 150px">
                            <label>
                                <input
                                        [(ngModel)]="ingredient.price"
                                        class="form-control form-control-low"
                                        type="number"/>
                            </label>
                        </td>
                        <td>
                            <button type="button" class="btn btn-link btn-sm mt-0 black"
                                    (click)="removeBoost(ingredient)"><i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>


        </tab>
        <tab heading='Steps'>

            <div class="form-horizontal">
                <div class="card-content">
                    <tabset>
                        <tab heading='Preparation'>
                            <steps-list-component [type]="'PREPARATION'"
                                                  [stepPhases]="recipe.stepPhases"
                                                  (onCreateStepClicked)="createStep($event)"
                                                  (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                        </tab>
                        <tab heading='Cooking'>
                            <steps-list-component [type]="'COOKING'"
                                                  [stepPhases]="recipe.stepPhases"
                                                  (onCreateStepClicked)="createStep($event)"
                                                  (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                        </tab>
                        <tab heading='Serving'>
                            <steps-list-component [type]="'SERVING'"
                                                  [stepPhases]="recipe.stepPhases"
                                                  (onCreateStepClicked)="createStep($event)"
                                                  (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                        </tab>
                    </tabset>
                </div>
            </div>
        </tab>
    </tabset>

</base-modal-component>
