<div [hidden]="!messages" class="animated fadeIn">

    <table-component (onAdd)="onAdd()"
                     (onEdit)="onEdit($event)"
                     (onRemove)="onRemove($event)"></table-component>

    <button (click)="save()" class="btn btn-primary pull-right" id="button_save" type="button">Save</button>
    <br/>
    <br/>
    <br/>
</div>

<user-messages-edit-component></user-messages-edit-component>
