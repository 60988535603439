import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {KioskTabletBean} from '../../../../model/model';
import {Helper} from '../../../../common/helper';
import {TableComponent} from '../../../../components/table.component';
import {Column} from '../../../../common/column';
import {StringBean} from '../../../../model/custom-model';
import {AppComponent} from '../../../../app.component';
import {KioskTabletsEditComponent} from './kiosk-tablets.edit.component';
import {KioskService} from '../../../../services/kiosk.service';

@Component({
    selector: 'kiosk-tablets-component',
    templateUrl: 'kiosk-tablets.component.html'
})
export class KioskTabletsComponent implements AfterViewInit {

    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskTabletsEditComponent, {static: false}) modal: KioskTabletsEditComponent;

    fields: Column[] = [
        new Column('id', 'ID'),
        new Column('name', 'Name'),
        new Column('version', 'Version'),
        new Column('firstSeenDate', 'First Seen Date').date(),
        new Column('lastSeenDate', 'Last Seen Date').date(),
        new Column('allowTestOrders', 'Allow Test Orders').checkbox(),
    ];
    kioskId: number;
    tablets: KioskTabletBean[];

    constructor(private kioskService: KioskService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        console.log(this.table);

        this.kioskId = kioskId;
        this.getTablets();
    }

    onEdit(items: KioskTabletBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Tablet');
        }
    }

    addOption(initValue: KioskTabletBean, value: KioskTabletBean) {
        this.kioskService.setKioskTablet(value).subscribe(response => {
            this.getTablets();
        });
    }

    updateTable() {
        if (this.table) {
            this.table.setData(this.tablets, this.fields);
        }
    }

    private getTablets() {
        this.kioskService.getKioskTablets(this.kioskId).subscribe(response => {
            if (response.success) {
                this.tablets = response.value;
                console.log(this.tablets);
                this.updateTable();
            }
        });
    }
}
