import {Component, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {ReviewBean} from '../../model/model';
import {Field, FieldType} from '../../common/field';
import {Column} from '../../common/column';

@Component({
    selector: 'review-quickview-component',
    templateUrl: 'review.quickview.component.html'
})

export class ReviewQuickviewComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    review: ReviewBean = {} as ReviewBean;

    fields: Field[] = [

        new Field('date', 'Date', FieldType.DATE).disable(),
        new Field('orderId', 'Order Id').disable(),
        new Field('userName', 'User Name').disable(),
        new Field('userEmail', 'User Email').disable(),

        new Field('ratingOverall', 'Overall').disable(),
        new Field('ratingFreshness', 'Freshness').disable(),
        new Field('ratingWaitTime', 'Wait Time').disable(),

        new Field('positive', 'Positive', FieldType.CHECKBOX).disable(),
        new Field('waitTime', 'Wait Time', FieldType.CHECKBOX).disable(),
        new Field('quality', 'Quality', FieldType.CHECKBOX).disable(),
        new Field('environment', 'Environment', FieldType.CHECKBOX).disable(),
        new Field('customerService', 'Customer Service', FieldType.CHECKBOX).disable(),
        new Field('other', 'Other', FieldType.CHECKBOX).disable(),
        new Field('allowToReply', 'Allow to Reply', FieldType.CHECKBOX).disable(),

        new Field('message', 'Description', FieldType.TEXT_AREA).disable(),
        new Field('rate', "Rate").disable()

    ];

    constructor() {
    }

    open(review: ReviewBean) {

        this.review = (review ? review : {} as ReviewBean);
        this.base.show('For order #' + review.orderId);
    }

    save() {
    }
}
