<div class="checkbox">
    <input
            type="checkbox"
            (change)="setSimulatorMode($event.target.checked)"
            [checked]="isSimulator()"
            class="custom-control-input"
            id="simulator_enable"/>
    <label for="simulator_enable">Enable Simulator</label>

</div>
<div [hidden]="!isSimulator()">
    <button type="button" class="btn btn-outline-primary pull-right"
            (click)="saveTimes()">Save
    </button>
    <div class="card-content table-responsive table-full-width" style="margin: 1px">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Device</th>
                <th>Process</th>
                <th>Time</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let simDeviceProcess of simDeviceProcesses">
                <td>{{getDeviceName(simDeviceProcess.deviceId)}}</td>
                <td>{{simDeviceProcess.process}}</td>
                <td>
                    <input
                            type="number"
                            [(ngModel)]="simDeviceProcess.time"/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>