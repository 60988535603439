import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {KioskService} from '../../services/kiosk.service';
import {Helper} from '../../common/helper';
import {EntityNameBean, PromotionBean, PromotionState} from '../../model/model';
import {PromotionsEditComponent} from './promotions.edit.component';
import {PromotionsService} from '../../services/promotions.service';

@Component({
    templateUrl: 'promotions.component.html',
})

export class PromotionsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(PromotionsEditComponent, {static: false}) serviceEventsEditComponent: PromotionsEditComponent;

    columns: Column[] = [
        new Column('id', 'Id'),
        new Column('name', 'Name'),
        new Column('startDate', 'Start Date').date(),
        new Column('endDate', 'End Date').date(),
        new Column('targetKiosks', 'Kiosks').data(this, this.getTargetKioskNames),

        new Column('state', 'State'),
        new Column('category', 'Category').concat('subCategory'),
        new Column('priceDiscountValue', 'Discount Value'),
        new Column('coupon.name', 'Coupon'),

    ];
    allKiosks: EntityNameBean = {id: 0} as EntityNameBean;

    kiosks: EntityNameBean[] = [];
    selectedKiosk: EntityNameBean = {id: 0} as EntityNameBean;
    selectedKioskId: number;
    compareBeans = Helper.compareBeans;

    selectedStates: string[] = [];
    categories: Array<string[]>;

    defaultStates: PromotionState[] = [
        'IN_WORKS',
        'PUBLISHED',
    ];

    constructor(private kioskService: KioskService,
        private promotionsService: PromotionsService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;

            if (params['states']) {
                for (const param of params['states'].split(',')) {
                    this.selectedStates.push(param);
                }
            } else {
                this.selectedStates = this.defaultStates;
            }

            Helper.selectPicker('select_kiosk', 'select_state');

            this.loadKiosks();
        });
    }

    loadPromotions() {

        if (!this.selectedKiosk) {
            return;
        }

        this.promotionsService.getPromotions(this.selectedKiosk.id,
            this.selectedStates.toString())
            .subscribe(response => {
                console.log(response);

                this.table.setData(response.value, this.columns);
            });

        this.promotionsService.getCategories().subscribe(response => {
            if (response.success) {
                this.categories = response.value;
            }
        });
    }

    onKioskChange() {
        this.loadPromotions();
        this.updateLocation();
    }

    onStateChange() {
        this.loadPromotions();
        this.updateLocation();
    }

    updateLocation() {
        let url = '/promotions';
        if (this.selectedKiosk != this.allKiosks && this.selectedKiosk.id) {
            url += ('?kiosk=' + this.selectedKiosk.id);
            if (this.selectedStates.length > 0) {
                url += ('&states=' + this.selectedStates);
            }
        } else {
            if (this.selectedStates.length > 0) {
                url += ('?states=' + this.selectedStates);
            }
        }

        this.location.replaceState(url);
    }

    loadKiosks() {

        this.kioskService.getKiosksNames().subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks: EntityNameBean[]) {

        this.kiosks = kiosks;
        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKioskId) {
            this.selectedKiosk = this.allKiosks;
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.id == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }

        Helper.selectPicker('select_kiosk');
        this.loadPromotions();
    }

    onEdit(items: PromotionBean[]) {
        if (items && items.length > 0) {
            this.serviceEventsEditComponent.open(items[0], this.kiosks, this.categories, 'Edit Promotion');
        }
    }

    onAdd() {
        this.serviceEventsEditComponent.open(null, this.kiosks, this.categories, 'Add Promotion');
    }

    private getTargetKioskNames(bean: PromotionBean) {
        return bean.targetKiosks.map(a => a.name).join(', ');
    }
}
