<div class="animated fadeIn" *ngIf="customRecipes">

    <fields-list-component [fields]="fields" name="kiosk_model_details"
                           [value]="customRecipes"></fields-list-component>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="select_category" class="col-md-3 control-label">Category</label>
                <div class="col-md-9">
                    <select [disabled]="!categories"
                            id="select_category"
                            data-style="select-btn"
                            title="Select Category"
                            data-size="7"
                            [(ngModel)]="customRecipes.customRecipesCategoryId">
                        <option [ngValue]="category.id" selected="selected"
                                *ngFor="let category of categories">{{category.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="select_category" class="col-md-3 control-label">Main Solid Ingredients</label>
                <div class="col-md-9">
                    <select multiple
                            id="select_solids"
                            data-style="select-btn"
                            title="Select Ingredients"
                            data-size="7"
                            [compareWith]="compareBeans"
                            [(ngModel)]="customRecipes.customRecipeMainSolidIngredients">
                        <ng-container *ngFor="let ingredient of  ingredients">
                            <option *ngIf="ingredient.type == 'Solid' && ingredient.enabledForCustomRecipes"
                                    [ngValue]="ingredient">{{ingredient.name}}
                            </option>
                        </ng-container>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br />
    <br />
    <br />
</div>
