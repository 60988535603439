import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {ActionButton, TableComponent} from '../../../components/table.component';
import {DetectionResult, VisionService} from '../../../services/vision.service';
import {Column} from '../../../common/column';
import {AppComponent} from '../../../app.component';
import {TagSelectComponent} from './tag.select.component';
import {FileUploaderComponent} from '../../../file-uploader/file-uploader.component';
import {VisionComponent} from '../vision.component';
import {Helper} from '../../../common/helper';
import {ActivatedRoute} from '@angular/router';
import {EntityNameBean, KioskBean} from '../../../model/model';
import {Location} from '@angular/common';
import {KioskService} from '../../../services/kiosk.service';
import {OperatorsService} from '../../../services/operators.service';
import * as moment from 'moment-timezone';

@Component({
    selector: 'vision-detect-component',
    templateUrl: 'vision-detect.component.html'
})
export class VisionDetectComponent implements AfterViewInit {

    @ViewChild('resultsTable', {static: false}) resultsTable: TableComponent;
    @ViewChild('detectImageAdd', {static: false}) detectImageAdd: FileUploaderComponent;
    @ViewChild(TagSelectComponent, {static: false}) tagSelectComponent: TagSelectComponent;

    selectedDeviceName: string[] = [];
    kiosks: EntityNameBean[] = [];
    allKiosks: KioskBean = {id: 0} as KioskBean;
    selectedKiosk: EntityNameBean = {id: 0} as KioskBean;
    selectedKioskId: number;
    compareBeans = Helper.compareBeans;

    fields: Column[] = [
        new Column('kiosk_name', 'Kiosk'),
        new Column('device_name', 'Device'),
        new Column('date', 'Date').date(),
        new Column('source_image', 'Source Image').thumbnail(50, false, false, 'date', true),
        new Column('result_image', 'Result Image').thumbnail(50, false, false, 'formatted_result', false),
        new Column('result', 'Result'),
    ];
    resultsButtons: ActionButton[] = [
        new ActionButton('Add to Data', 'addToData', 'ti-plus').click(this, this.addToData),
    ];

    tags: string[];
    deviceNames: string[];
    detectionResults: DetectionResult[];
    filter: string;
    selectedAppName: string = null;

    selectedRange = [
        moment().startOf('day').subtract(3, 'day').toDate(),
        moment().endOf('day').toDate()
    ];
    selectedResult: boolean;

    constructor(private visionService: VisionService,
        private operatorsService: OperatorsService,
        private kioskService: KioskService,
        private route: ActivatedRoute,
        private location: Location,
        @Inject(forwardRef(() => VisionComponent)) private parent: VisionComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.filter = params['filter'] ? params['filter'] : 0;
        });

    }

    onShow() {

        // this.allOperators.id = 0;
        this.route.queryParams.subscribe(params => {
            // this.selectedOperatorId = params['location'] != null ? params['location'] : 0;
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;
            // this.loadLocations();
            this.loadKiosks();
        });

    }

    onDetectTagChange(value) {
        this.selectedDeviceName = value;
        this.resultsTable.setData([], this.fields);
        this.getDetectionResults();
    }

    onAppChange(value) {
        this.selectedAppName = value;
        this.resultsTable.setData([], this.fields);
        this.getDetectionResults();
    }

    onResultChange(value) {
        this.selectedResult = value;
        this.resultsTable.setData([], this.fields);
        this.getDetectionResults();
    }

    setTags(tags: string[]) {
        this.tags = tags;
    }

    setDeviceNames(names: string[]) {
        this.deviceNames = names;
        // if (this.selectedDeviceName) {
        //     for (const name of this.deviceNames) {
        //         if (name == this.selectedDeviceName) {
        //             this.selectedDeviceName = name;
        //             break;
        //         }
        //     }
        // }
        this.onDetectTagChange(this.selectedDeviceName);
        Helper.selectPicker('select_detect_tag', 'select_detect_app', 'select_detect_result');
    }

    deleteDetectionResults() {

        // this.visionService.deleteDetectionResults(this.selectedDeviceName).subscribe(response => {
        //     if (response.success) {
        //         this.getDetectionResults();
        //         this.app.showNotification('Deleted');
        //
        //     } else {
        //         this.app.showError('Can\'t delete results');
        //     }
        // }, error => {
        //     this.app.showError('Can\'t delete results');
        // });
    }

    getDetectionResults() {

        console.log(this.selectedDeviceName);

        let start = Helper.startOf(this.selectedRange[0]);
        let end = Helper.endOf(this.selectedRange[1]);

        this.visionService.getDetectionResults(this.selectedDeviceName, this.selectedKiosk.id, this.selectedAppName, start, end).subscribe(results => {

            this.detectionResults = [];
            for (const v in results.value) {
                this.detectionResults.push(Helper.copyObject(results.value[v]));
            }
            this.detectionResults = this.detectionResults.filter(it => {
                const obj = JSON.parse(it.result);
                return this.selectedResult == null || obj.tag.result == this.selectedResult;
            });

            this.detectionResults.sort((a: DetectionResult, b: DetectionResult) => {
                return b.date - a.date;
            });

            this.detectionResults.forEach(it => {
                it['kiosk_name'] = this.kiosks.find(it1 => it1.id == it.kiosk_id).name;

                const obj = JSON.parse(it.result) as {
                    tag: {
                        final_level: string;
                        EWA_y: string;
                        EWA_level: string;
                        id: string;
                        ounces: string;
                        level: string;
                        application: {[key: string]: any}, result
                    }, objects: {x, y, w, h, conf, nane}[]
                };
                let table = moment(it.date).tz('America/Los_Angeles').format('MM/DD/YY hh:mm A');

                if (obj.tag.application && typeof obj.tag.application === 'object' && Object.keys(obj.tag.application).length) {
                    table += '<br/><br/><table class="table"><tr><th>Name</th><th>Ingredient</th><th>Value</th>';
                    for (let key in obj.tag.application) {
                        table += `<tr><td>${key}</td><td>${obj.tag.application[key][0]}</td><td>${obj.tag.application[key][1]}</td></tr>`;
                    }
                    table += '</table>';
                } else if (obj.tag.application && typeof obj.tag.application === 'string') {
                    table += '<br/><br/><table class="table"><tr><th>Name</th><th>Value</th>';
                    table += `<tr><td>Application</td><td>${obj.tag.application}</td></tr>`;
                    table += `<tr><td>Result</td><td>${obj.tag.result}</td></tr>`;

                    if (obj.tag.level) {
                        table += `<tr><td>Level</td><td>${obj.tag.level}</td></tr>`;
                    }
                    if (obj.tag.ounces) {
                        table += `<tr><td>Ounces</td><td>${obj.tag.ounces}</td></tr>`;
                    }
                    if (obj.tag.id) {
                        table += `<tr><td>Id</td><td>${obj.tag.id}</td></tr>`;
                    }
                    if (obj.tag.EWA_y) {
                        table += `<tr><td>EWA Y</td><td>${obj.tag.EWA_y}</td></tr>`;
                    }
                    if (obj.tag.EWA_level) {
                        table += `<tr><td>EWA Level</td><td>${obj.tag.EWA_level}</td></tr>`;
                    }
                    if (obj.tag.final_level) {
                        table += `<tr><td>Final Level</td><td>${obj.tag.final_level}</td></tr>`;
                    }
                    if (obj.tag['pouring duration']) {
                        table += `<tr><td>Pouring Duration</td><td>${obj.tag['pouring duration']}</td></tr>`;
                    }

                    table += '</table>';
                }

                if (obj.objects && obj.objects.length) {
                    table += '<table class="table"><tr><th>Name</th><th>X</th><th>Y</th><th>W</th><th>H</th>';
                    obj.objects.forEach(it => {
                        table += `<tr><td>${it.nane}</td><td>${it.x}</td><td>${it.y}</td><td>${it.w}</td><td>${it.h}</td></tr>`;
                    });
                    table += '</table>';
                }
                it['formatted_result'] = table;
            });

            this.resultsTable.setData(this.detectionResults, this.fields);

        }, error => {
            this.app.showError('Can\'t get results');
        });
    }

    onRemoveResults(results: DetectionResult[]) {

        for (const result of results) {
            this.visionService.deleteDetectionResult(result).subscribe(response => {
                if (response.success) {
                    this.parent.loadVision(false);
                    this.app.showNotification('Removed');

                } else {
                    this.app.showError('Can\'t remove image');
                }
            }, error => {
                this.app.showError('Can\'t remove image');
            });
        }
    }

    addToData(detectionResult: DetectionResult) {
        this.tagSelectComponent.open(this.tags, detectionResult);
    }

    onKioskChange() {
        this.getDetectionResults();
        this.updateLocation();
    }

    updateLocation() {
        let url = '/vision';
        if (this.selectedKiosk != this.allKiosks && this.selectedKiosk.id) {
            url += ('?kiosk=' + this.selectedKiosk.id);
        }

        this.location.replaceState(url);
    }

    loadKiosks() {
        this.kioskService.getKiosksNames().subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks: EntityNameBean[]) {

        this.kiosks = kiosks;

        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKioskId) {
            this.selectedKiosk = this.allKiosks;
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.id == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }

        Helper.selectPicker('select_kiosk');
        this.getDetectionResults();
    }

    onDateRangeChanged(event) {
        this.getDetectionResults();
    }

}
