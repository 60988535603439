import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {AppComponent} from '../../app.component';
import {SystemConfigurationBean} from '../../model/model';
import {Field} from '../../common/field';
import {FieldsListComponent} from '../../components/fields-list.component';
import {ConfigurationService} from '../../services/configuration.service';

@Component({
    templateUrl: 'system-configuration.component.html',
})

export class SystemConfigurationComponent implements AfterViewInit {

    systemConfig: SystemConfigurationBean;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields = [
        new Field('oldDevicePassword', 'Old Device Password'),
        new Field('devicePassword', 'Device Password'),
        new Field('remotePiPassword', 'Remote Pi Password')
    ];

    constructor(private clientService: ConfigurationService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.getPaymentCredentials();
    }

    getPaymentCredentials() {
        this.clientService.getSystemConfig().subscribe(response => {
            if (response) {
                this.systemConfig = response.value;
            }
        });
    }

    save() {
        this.clientService.updateSystemConfig(this.systemConfig).subscribe(response => {

            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update config');
            }
        }, error => {
            this.app.showError('Can\'t update config');
        });

    }
}
