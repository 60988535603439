import {KioskDefines} from './kiosk.defines';
import {DeviceModelBean} from '../model/model';
import {DeviceView} from './device.view';

const resourcesPath = '../assets/img/presentation/';

export class IngredientDispenserView extends DeviceView {
    private dispenserContent: fabric.Image;

    constructor(device: DeviceModelBean, ingredientName: string, place: [number, number],
        canvas: fabric.Canvas, callback?: (objects: fabric.Object[]) => any) {

        super();
        fabric.Image.fromURL(device.deviceTemplate.image1, img => {

            const hh = img.height / 2;
            const hw = img.width / 2;

            img.set({
                originY: 'center',
                originX: 'center',
                left: 0,
                top: -img.height,
            });

            fabric.Image.fromURL(resourcesPath + 'Dispenser_Content.png', dispenserContent => {

                dispenserContent.set({
                    originY: 'bottom',
                    originX: 'left',
                    top: hh + 20 - Math.random() * 10,
                    left: -hw
                });

                dispenserContent.filters.push(new fabric.Image.filters.Tint({
                    color: KioskDefines.getIngredientColor(ingredientName),
                    opacity: 0.9
                }));
                dispenserContent.applyFilters(canvas.renderAll.bind(canvas));

                this.dispenserContent = dispenserContent;

                const group = new fabric.Group([img], {
                    left: place[0],
                    top: place[1],
                    originY: 'center',
                    originX: 'center',
                    hasControls: false,
                    evented: false,
                    width: img.width,
                    height: img.height
                });

                group.add(dispenserContent);
                dispenserContent.sendToBack();

                group.setFlipX(device.rotation < 0);
                callback.call(this, [group]);
            });

        });

    }

}
