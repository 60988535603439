<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myLargeModalLabel" (onShow)="onShowModal($event)" (onShown)="onShownModal($event)"
     (onHidden)="onHiddenModal($event)"
     aria-hidden="true">
    <div class="modal-dialog {{large ? 'modal-lg' : ''}} {{extraLarge ? 'modal-xl' : ''}} ">
        <div class="modal-content">
            <div class="modal-header" *ngIf="headerVisible">
                <h4 class="modal-title pull-left">{{title}}</h4>
                <button type="button" class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-content></ng-content>
            </div>

            <div class="modal-footer" *ngIf="footerVisible">
                <button type="button" class="btn btn-default" data-dismiss="modal" id="button_cancel"
                        (click)="onCancelClicked()">Cancel
                </button>
                <button type="button" class="btn btn-primary" [disabled]="!isValid()" id="button_save" *ngIf="!confirmButtonHidden"
                        (click)="onConfirmClicked()">{{confirmButtonText}}
                </button>
            </div>
        </div>
    </div>
</div>

