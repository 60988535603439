import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {FieldsListComponent} from '../../../components/fields-list.component';
import {Field} from '../../../common/field';
import {UserBean} from '../../../model/model';
import {ModelNotificationEmailsComponent} from './model-notification-emails.component';
import {AppComponent} from '../../../app.component';
import {Helper} from '../../../common/helper';

@Component({
    selector: 'model-notification-emails-edit-component',
    templateUrl: 'model-notification-emails.edit.component.html'
})

export class ModelNotificationEmailsEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    user: UserBean = {} as UserBean;

    fields: Field[] = [
        new Field('email', 'Email').require(),
    ];
    initUser: UserBean;

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(@Inject(forwardRef(() => ModelNotificationEmailsComponent)) private parent: ModelNotificationEmailsComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(user: UserBean, title: string) {
        this.base.show(title);
        this.user = user ? Helper.copyObject(user) : {} as UserBean;
        this.initUser = user ? Helper.copyObject(user) : {} as UserBean;
    }

    save() {
        this.parent.addUser(this.initUser, this.user);
    }

}
