<div class="animated fadeIn">
    <button type="button" class="btn btn-primary" (click)="setNormalState()">Normal</button>&nbsp;
    <button type="button" class="btn btn-primary" (click)="setPseudoSimState()">Pseudo Sim</button>

    <table-component (onEdit)="onEdit($event)" (onClickRow)="onView($event)"
                     (onView)="onView($event)"></table-component>
</div>
<kiosk-devices-edit-component></kiosk-devices-edit-component>
<rect-select-component></rect-select-component>
<kiosk-devices-view-component></kiosk-devices-view-component>
