<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <fields-list-component [fields]="fields" name="device_model_edit" [value]="deviceModel"></fields-list-component>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">

                <label for="select_device" class="col-md-3 control-label">Device Template</label>
                <div class="col-md-9">

                    <select class="form-control form-control-low selectx" id="select_device"
                            [compareWith]="compareBeans"
                            [(ngModel)]="deviceModel.deviceTemplate">
                        <option *ngFor="let device of availableDevices" [ngValue]="device">
                            {{device.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div *ngIf="deviceModel.deviceTemplate &&
                    deviceModel.deviceTemplate.abstractDevice.dispenserType=='INGREDIENT'">

                <div class="form-group">

                    <label for="input_cnt" class="col-md-3 control-label">Sub Dispensers Count</label>
                    <div class="col-md-9">

                        <input id="input_cnt"
                               type="text"
                               [(ngModel)]="deviceModel.numberOfIngredients"
                               class="form-control form-control-low"/>
                    </div>
                </div>

                <div class="form-group">

                    <label for="select_ingredient_type" class="col-md-3 control-label">Ingredient Type</label>
                    <div class="col-md-9">

                        <select class="form-control form-control-low selectx" id="select_ingredient_type"
                                [(ngModel)]="deviceModel.ingredientType">
                            <option>Liquid</option>
                            <option>Solid</option>
                            <option>Powder</option>
                            <option>BulkPowder</option>
                            <option>FinePowder</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">

                    <label for="input_calibration" class="col-md-3 control-label">Calibration Data</label>
                    <div class="col-md-9">

                        <textarea id="input_calibration"
                                  type="text"
                                  class="form-control form-control-low">{{deviceModel.calibrationData}}</textarea>


                        <input class="form-control form-control-low" type="file" accept=".json, .txt"
                               (change)="onFileSelected($event)">
                    </div>
                </div>
            </div>

        </div>
    </div>


</base-modal-component>
