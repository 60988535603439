<base-modal-component class="base-modal" confirmButtonText="Edit" (onConfirm)="edit()">
    <fields-list-component #fieldsListComponent [fields]="fields" name="operators_view"
                           [value]="location"></fields-list-component>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label class="col-md-3 control-label">Locations</label>
                <div class="col-md-9">
                    <a (click)="showLocations('')">
                        ALL ({{kiosks.length}})
                    </a>
                    <br/>
                    <a (click)="showLocations('OUT_OF_SERVICE')">
                        OUT OF SERVICE ({{getKiosksCount('OUT_OF_SERVICE')}})
                    </a>
                    <br/>
                    <a (click)="showLocations('WAITING')">
                        WAITING ({{getKiosksCount('WAITING')}})
                    </a>
                    <br/>
                    <a (click)="showLocations('OPERATIONAL')">
                        OPERATIONAL ({{getKiosksCount('OPERATIONAL')}})
                    </a>
                    <br/>
                    <a (click)="showLocations('MAINTENANCE')">
                        MAINTENANCE ({{getKiosksCount('MAINTENANCE')}})
                    </a>
                    <br/>
                    <a (click)="showLocations('PROVISIONING')">
                        PROVISIONING ({{getKiosksCount('PROVISIONING')}})
                    </a>
                    <br/>
                    <a (click)="showLocations('DECOMMISSIONED')">
                        DECOMMISSIONED ({{getKiosksCount('DECOMMISSIONED')}})
                    </a>
                    <br/>


                    <!--<a  (click)="showLocation(kiosk.id)" *ngFor="let kiosk of getKiosks('OUT_OF_SERVICE')">{{kiosk.name}}</a>-->

                </div>
            </div>
        </div>
    </div>

</base-modal-component>
