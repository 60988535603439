<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">

                <label for="edit_select_kiosk" class="col-md-3 control-label">Location <span
                        class="text-danger">*</span></label>
                <div class="col-md-9">

                    <select hidden
                            id="edit_select_kiosk"
                            data-style="select-btn select-btn-grey"
                            title="Select Location"
                            data-size="7"
                            [compareWith]="compareBeans"
                            [(ngModel)]="selectedKiosk"
                            (change)="onKioskChange()">
                        <option [ngValue]="kiosk" selected="selected"
                                *ngFor="let kiosk of kiosks">{{kiosk.name}}
                        </option>
                    </select>

                </div>
            </div>

            <div class="form-group" *ngIf="deviceHardware && !deviceHardware.kioskService">

                <label class="col-md-3 control-label">Device <span
                        class="text-danger">*</span></label>
                <div class="col-md-9">

                    <table class="table table-hover">
                        <thead>
                        <th>Device</th>
                        <th>Connected</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let device of devices; let index=index">
                            <td>
                                {{device.name}}
                            </td>
                            <td>
                                <div class="checkbox" style="margin: 0">
                                    <input
                                            type="checkbox"
                                            [checked]="isDeviceChecked(device)"
                                            (change)="setDeviceChecked($event, device)"
                                            id="control_1{{index}}"/>
                                    <label for="control_1{{index}}"></label>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>


        </div>
    </div>
</base-modal-component>
