import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {OrderSize, RecipeStateBean} from '../../../../model/model';
import {Helper} from '../../../../common/helper';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-recipes-component',
    templateUrl: 'kiosk-recipes.component.html'
})
export class KioskRecipesComponent implements AfterViewInit {

    appRecipes: RecipeStateBean[];
    kioskRecipes: RecipeStateBean[];
    jumbotronRecipes: RecipeStateBean[];

    recipes: RecipeStateBean[];
    kioskId: number;

    constructor(private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    get filteredRecipes() {
        if (!this.recipes) {
            return;
        }
        return this.recipes.filter((value) => {
            return value.state == 'PROVISIONED';
        });
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;

        this.kioskManageService.getRecipes(kioskId).subscribe(response => {
            if (response.success) {
                this.recipes = response.value;

                for (const recipe of this.recipes) {
                    if (!recipe.price) {
                        recipe.price = {};
                    }
                }

                this.appRecipes = Helper.copyObject(this.filteredRecipes);
                this.appRecipes.sort((r1, r2) => {
                    return r1.priorityNumApp - r2.priorityNumApp;
                });
                this.kioskRecipes = Helper.copyObject(this.filteredRecipes);
                this.kioskRecipes.sort((r1, r2) => {
                    return r1.priorityNumTablet - r2.priorityNumTablet;
                });
                this.jumbotronRecipes = Helper.copyObject(this.filteredRecipes);
                this.jumbotronRecipes.sort((r1, r2) => {
                    return r1.priorityNumJumbotron - r2.priorityNumJumbotron;
                });
            }
        });

    }

    onSizeChanged(ev, size: OrderSize, recipeState: RecipeStateBean) {
        if (ev.target.checked) {
            delete recipeState.price[size];
        } else {
            recipeState.price[size] = recipeState.defaultPrice[size];
        }
    }

    onOrderChange() {
        if (!this.recipes) {
            return;
        }

        let priority = 0;
        for (const item of this.appRecipes) {
            for (const recipe of this.recipes) {
                if (recipe.id == item.id) {
                    recipe.priorityNumApp = priority++;
                }
            }
        }

        priority = 0;
        for (const item of this.kioskRecipes) {
            for (const recipe of this.recipes) {
                if (recipe.id == item.id) {
                    recipe.priorityNumTablet = priority++;
                }
            }
        }

        priority = 0;
        for (const item of this.jumbotronRecipes) {
            for (const recipe of this.recipes) {
                if (recipe.id == item.id) {
                    recipe.priorityNumJumbotron = priority++;
                }
            }
        }
    }

    save() {
        this.kioskManageService.setRecipes(this.kioskId, this.recipes).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
