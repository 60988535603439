import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {DevicesComponent} from './devices.component';
import {AppComponent} from '../../app.component';
import {ProcessBean} from '../../model/model';

@Component({
    selector: 'device-process-edit-component',
    templateUrl: 'device-process.edit.component.html'
})

export class DeviceProcessEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields: Field[] = [
        new Field('id', 'Id').disable(),
        new Field('name', 'Name'),
        new Field('serviceProcess', 'Service', FieldType.CHECKBOX),
        new Field('allowTestInOperationMode', 'Allow Test In Operation Mode', FieldType.CHECKBOX),

    ];
    process: ProcessBean = {} as ProcessBean;
    nextParameter: string;
    origProcess: ProcessBean;

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid() && this.isParametersValid();
        };
    }

    constructor(@Inject(forwardRef(() => DevicesComponent)) private parent: DevicesComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(process: ProcessBean) {
        this.process = Helper.copyObject(process ? process : {} as ProcessBean);
        if (!this.process.parameters) {
            this.process.parameters = [];
        }
        this.origProcess = process;

        this.base.show(process ? 'Edit Process' : 'New Process');
        this.nextParameter = '';
    }

    save() {
        this.parent.saveProcess(this.process, this.origProcess);
    }

    addParameter() {
        if (this.nextParameter.trim().length == 0) {
            this.app.showError('Please enter parameter name');

        } else {
            this.process.parameters.push(this.nextParameter.trim());
            this.nextParameter = '';
        }
    }

    removeParameter(ind: number) {
        this.process.parameters.splice(ind, 1);
    }

    isParametersValid() {
        if (!this.process.parameters) {
            return true;
        }
        for (const param of this.process.parameters) {
            if (param.trim().length == 0) {
                return false;
            }
        }
        return true;
    }
}
