<div class="animated fadeIn">
    <div class="col-md-12">

        <div class="row">

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="select_kiosk">Location</label>
                        <div class="col-md-10">
                            <select (change)="onKioskChange()"
                                    [(ngModel)]="selectedKiosk"
                                    [compareWith]="compareBeans" [disabled]="kiosks.length==0"
                                    class="form-control"
                                    data-size="7"
                                    data-style="select-btn"
                                    id="select_kiosk"
                                    title="Select Location">
                                <option [ngValue]="allKiosks" selected>All Locations</option>
                                <option *ngFor="let kiosk of kiosks" [ngValue]="kiosk"
                                        selected>{{kiosk.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="select_state">State</label>
                        <div class="col-md-10">
                            <select (change)="onStateChange()"
                                    [(ngModel)]="selectedStates"
                                    class="form-control" multiple
                                    data-size="7"
                                    data-style="select-btn"
                                    id="select_state"
                                    title="Select State">
                                <option value="IN_WORKS" selected>IN WORKS</option>
                                <option value="PUBLISHED" selected>PUBLISHED</option>
                                <option value="ARCHIVED" selected>ARCHIVED</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>


        </div>
        <br/><br/>


        <div class="card">
            <div class="card-content">
                <table-component (onEdit)="onEdit($event)" (onAdd)="onAdd()"></table-component>
            </div>
        </div>
    </div>
</div>

<promotions-edit-component></promotions-edit-component>
