import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'my-app',
    templateUrl: 'app.component.html'
})

export class AppComponent {

    constructor(private alertService: ToastrService) {
        localStorage.removeItem('firebase:previous_websocket_failure');
    }

    public showError(text: string) {
        this.alertService.error(text, "Error");
    }

    public showNotification(text: string) {
        this.alertService.info(text, "Info");
    }
}
