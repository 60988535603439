import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {DeviceService} from '../../services/device.service';
import {DevicesComponent} from './devices.component';
import {AppComponent} from '../../app.component';
import {isUndefined} from 'util';
import {KioskDataService} from '../../services/kiosk-data.service';
import {TableComponent} from '../../components/table.component';
import {Column} from '../../common/column';
import {AbstractDeviceBean, ProcessBean} from '../../model/model';
import {TabsetComponent} from 'ngx-bootstrap/tabs';

@Component({
    selector: 'devices-edit-component',
    templateUrl: 'devices.edit.component.html'
})

export class DevicesEditComponent {

    device: AbstractDeviceBean = {} as AbstractDeviceBean;

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    @ViewChild(TabsetComponent, {static: false}) tabset: TabsetComponent;

    fields: Field[] = [
        new Field('id', 'Device ID').disable(),
        new Field('name', 'Name').require(),
        new Field('model', 'Model'),
        new Field('size', 'Size'),
        new Field('type', 'Type'),
        new Field('robot', 'Robot', FieldType.CHECKBOX),
        new Field('vision', 'Vision', FieldType.CHECKBOX),
    ];

    processesFields: Column[] = [
        new Column('id', 'Id'),
        new Column('name', 'Name'),
        new Column('parameters', 'Parameters').data(this, this.formatParameters),
        new Column('serviceProcess', 'Service').checkbox(),
    ];

    origDevice: AbstractDeviceBean;
    devices: AbstractDeviceBean[];
    compareBeans = Helper.compareBeans;

    constructor(private deviceService: DeviceService,
        private kioskDataService: KioskDataService,
        @Inject(forwardRef(() => DevicesComponent)) private parent: DevicesComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    open(device: AbstractDeviceBean, devices: AbstractDeviceBean[], title: string) {
        this.device = Helper.copyObject(device ? device : {} as AbstractDeviceBean);
        this.origDevice = Helper.copyObject(device ? device : {} as AbstractDeviceBean);
        this.devices = devices;

        this.table.setData(this.device.processes, this.processesFields);
        this.base.show(title);

        this.tabset.tabs[0].active = true;
    }

    isDispenser(device: AbstractDeviceBean) {
        return device.dispenserType && !isUndefined(device.dispenserType) && device.dispenserType != 'NONE';
    }

    isDispenserDevice(device: AbstractDeviceBean) {
        return device.dispenserType && !isUndefined(device.dispenserType) && device.dispenserType == 'DEVICE';
    }

    setDispenser(ev) {
        this.device.dispenserType = (ev.target.checked ? (this.isDispenser(this.origDevice) ?
            this.origDevice.dispenserType : 'INGREDIENT') : 'NONE');
    }

    setUnlocker(ev) {
        this.device.unlockerDevice =
            (ev.target.checked ? (this.origDevice.unlockerDevice ? this.origDevice.unlockerDevice : this.devices[0]) : null);
        if (!ev.target.checked) {
            this.device.lockerDevice = (ev.target.checked ? (this.origDevice.lockerDevice ? this.origDevice.lockerDevice : this.devices[0])
                : null);
        }
    }

    setLocker(ev) {
        this.device.lockerDevice = (ev.target.checked ? (this.origDevice.lockerDevice ?
            this.origDevice.lockerDevice : this.devices[0]) : null);
    }

    // removeProcess(process: Process) {
    //     let index = this.device.processes.indexOf(process, 0);
    //     if (index > -1) this.device.processes.splice(index, 1);
    // }
    //
    // addProcess(name: string, params: string) {
    //     if (!name || name.length == 0) return;
    //
    //     let process: Process = new Process();
    //     process.name = name;
    //     if (params) {
    //         process.parameters = params.split(',');
    //     } else {
    //         process.parameters = [];
    //     }
    //     this.device.processes.push(process)
    // }

    save() {
        if (this.device.id == null) {

            this.deviceService.addDevice(this.device).subscribe(response => {

                if (response.success) {
                    this.parent.loadDevices();
                    this.kioskDataService.clear();
                } else {
                    this.app.showError('Can\'t create device');
                }
            }, error => {
                this.app.showError('Can\'t create device');
            });

        } else {

            this.deviceService.updateDevice(this.device).subscribe(response => {

                if (response.success) {
                    this.parent.loadDevices();
                    this.kioskDataService.clear();

                } else {
                    this.app.showError('Can\'t update device');
                }
            }, error => {
                this.app.showError('Can\'t update device');
            });
        }
    }

    onEdit(items: ProcessBean[]) {
        if (items && items.length > 0) {
            this.parent.openProcessEditComponent(items[0]);
        }
    }

    onRemove(items) {
        for (const process of items) {

            for (let i = 0; i < this.device.processes.length; i++) {
                if (this.device.processes[i].id == process.id) {
                    this.device.processes.splice(i, 1);
                    break;
                }
            }
        }

        this.table.setData(this.device.processes, this.processesFields);
    }

    onAdd() {
        this.parent.openProcessEditComponent(null);
    }

    saveProcess(process: ProcessBean, origProcess: ProcessBean) {

        if (origProcess == null) {
            this.device.processes.push(process);

        } else {
            for (let i = 0; i < this.device.processes.length; i++) {
                if (this.device.processes[i].id == process.id) {
                    this.device.processes[i] = process;
                    break;
                }
            }
        }

        this.table.setData(this.device.processes, this.processesFields);
    }

    formatParameters(process: ProcessBean) {
        if (!process || !process.parameters) {
            return '';
        }
        let result: string = '';
        for (const param of process.parameters) {
            result += ('(' + param + '), ');
        }
        if (result.length > 2) {
            result = result.slice(0, -2);
        }
        return result;
    }

}
