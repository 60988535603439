<base-modal-component class="base-modal" (onHidden)="ngOnDestroy()">

    <div class="form-horizontal" *ngIf="deviceHardware">
        <div class="card-content">

            <div class="form-group row">
                <div class="col-md-2">
                    <label class="col-form-label" for="input-rename">Device Name</label>
                </div>
                <div class="col-md-6">
                    <input type="text"
                           [(ngModel)]="deviceHardware.name"
                           class="form-control form-control-low"
                           id="input-rename"
                           name="input-rename"/>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-outline-primary pull-right"
                            (click)="renameDevice()">Rename Device
                    </button>
                </div>
                <div class="col-md-2">
                    <button type="button" class="btn btn-outline-primary pull-right"
                            (click)="updateSoftware()">Update Software
                    </button>
                </div>
            </div>

            <tabset>
                <tab heading='History'>
                    <ng-container *ngIf="history; else loading">
                        <ng-container *ngFor="let item of history">
                            <div>{{item.date | los_angeles: 'MM/dd/yyyy hh:mm a'}}:
                                {{item.eventType}}
                                {{item.operator}} - {{item.kiosk}}

                                <span *ngIf="item.devices.length > 0">
                                    [{{item.devices.join(', ')}}]
                                </span>

                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #loading>Fetching data...</ng-template>
                </tab>
                <tab heading='Functional Tests'>

                    <div class="row" *ngIf="abstractDevices">
                        <div class="col-md-9">
                            <select hidden
                                    id="select_func"
                                    data-style="select-btn"
                                    title="Select Process"
                                    data-size="7"
                                    [(ngModel)]="selectedFunc">
                                <option [ngValue]="test.key" selected="selected"
                                        *ngFor="let test of deviceHardware.functionalTests | dictionaryValues">
                                    {{test.key}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3" *ngIf="selectedFunc">
                            <button type="button" class="btn btn-outline-primary pull-right" style="margin-bottom: 20px"
                                    style="width: 100%"
                                    (click)="executeFunc()">Execute
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group row col-md-12"
                             *ngFor="let entry of deviceHardware.functionalTests[selectedFunc]; let i = index;">
                            <div class="col-md-2">
                                <label class="col-form-label">{{entry}}</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text"
                                       [(ngModel)]="selectedValue[entry]"
                                       class="form-control form-control-low"
                                       id="edit_value_{{i}}"
                                       name="edit_value_{{i}}"/>
                            </div>
                        </div>

                    </div>

                </tab>
                <tab heading='Device Tests'>
                    <div class="row" *ngIf="abstractDevices">
                        <div class="col-md-5">
                            <select hidden
                                    id="select_abstract_device"
                                    data-style="select-btn"
                                    title="Select Device Type"
                                    data-size="7"
                                    [compareWith]="compareBeans"
                                    [(ngModel)]="selectedAbstractDevice"
                                    (change)="onAbstractDeviceChange()">

                                <ng-container *ngFor="let device of abstractDevices">
                                    <option *ngIf="isBoardDevice(device)" [ngValue]="device" selected="selected">
                                        {{device.name}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-md-4" *ngIf="selectedAbstractDevice">
                            <select hidden
                                    id="select_process"
                                    data-style="select-btn"
                                    title="Select Process"
                                    data-size="7"
                                    [(ngModel)]="selectedProcess">
                                <option [ngValue]="process" selected="selected"
                                        *ngFor="let process of selectedAbstractDevice.processes">{{process.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3" *ngIf="selectedProcess">
                            <button type="button" class="btn btn-outline-primary pull-right" style="margin-bottom: 20px"
                                    style="width: 100%"
                                    (click)="execute()">Execute Step
                            </button>
                        </div>
                    </div>


                    <div class="row" *ngIf="haveParameters()">
                        <div class="  col-md-12">
                            <div class="form-group row"
                                 *ngIf="selectedAbstractDevice && selectedAbstractDevice.dispenserType == 'INGREDIENT'">
                                <div class="col-md-2">
                                    <label class="col-form-label">type</label>
                                </div>
                                <div class="col-md-4">
                                    <select
                                            id="edit_value_sub_id"
                                            class="form-control form-control-low selectx"
                                            [(ngModel)]="selectedIngredientType"
                                            size="1">
                                        <option>Liquid</option>
                                        <option>Solid</option>
                                        <option>Powder</option>
                                        <option>BulkPowder</option>
                                        <option>FinePowder</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row"
                                 *ngIf="selectedAbstractDevice && selectedAbstractDevice.dispenserType == 'INGREDIENT'">
                                <div class="col-md-2">
                                    <label class="col-form-label">subdispenser</label>
                                </div>
                                <div class="col-md-4">
                                    <select
                                            id="edit_value_sub_id"
                                            class="form-control form-control-low selectx"
                                            [compareWith]="compareBeans"
                                            [(ngModel)]="selectedIngredientId"
                                            size="1">
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>


                            <div class="form-group row"
                                 *ngFor="let entry of selectedProcess.parameters | dictionaryValues; let i = index;">
                                <div class="col-md-2">
                                    <label class="col-form-label">{{entry.value}}</label>
                                </div>
                                <div class="col-md-4" *ngIf="entry.value != ''">
                                    <input type="text"
                                           [(ngModel)]="selectedValue[entry.value]"
                                           class="form-control form-control-low"
                                           id="edit_value_{{i}}"
                                           name="edit_value_{{i}}"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>


            <br/> <br/>
            <textarea disabled class="form-control" rows="20">{{log}}</textarea>
            <br/>

            <button type="button" class="btn btn-outline-primary pull-right"
                    (click)="clearLog()">Clear log
            </button>
            <br/>
            <br/>
        </div>
    </div>
</base-modal-component>
