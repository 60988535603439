import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ROUTES} from './sidebar-routes.config';
import {NavigationEnd, Route, Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {UploadService} from '../../services/upload.service';
import {CompaniesSelectComponent} from '../../dashboard/companies/companies.select.component';
import {UserEditComponent} from '../../dashboard/user/user.edit.component';
import {isUndefined} from 'util';
import {RouteInfo} from './sidebar.metadata';
import {DASHBOARD_MODULE_ROUTES} from '../../dashboard/dashboard.routes';
import {UserInfoBean} from '../../model/model';
import {ProfileService} from '../../services/profile.service';
import {RightsGuard} from '../../common/auth.guard';
import {DashboardService} from '../../services/dashboard.service';
import {RecipeService} from '../../services/recipe.service';
import {OperatorsService} from '../../services/operators.service';
import {KioskTemplateService} from '../../services/kiosk-template.service';
import {KioskModelService} from '../../services/kiosk-model.service';
import {KioskDataService} from '../../services/kiosk-data.service';
import {IngredientService} from '../../services/ingredient.service';
import {DeviceTemplateService} from '../../services/device-template.service';
import {DeviceService} from '../../services/device.service';
import {CategoryService} from '../../services/category.service';
import {MealplanService} from '../../services/mealplan.service';
import {SocialAuthService} from 'angularx-social-login';

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements AfterViewInit {

    @ViewChild(CompaniesSelectComponent, {static: false}) companiesSelectComponent: CompaniesSelectComponent;
    @ViewChild(UserEditComponent, {static: false}) userEditComponent: UserEditComponent;

    menuItems: any[];
    userInfo: UserInfoBean;
    isRootAdmin = RightsGuard.isSuperAdmin;
    isImageManager: boolean;

    constructor(private dataService: DataService,
        private authService: SocialAuthService,
        private uploadService: UploadService,
        private profileService: ProfileService,
        private dashboardService: DashboardService,
        private recipeService: RecipeService,
        private operatorsService: OperatorsService,
        private kioskTemplateService: KioskTemplateService,
        private kioskModelService: KioskModelService,
        private kioskDataService: KioskDataService,
        private ingredientService: IngredientService,
        private deviceTemplateService: DeviceTemplateService,
        private deviceService: DeviceService,
        private categoryService: CategoryService,
        private mealplanService: MealplanService,
        private router: Router) {

        this.refreshUser();
        this.router.events.subscribe((event) => {

            if (event instanceof NavigationEnd) {
                console.log(event.url);
                this.isImageManager = event.url.indexOf('image-manager') > 0;
            }
        });
    }

    ngAfterViewInit(): void {
        this.menuItems = ROUTES;
    }

    public refreshUser() {
        this.userInfo = this.dataService.getUserInfo();

        const lastUserInfoTime = this.dataService.getSaveUserInfoTime();
        const now = (new Date()).getTime();
        const min = (now - lastUserInfoTime) / 1000.0 / 60.0;

        if (min > 60) {
            this.profileService.getProfile(0, this.userInfo.user.id).subscribe(response => {
                if (response && response.success) {
                    this.userInfo.profile = response.value;
                    this.dataService.setUserInfo(this.userInfo);
                }
            });
        }
        this.profileService.me().subscribe(response => {
            if (response && response.success) {
                this.userInfo.user = response.value;
                this.dataService.setUserInfo(this.userInfo);
            }
        });
    }

    public getUserImage() {
        if (this.userInfo && this.userInfo.profile.profilePicture) {
            return this.userInfo.profile.profilePicture;
        }
        return '../../assets/img/avatars/0.jpg';
    }

    private haveAccess(item: RouteInfo) {

        for (const route of DASHBOARD_MODULE_ROUTES[0].children) {
            if (item.path) {
                const itemPath = item.path.slice(item.path.lastIndexOf('/') + 1);

                if (route.data) {
                    if (route.path == itemPath) {
                        return this.userAccess(route);
                    }

                } else if (route.children) {
                    for (const childRoute of route.children) {
                        if (childRoute.data) {
                            if (childRoute.path == itemPath) {
                                return this.userAccess(childRoute);
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    public editProfile(): void {
        this.userEditComponent.open(this.dataService.getUserInfo());
    }

    public selectCompany(): void {
        this.companiesSelectComponent.open();
    }

    private childrenAccess(item: RouteInfo) {
        for (const sub of item.children) {
            if (this.haveAccess(sub)) {
                return true;
            }
        }
        return false;
    }

    private userAccess(route: Route) {

        const rights = route.data.rights;

        if (isUndefined(rights) || !this.userInfo) {
            return false;
        }
        if (route.data.rightsOnly) {
            return this.userInfo.user.rights == rights;
        }

        if (this.isRootAdmin()) {
            return true;
        }

        for (const right of rights) {
            if ((this.userInfo.user.rights & right) == right || this.userInfo.user.rights == right) {
                return true;
            }
        }
        return false;
    }

    logout(): void {
        this.dataService.clear();
        this.dashboardService.clear();
        this.recipeService.clear();
        this.operatorsService.clear();
        this.kioskTemplateService.clear();
        this.kioskModelService.clear();
        this.kioskDataService.clear();
        this.ingredientService.clear();
        this.deviceTemplateService.clear();
        this.deviceService.clear();
        this.categoryService.clear();
        this.mealplanService.clear();

        this.authService.signOut().then(res => {
            this.router.navigate(['/login']);
        }).catch(res => {
            this.router.navigate(['/login']);
        });
    }

}
