<!--suppress XmlDuplicatedId -->
<div class="form-horizontal">
    <div class="card-content">

        <ng-container *ngFor="let f of fields; let i = index;">
            <div *ngIf="available(f)" class="form-group">
                <label class="col-md-3 control-label" for="{{name}}_{{f.field}}_{{f.subfield}}">{{f.title}}
                    <span *ngIf="f.required" class="text-danger">*</span>
                </label>
                <div class="col-md-9">

                    <div *ngIf="!isObject(_value[f.field])">
                        <input *ngIf="isInput(f.fieldType)"
                               [(ngModel)]="_value[f.field]" [disabled]="canDisable(f)"
                               [required]="f.required"
                               [type]="getType(f.fieldType)"
                               autocomplete="off"
                               class="form-control form-control-low"
                               id="{{name}}_{{f.field}}_{{f.subfield}}"/>

                        <input type="text" *ngIf="isDate(f.fieldType)" [disabled]="canDisable(f)"
                               (bsValueChange)="saveBsDate($event, f.field)"
                               placeholder="Date" [required]="f.required" [bsValue]="date(f.field)"
                               class="form-control" id="{{name}}_{{f.field}}_{{f.subfield}}"
                               bsDatepicker>

                        <input type="text" *ngIf="isDateTime(f.fieldType)" [disabled]="canDisable(f)"
                               (bsValueChange)="saveBsDate($event, f.field)"
                               placeholder="Date" [required]="f.required" [bsValue]="date(f.field)"
                               [bsConfig]="{ dateInputFormat: 'MMMM Do YYYY, h:mm:ss a' }"
                               class="form-control" id="{{name}}_{{f.field}}_{{f.subfield}}"
                               bsDatepicker>

                        <input *ngIf="isDuration(f.fieldType)" [disabled]="canDisable(f)"
                               [required]="f.required" [type]="getType(f.fieldType)"
                               [value]="_value[f.field] | minute"
                               autocomplete="off" class="form-control form-control-low"
                               id="{{name}}_{{f.field}}_{{f.subfield}}"/>

                        <div *ngIf="isCheckbox(f.fieldType)" class="checkbox">
                            <input
                                    [(ngModel)]="_value[f.field]"
                                    [disabled]="canDisable(f)"
                                    autocomplete="off" id="{{name}}_{{f.field}}_{{f.subfield}}"
                                    type="checkbox"/>
                            <label for="{{name}}_{{f.field}}_{{f.subfield}}"></label>
                        </div>

                        <textarea *ngIf="isTextArea(f.fieldType)"
                                  [(ngModel)]="_value[f.field]" rows="4"
                                  [required]="f.required"
                                  autocomplete="off" class="form-control form-control-low"
                                  id="{{name}}_{{f.field}}_{{f.subfield}}">
                    </textarea>

                        <input *ngIf="isColor(f.fieldType)" [disabled]="f.disabled" class="form-control form-control-low"
                               id="{{name}}_{{f.field}}_{{f.subfield}}" cpPosition="bottom"  [style.background]="_value[f.field]"
                               [required]="f.required" [(colorPicker)]="_value[f.field]"/>

                        <img (click)="selectImage(f)"
                             *ngIf="isFile(f.fieldType)"
                             [src]="_value[f.field] ? _value[f.field] : 'assets/img/no-image.png'"
                             alt="" class="img-thumbnail" height="128px"
                             width="128px"/>

                        <a rel="tooltip" title="Clear" class="remove m5" href="javascript:void(0)"
                           (click)="_value[f.field] = ''" *ngIf="isFile(f.fieldType)">
                            <i class="ti-close"></i>
                        </a>

                        <select *ngIf="isSelect(f.fieldType)"
                                [(ngModel)]="_value[f.field]"
                                [required]="f.required"
                                (change)="onSelectChange(f)"
                                autocomplete="off"
                                class="form-control form-control-low selectx" id="{{name}}_{{f.field}}_{{f.subfield}}"
                                name="{{name}}_{{f.field}}_{{f.subfield}}"
                                size="1">
                            <option *ngFor="let option of f.options" value="{{option}}">{{option}}</option>
                        </select>
                    </div>

                    <div *ngIf="isObject(_value[f.field])">
                        <input *ngIf="isInput(f.fieldType)"
                               [(ngModel)]="_value[f.field][f.subfield]"
                               [disabled]="canDisable(f)"
                               [required]="f.required"
                               [type]="getType(f.fieldType)" autocomplete="off"
                               class="form-control form-control-low"
                               id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"/>


                        <input type="text" *ngIf="isDate(f.fieldType)" [disabled]="canDisable(f)"
                               (bsValueChange)="saveBsDate($event, f.field, f.subfield)"
                               placeholder="Date" [required]="f.required" [bsValue]="date(f.field, f.subfield)"
                               class="form-control" id="{{name}}_{{f.field}}_{{f.subfield}}"
                               bsDatepicker>

                        <input type="text" *ngIf="isDateTime(f.fieldType)" [disabled]="canDisable(f)"
                               (bsValueChange)="saveBsDate($event, f.field, f.subfield)"
                               placeholder="Date" [required]="f.required" [bsValue]="date(f.field, f.subfield)"
                               class="form-control" id="{{name}}_{{f.field}}_{{f.subfield}}"
                               [bsConfig]="{ dateInputFormat: 'MM/dd/YYYY, h:mm:ss a' }"
                               bsDatepicker>

                        <input *ngIf="isDuration(f.fieldType)" [disabled]="canDisable(f)"
                               [required]="f.required"
                               [type]="getType(f.fieldType)"
                               [value]="_value[f.field][f.subfield] | minute"
                               autocomplete="off"
                               class="form-control form-control-low"
                               id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"/>

                        <div *ngIf="isCheckbox(f.fieldType)" class="checkbox">
                            <input
                                    [(ngModel)]="_value[f.field][f.subfield]"
                                    [disabled]="canDisable(f)" autocomplete="off"
                                    id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"
                                    type="checkbox"/>
                            <label for="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"></label>
                        </div>

                        <textarea *ngIf="isTextArea(f.fieldType)" rows="4"
                                  [(ngModel)]="_value[f.field][f.subfield]"
                                  [required]="f.required"
                                  autocomplete="off" class="form-control form-control-low"
                                  id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}">
                    </textarea>

                        <input *ngIf="isColor(f.fieldType)" [disabled]="f.disabled" class="form-control form-control-low"
                               id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}" cpPosition="bottom"  [style.background]="_value[f.field][f.subfield]"
                               [required]="f.required" [(colorPicker)]="_value[f.field][f.subfield]"/>

                        <img (click)="selectImage(f)"
                             *ngIf="isFile(f.fieldType)"
                             [src]="_value[f.field][f.subfield] ? _value[f.field][f.subfield] : 'assets/img/no-image.png'"
                             alt=""
                             class="img-thumbnail" height="128px" width="128px"/>


                        <select *ngIf="isSelect(f.fieldType)"
                                [(ngModel)]="_value[f.field][f.subfield]"
                                [required]="f.required"
                                class="form-control form-control-low selectx"
                                (change)="onSelectChange(f)"
                                id="{{name}}_{{f.field}}_{{f.subfield}}{{f.subfield}}"
                                size="1">
                            <option *ngFor="let option of f.options" value="{{option}}">{{option}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<image-manager-select-component></image-manager-select-component>
