<div class="animated fadeIn">
    <div class="col-md-12">

        <div *ngIf="systemConfig">

            <fields-list-component [fields]="fields" [value]="systemConfig"
                                   name="kiosk_description"></fields-list-component>

            <button (click)="save()" class="btn btn-outline-primary  pull-right"
                    type="button">Save
            </button>
        </div>
    </div>
</div>
