import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Helper} from '../../common/helper';
import {DeviceProvisioningComponent} from './device-provisioning.component';
import {BaseModalComponent} from '../../components/base.modal.component';
import {AppComponent} from '../../app.component';
import {KioskService} from '../../services/kiosk.service';
import {DeviceService} from '../../services/device.service';
import {EntityNameBean, HardwareWithProvisioningBean, KioskBean} from '../../model/model';

@Component({
    selector: 'device-provisioning-edit-component',
    templateUrl: 'device-provisioning.edit.component.html'
})

export class DeviceProvisioningEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    compareBeans = Helper.compareBeans;
    deviceHardware: HardwareWithProvisioningBean;

    kiosks: EntityNameBean[] = [];
    selectedKiosk: EntityNameBean = {} as KioskBean;
    devices: EntityNameBean[];

    isValid() {
        return () => {
            return true;
        };
    }

    constructor(private deviceService: DeviceService,
        private kioskService: KioskService,
        @Inject(forwardRef(() => DeviceProvisioningComponent)) private parent: DeviceProvisioningComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(deviceHardware: HardwareWithProvisioningBean, title: string) {
        this.deviceHardware = (deviceHardware ? Helper.copyObject(deviceHardware) : {} as HardwareWithProvisioningBean);
        this.base.show(title);

        this.devices = null;
        this.loadKiosks();
    }

    loadKiosks() {

        if (this.kiosks.length == 0) {
            this.kioskService.getActiveKiosksNames().subscribe(kiosks => {
                this.kiosks = kiosks.value;

                this.selectedKiosk = this.findKiosk();
                Helper.selectPicker('edit_select_kiosk');

                this.loadDevices();
            });
        } else {

            this.selectedKiosk = this.findKiosk();
            Helper.selectPicker('edit_select_kiosk');

            this.loadDevices();
        }
    }

    loadDevices() {

        this.kioskService.getDeviceNames(this.selectedKiosk.id).subscribe(devices => {
            this.devices = devices.value;
            Helper.selectPicker('edit_select_device');
        });
    }

    findKiosk(): EntityNameBean {
        for (const kiosk of this.kiosks) {
            if (this.deviceHardware.kioskId == kiosk.id) {
                return kiosk;
            }
        }
        return this.kiosks[0];
    }

    isDeviceChecked(device: EntityNameBean) {
        if (this.deviceHardware.devices) {
            for (const d of this.deviceHardware.devices) {
                if (device.id == d.id) {
                    return true;
                }
            }
        }
        return false;
    }

    setDeviceChecked(ev, device: EntityNameBean) {
        if (!this.deviceHardware.devices) {
            return;
        }
        if (ev.target.checked) {

            const dp = {} as EntityNameBean;
            dp.id = device.id;
            this.deviceHardware.devices.push(dp);

        } else {
            for (let i = 0; i < this.deviceHardware.devices.length; i++) {
                if (this.deviceHardware.devices[i].id == device.id) {
                    this.deviceHardware.devices.splice(i, 1);
                    break;
                }
            }
        }
    }

    onOperatorChange() {
        this.devices = [];
        this.deviceHardware.devices = [];
        this.loadKiosks();
    }

    onKioskChange() {
        this.devices = [];
        this.deviceHardware.devices = [];
        this.loadDevices();
    }

    save() {

        if (!this.selectedKiosk) {
            this.app.showError('Please select Location');
            return;
        }

        this.deviceHardware.kioskId = this.selectedKiosk.id;
        this.deviceService.updateDeviceHardwareProvisioning(this.deviceHardware).subscribe(response => {

            if (response.success) {
                this.parent.reload();
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t create Device Provisioning');
            }
        }, error => {
            this.app.showError('Can\'t create Device Provisioning');
        });

    }

}
