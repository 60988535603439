import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {AppComponent} from '../../../app.component';
import {VisionComponent} from '../vision.component';
import {LearnResult, VisionService} from '../../../services/vision.service';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {ImageCropperComponent} from 'ngx-image-cropper';
import {Helper} from '../../../common/helper';

@Component({
    selector: 'image-select-component',
    templateUrl: 'image.select.component.html'
})

export class ImageSelectComponent {

    @ViewChild('imageCropper', {static: false}) imageCropper: ImageCropperComponent;
    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    visionTags: string[];
    selectedTag: string;
    learnResult: LearnResult;

    imageCropperReady = false;
    imageBase64Data: string;
    cWidth: number;
    cHeight: number;

    constructor(private visionService: VisionService,
        private httpClient: HttpClient,
        private sanitizer: DomSanitizer,
        @Inject(forwardRef(() => VisionComponent)) private parent: VisionComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(tags: string[], learnResult: LearnResult) {

        this.imageCropperReady = false;
        this.visionTags = tags;
        this.selectedTag = this.visionTags[0];

        // for (const tag of this.visionTags) {
        //     if (tag == detectionResult.tag) {
        //         this.selectedTag = tag;
        //         break;
        //     }
        // }

        this.learnResult = learnResult;
        this.base.show('Add to Learning Data');

        this.getImage();
        Helper.selectPicker('selet_learn_tag_modal');
    }

    getImage() {

        const dfd = $.Deferred();
        const image = new Image();
        image.onload = dfd.resolve;
        image.onerror = dfd.reject;
        image.src = this.learnResult.path;

        const self = this;
        dfd.then(function() {

            const c = document.createElement('canvas');
            self.cWidth = c.width = this.width;
            self.cHeight = c.height = this.height;
            self.imageBase64Data = c.toDataURL();

            setTimeout(() => {
                self.imageCropperReady = true;
            }, 150);
        });
    }

    getBackground() {
        return this.sanitizer.bypassSecurityTrustUrl(this.learnResult.path);
    }

    save() {

        const w = this.cWidth / this.imageCropper['maxSize'].width;
        const h = this.cHeight / this.imageCropper['maxSize'].height;

        console.log(w + ' ' + h);

        const x1 = Math.round(this.imageCropper.cropper.x1 * w);
        const x2 = Math.round(this.imageCropper.cropper.x2 * w);
        const y1 = Math.round(this.imageCropper.cropper.y1 * h);
        const y2 = Math.round(this.imageCropper.cropper.y2 * h);

        console.log('XXXX ' + x1 + ' ' + x2 + ' ' + y1 + ' ' + y2);

        this.visionService.addTagToImage(this.selectedTag, this.learnResult, x1, x2, y1, y2).subscribe(response => {
            console.log(response);
            if (response) {
                // this.parent.loadVision(true);
                this.app.showNotification('Added');

            } else {
                this.app.showError('Can\'t add image');
            }
        }, error => {
            console.log(error);
            this.app.showError('Can\'t add image');
        });

    }

}
