<base-modal-component class="base-modal" (onConfirm)="save()">

    <div class="form-group row">
        <div class="form-group">
            <label for="selet_tag_modal" class="col-md-3 control-label">Tag</label>
            <div class="col-md-9">
                <select hidden
                        id="selet_tag_modal"
                        data-style="select-btn select-btn-grey"
                        title="Select Category"
                        data-size="7"
                        [(ngModel)]="selectedTag">

                    <option [ngValue]="visionTag" selected="selected"
                            *ngFor="let visionTag of visionTags">{{visionTag}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group">
            <label for="selet_device_modal" class="col-md-3 control-label">Device</label>
            <div class="col-md-9">
                <select hidden
                        id="selet_device_modal"
                        data-style="select-btn select-btn-grey"
                        title="Select Device"
                        data-size="7"
                        [(ngModel)]="selectedDevice">

                    <option [ngValue]="visionDevice" selected="selected"
                            *ngFor="let visionDevice of devices">{{visionDevice.name}}
                    </option>
                </select>
            </div>
        </div>


        <div class="form-group">
            <label for="rect_name" class="col-md-3 control-label">Name</label>
            <div class="col-md-9">
                <input
                        type="text"
                        [(ngModel)]="rect.objectName"
                        class="form-control form-control-low"
                        id="rect_name">
            </div>
        </div>
    </div>


    <div *ngIf="imageBase64Data" style="position: relative;">

        <image-cropper *ngIf="imageCropperReady"
                       #imageCropper
                       [imageBase64]="imageBase64Data"
                       [maintainAspectRatio]="false"
                       (imageLoaded)="imageLoaded()"
                       format="jpg"
        ></image-cropper>

        <img [src]="getBackground()"
             style="position: absolute; top: 0; left: 0; padding: 5px; margin: auto; bottom: 0; right: 0; max-width: 100%; max-height: 100%"/>

    </div>

</base-modal-component>
