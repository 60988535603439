<div class="animated fadeIn" *ngIf="kioskDescription">

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="select_operator" class="col-md-3 control-label">Operator</label>
                <div class="col-md-9">
                    <select class="form-control form-control-low show-menu-arrow selectx" id="select_operator"
                            [disabled]="kioskDescription.id != null"
                            [(ngModel)]="kioskDescription.operatorId">
                        <option *ngFor="let operator of operators" [value]="operator.id">
                            {{operator.name}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
    </div>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="select_model" class="col-md-3 control-label">Model</label>
                <div class="col-md-9">
                    <select class="form-control form-control-low show-menu-arrow selectx" id="select_model"
                            [(ngModel)]="kioskDescription.kioskModelId">
                        <option *ngFor="let model of modelNames" [value]="model.id">
                            {{model.name}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
    </div>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="select_ss" class="col-md-3 control-label">Serving Steps</label>
                <div class="col-md-9">
                    <select class="form-control form-control-low show-menu-arrow selectx" id="select_ss"
                            [(ngModel)]="kioskDescription.cleaningStepsVariant">
                        <option [value]="null">
                            Default
                        </option>
                        <option *ngFor="let variant of kioskDescription.cleaningStepsVariants" [value]="variant">
                            {{variant}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
    </div>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="select_ss" class="col-md-3 control-label">Serving Steps For Orders Queue Size</label>

                <div class="col-md-9">
                    <input type="number"
                           [(ngModel)]="kioskDescription.minOrdersForServingStepsVariant2"
                           class="form-control form-control-low mb5" autocomplete="off"
                           id="input_ss"/>

                    <select class="form-control form-control-low show-menu-arrow selectx" id="select_ss2"
                            [(ngModel)]="kioskDescription.cleaningStepsVariant2">
                        <option [value]="null">
                            Default
                        </option>
                        <option *ngFor="let variant of kioskDescription.cleaningStepsVariants" [value]="variant">
                            {{variant}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
    </div>


    <fields-list-component #kioskFieldsListComponent [fields]="adminMode? fields : fieldsChef" name="kiosk_description"
                           [value]="kioskDescription"></fields-list-component>


    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label class="col-md-3 control-label" for="select_mealplans">Mealplans</label>
                <div class="col-md-9">
                    <select [(ngModel)]="kioskDescription.mealplans" [compareWith]="compareBeans"
                            class="form-control form-control-low show-menu-arrow selectx" id="select_mealplans"
                            multiple>
                        <option *ngFor="let mealplan of mealplans" [value]="mealplan">
                            {{mealplan.name}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
    </div>


    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="button_get_location" class="col-md-3 control-label">Get Location by Address
                </label>
                <div class="col-md-9">

                    <button type="button" class="btn btn-primary" id="button_get_location"
                            (click)="getLocation()">Get
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="form-horizontal" *ngIf="kioskDescription.promo">
        <div class="card-content">

            <div class="form-group">
                <label for="select_recipes_list" class="col-md-3 control-label">Promo Recipes List</label>
                <div class="col-md-9">
                    <select class="form-control form-control-low show-menu-arrow selectx" id="select_recipes_list"
                            [(ngModel)]="kioskDescription.promoRecipeListId">
                        <option *ngFor="let recipesList of recipesLists" [value]="recipesList.id">
                            {{recipesList.name}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
    </div>

    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
</div>
