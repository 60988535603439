<base-modal-component class="base-modal" (onConfirm)="save()" [large]="true">

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="radio-process" class="col-md-3 control-label">Step Type</label>
                <div class="col-md-9">

                    <div class="radio radio-inline">
                        <input type="radio" name="radio-type" id="radio-process" value="option20" [checked]="isProcess"
                               (change)="onProcessChecked()">
                        <label for="radio-process">
                            Process
                        </label>
                    </div>

                    <div class="radio radio-inline">
                        <input type="radio" name="radio-type" id="radio-sequence" value="option25"
                               [checked]="!isProcess"
                               (change)="onSequnceChecked()">
                        <label for="radio-sequence">
                            Sequence
                        </label>
                    </div>

                </div>
            </div>


            <div *ngIf="isProcess">
                <div class="form-group">
                    <label for="edit_2" class="col-md-3 control-label">Device</label>
                    <div class="col-md-9">
                        <select
                                id="edit_2"
                                name="edit_2"
                                class="form-control form-control-low selectx"
                                size="1"
                                (change)="onDeviceChange($event.target)"
                                [compareWith]="compareBeans"
                                [(ngModel)]="selectedDeviceModel">
                            <option [ngValue]="d" *ngFor="let d of deviceModelsWithParams">{{d.deviceName}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label for="edit_3" class="col-md-3 control-label">Process</label>
                    <div class="col-md-9">
                        <select
                                id="edit_3"
                                name="edit_3"
                                class="form-control form-control-low selectx"
                                size="1"
                                [compareWith]="compareBeans"
                                (change)="onProcessChange($event.target)"
                                [(ngModel)]="entry.step.process">
                            <option [ngValue]="p"
                                    *ngFor="let p of (getSelectedAbstractDeviceBean() ? getSelectedAbstractDeviceBean().processes : [])">
                                {{p.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div *ngIf="haveParameters()">

                    <div class="form-group">
                        <label class="col-md-3 control-label">Parameters</label>

                        <div class="col-md-9" style="padding-top: 12px">

                            <div class="row"
                                 *ngIf="selectedDeviceModel.deviceTemplate.abstractDevice.dispenserType == 'INGREDIENT'">
                                <div class="col-md-3">
                                    <label class="col-form-label">subdispenser</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="edit_value_sub_id" class="col-form-label">Value
                                        <select
                                                id="edit_value_sub_id"
                                                class="form-control form-control-low selectx"
                                                [(ngModel)]="entry.step.subDispenserId"
                                                size="1">
                                            <option [ngValue]="i"
                                                    *ngFor="let i of range(selectedDeviceModel.numberOfIngredients)">
                                                {{i}}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div class="row"
                                 *ngFor="let parameter of entry.step.parameters | dictionaryValues; let i = index;">
                                <div class="col-md-3">
                                    <label class="col-form-label">{{parameter.key}}</label>
                                </div>
                                <div class="col-md-4" *ngIf="parameter.key != ''">
                                    <label for="edit_type_{{i}}" class="col-form-label">Value Type
                                        <select
                                                id="edit_type_{{i}}"
                                                name="edit_type_{{i}}"
                                                class="form-control form-control-low selectx"
                                                [(ngModel)]="parameter.value.type"
                                                size="1">
                                            <option [ngValue]="'INGREDIENT_QUANTITY'">INGREDIENT_QUANTITY</option>
                                            <option [ngValue]="'INGREDIENT_QUANTITY_SECONDS'">INGREDIENT_QUANTITY_SECONDS</option>
                                            <option [ngValue]="'NUMBER'">NUMBER</option>
                                            <option [ngValue]="'TEXT'">TEXT</option>
                                            <option [ngValue]="'DEVICE'">DEVICE</option>
                                            <option [ngValue]="'DISPENSER'">DISPENSER</option>
                                            <option [ngValue]="'DEVICE_PLACE'">DEVICE_PLACE</option>
                                            <option [ngValue]="'DELIVERY_PLACE'">DELIVERY_PLACE</option>
                                            <option [ngValue]="'VENDING_MACHINE_PRODUCT'">VENDING_MACHINE_PRODUCT</option>
                                        </select>
                                    </label>
                                </div>
                                <div class="col-md-5" *ngIf="parameter.key != ''"
                                     [hidden]="!isNumberType(parameter.value.type, parameter.key)">
                                    <label for="edit_value_{{i}}" class="col-form-label">Value
                                        <input type="text"
                                               [(ngModel)]="parameter.value.value"
                                               class="form-control form-control-low "
                                               id="edit_value_{{i}}"
                                               name="edit_value_{{i}}"/>
                                    </label>
                                </div>
                                <div class="col-md-5" *ngIf="parameter.key != ''"
                                     [hidden]="!isTextType(parameter.value.type, parameter.key)">
                                    <label for="edit_value_{{i}}" class="col-form-label">Value
                                        <input type="text"
                                               [(ngModel)]="parameter.value.stringValue"
                                               class="form-control form-control-low "
                                               id="edit_text_value_{{i}}"
                                               name="edit_value_{{i}}"/>
                                    </label>
                                </div>
                                <div class="col-md-5" *ngIf="parameter.key != ''"
                                     [hidden]="!isDeviceOrPlace(parameter.value.type, parameter.key)">
                                    <label for="edit_device_{{i}}" class="col-form-label">Value
                                        <select
                                                id="edit_device_{{i}}"
                                                name="edit_device_{{i}}"
                                                class="form-control form-control-low selectx"
                                                [(ngModel)]="parameter.value.value"
                                                size="1">
                                            <option [ngValue]="device.id" *ngFor="let device of deviceModels">
                                                {{device.deviceName}}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="!isProcess">

                <div class="form-group">
                    <label for="edit_2" class="col-md-3 control-label">Sequence</label>
                    <div class="col-md-9">
                        <select
                                id="edit_0"
                                name="edit_0"
                                class="form-control form-control-low selectx"
                                size="1"
                                [compareWith]="compareBeans"
                                [(ngModel)]="selectedSequence">
                            <option [ngValue]="d" *ngFor="let d of sequences">{{d.description}}</option>
                        </select>
                    </div>
                </div>

            </div>
        </div>
    </div>
</base-modal-component>
