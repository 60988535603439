import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field} from '../../common/field';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {KioskModelDetailsComponent} from './kiosk-model-details.component';
import {RobotCommandService} from '../../services/robot-command.service';
import {Helper} from '../../common/helper';
import {DeviceModelBean, KioskModelBean, ProcessParameterValueBean, RobotCommandBean} from '../../model/model';

@Component({
    selector: 'robot-command-edit-component',
    templateUrl: 'robot-command.edit.component.html'
})

export class RobotCommandEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields: Field[] = [
        new Field('id', 'ID').require(),
    ];
    kioskModel: KioskModelBean;
    robotCommand: RobotCommandBean = {} as RobotCommandBean;
    robotDevices: DeviceModelBean[];

    compareBeans = Helper.compareBeans;
    selectedRobotDevice: DeviceModelBean;
    origRobotCommand: RobotCommandBean;

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private robotCommandService: RobotCommandService,
        @Inject(forwardRef(() => KioskModelDetailsComponent)) private parent: KioskModelDetailsComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(kioskModel: KioskModelBean, robotCommand: RobotCommandBean, title: string) {
        this.kioskModel = kioskModel;
        this.robotCommand = (robotCommand ? Helper.copyObject(robotCommand) : {} as RobotCommandBean);
        this.origRobotCommand = robotCommand;

        this.robotDevices = [];
        for (const device of this.kioskModel.devices) {
            if (device.deviceTemplate.abstractDevice.robot) {
                if (!this.selectedRobotDevice) {
                    this.selectedRobotDevice = device;
                }
                if (this.robotCommand.deviceModelId == device.id) {
                    this.selectedRobotDevice = device;
                }

                this.robotDevices.push(device);
            }
        }
        this.createParameters();
        this.base.show(title);
    }

    haveParameters() {
        if (!this.robotCommand.process || !this.robotCommand.process.parameters) {
            return false;
        }

        for (const param in this.robotCommand.parameters) {
            return true;
        }
        return false;
    }

    createParameters() {
        this.robotCommand.parameters = {};
        if (this.robotCommand.process) {
            for (const param of this.robotCommand.process.parameters) {
                this.robotCommand.parameters[param] = {} as ProcessParameterValueBean;
            }
        }
    }

    isNumberType(value) {
        return value == 'NUMBER';
    }

    isDeviceOrPlace(value) {
        return value == 'DEVICE' || value == 'DEVICE_PLACE';
    }

    save() {

        this.robotCommand.deviceModelId = this.selectedRobotDevice.id;

        if (this.origRobotCommand == null) {

            this.robotCommandService.addRobotCommand(this.kioskModel.id, this.robotCommand).subscribe(response => {

                if (response.success) {
                    this.parent.loadRobotCommands();
                } else {
                    this.app.showError('Can\'t create command');
                }
            }, error => {
                this.app.showError('Can\'t create command');
            });

        } else {

            this.robotCommandService.updateRobotCommand(this.kioskModel.id, this.robotCommand).subscribe(response => {

                if (response.success) {
                    this.parent.loadRobotCommands();
                } else {
                    this.app.showError('Can\'t update command');
                }
            }, error => {
                this.app.showError('Can\'t update command');
            });
        }
    }
}
