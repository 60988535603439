import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {OrderBean} from '../../model/model';
import {OrderService} from '../../services/order.service';
import {RightsGuard} from '../../common/auth.guard';
import {AppComponent} from '../../app.component';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';

@Component({
    selector: 'orders-quickview-component',
    templateUrl: 'orders.quickview.component.html'
})

export class OrdersQuickViewComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    order: OrderBean;

    constructor(private orderService: OrderService,
        private coolDialogs: NgxCoolDialogsService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(order: OrderBean) {

        this.order = (order ? order : {} as OrderBean);
        this.base.show('Order #' + order.id);

        console.log(this.order);
    }

    openWithId(orderId: number) {

        this.order = {} as OrderBean;
        this.base.show('Order #' + orderId);
        this.orderService.getOrder(orderId).subscribe(response => {
            if (response.success) {
                this.order = response.value;
            }
        });
    }

    isSuperAdmin() {
        return RightsGuard.isSuperAdmin();
    }

    isDeveloper() {
        return RightsGuard.isDeveloper();
    }

    save() {
        this.orderService.setOrderNote(this.order.id, this.order.note).subscribe(response => {
            console.log(response);
        });
    }

    refund() {

        this.coolDialogs.confirm('Are you sure you want to refund this order?', {
            okButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).subscribe(res => {
            if (res) {
                this.orderService.refund(this.order.id).subscribe(response => {
                    if (response.success) {
                        this.app.showNotification('Refunded');
                    } else {
                        this.app.showError('Refund failed');
                    }
                }, error => {
                    this.app.showError('Refund failed');
                });
            } else {
            }
        });

    }

    pickup() {
        this.coolDialogs.confirm('Are you sure you want to pickup this order?', {
            okButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).subscribe(res => {
            if (res) {
                this.orderService.pickup(this.order.id).subscribe(response => {
                    if (response.success) {
                        this.app.showNotification('Picked up');
                    } else {
                        this.app.showError('Pickup failed');
                    }
                }, error => {
                    this.app.showError('Pickup failed');
                });
            } else {
            }
        });
    }
}
