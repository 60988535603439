import {Component, ViewChild} from '@angular/core';
import {BaseModalComponent} from './base.modal.component';
import {AgmMap} from '@agm/core';

@Component({
    selector: 'map-modal-component',
    templateUrl: 'map.modal.component.html'
})

export class MapModalComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(AgmMap, {static: false}) sebmGoogleMap: AgmMap;

    lat = 0;
    lng = 0;
    firstOpen = true;

    constructor() {
    }

    open(lat: number, lng: number) {

        this.lat = lat;
        this.lng = lng;

        this.base.setFooterVisibility(false);
        this.base.setHeaderVisibility(false);
        this.base.show('');

        if (this.firstOpen) {
            this.firstOpen = false;
            setTimeout(() => {
                this.sebmGoogleMap.triggerResize();
            }, 100);
        }
    }

}
