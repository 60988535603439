import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {KioskTemplatesEditComponent} from './kiosk-templates.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {KioskTemplateService} from '../../services/kiosk-template.service';
import {DeviceTemplateService} from '../../services/device-template.service';
import {DeviceTemplateBean, KioskTemplateBean} from '../../model/model';

@Component({
    templateUrl: 'kiosk-templates.component.html',
})

export class KioskTemplatesComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild('kioskTemplatesEditComponent', {static: false}) modal: KioskTemplatesEditComponent;

    fields: Column[] = [
        new Column('id', 'Id'),
        new Column('name', 'Name'),
        new Column('devices', 'Devices').data(this, this.getDeviceTemplates),
        new Column('bgImage', 'Background').thumbnail(150),
        new Column('angleA', 'angleA'),
        new Column('angleB', 'angleB'),
        new Column('x0', 'x0'),
        new Column('y0', 'y0'),
        new Column('a', 'A'),
        new Column('b', 'B'),
        new Column('c', 'C'),
    ];
    deviceTemplates: DeviceTemplateBean[];

    constructor(private kioskTemplateService: KioskTemplateService,
        private deviceTemplateService: DeviceTemplateService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadDeviceTemplates();
    }

    loadDeviceTemplates() {
        this.deviceTemplateService.getDeviceTemplates().subscribe(deviceTemplates => {
            this.deviceTemplates = deviceTemplates.list;
            this.loadKioskTemplates();
        });
    }

    loadKioskTemplates() {
        this.kioskTemplateService.getKioskTemplates().subscribe(kioskTemplates => {
            this.table.setData(kioskTemplates.list, this.fields);
        });
    }

    onEdit(items: KioskTemplateBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], this.deviceTemplates, 'Edit ' + items[0].name);
        }
    }

    getDeviceTemplates(kioskTemplate: KioskTemplateBean) {

        let result = '<ul>';
        if (kioskTemplate.deviceTemplates) {
            for (const d of kioskTemplate.deviceTemplates) {
                for (const deviceTemplate of this.deviceTemplates) {
                    if (deviceTemplate.id == d.id) {
                        result += ('<li>' + deviceTemplate.name + '</li>');
                    }
                }
            }
        }
        result += '<ul/>';
        return result;
    }

    onRemove(items) {
        for (const kioskTemplate of items) {

            this.kioskTemplateService.deleteKioskTemplate(kioskTemplate).subscribe(response => {
                if (response.success) {
                    if (kioskTemplate == items.pop()) {
                        this.loadKioskTemplates();
                    }

                } else {
                    this.app.showError('Can\'t delete Kiosk Template');
                }
            }, error => {
                this.app.showError('Can\'t delete Kiosk Template');
            });
        }
    }

    onAdd() {
        this.modal.open(null, this.deviceTemplates, 'New Kiosk Template');
    }
}
