<div class="animated fadeIn">
    <div class="col-md-12">
        <tabset>
            <tab heading="Description">
                <fields-list-component [fields]="fields" name="kiosk_model_details"
                                       [value]="kioskModel"></fields-list-component>

                <div class="form-horizontal">
                    <div class="card-content">

                        <div class="form-group">
                            <label for="select_template" class="col-md-3 control-label">Template <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <select class="form-control form-control-low selectx" id="select_template"
                                                (change)="getAvailableDevices()"
                                                [(ngModel)]="selectedKioskTemplate">
                                            <option *ngFor="let template of templates" [ngValue]="template">
                                                {{template.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="Devices" [disabled]="!kioskModel.id">

                <table-component #devicesTable
                                 (onEdit)="onDeviceEdit($event)"
                                 (onRemove)="onDeviceRemove($event)"
                                 (onAdd)="onDeviceAdd()"></table-component>

            </tab>

            <tab (selectTab)="onQuestionsTabSelected($event)" [disabled]="!kioskModel.id"
                 heading="Recipe Maker Questions">
                <kiosk-model-questions-component></kiosk-model-questions-component>
            </tab>

<!--            <tab heading="Presentation" (selectTab)="onPresentationTabSelected($event)" [disabled]="!kioskModel.id">-->
<!--                <model-presentation-component></model-presentation-component>-->
<!--            </tab>-->

            <tab heading="Steps" [disabled]="!kioskModel.id">
                <tabset *ngIf="kioskModel.stepPhases">
                    <tab heading='Preparation'>
                        <steps-list-component [type]="'PREPARATION'"
                                              [stepPhases]="kioskModel.stepPhases"
                                              (onCreateStepClicked)="createStep($event)"
                                              (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                    </tab>
                    <tab heading='Cooking'>
                        <steps-list-component [type]="'COOKING'"
                                              [stepPhases]="kioskModel.stepPhases"
                                              (onCreateStepClicked)="createStep($event)"
                                              (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                    </tab>
                    <tab heading='Serving'>
                        <steps-list-component [type]="'SERVING'"
                                              [stepPhases]="kioskModel.stepPhases"
                                              (onCreateStepClicked)="createStep($event)"
                                              (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                    </tab>
                    <tab heading='Delivery'>
                        <steps-list-component [type]="'DELIVERY'"
                                              [stepPhases]="kioskModel.stepPhases"
                                              (onCreateStepClicked)="createStep($event)"
                                              (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                    </tab>
                    <tab heading='Cleaning'>
                        <steps-list-component [type]="'CLEANING'"
                                              [stepPhases]="kioskModel.stepPhases"
                                              (onCreateStepClicked)="createStep($event)"
                                              (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                    </tab>
                    <tab heading='Cleaning Alternatives'>

                        <div class="row" style="margin-bottom: 20px">
                            <div class="col-md-10" style="padding: 0">
                                <input type="text"
                                       [(ngModel)]="alternativeName"
                                       class="form-control form-control-low"
                                       id="input-rename"
                                       name="input-rename"/>
                            </div>
                            <div class="col-md-2">
                                <button type="button" class="btn btn-primary" style="width: 100%"
                                        (click)="copyServingSteps()">Clone
                                </button>
                            </div>
                        </div>

                        <tabset *ngIf="!changes">
                            <tab heading="{{kioskModel.stepPhases.cleaningStepsVariants[steps.key] ? steps.key: 'Removed'}}"
                                 [disabled]="!kioskModel.stepPhases.cleaningStepsVariants[steps.key]"
                                 *ngFor="let steps of kioskModel.stepPhases.cleaningStepsVariants | dictionaryValues">

                                <steps-list-component [type]="steps.key"
                                                      [stepPhases]="kioskModel.stepPhases"
                                                      (onCreateStepClicked)="createStep($event)"
                                                      (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>


                                <div class="row">

                                    <div class="col-md-8" style="padding: 0">
                                        <input type="text"
                                               [(ngModel)]="newName"
                                               class="form-control form-control-low"/>
                                    </div>
                                    <div class="col-md-2">
                                        <button type="button" class="btn btn-primary" style="width: 100%"
                                                (click)="rename(steps.key)">Rename
                                        </button>
                                    </div>
                                    <div class="col-md-2">
                                        <button type="button" class="btn btn-danger pull-right ml5"
                                                (click)="deleteServingStepAlternative(steps.key)">Delete
                                        </button>
                                    </div>
                                </div>
                            </tab>
                        </tabset>
                    </tab>
                    <tab heading='Wrap Up'>
                        <steps-list-component [type]="'WRAPUP'"
                                              [stepPhases]="kioskModel.stepPhases"
                                              (onCreateStepClicked)="createStep($event)"
                                              (onDependsOnEditSelected)="editDependsOn($event)"></steps-list-component>
                    </tab>
                </tabset>
            </tab>

            <tab heading="Diagnostics Steps" (selectTab)="onDiagnosticStepsTabSelected($event)"
                 [disabled]="!kioskModel.id">

                <table-component #diagnosticsStepsTable
                                 (onEdit)="onDiagnosticStepsSequenceEdit($event)"
                                 (onRemove)="onDiagnosticStepsSequenceRemove($event)"
                                 (onAdd)="onDiagnosticStepsSequenceAdd()"></table-component>
            </tab>


            <tab heading="Robot Commands" (selectTab)="onRobotCommandsTabSelected($event)" [disabled]="!kioskModel.id">

                <table-component #robotCommandsTable
                                 (onEdit)="onRobotCommandEdit($event)"
                                 (onRemove)="onRobotCommandRemove($event)"
                                 (onAdd)="onRobotCommandAdd()"></table-component>
            </tab>

            <tab heading="Notification Emails"   (selectTab)="onChangeTabSelected($event)" >
                <model-notification-emails-component></model-notification-emails-component>
            </tab>

        </tabset>

        <br/>
        <button type="button" class="btn btn-primary pull-right" [disabled]="!isValid()" id="button_save"
                (click)="save()">Save
        </button>

        <button type="button" class="btn btn-secondary" [disabled]="!isValid()"
                (click)="clone()">Clone Model
        </button>
        <br/>
        <br/>
        <br/>
    </div>
</div>

<depends-on-edit-component #dependsOnEditComponent></depends-on-edit-component>
<process-edit-component #processEditComponent (onResult)="addProcess($event)"></process-edit-component>
<diagnostics-step-edit-component #diagnosticsStepEditComponent
                                 (onResult)="addDiagnosticsStep($event)"></diagnostics-step-edit-component>
<device-model-edit-component #deviceModelEditComponent></device-model-edit-component>
<diagnostic-steps-sequence-edit-component
    #diagnosticStepsSequenceEditComponent></diagnostic-steps-sequence-edit-component>
<robot-command-edit-component
    #robotCommandEditComponent></robot-command-edit-component>
