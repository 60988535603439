import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {CategoryBean, IngredientStateBean, ManageKioskCustomRecipesBean} from '../../../../model/model';
import {Field, FieldType} from '../../../../common/field';
import {CategoryService} from '../../../../services/category.service';
import {Helper} from '../../../../common/helper';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-custom-recipes-component',
    templateUrl: 'kiosk-custom-recipes.component.html'
})
export class KioskCustomRecipesComponent implements AfterViewInit {

    fields: Field[] = [
        new Field('customRecipeEnabled', 'Enabled', FieldType.CHECKBOX),
        new Field('volumeSolidToLiquid', 'Solid To Liquid', FieldType.NUMBER),
        new Field('targetedVolumeML.SMALL', 'Targeted Volume SMALL, ml', FieldType.NUMBER),
        new Field('targetedVolumeML.MEDIUM', 'Targeted Volume MEDIUM, ml', FieldType.NUMBER),
        new Field('targetedVolumeML.LARGE', 'Targeted Volume LARGE, ml', FieldType.NUMBER),
        new Field('customRecipePrice.SMALL', 'Price SMALL', FieldType.NUMBER),
        new Field('customRecipePrice.MEDIUM', 'Price MEDIUM', FieldType.NUMBER),
        new Field('customRecipePrice.LARGE', 'Price LARGE', FieldType.NUMBER),

    ];
    categories: CategoryBean[];
    compareBeans = Helper.compareBeans;
    customRecipes: ManageKioskCustomRecipesBean;
    ingredients: IngredientStateBean[];
    kioskId: number;

    constructor(private categoryService: CategoryService,
        private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        Helper.selectPicker('select_category', 'select_solids');
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;

        this.kioskManageService.getCustomRecipes(kioskId).subscribe(response => {
            if (response.success) {
                this.customRecipes = response.value;
            }
        });
        this.kioskManageService.getIngredients(kioskId).subscribe(response => {
            if (response.success) {
                this.ingredients = response.value;
            }
        });
        this.categoryService.getCategories().subscribe(categories => {
            this.categories = categories;
            Helper.selectPicker('select_category', 'select_solids');
        });
    }

    save() {
        this.kioskManageService.setCustomRecipes(this.kioskId, this.customRecipes).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
