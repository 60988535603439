import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {ProfileService} from '../../services/profile.service';
import {ProfilesComponent} from './profiles.component';
import {AppComponent} from '../../app.component';
import * as moment from 'moment';
import 'moment-timezone';
import {Rights, RightsGroup, RightsManager} from '../../common/rights';
import {AdminRegistrationBean, UpdateCredentialsBean, UserBean, UserProfileBean} from '../../model/model';
import {RightsGuard} from '../../common/auth.guard';

@Component({
    selector: 'profiles-edit-component',
    templateUrl: 'profiles.edit.component.html'
})

export class ProfilesEditComponent {

    userProfile: UserProfileBean = {} as UserProfileBean;
    password1: string = '';
    password2: string = '';
    user: UserBean = {} as UserBean;
    companyId: number;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild('fieldsListComponent', {static: false}) fieldsListComponent: FieldsListComponent;
    @ViewChild('fieldsListComponentClient', {static: false}) fieldsListComponentClient: FieldsListComponent;

    fields: Field[] = [
        new Field('displayName', 'Display Name').require(),
        new Field('firstName', 'First Name'),
        new Field('lastName', 'Last Name'),
        new Field('birthdayDate', 'Birthday', FieldType.DATE),
        new Field('country', 'Country'),
        new Field('state', 'State'),
        new Field('city', 'City'),
        new Field('phoneNumber', 'Phone', FieldType.TEL),
        new Field('timezoneId', 'Time zone', FieldType.SELECT).setOptions(moment.tz.names()),
        new Field('profilePicture', 'Image', FieldType.FILE),

    ];

    clientFields: Field[] = [

        new Field('clientKey', 'Client Key').disable(),
        new Field('clientSecret', 'Client Secret').disable(),
    ];

    rights: RightsManager[] = [
        new RightsManager('categories', Rights.MANAGE_CATEGORIES),
        new RightsManager('ingredients', Rights.MANAGE_INGREDIENTS),
        new RightsManager('recipes', Rights.MANAGE_RECIPES),
        new RightsManager('inventory', Rights.MANAGE_DEVICES),
        new RightsManager('operators', Rights.MANAGE_OPERATORS),
        new RightsManager('kiosks', Rights.MANAGE_KIOSKS),
        new RightsManager('orders', Rights.MANAGE_ORDERS),
        new RightsManager('reviews', Rights.MANAGE_REVIEWS),
        new RightsManager('customers', Rights.MANAGE_CUSTOMERS),
        new RightsManager('hardware tests', Rights.MANAGE_HW_TESTS),
        new RightsManager('users', Rights.MANAGE_USERS),
        new RightsManager('images', Rights.MANAGE_IMAGES),
        new RightsManager('all kiosks', Rights.MANAGE_ALL_STORES),
        new RightsManager('selected kiosks', Rights.MANAGE_SELECTED_STORES),
        new RightsManager('dashboard', Rights.MANAGE_DASHBOARD),
    ];
    admin: boolean;
    isRootAdmin = RightsGuard.isSuperAdmin;
    profileTypes = Helper.profileTypes;

    constructor(private profileService: ProfileService,
        @Inject(forwardRef(() => ProfilesComponent)) private parent: ProfilesComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(user: UserBean, companyId: number, admin: boolean, title: string) {

        this.admin = admin;
        this.companyId = companyId;
        this.user = (user ? Helper.copyObject(user) : {} as UserBean);
        this.password1 = this.password2 = '';

        if (!user) {
            this.user.rights = admin ? Rights.SUPER_ADMIN : RightsGroup.ADMIN;
        }

        if (user) {
            this.profileService.getProfile(companyId, user.id).subscribe(response => {
                if (response && response.success) {
                    this.userProfile = response.value;
                } else {
                    this.userProfile = {} as UserProfileBean;
                }
            }, error2 => {
                this.userProfile = {} as UserProfileBean;
            });
        } else {
            this.userProfile = {} as UserProfileBean;
        }
        this.base.show(title);
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid() && this.passwordsValid();
        };
    }

    setRightChecked(ev, rights: number) {

        if (!this.isRightChecked(rights)) {
            this.user.rights |= rights;

        } else {
            this.user.rights = this.user.rights & (~rights);
            this.user.rights |= Rights.MANAGER;
        }
    }

    isRightChecked(rights: number) {
        if (!this.user) {
            return false;
        }
        return (this.user.rights & rights) == rights;
    }

    passwordsValid() {
        return ((this.password1.length == 0 || this.password1.length > 3) && this.password1 == this.password2);
    }

    save() {

        const dateStr = this.fieldsListComponent.getValue('birthdayDate');
        this.userProfile.birthdayDate = new Date(dateStr);

        if (this.user.id != null) {
            this.profileService.updateProfile(this.companyId, this.user.id, this.userProfile).subscribe(response => {

                if (response.success) {

                    const credentials = {} as UpdateCredentialsBean;
                    credentials.email = null;
                    credentials.password = this.password1.length == 0 ? null : Helper.encodePassword(this.password1);
                    credentials.companyId = this.companyId;
                    credentials.userId = this.user.id;
                    credentials.rights = this.user.rights;

                    this.profileService.updateCredentials(credentials).subscribe(credentialsResponse => {

                        if (credentialsResponse.success) {
                            this.parent.load();
                        } else {

                            this.app.showError('Can\'t update user');
                        }
                    }, error => {
                        this.app.showError('Can\'t update user');
                    });

                } else {

                    this.app.showError('Can\'t update user');
                }
            }, error => {
                this.app.showError('Can\'t update user');
            });

        } else {

            const reg = {} as AdminRegistrationBean;
            reg.displayName = this.userProfile.displayName;
            reg.rights = this.user.rights;
            reg.profile = this.userProfile;
            reg.email = this.userProfile.email;
            reg.password = Helper.encodePassword(this.password1);

            this.profileService.register(reg).subscribe(response => {
                if (response.success) {
                    this.parent.load();
                } else {
                    this.app.showError('Can\'t create user');
                }
            }, error => {
                this.app.showError('Can\'t create user');
            });
        }
    }

    generateSecret() {
        this.profileService.generateClientKey(this.user.id).subscribe(response => {
            console.log(response);
            if (response.success) {
                this.user = response.value;
            } else {
                this.app.showError('Can\'t generate key');
            }
        }, error => {
            this.app.showError('Can\'t generate key');
        });
    }

    setProfileType(profileType: RightsManager) {
        this.user.rights = profileType.right;
    }

    isProfileType(profileType: RightsManager) {
        return this.user.rights == profileType.right;
    }
}
