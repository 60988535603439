<div class="animated fadeIn">

    <div class="card-content table-responsive table-full-width" style="margin: 1px">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Recipe List</th>
                <th>State</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let listState of this.recipesList">
                <td>{{listState.name}}</td>
                <td>

                    <select
                            data-style="select-btn"
                            title="Select State"
                            data-size="7"
                            [(ngModel)]="listState.state">
                        <option [ngValue]="'AVAILABLE'">AVAILABLE</option>
                        <option [ngValue]="'PROVISIONED'">PROVISIONED</option>
                        <option disabled [ngValue]="'ACTIVE'">ACTIVE</option>

                    </select>
                </td>
            </tr>
            </tbody>
        </table>
    </div>


    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
    <br/>

</div>
