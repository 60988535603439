import {AfterViewInit, Component, forwardRef, Inject, Injectable, ViewChild} from '@angular/core';
import {Field} from '../../common/field';
import {MacroStepService} from '../../services/macro-step.service';
import {ProcessEditComponent} from '../../components/process.edit.component';
import {ActivatedRoute, ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot} from '@angular/router';
import {Helper} from '../../common/helper';
import {AppComponent} from '../../app.component';
import {TableComponent} from '../../components/table.component';
import {Column} from '../../common/column';
import {KioskDataService} from '../../services/kiosk-data.service';
import {AbstractDeviceBean, IngredientBean, MacroStepBean, StepBean} from '../../model/model';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';

@Injectable()
export class CanDeactivateMacroStepEdit implements CanDeactivate<MacroStepEditComponent> {
    constructor() {
    }

    canDeactivate(component: MacroStepEditComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot) {

        const isUnsaved = component.isChanged();
        if (isUnsaved) {
            component.showSaveConfirmation(nextState.url);
        }
        return !isUnsaved;
    }
}

@Component({
    templateUrl: 'macro-step-edit.component.html',
})

export class MacroStepEditComponent implements AfterViewInit {

    @ViewChild(ProcessEditComponent, {static: false}) modal: ProcessEditComponent;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    step: MacroStepBean = {} as MacroStepBean;
    fields: Field[] = [];
    devices: AbstractDeviceBean[];
    ingredients: IngredientBean[] = [];
    macroStepId: number;
    compareBeans = Helper.compareBeans;

    stepFields: Column[] = [
        new Column('id', 'Id'),
        new Column('device.name', 'Device Name'),
        new Column('process.name', 'Process Name'),
        new Column('', 'Parameters').data(this, this.getStepParametersString),
        new Column('description', 'Description'),
    ];
    origStep: MacroStepBean;

    constructor(private stepService: MacroStepService,
        private kioskDataService: KioskDataService,
        private router: Router,
        private route: ActivatedRoute,
        private coolDialogs: NgxCoolDialogsService,
        @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {

        this.fields = [
            new Field('description', 'Description').require(),
        ];
    }

    ngAfterViewInit(): void {

        if (this.route) {
            this.route.queryParams.subscribe(params => {
                this.macroStepId = params['id'] != null ? params['id'] : 0;
            });
        }

        this.kioskDataService.getData().subscribe(kioskData => {

            this.ingredients = kioskData.value.ingredients;
            this.devices = kioskData.value.devices;

            for (const macroStep of kioskData.value.macroSteps) {
                if (macroStep.id == this.macroStepId) {
                    this.step = Helper.copyObject(macroStep);
                    this.origStep = Helper.copyObject(macroStep);

                    this.updateTable();
                    setTimeout(() => {
                        this.layout.navbarComponent.setTitle(this.step.description);
                    }, 100);

                    break;
                }
            }
        });
    }

    getStepParametersString(step: StepBean) {
        return Helper.getStepParametersString(step, this.devices);
    }

    isIngredient() {
        return this.step.template == 'INGREDIENT';
    }

    getDevice(id: number) {
        for (const device of this.devices) {
            if (device.id == id) {
                return device;
            }
        }
        return null;
    }

    saveMacroStep() {

        if (this.step.id == null) {

            this.stepService.addMacroStep(this.step).subscribe(response => {
                if (response.success) {

                    this.kioskDataService.clear();
                    this.origStep = this.step;
                    this.router.navigate(['/macro-steps/']);
                } else {
                    this.app.showError('Can\'t create step');
                }
            }, error => {
                this.app.showError('Can\'t create step');
            });

        } else {

            this.stepService.updateMacroStep(this.step).subscribe(response => {
                if (response.success) {

                    this.kioskDataService.clear();
                    this.origStep = this.step;
                    this.router.navigate(['/macro-steps/']);
                } else {
                    this.app.showError('Can\'t update step');
                }
            }, error => {
                this.app.showError('Can\'t update step');
            });
        }
    }

    onEdit(items: StepBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0]);
        }
    }

    onRemove(items) {
        for (const step of items) {
            const index = this.step.steps.indexOf(step);
            this.step.steps.splice(index, 1);
        }
    }

    onAdd() {
        this.modal.open(null);
    }

    addProcess(event) {

        if (event.origProcess == null) {
            this.step.steps.push(event.process);

        } else {

            const index = this.step.steps.indexOf(event.origProcess);
            this.step.steps.splice(index, 1);
            this.step.steps.splice(index, 0, event.process);
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {
            this.table.setData(this.step.steps, this.stepFields);
        }
    }

    isChanged() {
        return JSON.stringify(this.origStep) != JSON.stringify(this.step);
    }

    showSaveConfirmation(url: string) {

        this.coolDialogs.confirm('Save Steps Configuration?', {
            okButtonText: 'Yes, Save changes',
            cancelButtonText: 'No, Discard changes',
        })
            .subscribe(res => {
                if (res) {
                    this.saveMacroStep();

                } else {
                    this.origStep = this.step;
                    this.router.navigate([url]);
                }
            });
    }
}
