<div *ngIf="diagnosticsStates">
    <div class="checkbox checkbox-inline">
        <input
                type="checkbox"
                [(ngModel)]="diagnosticsStates.autoPickup"
                class="custom-control-input"
                id="auto_pickup"/>
        <label for="auto_pickup">Auto Pickup</label>
    </div>

    <div class="checkbox checkbox-inline">
        <input
                type="checkbox"
                [(ngModel)]="diagnosticsStates.autoRefill"
                class="custom-control-input"
                id="auto_refill"/>
        <label for="auto_refill">Auto Refill</label>
    </div>

    <div class="checkbox checkbox-inline">
        <input
                type="checkbox"
                [(ngModel)]="diagnosticsStates.autoReset"
                class="custom-control-input"
                id="auto_reset"/>
        <label for="auto_reset">Auto Reset</label>
    </div>

    <div class="checkbox checkbox-inline">
        <input
                type="checkbox"
                [(ngModel)]="diagnosticsStates.pseudoSimErrors"
                class="custom-control-input"
                id="pseudo_sim"/>
        <label for="pseudo_sim">Pseudo Sim Errors</label>
    </div>

    <div class="checkbox checkbox-inline">
        <input
                type="checkbox"
                [(ngModel)]="diagnosticsStates.robotSim"
                class="custom-control-input"
                id="robot_sim"/>
        <label for="pseudo_sim">Robot Simulation</label>
    </div>


    <div class="checkbox checkbox-inline">
        <input
                type="checkbox"
                [(ngModel)]="diagnosticsStates.robotAutoRecovery"
                class="custom-control-input"
                id="auto_recovery"/>
        <label for="auto_recovery">Robot Auto Recovery</label>
    </div>

    <div class="checkbox checkbox-inline">
        <input
                [(ngModel)]="diagnosticsStates.diagnosticStepStatuses"
                class="custom-control-input"
                id="diagnostic_step_statuses"
                type="checkbox"/>
        <label for="diagnostic_step_statuses">Send Diagnostic Step Statuses</label>
    </div>

    <br />

    <button (click)="saveDiagnosticStates()" class="btn btn-primary"
            type="button">Save Diagnostic States
    </button>
    <br/>
    <br/>

    <div class="checkbox">
        <input
                (change)="saveDiagnosticStates()"
                [(ngModel)]="diagnosticsStates.maintenance"
                class="custom-control-input"
                id="diagnostics_enable"
                type="checkbox"/>
        <label for="diagnostics_enable">Enable Diagnostics</label>
    </div>

</div>

<tabset>
    <tab heading='Orders' (selectTab)="onOrdersTabSelected($event)">
        <kiosk-diagnostics-orders-component
                [diagnostics]="diagnosticsStates && diagnosticsStates.maintenance"></kiosk-diagnostics-orders-component>
    </tab>
    <tab heading='Run Step' (selectTab)="onRunStepTabSelected($event)">
        <kiosk-diagnostics-run-step-component
                [diagnostics]="diagnosticsStates && diagnosticsStates.maintenance"></kiosk-diagnostics-run-step-component>
    </tab>
</tabset>



