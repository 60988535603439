<div class="animated fadeIn" [hidden]="!notificationEmails">

    <table-component (onEdit)="onEdit($event)"
                     (onAdd)="onAdd()"
                     (onRemove)="onRemove($event)"></table-component>

    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
</div>

<kiosk-notification-emails-edit-component></kiosk-notification-emails-edit-component>
