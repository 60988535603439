<div class="animated fadeIn">
    <div class="col-md-12" *ngIf="kiosk && kioskNOC">

        <button (click)="switchKioskState()" class="btn btn-primary ml5"
                type="button">{{lowerCaseAllWordsExceptFirstLetters(kioskState)}}
        </button>
        <button (click)="updateConfig()" class="btn btn-primary ml5" type="button">Configuration Update
        </button>
        <button (click)="resetKiosk()" class="btn btn-primary ml5" type="button">Reset Kiosk
        </button>
        <button (click)="unlockJars()" class="btn btn-primary ml5" type="button">Unlock Jars
        </button>
        <br/><br/>

        Diagnostics state: {{kioskNOC.diagnostics.state}}
        <br/><br/>

        <tabset>
            <tab heading="Orders">
                <div class="card-content table-responsive" *ngIf="kioskNOC.orders">
                    <table class="table table-striped table-compact">
                        <thead>
                        <tr>
                            <th class="col-lg-3">Order</th>
                            <th class="col-lg-3">State</th>
                            <th class="col-lg-3">Progress</th>
                            <th class="col-lg-2">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of kioskNOC.orders | keyvalue">
                            <td *ngIf="item.value.value as order">
                                {{order.id}}<br/>
                                {{order.recipe}}<br/>
                                {{order.customer_name}}<br/>
                                <ng-container
                                        *ngIf="order.delivery_time">{{order.delivery_time | los_angeles: 'MM/dd/yyyy hh:mm:ss a'}}</ng-container>
                                <ng-container *ngIf="!order.delivery_time">ASAP</ng-container>
                            </td>
                            <td *ngIf="item.value.value as order">
                                {{order.state}}<br/>
                            </td>
                            <td *ngIf="item.value.value as order">
                                Ready: {{order.ready_pct}}%<br/>
                                Complete: {{order.complete_pct}}%<br/>
                                <ng-container *ngIf="order.wait_time">
                                    Wait Time: {{order.wait_time / 1000 | time}}<br/>
                                </ng-container>
                                <ng-container *ngIf="order.start_date">
                                    Start Time: {{order.start_date | los_angeles: 'MM/dd/yyyy hh:mm:ss a'}}<br/>
                                    Processing Time: {{((now - order.start_date) / 1000) | time}}<br/>
                                </ng-container>
                            </td>
                            <td *ngIf="item.value.value as order">
                                <button type="button"
                                        class="btn btn-link btn-sm mt-0 black" (click)="cancelOrder(order)">Cancel
                                </button>
                                <br/>
                                <button type="button" *ngIf="order.state=='ACCEPTED'||order.state=='WAITING'"
                                        class="btn btn-link btn-sm mt-0 black" (click)="startNow(order)">Start Now
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </tab>
            <tab heading="Active Devices">

                <table class="table table-striped table-compact" *ngIf="kioskNOC.devices_snapshot">
                    <thead>
                    <tr>
                        <th class="col-lg-2">Name</th>
                        <th class="col-lg-2">State</th>
                        <th class="col-lg-2">Operation Type</th>
                        <th class="col-lg-2">Ingredient</th>
                        <th class="col-lg-2">Details/Error/Invalid</th>
                        <th class="col-lg-1">Enabled</th>
                        <th class="col-lg-1">Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let device of kioskNOC.devices_snapshot.value.devices">
                        <tr *ngIf=" device.state=='ACTIVE' && (device.operation_type!='SIMULATION' || device.abstract_name=='Jar')">
                            <td>{{device.name}}</td>
                            <td>{{device.invalid ? 'INVALID' : device.state}}</td>
                            <td>{{device.operation_type}}</td>
                            <td>

                                <div *ngFor="let ingredient of device.ingredients">
                                    <div>{{ingredient.name}}
                                        <ng-container *ngFor="let pct of [getIngredientPercent(ingredient)]">
                                            <span>{{pct | number : '1.0-2'}}%</span>
                                        </ng-container>
                                    </div>
                                    <div>
                                        <ng-container *ngIf="device.error_code!=0">
                                            <br/>
                                            {{device.error_msg}}
                                            <ng-container *ngIf="device.error_code==101">
                                                <button type="button" class="btn btn-link btn-sm mt-0 black"
                                                        [disabled]="!isMaintenance()"
                                                        (click)="refill(device)">Refill
                                                </button>
                                            </ng-container>

                                        </ng-container>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <ng-container *ngIf="device.error_code">
                                    {{device.error_code}} {{device.error_msg}}
                                </ng-container>
                                <ng-container *ngIf="device.invalid">
                                    <button type="button" class="btn btn-link btn-sm mt-0 black"
                                            [disabled]="!isMaintenance()"
                                            (click)="unlock(device.id)">Unlock
                                    </button>
                                </ng-container>
                            </td>
                            <td>
                                <input
                                        id="checkbox_small{{device.id}}"
                                        type="checkbox"
                                        (click)="setDeviceDisabled(device);false"
                                        [checked]="!device.disabled"
                                        [disabled]="!isMaintenance()"
                                        class="custom-control-input"/>
                            </td>
                            <td>
                                <button [disabled]="!isMaintenance()" type="button"
                                        class="btn btn-link btn-sm mt-0 black" (click)="editDevice(device)">Edit
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </tab>


            <tab heading="Disconnected Devices">

                <table class="table table-striped table-compact" *ngIf="kioskNOC.devices_snapshot">
                    <thead>
                    <tr>
                        <th class="col-lg-2">Name</th>
                        <th class="col-lg-2">State</th>
                        <th class="col-lg-2">Operation Type</th>
                        <th class="col-lg-2">Ingredient</th>
                        <th class="col-lg-2">Details/Error/Invalid</th>
                        <th class="col-lg-1">Enabled</th>
                        <th class="col-lg-1">Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let device of kioskNOC.devices_snapshot.value.devices">
                        <tr *ngIf=" device.state!='ACTIVE'">
                            <td>{{device.name}}</td>
                            <td>{{device.invalid ? 'INVALID' : device.state}}</td>
                            <td>{{device.operation_type}}</td>
                            <td>

                                <div *ngFor="let ingredient of device.ingredients">
                                    <div>{{ingredient.name}}
                                        <ng-container *ngFor="let pct of [getIngredientPercent(ingredient)]">
                                            <span>{{pct | number : '1.0-2'}}%</span>
                                        </ng-container>
                                    </div>
                                    <div>
                                        <ng-container *ngIf="device.error_code!=0">
                                            <br/>
                                            {{device.error_msg}}
                                            <ng-container *ngIf="device.error_code==101">
                                                <button type="button" class="btn btn-link btn-sm mt-0 black"
                                                        [disabled]="!isMaintenance()"
                                                        (click)="refill(device)">Refill
                                                </button>
                                            </ng-container>

                                        </ng-container>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <ng-container *ngIf="device.error_code">
                                    {{device.error_code}} {{device.error_msg}}
                                </ng-container>
                                <ng-container *ngIf="device.invalid">
                                    <button type="button" class="btn btn-link btn-sm mt-0 black"
                                            [disabled]="!isMaintenance()"
                                            (click)="unlock(device.id)">Unlock
                                    </button>
                                </ng-container>
                            </td>
                            <td>
                                <input
                                        id="checkbox_small_{{device.id}}"
                                        type="checkbox"
                                        (click)="setDeviceDisabled(device);false"
                                        [checked]="!device.disabled"
                                        [disabled]="!isMaintenance()"
                                        class="custom-control-input"/>
                            </td>
                            <td>
                                <button [disabled]="!isMaintenance()" type="button"
                                        class="btn btn-link btn-sm mt-0 black" (click)="editDevice(device)">Edit
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </tab>


            <tab heading="Not Connected Devices">

                <table class="table table-striped table-compact" *ngIf="notConnectedDevices">
                    <thead>
                    <tr>
                        <th class="col-lg-2">Name</th>
                        <th class="col-lg-2">IP</th>
                        <th class="col-lg-2">Kiosk Service</th>
                        <th class="col-lg-2">Alive</th>
                        <th class="col-lg-2">Last Seen</th>
                    </tr>
                    </thead>
                    <tbody>

                    <ng-container *ngFor="let device of notConnectedDevices">
                        <tr>
                            <td>{{device.name}}</td>
                            <td>{{device.ip}}</td>
                            <td>{{device.kioskService ? '&#9745;' : '&#9744;'}}</td>
                            <td>{{device.alive ? '&#9745;' : '&#9744;'}}</td>
                            <td>{{formatDate(device.lastSeen)}}</td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </tab>

            <tab heading="Recipes" (selectTab)="onRecipesTabSelected($event)">
                <kiosk-recipes-component></kiosk-recipes-component>
                <button type="button" class="btn btn-primary pull-right" id="button_save"
                        (click)="save()">Save
                </button>
                <br/><br/>
            </tab>


            <tab heading="Diagnostics" (selectTab)="onDiagnosticsTabSelected($event)">
                <kiosk-diagnostics-component></kiosk-diagnostics-component>
            </tab>

            <tab heading="Service Events" (selectTab)="onServiceEventsTabSelected($event)">
                <service-events-component class="block-component"></service-events-component>
            </tab>

        </tabset>
        <br/><br/>
    </div>
    <div *ngIf="!kiosk" class="center-block text-center"><br/>Loading kiosk...</div>
    <div *ngIf="!kioskNOC" class="center-block text-center"><br/>Loading data...</div>
</div>

<store-manager-kiosk-devices-edit-component></store-manager-kiosk-devices-edit-component>
