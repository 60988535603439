import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {Helper} from '../common/helper';
import {KioskDataService} from '../services/kiosk-data.service';
import {AbstractDeviceBean, MacroStepBean, RecipeStepBean, StepPhasesBean} from '../model/model';

@Component({
    selector: 'steps-list-component',
    templateUrl: 'steps-list.component.html'
})
export class StepsListComponent implements AfterViewInit {

    @Input() type: string;
    @Input() stepPhases: StepPhasesBean;
    @Output() onDependsOnEditSelected = new EventEmitter();
    @Output() onCreateStepClicked = new EventEmitter();

    selectedMacroStep: MacroStepBean;
    macroSteps: MacroStepBean[] = [];
    devices: AbstractDeviceBean[] = [];

    constructor(private kioskDataService: KioskDataService) {
        this.init();
    }

    init() {

        this.kioskDataService.getData().subscribe(kioskData => {
            this.devices = kioskData.value.devices;
            this.macroSteps = kioskData.value.macroSteps;
            Helper.selectPicker('edit_step_name' + this.type);
        });
    }

    getSteps(): RecipeStepBean[] {
        if (!this.stepPhases) {
            return [];
        }

        switch (this.type) {
            case 'PREPARATION':
                return this.stepPhases.preparationSteps;
            case 'COOKING':
                return this.stepPhases.cookingSteps;
            case 'SERVING':
                return this.stepPhases.servingSteps;
            case 'DELIVERY':
                return this.stepPhases.deliverySteps;
            case 'CLEANING':
                return this.stepPhases.cleaningSteps;
            case 'WRAPUP':
                return this.stepPhases.wrapupSteps;
            default:
                return this.stepPhases.cleaningStepsVariants[this.type];
        }
    }

    getStepDescription(step: RecipeStepBean) {
        return step.step ? Helper.getStepDescription(step.step, this.devices) : step.macroStep.description;
    }

    getDependsOnString(step: RecipeStepBean) {
        if (!step.dependsOn || step.dependsOn.length == 0) {
            return '-';
        }
        let result: string = '';
        for (const d of step.dependsOn.sort()) {
            result += ('' + (d) + ', ');
        }
        return result.slice(0, -2);
    }

    editDependsOn(step: RecipeStepBean) {
        this.onDependsOnEditSelected.emit({step, recipeSteps: this.getSteps()});
    }

    editStep(step: RecipeStepBean) {
        this.onCreateStepClicked.emit({step, recipeSteps: this.getSteps()});
    }

    addMacroStep() {
        if (this.selectedMacroStep) {
            const recipeStep: RecipeStepBean = {} as RecipeStepBean;
            recipeStep.macroStep = this.selectedMacroStep;
            recipeStep.dependsOn = [this.getSteps().length - 1];
            this.getSteps().push(recipeStep);
        }
    }

    createStep() {
        this.onCreateStepClicked.emit({step: null, recipeSteps: this.getSteps()});
    }

    incPriority(step: RecipeStepBean) {
        const index = this.getSteps().indexOf(step, 0);
        if (index > -1) {
            const steps = this.getSteps();
            steps.splice(index - 1, 0, steps.splice(index, 1)[0]);
        }
        this.checkDependsOn();
    }

    decPriority(step: RecipeStepBean) {
        const index = this.getSteps().indexOf(step, 0);
        if (index > -1) {
            const steps = this.getSteps();
            steps.splice(index + 1, 0, steps.splice(index, 1)[0]);
        }
        this.checkDependsOn();
    }

    removeStep(step: RecipeStepBean) {
        const index = this.getSteps().indexOf(step, 0);
        if (index > -1) {

            this.getSteps().splice(index, 1);
            this.checkDependsOn();
        }
    }

    ngAfterViewInit(): void {
    }

    checkDependsOn() {
        const steps = this.getSteps();
        let index = 0;
        for (const step of steps) {
            for (let i = step.dependsOn.length - 1; i >= 0; i--) {
                if (step.dependsOn[i] >= index) {
                    step.dependsOn.splice(i, 1);
                }
            }
            step.priorityNum = index++;
        }
    }
}
