import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {
    ChartMaintenanceCountBean,
    ChartOrderDistributionBean,
    CustomizationCountByMonthChartBean,
    IngredientWeightChartBean,
    IngredientWeightStatsBean,
    KioskBean,
    RecipeCountByMonthChartBean
} from '../../../model/model';
import {OrdersQuickViewComponent} from '../../orders/orders.quickview.component';
import * as FileSaver from 'file-saver';
import {TabDirective} from 'ngx-bootstrap';
import {KioskService} from '../../../services/kiosk.service';
import {Moment} from 'moment';
import * as moment from 'moment-timezone';
import regression from 'regression';
import {LineChartData} from '../../../common/line.chart';

@Component({
    selector: 'kiosk-stats-component',
    templateUrl: 'kiosk-stats.component.html'
})
export class KioskStatsComponent implements AfterViewInit {

    recipeId: number;
    kiosk: KioskBean;
    stats: IngredientWeightStatsBean;
    @ViewChild(OrdersQuickViewComponent, {static: false}) ordersQuickViewComponent: OrdersQuickViewComponent;

    lineChartOptions: any = {
        responsive: true,
        animation: {
            duration: 0 // general animation time
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        maintainAspectRatio: false,
    };

    stackedChartOptions = LineChartData.stackedChartOptions;
    colorsArray = [LineChartData.colorRed, LineChartData.colorGreen];

    maintenanceChartData: Array<any>;
    maintenanceChartLabels: Array<any>;

    processingTimeChartData: Array<any>;
    processingTimeChartLabels: Array<any>;

    waitingTimeChartData: Array<any>;
    waitingTimeChartLabels: Array<any>;

    ingredientErrorsChartData: Array<any>[];
    ingredientErrorsChartLabels: Array<any>[];

    statsChartData: Array<any>;
    statsChartLabels: Array<any>;

    recipesChartData: Array<any>;
    recipesChartLabels: Array<any>;

    solidIngredientsChartData: Array<any>;
    solidIngredientsChartLabels: Array<any>;
    liquidIngredientsChartData: Array<any>;
    liquidIngredientsChartLabels: Array<any>;
    powderIngredientsChartData: Array<any>;
    powderIngredientsChartLabels: Array<any>;

    recipesStats: RecipeCountByMonthChartBean[];
    ingredientsStats: IngredientWeightChartBean[];
    customizationStats: CustomizationCountByMonthChartBean[];

    customizationChartData: Array<any>;
    customizationChartLabels: Array<any>;

    customizationIngredientsChartData: Array<any>;
    customizationIngredientsChartLabels: Array<any>;

    customizationRecipeId: number;
    customizationIngredientsStats: CustomizationCountByMonthChartBean[];
    recipesDuration = 'DAILY';
    ingredientsDuration = 'DAILY';
    customizationDuration = 'DAILY';
    maintenanceDuration = 'DAILY';
    maintenanceStats: ChartMaintenanceCountBean[];
    timeStats: ChartOrderDistributionBean;

    equation: string[] = [];
    r2: number[] = [];

    dispensingDateRange = [
        moment().subtract(1, 'week').toDate(),
        moment().endOf('day').toDate()
    ];

    distributionDateRange = [
        moment().subtract(3, 'day').toDate(),
        moment().endOf('day').toDate()
    ];

    ordersDateRange = [
        moment().subtract(1, 'week').toDate(),
        moment().endOf('day').toDate()
    ];

    constructor(private orderService: OrderService,
        private kioskService: KioskService) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {

        this.kiosk = kiosk;
        this.onDispensersTabSelected(null);
    }

    onDispensersTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.loadDispensersStats();
        }
    }

    onRecipesTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.loadRecipesStats();
        }
    }

    onIngredientsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.loadIngredientsStats();
        }
    }

    onCustomizationTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.loadCustomizationStats();
        }
    }

    onMaintenanceTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.loadMaintenanceStats();
        }
    }

    onOrdersTabSelected(ev) {
    }

    onTimeTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.loadTimeStats();
        }
    }

    loadCharts() {
        if (!this.kiosk) {
            return;
        }

        this.orderService.getIngredientWeightStats(this.kiosk, this.dispensingDateRange[0].getTime(), this.dispensingDateRange[1].getTime(), this.recipeId)
            .subscribe(response => {
                if (response.success) {
                    this.stats = response.value;

                    this.statsChartData = [
                        {data: [], label: 'Desired', fill: false},
                        {data: [], label: 'Corrected', fill: false},
                        {data: [], label: 'Dispensed', fill: false},
                    ];
                    this.statsChartLabels = [];

                    for (let i = 0; i < this.stats.orderId.length; i++) {
                        if (this.stats.desiredWeight[i] != 0) {
                            this.statsChartLabels.push('' + this.stats.orderId[i]);
                            this.statsChartData[0].data.push(this.stats.desiredWeight[i]);
                            this.statsChartData[1].data.push(this.stats.correctedWeight[i]);
                            this.statsChartData[2].data.push(this.stats.dispensedWeight[i]);
                        }
                    }
                }
            });
    }

    selectData(e) {
        if (e.active.length > 0) {
            const chart = e.active[0]._chart;
            const activePoints = chart.getElementAtEvent(e.event);
            if (activePoints.length > 0) {
                const clickedElementIndex = activePoints[0]._index;
                const label = chart.data.labels[clickedElementIndex];
                this.ordersQuickViewComponent.openWithId(+label);
            }
        }

    }

    download() {

        let from = moment(this.dispensingDateRange[0].getTime()).startOf('day').unix() * 1000;
        let to = moment(this.dispensingDateRange[1].getTime()).endOf('day').unix() * 1000;

        this.orderService.getIngredientWeightStatsCSV(this.kiosk, from, to, this.recipeId)
            .subscribe(response => {

                for (const recipe of this.kiosk.recipeStates) {
                    if (recipe.id == this.recipeId) {
                        FileSaver.saveAs(response, this.kiosk.name + ' - ' + recipe.name + '.csv');
                        break;
                    }
                }

            });
    }

    downloadRecipes() {

        this.kioskService.getRecipesChartsCSV(this.kiosk.id, this.recipesDuration).subscribe(response => {
            FileSaver.saveAs(response, this.kiosk.name + ' - Recipes' + '.csv');
        });
    }

    downloadIngredients() {

        this.kioskService.getIngredientsChartsCSV(this.kiosk.id, this.ingredientsDuration).subscribe(response => {
            FileSaver.saveAs(response, this.kiosk.name + ' - Ingredients' + '.csv');
        });
    }

    downloadCustomization() {

        this.kioskService.getCustomizationChartsCSV(this.kiosk.id, this.customizationDuration).subscribe(response => {
            FileSaver.saveAs(response, this.kiosk.name + ' - Customizations' + '.csv');
        });
    }

    downloadCustomizationIngredients() {

        this.kioskService.getCustomizationIngredientsChartsCSV(this.kiosk.id, this.customizationRecipeId, this.customizationDuration)
            .subscribe(response => {
                FileSaver.saveAs(response, this.kiosk.name + ' - ' + this.getSelectedRecipeName(this.customizationRecipeId)
                    + ' - Customizations' + '.csv');
            });
    }

    downloadMaintenance() {

        this.kioskService.getMaintenanceChartsCSV(this.kiosk.id, this.maintenanceDuration).subscribe(response => {
            FileSaver.saveAs(response, this.kiosk.name + ' - Maintenance' + '.csv');
        });
    }

    downloadOrders() {

        let from = moment(this.ordersDateRange[0].getTime()).startOf('day').unix() * 1000;
        let to = moment(this.ordersDateRange[1].getTime()).endOf('day').unix() * 1000;

        this.orderService.getOrdersChartsCSV(this.kiosk, from, to).subscribe(response => {
            FileSaver.saveAs(response, this.kiosk.name + ' - Orders' + '.csv');
        });
    }

    loadCustomizationStats() {
        this.customizationChartData = null;
        this.customizationChartLabels = null;

        this.customizationIngredientsChartData = null;
        this.customizationIngredientsChartLabels = null;
        this.customizationIngredientsStats = null;
        this.customizationRecipeId = null;

        this.kioskService.getCustomizationCharts(this.kiosk.id, this.customizationDuration).subscribe(response => {

            if (response.success) {
                console.log(response.value);
                this.customizationStats = response.value;

                this.customizationStats.sort((a, b) => {
                    return (new Date(a.year, a.month, a.day).getTime() - new Date(b.year, b.month, b.day).getTime());
                });

                this.customizationChartData = [];
                this.customizationChartLabels = [];

                const recipeNames = new Set();
                for (const date of this.customizationStats) {
                    for (const key in date.data) {
                        recipeNames.add(date.data[key].name);
                    }
                }

                let ind = 0;
                recipeNames.forEach((recipe) => {

                    this.colorsArray.push(LineChartData.colorRed);
                    this.colorsArray.push(LineChartData.colorGreen);
                    this.customizationChartData.push({
                        data: [],
                        label: recipe + ' - customized',
                        fill: false,
                        stack: 'Stack ' + ind,
                    });
                    this.customizationChartData.push({
                        data: [],
                        label: recipe + ' - not customized',
                        fill: false,
                        stack: 'Stack ' + ind++,
                    });
                });

                for (const date of this.customizationStats) {
                    if (this.customizationDuration == 'MONTHLY') {
                        this.customizationChartLabels.push('' + (date.month + 1) + '/' + date.year);
                    } else {
                        this.customizationChartLabels.push('' + (date.month + 1) + '/' + (date.day) + '/' + date.year);
                    }

                    recipeNames.forEach((recipe) => {

                        let found = false;
                        for (const chartData of this.customizationChartData) {
                            if (chartData.label == recipe + ' - customized') {
                                for (const item of date.data) {
                                    if (item.name == recipe) {
                                        chartData.data.push(item.customized);
                                        found = true;
                                    }
                                }
                            }
                            if (chartData.label == recipe + ' - not customized') {
                                for (const item of date.data) {
                                    if (item.name == recipe) {
                                        chartData.data.push(item.notCustomized);
                                        found = true;
                                    }
                                }
                            }
                        }
                        if (!found) {
                            for (const chartData of this.customizationChartData) {
                                if (chartData.label == recipe + ' - customized') {
                                    chartData.data.push(0);
                                }
                                if (chartData.label == recipe + ' - not customized') {
                                    chartData.data.push(0);
                                }
                            }
                        }
                    });
                }
            }
        });
    }

    loadRecipesStats() {

        this.recipesChartData = null;
        this.recipesChartLabels = null;

        this.kioskService.getRecipesCharts(this.kiosk.id, this.recipesDuration).subscribe(response => {

            if (response.success) {
                console.log(response.value);
                this.recipesStats = response.value;

                this.recipesStats.sort((a, b) => {
                    return (new Date(a.year, a.month, a.day).getTime() - new Date(b.year, b.month, b.day).getTime());
                });

                this.recipesChartData = [];
                this.recipesChartLabels = [];

                const recipeNames = new Set<string>();
                for (const date of this.recipesStats) {
                    for (const key in date.data) {
                        recipeNames.add(key);
                    }
                }

                recipeNames.forEach((recipe) => {
                    this.recipesChartData.push({data: [], label: recipe, fill: false});
                });

                console.log(this.recipesStats);

                for (const date of this.recipesStats) {

                    if (this.recipesDuration == 'MONTHLY') {
                        this.recipesChartLabels.push('' + (date.month + 1) + '/' + date.year);
                    } else {
                        this.recipesChartLabels.push('' + (date.month + 1) + '/' + (date.day) + '/' + date.year);
                    }

                    recipeNames.forEach((recipe) => {

                        let found = false;
                        for (const chartData of this.recipesChartData) {
                            if (chartData.label == recipe) {
                                if (date.data[recipe]) {
                                    chartData.data.push(date.data[recipe]);
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (const chartData of this.recipesChartData) {
                                if (chartData.label == recipe) {
                                    chartData.data.push(0);
                                }
                            }
                        }
                    });
                }
            }
        });
    }

    loadIngredientsStats() {
        this.solidIngredientsChartData = null;
        this.solidIngredientsChartLabels = null;
        this.liquidIngredientsChartData = null;
        this.liquidIngredientsChartLabels = null;
        this.powderIngredientsChartData = null;
        this.powderIngredientsChartLabels = null;

        this.kioskService.getIngredientsCharts(this.kiosk.id, this.ingredientsDuration).subscribe(response => {

            if (response.success) {

                this.solidIngredientsChartData = [];
                this.solidIngredientsChartLabels = [];
                this.liquidIngredientsChartData = [];
                this.liquidIngredientsChartLabels = [];
                this.powderIngredientsChartData = [];
                this.powderIngredientsChartLabels = [];

                this.ingredientsStats = response.value;

                this.ingredientsStats.sort((a, b) => {
                    return (new Date(a.year, a.month, a.day).getTime() - new Date(b.year, b.month, b.day).getTime());
                });

                const liquidIngredientNames = new Set<string>();
                for (const date of this.ingredientsStats) {
                    for (const key in date.liquid) {
                        liquidIngredientNames.add(key);
                    }
                }

                liquidIngredientNames.forEach((ingredient) => {
                    this.liquidIngredientsChartData.push({data: [], label: ingredient, fill: false});
                });

                for (const date of this.ingredientsStats) {

                    if (this.ingredientsDuration == 'MONTHLY') {
                        this.liquidIngredientsChartLabels.push('' + (date.month + 1) + '/' + date.year);
                    } else {
                        this.liquidIngredientsChartLabels.push('' + (date.month + 1) + '/' + (date.day) + '/' + date.year);
                    }

                    liquidIngredientNames.forEach((ingredient) => {

                        let found = false;
                        for (const chartData of this.liquidIngredientsChartData) {
                            if (chartData.label == ingredient) {
                                if (date.liquid[ingredient]) {
                                    chartData.data.push(date.liquid[ingredient]);
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (const chartData of this.liquidIngredientsChartData) {
                                if (chartData.label == ingredient) {
                                    chartData.data.push(0);
                                }
                            }
                        }
                    });

                }

                const solidIngredientNames = new Set<string>();
                for (const date of this.ingredientsStats) {
                    for (const key in date.solid) {
                        solidIngredientNames.add(key);
                    }
                }

                solidIngredientNames.forEach((ingredient) => {
                    this.solidIngredientsChartData.push({data: [], label: ingredient, fill: false});
                });

                for (const date of this.ingredientsStats) {
                    if (this.ingredientsDuration == 'MONTHLY') {
                        this.solidIngredientsChartLabels.push('' + (date.month + 1) + '/' + date.year);
                    } else {
                        this.solidIngredientsChartLabels.push('' + (date.month + 1) + '/' + (date.day) + '/' + date.year);
                    }

                    solidIngredientNames.forEach((ingredient) => {

                        let found = false;
                        for (const chartData of this.solidIngredientsChartData) {
                            if (chartData.label == ingredient) {
                                if (date.solid[ingredient]) {
                                    chartData.data.push(date.solid[ingredient]);
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (const chartData of this.solidIngredientsChartData) {
                                if (chartData.label == ingredient) {
                                    chartData.data.push(0);
                                }
                            }
                        }
                    });
                }

                const powderIngredientNames = new Set<string>();
                for (const date of this.ingredientsStats) {
                    for (const key in date.powder) {
                        powderIngredientNames.add(key);
                    }
                }

                powderIngredientNames.forEach((ingredient) => {
                    this.powderIngredientsChartData.push({data: [], label: ingredient, fill: false});
                });

                for (const date of this.ingredientsStats) {
                    if (this.ingredientsDuration == 'MONTHLY') {
                        this.powderIngredientsChartLabels.push('' + (date.month + 1) + '/' + date.year);
                    } else {
                        this.powderIngredientsChartLabels.push('' + (date.month + 1) + '/' + (date.day) + '/' + date.year);
                    }

                    powderIngredientNames.forEach((ingredient) => {

                        let found = false;
                        for (const chartData of this.powderIngredientsChartData) {
                            if (chartData.label == ingredient) {
                                if (date.powder[ingredient]) {
                                    chartData.data.push(date.powder[ingredient]);
                                    found = true;
                                }
                            }
                        }
                        if (!found) {
                            for (const chartData of this.powderIngredientsChartData) {
                                if (chartData.label == ingredient) {
                                    chartData.data.push(0);
                                }
                            }
                        }
                    });
                }

            }
        });
    }

    loadCustomizationIngredientsChart() {
        this.customizationIngredientsChartData = null;
        this.customizationIngredientsChartLabels = null;
        this.customizationIngredientsStats = null;
        this.kioskService.getCustomizationIngredientsCharts(this.kiosk.id, this.customizationRecipeId, this.customizationDuration)
            .subscribe(response => {

                if (response.success) {
                    // console.log(response.value);
                    this.customizationIngredientsStats = response.value;

                    this.customizationIngredientsStats.sort((a, b) => {
                        return (new Date(a.year, a.month, a.day).getTime() - new Date(b.year, b.month, b.day).getTime());
                    });

                    this.customizationIngredientsChartData = [];
                    this.customizationIngredientsChartLabels = [];

                    const ingredientNames = new Set();
                    for (const date of this.customizationIngredientsStats) {
                        for (const key in date.data) {
                            ingredientNames.add(date.data[key].name);
                        }
                    }

                    if (ingredientNames.size == 0) {
                        this.customizationIngredientsStats = null;
                        return;
                    }

                    let ind = 0;
                    ingredientNames.forEach((ingredient) => {
                        // console.log(ingredient);
                        this.colorsArray.push(LineChartData.colorRed);
                        this.colorsArray.push(LineChartData.colorGreen);
                        this.customizationIngredientsChartData.push({
                            data: [],
                            label: ingredient + ' - customized',
                            fill: false,
                            stack: 'Stack ' + ind,
                        });
                        this.customizationIngredientsChartData.push({
                            data: [],
                            label: ingredient + ' - not customized',
                            fill: false,
                            stack: 'Stack ' + ind++,
                        });
                    });

                    for (const date of this.customizationIngredientsStats) {

                        if (this.customizationDuration == 'MONTHLY') {
                            this.customizationIngredientsChartLabels.push('' + (date.month + 1) + '/' + date.year);
                        } else {
                            this.customizationIngredientsChartLabels.push('' + (date.month + 1) + '/' + (date.day) + '/' + date.year);
                        }

                        ingredientNames.forEach((recipe) => {

                            let found = false;
                            for (const chartData of this.customizationIngredientsChartData) {
                                if (chartData.label == recipe + ' - customized') {
                                    for (const item of date.data) {
                                        if (item.name == recipe) {
                                            chartData.data.push(item.customized);
                                            found = true;
                                        }
                                    }
                                }
                                if (chartData.label == recipe + ' - not customized') {
                                    for (const item of date.data) {
                                        if (item.name == recipe) {
                                            chartData.data.push(item.notCustomized);
                                            found = true;
                                        }
                                    }
                                }
                            }
                            if (!found) {
                                for (const chartData of this.customizationIngredientsChartData) {
                                    if (chartData.label == recipe + ' - customized') {
                                        chartData.data.push(0);
                                    }
                                    if (chartData.label == recipe + ' - not customized') {
                                        chartData.data.push(0);
                                    }
                                }
                            }
                        });
                    }
                }
            });
    }

    private loadDispensersStats() {
        if (this.kiosk.recipeStates) {

            for (const recipe of this.kiosk.recipeStates) {
                if (recipe.state == 'PROVISIONED') {
                    this.recipeId = recipe.id;
                    setTimeout(() => {
                        this.loadCharts();
                    }, 100);
                    break;
                }
            }
        }
    }

    private getSelectedRecipeName(id: number) {
        for (const recipe of this.kiosk.recipeStates) {
            if (recipe.id == id) {
                return recipe.name;
            }
        }
        return 'Recipe';
    }

    private loadMaintenanceStats() {
        this.maintenanceChartData = null;
        this.maintenanceChartLabels = null;

        this.kioskService.getMaintenanceCharts(this.kiosk.id, this.maintenanceDuration).subscribe(response => {

            if (response.success) {
                console.log(response.value);
                this.maintenanceStats = response.value;

                this.maintenanceStats.sort((a, b) => {
                    return (new Date(a.year, a.month, a.day).getTime() - new Date(b.year, b.month, b.day).getTime());
                });

                this.maintenanceChartLabels = [];

                this.maintenanceChartData = [
                    {data: [], label: 'Count', fill: false},
                    {data: [], label: 'Time', fill: false},
                ];

                for (const date of this.maintenanceStats) {
                    if (this.maintenanceDuration == 'MONTHLY') {
                        this.maintenanceChartLabels.push('' + (date.month + 1) + '/' + date.year);
                    } else {
                        this.maintenanceChartLabels.push('' + (date.month + 1) + '/' + (date.day) + '/' + date.year);
                    }
                    this.maintenanceChartData[0].data.push(date.count);
                    this.maintenanceChartData[1].data.push(date.timeSec);
                }

            }
        });
    }

    loadTimeStats() {
        if (!this.kiosk) {
            return;
        }

        this.processingTimeChartData = null;
        this.processingTimeChartLabels = null;
        this.waitingTimeChartData = null;
        this.waitingTimeChartLabels = null;

        this.ingredientErrorsChartData = [];
        this.equation = [];
        this.r2 = [];

        this.kioskService.getTimeCharts(this.kiosk.id, this.distributionDateRange[0].getTime(), this.distributionDateRange[1].getTime()).subscribe(response => {

            if (response.success) {
                console.log(response.value);
                this.timeStats = response.value;

                this.processingTimeChartLabels = [];
                this.processingTimeChartData = [
                    {data: [], label: 'Processing Time', fill: false},
                ];
                for (const date of this.timeStats.processingTime) {
                    this.processingTimeChartLabels.push(date.x);
                    this.processingTimeChartData[0].data.push(date.y);
                }

                this.waitingTimeChartLabels = [];
                this.waitingTimeChartData = [
                    {data: [], label: 'Waiting Time', fill: false},
                ];
                for (const date of this.timeStats.waitingTime) {
                    this.waitingTimeChartLabels.push(date.x);
                    this.waitingTimeChartData[0].data.push(date.y);
                }

                this.ingredientErrorsChartLabels = [];
                this.ingredientErrorsChartData = [];

                let ind = 0;
                let pairs = [];
                for (const ing in this.timeStats.ingredientsDispenseValues) {

                    this.ingredientErrorsChartLabels[ind] = [];
                    this.ingredientErrorsChartData[ind] = [
                        {data: [], label: 'Distribution of the dispensing error for: ' + ing, fill: false},
                        {data: [], label: 'Linear regression', fill: false},
                    ];

                    let maxX = 0;
                    for (const date of this.timeStats.ingredientsDispenseValues[ing]) {
                        this.ingredientErrorsChartLabels[ind].push(date.x);
                        this.ingredientErrorsChartData[ind][0].data.push({x: date.x, y: date.y});
                        pairs.push([date.x, date.y]);
                        maxX = Math.max(maxX, date.x);
                    }

                    maxX = Math.ceil(maxX);
                    const result = regression.linear(pairs, {
                        order: 2,
                        precision: 5,
                    });
                    this.equation.push(result.string);
                    this.r2.push(result.r2);

                    for (let i = 0; i <= 200; i++) {
                        let val = maxX / 200.0 * i;
                        this.ingredientErrorsChartData[ind][1].data.push({x: val, y: result.predict(val)[1]});
                    }
                    ind++;
                }
            }
        });
    }
}
