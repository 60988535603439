import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {UserBean} from '../../../../model/model';
import {Helper} from '../../../../common/helper';
import {TableComponent} from '../../../../components/table.component';
import {Column} from '../../../../common/column';
import {KioskBlacklistEditComponent} from './kiosk-blacklist.edit.component';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-blacklist-component',
    templateUrl: 'kiosk-blacklist.component.html'
})
export class KioskBlacklistComponent implements AfterViewInit {

    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskBlacklistEditComponent, {static: false}) modal: KioskBlacklistEditComponent;

    fields: Column[] = [
        new Column('email', 'Email'),
    ];
    blacklist: string[];
    kioskId: number;

    constructor(private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;
        this.kioskManageService.getBlacklistEmails(kioskId).subscribe(response => {
            if (response.success) {
                this.blacklist = response.value;
                this.updateTable();
            }
        });
    }

    onEdit(items: UserBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Email');
        }
    }

    onRemove(items) {
        for (const step of items) {
            const index = this.blacklist.indexOf(step.email);
            this.blacklist.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Email');
    }

    addUser(initUser: UserBean, user: UserBean) {

        if (this.blacklist == null) {
            this.blacklist = [];
        }
        if (initUser.email == null) {
            this.blacklist.push(user.email);

        } else {

            let ind = -1;
            let i = 0;
            for (const email of this.blacklist) {
                if (email == initUser.email) {
                    ind = i;
                }
                i++;
            }

            if (ind >= 0) {
                this.blacklist.splice(ind, 1);
                this.blacklist.splice(ind, 0, user.email);
            }
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {

            const users: UserBean[] = [];
            if (this.blacklist) {
                for (const email of this.blacklist) {
                    const user: UserBean = {} as UserBean;
                    user.email = email;
                    users.push(user);
                }
            }

            this.table.setData(users, this.fields);
        }
    }

    save() {

        this.kioskManageService.setBlacklistEmails(this.kioskId, this.blacklist).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
