<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <fields-list-component [fields]="fields" name="kiosk_templates" [value]="kioskTemplate"></fields-list-component>
    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="select_devices" class="col-md-3 control-label">Devices</label>
                <div class="col-md-9">
                    <select
                            multiple hidden
                            id="select_devices"
                            data-style="select-btn select-btn-grey"
                            data-size="7"
                            [(ngModel)]="selectedDeviceTemplateIds">
                        <option [value]="deviceTemplate.id" *ngFor="let deviceTemplate of deviceTemplates">
                            {{deviceTemplate.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</base-modal-component>
