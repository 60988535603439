<div class="animated fadeIn">

    <fields-list-component *ngIf="rewards" #kioskFieldsListComponent [fields]="fields" name="kiosk_rewards"
                           [value]="rewards"></fields-list-component>

    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
    <br/>
</div>
