<nav class="navbar navbar-transparent navbar-absolute">
    <div class="container">
        <div class="navbar-header">
            <a class="navbar-brand" href="#">Blendid Dashboard</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <!--<li>-->
                <!--<a href="register.html">Register</a>-->
                <!--</li>-->
            </ul>
        </div>
    </div>
</nav>
<div class="wrapper wrapper-full-page">
    <div class="full-page login-page" data-color="" data-image="../../assets/img/background/background-2.jpg">
        <div class="content">
            <router-outlet></router-outlet>
        </div>

        <footer class="footer footer-transparent">
            <div class="container">
                <div class="copyright">
                    &copy; {{currentDate | date: 'yyyy'}}, made by <a href="http://6dbytes.com">6d Bytes Team</a>
                </div>
            </div>
        </footer>

        <div class="full-page-background"
             style="background-image: url(../../assets/img/background/background-2.jpg)"></div>
    </div>
</div>
