<ng-template #itemAppTemplate let-item="item" let-index="index"><span [ngClass]="item['value']['disabledForMobileApp'] ? 'cross' : ''">{{item["value"]["name"]}}</span></ng-template>
<ng-template #itemKioskTemplate let-item="item" let-index="index"><span [ngClass]="item['value']['disabledForKiosk'] ? 'cross' : ''">{{item["value"]["name"]}}</span></ng-template>


<div class="animated fadeIn">

    <tabset>
        <tab heading="Configuration">
            <div class="card-content table-responsive table-full-width" style="margin: 1px">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>Recipe</th>
                        <th>Info</th>
                        <th>Small</th>
                        <th>Medium</th>
                        <th>Large</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let recipeState of filteredRecipes; let index=index">
                        <td style="vertical-align: top">
                            <b>{{recipeState.name}}</b>
                            <div [style.color]="'red'" *ngIf="recipeState.error">{{recipeState.error}}</div>

                            <div class="form-group" style="margin-left: 20px; margin-top: 20px;">
                                <div class="checkbox">
                                    <input
                                            [(ngModel)]="recipeState.disabledForKiosk"
                                            class="custom-control-input" id="checkbox_kiosk{{index}}"
                                            type="checkbox"/>
                                    <label for="checkbox_kiosk{{index}}">Disabled&nbsp;for&nbsp;Kiosk</label>
                                </div>
                            </div>

                            <div class="form-group" style="margin-left: 20px">
                                <div class="checkbox">
                                    <input
                                            [(ngModel)]="recipeState.disabledForMobileApp"
                                            class="custom-control-input" id="checkbox_mobile{{index}}"
                                            type="checkbox"/>
                                    <label for="checkbox_mobile{{index}}">Disabled&nbsp;for&nbsp;Mobile&nbsp;App</label>
                                </div>
                            </div>

                        </td>
                        <td style="vertical-align: top">
                            <div class="form-group">
                                <label for="input_jumbotron_slideshow_timeout{{index}}">Slideshow Timeout</label>
                                <input type="number" class="form-control form-control-low"
                                       id="input_jumbotron_slideshow_timeout{{index}}"
                                       [(ngModel)]="recipeState.jumbotronSlideshowTimeout"/>
                            </div>
                            <div class="form-group">
                                <label for="input_ingredients_list{{index}}">Ingredients List</label>
                                <input type="text" class="form-control form-control-low"
                                       id="input_ingredients_list{{index}}"
                                       [(ngModel)]="recipeState.ingredientNamesList"/>
                            </div>
                        </td>
                        <td>

                            <div *ngIf="recipeState.defaultPrice['SMALL'] || recipeState.price['SMALL']">
                                <div class="form-group" style="margin-left: 20px">
                                    <div class="checkbox">
                                        <input (change)="onSizeChanged($event, 'SMALL', recipeState)"
                                               id="checkbox_small{{index}}"
                                               type="checkbox" [checked]="!recipeState.price['SMALL']"
                                               class="custom-control-input"/>
                                        <label for="checkbox_small{{index}}">Disabled</label>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="input_price_small{{index}}">Price {{recipeState.price['SMALL'] ==
                                    recipeState.defaultPrice['SMALL'] ? "(default)" : ""}}</label>
                                    <input type="number" class="form-control form-control-low"
                                           id="input_price_small{{index}}" [disabled]="!recipeState.price['SMALL']"
                                           [(ngModel)]="recipeState.price['SMALL']"/>
                                </div>

                                <!--                        <div class="form-group">-->
                                <!--                            <label for="input_discount_small{{index}}">Discount %</label>-->
                                <!--                            <input type="number" class="form-control form-control-low"-->
                                <!--                                   id="input_discount_small{{index}}" [disabled]="!recipeState.price['SMALL']"-->
                                <!--                                   [(ngModel)]="recipeState.discount['SMALL']"/>-->
                                <!--                        </div>-->
                            </div>
                        </td>
                        <td>
                            <div *ngIf="recipeState.defaultPrice['MEDIUM'] || recipeState.price['MEDIUM']">
                                <div class="form-group" style="margin-left: 20px">
                                    <div class="checkbox">
                                        <input (change)="onSizeChanged($event, 'MEDIUM', recipeState)"
                                               id="checkbox_medium{{index}}"
                                               type="checkbox" [checked]="!recipeState.price['MEDIUM']"
                                               class="custom-control-input"/>
                                        <label for="checkbox_medium{{index}}">Disabled</label>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="input_price_medium{{index}}">Price {{recipeState.price['MEDIUM'] ==
                                    recipeState.defaultPrice['MEDIUM'] ? "(default)" : ""}}</label>
                                    <input type="number" class="form-control form-control-low"
                                           id="input_price_medium{{index}}" [disabled]="!recipeState.price['MEDIUM']"
                                           [(ngModel)]="recipeState.price['MEDIUM']"/>
                                </div>

                                <!--                        <div class="form-group">-->
                                <!--                            <label for="input_discount_medium{{index}}">Discount %</label>-->
                                <!--                            <input type="number" class="form-control form-control-low"-->
                                <!--                                   id="input_discount_medium{{index}}" [disabled]="!recipeState.price['MEDIUM']"-->
                                <!--                                   [(ngModel)]="recipeState.discount['MEDIUM']"/>-->
                                <!--                        </div>-->
                            </div>
                        </td>
                        <td>
                            <div *ngIf="recipeState.defaultPrice['LARGE'] || recipeState.price['LARGE']">
                                <div class="form-group" style="margin-left: 20px">
                                    <div class="checkbox">
                                        <input (change)="onSizeChanged($event, 'LARGE', recipeState)"
                                               id="checkbox_large{{index}}"
                                               type="checkbox" [checked]="!recipeState.price['LARGE']"
                                               class="custom-control-input"/>
                                        <label for="checkbox_large{{index}}">Disabled</label>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="input_price_large{{index}}">Price {{recipeState.price['LARGE'] ==
                                    recipeState.defaultPrice['LARGE'] ? "(default)" : ""}}</label>
                                    <input type="number" class="form-control form-control-low"
                                           id="input_price_large{{index}}" [disabled]="!recipeState.price['LARGE']"
                                           [(ngModel)]="recipeState.price['LARGE']"/>
                                </div>

                                <!--                        <div class="form-group">-->
                                <!--                            <label for="input_discount_large{{index}}">Discount %</label>-->
                                <!--                            <input type="number" class="form-control form-control-low"-->
                                <!--                                   id="input_discount_large{{index}}" [disabled]="!recipeState.price['LARGE']"-->
                                <!--                                   [(ngModel)]="recipeState.discount['LARGE']"/>-->
                                <!--                        </div>-->
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </tab>
        <tab heading="Priorities">

            <div class="row">

                <div class="col-md-4  text-center">
                    Application

                    <div class="panel-body">
                        <bs-sortable
                                [(ngModel)]="appRecipes"
                                [itemTemplate]="itemAppTemplate"
                                (onChange)="onOrderChange()"
                                itemClass="list-group-item"
                                itemActiveClass="list-group-item"
                                placeholderItem="Drop recipes here"
                                placeholderClass="callout sortable placeholder"
                                wrapperClass="list-group">
                        </bs-sortable>
                    </div>
                </div>

                <div class="col-md-4  text-center">
                    Tablet

                    <div class="panel-body">
                        <bs-sortable
                                [(ngModel)]="kioskRecipes"
                                [itemTemplate]="itemKioskTemplate"
                                (onChange)="onOrderChange()"
                                itemClass="list-group-item"
                                itemActiveClass="list-group-item"
                                placeholderItem="Drop recipes here"
                                placeholderClass="callout sortable placeholder"
                                wrapperClass="list-group">
                        </bs-sortable>
                    </div>
                </div>

                <div class="col-md-4 text-center">
                    Jumbotron

                    <div class="panel-body">
                        <bs-sortable
                                [(ngModel)]="jumbotronRecipes"
                                [itemTemplate]="itemKioskTemplate"
                                (onChange)="onOrderChange()"
                                itemClass="list-group-item"
                                itemActiveClass="list-group-item"
                                placeholderItem="Drop recipes here"
                                placeholderClass="callout sortable placeholder"
                                wrapperClass="list-group">
                        </bs-sortable>
                    </div>
                </div>

            </div>

        </tab>
    </tabset>

    <br/>
    <br/>
    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
    <br/>
</div>
