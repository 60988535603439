<div class="animated fadeIn" *ngIf="workingHours">

    <tabset>
        <tab heading="Schedule">
            <div *ngFor="let i of [0, 1, 2, 3, 4, 5, 6]" class="row" style="margin-bottom: 5px">

                <div class="col-lg-2">{{days[i]}}</div>
                <div class="col-lg-6 custom-slider">

                    <ng5-slider [(highValue)]="workingHours.workingHoursTo[i]"
                                [(value)]="workingHours.workingHoursFrom[i]" [options]="options"></ng5-slider>

                </div>

                <div class="col-lg-4">

                    <div class="checkbox checkbox-inline">
                        <input
                                [(ngModel)]="workingHours.autoMaintenanceActivation[i]"
                                class="custom-control-input"
                                id="auto_maintenance_activation_{{i}}"
                                type="checkbox"/>
                        <label for="auto_maintenance_activation_{{i}}">Auto Maintenance Activation</label>
                    </div>

                </div>

            </div>
        </tab>

        <tab heading="Customizations">

            <div class="form-horizontal">
                <div class="card-content">

                    <div class="form-group">
                        <label class="col-md-3 control-label" for="working_hours_range">Date Range</label>
                        <div class="col-md-9">
                            <input (bsValueChange)="onDateRangeChanged($event)" [(ngModel)]="selectedRange"
                                   class="form-control"
                                   id="working_hours_range" bsDaterangepicker type="text"/>
                        </div>
                    </div>

                </div>
            </div>


            <table-component (onAdd)="onAdd()"
                             (onEdit)="onEdit($event)"></table-component>

        </tab>
    </tabset>

    <br/>
    <br/>
    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
</div>


<kiosk-hours-edit-component></kiosk-hours-edit-component>
