import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {AppComponent} from '../../app.component';
import {CredentialsBean} from '../../model/model';
import {PaymentService} from '../../services/payment.service';
import {Field} from '../../common/field';
import {FieldsListComponent} from '../../components/fields-list.component';

@Component({
    templateUrl: 'payment-credentials.component.html',
})

export class PaymentCredentialsComponent implements AfterViewInit {

    paymentCredentials: CredentialsBean;
    @ViewChild('paymentCredentialsFieldsListComponent', {static: false}) fieldsListComponent: FieldsListComponent;

    fields = [
        // new Field('braintreeMerchantIdSandbox', 'Braintree Merchant ID Sandbox'),
        // new Field('braintreeMerchantIdProd', 'Braintree Merchant ID Production'),
        // new Field('braintreePublicKeySandbox', 'Braintree Public Key Sandbox'),
        // new Field('braintreePublicKeyProd', 'Braintree Public Key Production'),
        // new Field('braintreePrivateKeySandbox', 'Braintree Private Key Sandbox'),
        // new Field('braintreePrivateKeyProd', 'Braintree Private Key Production'),
        new Field('stripePublicKeySandbox', 'Stripe Public Key Sandbox'),
        new Field('stripePublicKeyProd', 'Stripe Public Key Production'),
        new Field('stripePrivateKeySandbox', 'Stripe Private Key Sandbox'),
        new Field('stripePrivateKeyProd', 'Stripe Private Key Production'),
        new Field('squareAccessToken', 'Square Access Token'),
        new Field('squareAccessTokenV2', 'Square Access Token v2'),

        new Field('blackboardInstitutionId', 'Blackboard Institution Id'),
        new Field('blackboardMerchantId', 'Blackboard Merchant Id'),
        new Field('blackboardMerchantKey', 'Blackboard Merchant Key'),
        new Field('blackboardMerchantSecret', 'Blackboard Merchant Secret'),
        new Field('blackboardApplicationKey', 'Blackboard Application Key'),
        new Field('blackboardApplicationSecret', 'Blackboard Application Secret'),
    ];

    constructor(private paymentService: PaymentService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.getPaymentCredentials();
    }

    getPaymentCredentials() {
        this.paymentService.getPaymentCredentials().subscribe(response => {

            if (response) {
                this.paymentCredentials = response.value;
            }
        });
    }

    save() {
        this.paymentService.updatePaymentCredentials(this.paymentCredentials).subscribe(response => {

            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update credentials');
            }
        }, error => {
            this.app.showError('Can\'t update credentials');
        });

    }
}
