import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Field, FieldType, FieldTypeHelper} from '../common/field';
import {ImageManagerSelectComponent} from '../dashboard/image-manager/image-manager.select.component';
import * as moment from 'moment-timezone';

@Component({
    selector: 'fields-list-component',
    templateUrl: 'fields-list.component.html'
})
export class FieldsListComponent implements AfterViewInit {

    @Input() name: string = 'control';

    @Input() fields: Field[];
    dates: Date[] = [];

    constructor(private elRef: ElementRef) {
    }

    // @Input() value: any;

    _value: any;
    @Input()
    set value(value: any) {
        this._value = value;
        this.dates = [];
    }

    get value() {
        return this._value;
    }

    @ViewChild(ImageManagerSelectComponent, {static: false}) imageManagerSelectComponent: ImageManagerSelectComponent;
    selectedField: Field;

    static parseISOLocal(s) {
        const b = s.split(/\D/);
        return new Date(Date.UTC(b[0], b[1] - 1, b[2], b[3], b[4], b[5]));
    }

    public valid() {
        if (!this.fields) {
            return true;
        }
        for (const f of this.fields) {
            if (f.required && !this._value[f.field]) {
                return false;
            }
        }
        return true;
    }

    isObject(obj) {
        return obj instanceof Object;
    }

    getType(fieldType: FieldType) {
        return FieldTypeHelper.toString(fieldType);
    }

    isInput(fieldType: FieldType) {
        switch (fieldType) {
            case FieldType.TEXT:
            case FieldType.NUMBER:
            case FieldType.TIMEZONE:
            case FieldType.TEL:
                return true;
            default:
                return false;
        }
    }

    isDate(fieldType: FieldType) {
        return fieldType == FieldType.DATE;
    }

    isDateTime(fieldType: FieldType) {
        return fieldType == FieldType.DATE_TIME;
    }

    isDuration(fieldType: FieldType) {
        return fieldType == FieldType.DURATION;
    }

    isCheckbox(fieldType: FieldType) {
        return fieldType == FieldType.CHECKBOX;
    }

    isSelect(fieldType: FieldType) {
        return fieldType == FieldType.SELECT;
    }

    isTextArea(fieldType: FieldType) {
        return fieldType == FieldType.TEXT_AREA;
    }

    isColor(fieldType: FieldType) {
        return fieldType === FieldType.COLOR;
    }

    isFile(fieldType: FieldType) {
        return fieldType == FieldType.FILE;
    }

    public getValue(fieldName: string) {
        const selector = '#control_' + fieldName.replace('.', '');
        const div = this.elRef.nativeElement.querySelector(selector);
        if (div) {
            return div.value;
        }
        return null;
    }

    ngAfterViewInit(): void {
    }

    date(field: string, subfield: string = '') {
        if (!this.dates[field + subfield]) {
            if (this._value[field + subfield]) {
                this.dates[field + subfield] = new Date(subfield != '' ? this._value[field][subfield] : this._value[field]);
            }
        }
        return this.dates[field + subfield];
    }

    saveBsDate(date: Date, field, subfield: string = '') {

        if (date) {
            if (subfield != '') {
                this._value[field][subfield] = moment(date.getTime()).tz('America/Los_Angeles').valueOf();
            } else {
                this._value[field] = moment(date.getTime()).tz('America/Los_Angeles').valueOf();
            }
        }
    }

    selectImage(field: Field) {
        this.selectedField = field;
        this.imageManagerSelectComponent.open(field.subfield ? this._value[field.field][field.subfield] : this._value[field.field]);
    }

    onImageSelected(selectedImage: string) {
        if (this.selectedField.subfield) {
            this._value[this.selectedField.field][this.selectedField.subfield] = selectedImage;
        } else {
            this._value[this.selectedField.field] = selectedImage;
        }
    }

    available(field: Field) {
        return !field.ifFunction || field.ifFunction.call(null, this._value);
    }

    canDisable(field: Field) {
        return field.disabled && (!field.disableIfFunction || field.disableIfFunction.call(null, this._value));
    }

    onSelectChange(field: Field) {
        if (field.changeFunction) {
            field.changeFunction.call(field.changeObject);
        }
    }
}
