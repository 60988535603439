<div class="form-horizontal">
    <div class="card-content">

        <div class="form-group">
            <label for="presentation_from_date" class="col-md-3 control-label">From</label>
            <div class="col-md-9">
                <input type="text" id="presentation_from_date" class="form-control datetimepicker"
                       placeholder="From"/>
            </div>
        </div>

        <div class="form-group">
            <label for="presentation_to_date" class="col-md-3 control-label">To</label>
            <div class="col-md-9">
                <input type="text" id="presentation_to_date" class="form-control datetimepicker"
                       placeholder="To"/>
            </div>
        </div>

        <div class="form-group">
            <label for="presentation_to_date" class="col-md-3 control-label">Speed</label>
            <div class="col-md-9">
                <!--<nouislider [connect]="true" [min]="1" [max]="12" [step]="1" [(ngModel)]="speed"></nouislider>-->
                <ng5-slider [(value)]="speed" [options]="options"></ng5-slider>
            </div>
        </div>

        <div class="form-group">
            <label for="presentation_to_date" class="col-md-3 control-label"></label>
            <div class="col-md-9">

                <button type="button" class="btn btn-outline-primary"
                        (click)="loadSnapshots()">Load snapshots
                </button>&nbsp;

                <button type="button" class="btn btn-outline-primary"
                        (click)="goLive()">Go Live
                </button>&nbsp;

                <button type="button" class="btn btn-outline-primary" [disabled]="pauseDisabled"
                        (click)="togglePause()">{{paused ? "Resume": "Pause"}}
                </button>&nbsp;

            </div>
        </div>

    </div>
</div>


<div class="form-horizontal">
    <div class="card-content">
        <div [visTimeline]="visTimeline" [visTimelineItems]="visTimelineItems"></div>
        <br/>
        <div style="overflow-x: scroll; overflow-y: hidden; height:784px;">
            <canvas id="kioskCanvas" width="1241" height="698" style="border:1px solid #aaa"></canvas>
        </div>
    </div>
</div>