import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {KioskService} from '../../../services/kiosk.service';
import {AppComponent} from '../../../app.component';
import {KioskBean, OrderThickness} from '../../../model/model';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';

@Component({
    selector: 'kiosk-actions-component',
    templateUrl: 'kiosk-actions.component.html'
})
export class KioskActionsComponent implements AfterViewInit {

    ordersCount = 1;
    ordersThickness: OrderThickness = 'NORMAL';
    copyKioskName: string;
    kioskId: number;
    operatorId: number;

    constructor(private dataService: DataService,
        private kioskService: KioskService,
        private coolDialogs: NgxCoolDialogsService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;
        this.operatorId = operatorId;
    }

    restart() {
        this.kioskService.restartKiosk(this.kioskId, this.operatorId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Restarting...');
            } else {
                this.app.showError('Can\'t restart Kiosk');
            }
        }, error => {
            this.app.showError('Can\'t restart Kiosk');
        });
    }

    updateDeviceSW() {
        this.kioskService.updateDeviceSW(this.kioskId, this.operatorId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updating devices...');
            } else {
                this.app.showError('Can\'t update devices');
            }
        }, error => {
            this.app.showError('Can\'t update devices');
        });
    }

    updateConfig() {
        this.kioskService.updateConfig(this.kioskId, this.operatorId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updating app configuration...');
            } else {
                this.app.showError('Can\'t update app configuration');
            }
        }, error => {
            this.app.showError('Can\'t update app configuration');
        });
    }

    reset() {
        this.kioskService.resetKiosk(this.kioskId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Resetting...');
            } else {
                this.app.showError('Can\'t reset Kiosk');
            }
        }, error => {
            this.app.showError('Can\'t reset Kiosk');
        });
    }

    placeOrders() {
        if (this.ordersCount <= 0 || this.ordersCount > 150) {
            this.app.showError('Wrong orders count');
            return;
        }

        this.kioskService.placeOrders(this.kioskId, this.ordersCount, this.ordersThickness).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Orders placed');
            } else {
                this.app.showError('Can\'t place orders');
            }
        }, error => {
            this.app.showError('Can\'t place orders');
        });
    }

    resumeTakingOrders() {
        this.kioskService.toggleTakingOrders(this.kioskId, false).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Orders taking resumed');
            } else {
                this.app.showError('Can\'t switch state');
            }
        }, error => {
            this.app.showError('Can\'t switch state');
        });
    }

    pauseTakingOrders() {
        this.kioskService.toggleTakingOrders(this.kioskId, true).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Orders taking paused');
            } else {
                this.app.showError('Can\'t switch state');
            }
        }, error => {
            this.app.showError('Can\'t switch state');
        });
    }

    copyKiosk() {

        if (this.copyKioskName && this.copyKioskName.length > 0) {

            this.coolDialogs.confirm('Are you sure you want to copy kiosk?', {
                okButtonText: 'Yes',
                cancelButtonText: 'Cancel',
            })
                .subscribe(res => {
                    if (res) {
                        this.kioskService.copyKiosk(this.kioskId, this.copyKioskName).subscribe(response => {
                            if (response.success) {
                                this.app.showNotification('Copied successfully!');

                            } else {
                                this.app.showError('Can\'t copy kiosk');
                            }
                        }, error => {
                            this.app.showError('Can\'t copy kiosk');
                        });
                    } else {
                    }
                });
        } else {
            this.app.showError('Please enter kiosk name');

        }

    }

    testSpeed() {
        this.kioskService.testNetworkSpeed(this.kioskId).subscribe(response => {
            if (response.success) {
                this.app.showNotification(response.value);
            } else {
                this.app.showError('Can\'t calculate speed');
            }
        }, error => {
            this.app.showError('Can\'t calculate speed');
        });
    }
}
