<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <tabset>
        <tab heading='Profile Data' (selectTab)="onDataTabSelected($event)">
            <fields-list-component #fieldsListComponent [fields]="fields" name="user_edit" [value]="userInfo"></fields-list-component>
        </tab>
        <tab heading='Change Password' (selectTab)="onPasswordTabSelected($event)">
            <div class="form-horizontal">
                <div class="card-content">

                    <div class="form-group">
                        <label for="edit_user_password1" class="col-md-3 control-label">Password</label>
                        <div class="col-md-9">
                            <input type="password"
                                   [(ngModel)]="password1"
                                   class="form-control"
                                   id="edit_user_password1"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="edit_user_password2" class="col-md-3 control-label">Repeat password</label>
                        <div class="col-md-9">
                            <input type="password"
                                   [(ngModel)]="password2"
                                   class="form-control"
                                   id="edit_user_password2"/>
                        </div>
                    </div>

                </div>
            </div>
        </tab>
    </tabset>
</base-modal-component>
