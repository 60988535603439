import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {AppComponent} from '../../app.component';
import {UploadService} from '../../services/upload.service';
import {BaseModalComponent} from '../../components/base.modal.component';
import {environment} from '../../environments/environment';
import {FieldsListComponent} from '../../components/fields-list.component';
import {ImageBean} from '../../model/model';

@Component({
    selector: 'image-manager-select-component',
    templateUrl: 'image-manager.select.component.html',
})

export class ImageManagerSelectComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;

    images: ImageBean[];
    filteredImages: ImageBean[];

    selectedImage: ImageBean;
    tags: Set<string> = new Set<string>();
    selectedTag: string = '';

    constructor(private uploadService: UploadService,
        @Inject(forwardRef(() => FieldsListComponent)) private parent: FieldsListComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    loadData() {

        this.tags.clear();
        this.images = [];
        this.uploadService.list().subscribe(response => {

            if (response.success) {
                this.images = response.value;
                this.filterImages();

                for (const image of this.images) {
                    this.tags.add(image.tag);
                }

            } else {
                this.app.showError('Can\'t load images');
            }
        }, error => {
            this.app.showError('Can\'t load images');
        });
    }

    open(image: ImageBean) {
        this.loadData();

        this.selectedImage = image;
        this.base.show('Select Image');
    }

    save() {
        this.parent.onImageSelected(this.selectedImage ? environment.FILE_STORAGE_URL + this.selectedImage.id : null);
    }

    getThumbnail(orig: ImageBean) {
        return environment.SERVER_URL + 'admin/image/' + orig.id + '/thumbnail?size=' + 128;
    }

    select(image: ImageBean) {
        console.log('select ' + this.selectedImage);

        if (this.selectedImage == image) {
            this.selectedImage = null;
        } else {
            this.selectedImage = image;
        }
    }

    onTagChanged() {
        this.filterImages();
    }

    private filterImages() {
        this.filteredImages = [];
        for (const image of this.images) {
            if (this.selectedTag == '' || (this.selectedTag != '' && image.tag == this.selectedTag)) {
                this.filteredImages.push(image);
            }
        }
    }
}
