import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {EventLogService} from '../../services/event-log.service';

@Component({
    templateUrl: 'event-log.component.html',
})

export class EventLogComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    columns: Column[] = [
        new Column('date', 'Date').date(),
        new Column('user', 'User'),
        new Column('company', 'Company'),
        new Column('message', 'Message')
    ];

    private params: any;
    private query: string;

    constructor(private eventLogService: EventLogService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        setTimeout(() => {
            this.loadEventLogs();
        }, 1);
    }

    loadEventLogs() {
        this.table.setData([], this.columns);
    }

    getData(params, query) {

        if (!query && params && this.params && params.data.offset == this.params.data.offset) {
            return;
        }

        if (params != null) {
            this.params = params;
        }
        if (query != null) {
            this.query = query;
        }

        this.eventLogService.getLogs(this.params.data.limit, this.params.data.offset, this.query).subscribe(events => {

            this.params.success({
                total: events.pagesCount * this.params.data.limit,
                rows: events.list
            });
            this.params.complete();
        });
    }

}
