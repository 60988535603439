<div class="form-horizontal">
    <div class="card-content">

        <div class="form-group">
            <label for="presentation_from_date" class="col-md-3 control-label">From</label>
            <div class="col-md-9">
                <input type="text" id="presentation_from_date" class="form-control datetimepicker"
                       placeholder="From"/>
            </div>
        </div>

        <div class="form-group">
            <label for="presentation_to_date" class="col-md-3 control-label">To</label>
            <div class="col-md-9">
                <input type="text" id="presentation_to_date" class="form-control datetimepicker"
                       placeholder="To"/>
            </div>
        </div>

        <div class="form-group">
            <label for="query_text" class="col-md-3 control-label">Filter</label>
            <div class="col-md-9">
                <div class="row">
                    <input type="text" id="query_text" class="form-control col-md-6" style="width: 60%"
                           [(ngModel)]="query"
                           placeholder="Query"/>

                    <button type="button" class="btn btn-outline-primary col-md-4"
                            style="height: 40px; margin-left: 10px"
                            (click)="loadDevicesLogs()">Load Logs
                    </button>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="presentation_to_date" class="col-md-3 control-label"></label>
            <div class="col-md-9">

                <button type="button" class="btn btn-outline-primary"
                        (click)="loadDiagnosticsSteps()">Load Diagnostics Steps
                </button>&nbsp;


                <button type="button" class="btn btn-outline-primary"
                        (click)="loadDispenseSteps()">Load Dispense Steps
                </button>&nbsp;

                <div class="checkbox checkbox-inline">
                    <input
                            type="checkbox"
                            [(ngModel)]="realDispenseStepsOnly"
                            class="custom-control-input"
                            id="auto_pickup"/>
                    <label for="auto_pickup">Real Only</label>
                </div>

            </div>
        </div>

    </div>

    <div class="card-content table-responsive table-full-width" *ngIf="mode==MODE_ALL">
        <table class="table table-striped">
            <thead>
            <tr>
                <th>Date</th>
                <!--<th>Level</th>-->
                <!--<th>Tag</th>-->
                <!--<th>Device</th>-->
                <th>Message</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let step of messages">
                <td>{{(step.date) | los_angeles: 'MM/dd/yyyy hh:mm a'}}</td>
                <!--<td>{{step.level}}</td>-->
                <!--<td>{{step.tag}}</td>-->
                <!--<td>{{step.device}}</td>-->
                <td>{{step.message}}</td>
            </tr>
            </tbody>
        </table>
    </div>

    <div class="card-content table-responsive table-full-width" *ngIf="mode!=MODE_ALL">
        <table-component></table-component>
    </div>
</div>

