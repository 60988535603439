import {share} from 'rxjs/operators';
import {DataService} from './data.service';
import {Observable} from 'rxjs';
import {ProgressLoaderService} from './loader.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable()
export abstract class BaseRequestService {

    constructor(protected http: HttpClient, protected dataService: DataService,
        protected loaderService: ProgressLoaderService) {
    }

    private static startCount = 0;

    public static apiServerUrl(): string {
        return environment.SERVER_URL;
    }

    protected static buildOffsetParams(limit?: number, offset?: number): any {
        const p = {};
        if (limit != null) {
            p['limit'] = limit.toString();
        }
        if (offset != null) {
            p['offset'] = offset.toString();
        }
        return p;
    }

    protected serverUrl(): string {
        return BaseRequestService.apiServerUrl();
    }

    protected headers(): HttpHeaders {
        return this.dataService.requestHeaders;
    }

    protected upload<T>(url: string, body: any): Observable<T> {
        return this.post(url, body, true);
    }

    protected post<T>(url: string, body: any, stream: boolean = false): Observable<T> {
        let options = {headers: this.headers()};
        if (stream) {
            options = {headers: this.dataService.octet()};
        }

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<T> = this.http.post<T>(this.serverUrl() + url, body, options).pipe(share());
        observable.subscribe(data => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

    protected put<T>(url: string, body: any): Observable<T> {
        const options = {headers: this.headers()};

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<T> = this.http.put<T>(this.serverUrl() + url, body, options).pipe(share());
        observable.subscribe(data => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

    protected get<T>(url: string, search?: any): Observable<T> {
        const options = {headers: this.headers(), params: search};

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<T> = this.http.get<T>(this.serverUrl() + url, options).pipe(share());
        observable.subscribe(data => {

            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

    protected blob(url: string, search?: any): Observable<Blob> {
        const options = {headers: this.headers(), params: search, responseType: 'blob' as 'blob'};

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<Blob> = this.http.get(this.serverUrl() + url, options).pipe(share());
        observable.subscribe(data => {

            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

    protected del<T>(url: string, search?: any): Observable<T> {
        const options = {headers: this.headers(), params: search};

        BaseRequestService.startCount++;
        this.loaderService.start();

        const observable: Observable<T> = this.http.delete<T>(this.serverUrl() + url, options).pipe(share());
        observable.subscribe(data => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        }, error => {
            BaseRequestService.startCount--;
            if (BaseRequestService.startCount == 0) {
                this.loaderService.done();
            }
        });
        return observable;
    }

}
