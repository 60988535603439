import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {ReviewService} from '../../services/review.service';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {CategoryService} from '../../services/category.service';
import {RecipeService} from '../../services/recipe.service';
import {Helper} from '../../common/helper';
import {KioskBean, RecipeStateBean, ReviewBean} from '../../model/model';
import {RightsGuard} from '../../common/auth.guard';
import {ReviewQuickviewComponent} from './review.quickview.component';
import {OperatorsService} from '../../services/operators.service';
import {KioskService} from '../../services/kiosk.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment-timezone';

@Component({
    templateUrl: 'reviews.component.html',
})

export class ReviewsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(ReviewQuickviewComponent, {static: false}) reviewQuickviewComponent: ReviewQuickviewComponent;

    columnsAdmin: Column[] = [
        new Column('date', 'Date').date(),
        // new Column('orderId', 'Order Id'),
        new Column('userName', 'User Name'),
        new Column('ratingOverall', 'Overall'),
        new Column('ratingFreshness', 'Freshness'),
        new Column('ratingWaitTime', 'Wait Time'),

        new Column('positive', 'Positive').checkbox(),
        new Column('rate', "Rate")
        // new Column('waitTime', 'waitTime').checkbox(),
        // new Column('quality', 'quality').checkbox(),
        // new Column('environment', 'environment').checkbox(),
        // new Column('customerService', 'customerService').checkbox(),
        // new Column('other', 'other').checkbox(),
        // new Column('allowToReply', 'allowToReply').checkbox(),
        // new Column('message', 'Description'),
    ];

    columnsUser: Column[] = [
        new Column('date', 'Date').date(),
        new Column('orderId', 'Order Id'),
        new Column('ratingOverall', 'Overall'),
        new Column('ratingFreshness', 'Freshness'),
        new Column('ratingWaitTime', 'Wait Time'),
        new Column('message', 'Description'),
        new Column('rate', "Rate")
    ];

    // operators: OperatorBean[] = [];
    // selectedOperator: OperatorBean = {} as OperatorBean;

    kiosks: KioskBean[] = [];
    selectedKiosk: KioskBean = {} as KioskBean;

    allRecipes: RecipeStateBean = {id: 0} as RecipeStateBean;

    recipes: RecipeStateBean[];
    selectedRecipe: RecipeStateBean;
    compareBeans = Helper.compareBeans;

    selectedRange = [
        moment().startOf('day').subtract(1, 'week').toDate(),
        moment().endOf('day').toDate()
    ];

    constructor(private reviewService: ReviewService,
        private operatorsService: OperatorsService,
        private kioskService: KioskService,
        private categoryService: CategoryService,
        private recipeService: RecipeService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        Helper.selectPicker('select_kiosk');

        this.loadKiosks();
    }

    loadReviews() {

        if (this.selectedRecipe) {
            this.reviewService.getReviewsByKiosk(this.selectedKiosk.id, this.selectedRecipe.id).subscribe(reviews => {
                if (this.table) {
                    this.table.setData(reviews.list, RightsGuard.isSuperAdmin() ? this.columnsAdmin : this.columnsUser);
                }
            });
        }
    }

    onRecipeChange() {
        this.loadReviews();
    }

    onRemove(items) {

        for (const review of items) {

            this.reviewService.deleteReview(review).subscribe(response => {
                if (response.success) {
                    if (review == items.pop()) {
                        this.loadReviews();
                    }

                } else {
                    this.app.showError('Can\'t delete review');
                }
            }, error => {
                this.app.showError('Can\'t delete review');
            });
        }
    }

    onView(items: ReviewBean[]) {
        if (items && items.length > 0) {
            this.reviewQuickviewComponent.open(items[0]);
        }
    }

    onOperatorChange() {
        this.loadKiosks();
    }

    onKioskChange() {
        this.updateRecipes();
        this.loadReviews();
    }

    loadKiosks() {
        this.kioskService.getAllKiosks(false).subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks: KioskBean[]) {

        this.kiosks = [];
        for (const kiosk of kiosks) {
            if (kiosk.state != 'DECOMMISSIONED') {
                this.kiosks.push(kiosk);
            }
        }

        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        this.selectedKiosk = this.kiosks[0];
        this.updateRecipes();
        Helper.selectPicker('select_kiosk', 'select_state');

        this.loadReviews();
    }

    download() {

        let start = Helper.startOf(this.selectedRange[0]);
        let end = Helper.endOf(this.selectedRange[1]);

        this.reviewService.downloadReviewsByKiosk(this.selectedKiosk.id, this.selectedRecipe.id, start, end).subscribe(response => {
            FileSaver.saveAs(response, this.selectedKiosk.name + ' - Reviews.csv');
        });
    }

    private updateRecipes() {
        this.recipes = [];
        if (this.selectedKiosk && this.selectedKiosk.recipeStates) {
            for (const recipe of this.selectedKiosk.recipeStates) {
                if (recipe.state == 'PROVISIONED') {
                    this.recipes.push(recipe);
                }
            }
        }
        this.selectedRecipe = this.allRecipes;
        Helper.selectPicker('select_recipe');
    }
}
