import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {KioskWorkingHoursBean, ManageKioskWorkingHoursBean} from '../../../../model/model';
import {Options} from 'ng5-slider';
import {KioskService} from '../../../../services/kiosk.service';
import * as moment from 'moment-timezone';
import {TableComponent} from '../../../../components/table.component';
import {KioskHoursEditComponent} from './kiosk-hours.edit.component';
import {Column} from '../../../../common/column';
import {AppComponent} from '../../../../app.component';
import {Helper} from '../../../../common/helper';
import {KioskManageService} from '../../../../services/kiosk-manage.service';

@Component({
    selector: 'kiosk-hours-component',
    templateUrl: 'kiosk-hours.component.html',
    styleUrls: ['kiosk-hours.component.scss']
})
export class KioskHoursComponent implements AfterViewInit {

    days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];

    options: Options = {
        floor: 0,
        ceil: 24,
        step: 1,
        translate: (value: number): string => {

            if (value == 0 || value == 24) {
                return '12 a.m.';
            }
            if (value == 12) {
                return '12 p.m.';
            }

            const amOrPm = (value < 12) ? ' a.m.' : ' p.m.';
            const hour = (value < 12) ? value : value - 12;

            return hour + amOrPm;
        }
    };

    selectedRange = [
        moment().startOf('day').subtract(1, 'month').toDate(),
        moment().endOf('day').toDate()
    ];

    fields: Column[] = [
        new Column('ts', 'Day of Week').shortDate(),
        new Column('hourOpen', 'Open'),
        new Column('hourClose', 'Close'),
        new Column('autoMaintenanceActivation', 'Auto Maintenance Activation').checkbox()
    ];

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskHoursEditComponent, {static: false}) modal: KioskHoursEditComponent;
    workingHours: ManageKioskWorkingHoursBean;
    kioskId: number;

    constructor(private kioskService: KioskService,
        private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;

        this.kioskManageService.getWorkingHours(kioskId).subscribe(response => {
            if (response.success) {

                this.workingHours = response.value;
                if (!this.workingHours || !this.workingHours.workingHoursFrom) {
                    this.workingHours.workingHoursFrom = [8, 8, 8, 8, 8, 8, 8];
                }
                if (!this.workingHours || !this.workingHours.workingHoursTo) {
                    this.workingHours.workingHoursTo = [20, 20, 20, 20, 20, 20, 20];
                }
                if (!this.workingHours || !this.workingHours.autoMaintenanceActivation) {
                    this.workingHours.autoMaintenanceActivation = [false, false, false, false, false, false, false];
                }
            }
        });

    }

    onDateRangeChanged(range) {
        this.loadWorkingHours();
    }

    onEdit(items: KioskWorkingHoursBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Hours');
        }
    }

    onAdd() {
        this.modal.open(null, 'Add Hours');
    }

    addHours(initHours: KioskWorkingHoursBean, hours: KioskWorkingHoursBean) {

        this.kioskService.setWorkingHours(this.kioskId, hours).subscribe(response => {
            if (response.success) {
                this.loadWorkingHours();

            } else {
                this.app.showError('Can\'t set hours');
            }
        }, error => {
            this.app.showError('Can\'t set hours');
        });
    }

    private loadWorkingHours() {

        let start = Helper.startOf(this.selectedRange[0]);
        let end = Helper.endOf(this.selectedRange[1]);

        this.kioskService.getWorkingHours(this.kioskId, start, end).subscribe(response => {
            if (response.success) {
                this.table.setData(response.value, this.fields);
            }
        });
    }

    save() {

        this.kioskManageService.setWorkingHours(this.kioskId, this.workingHours).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
