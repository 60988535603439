<div class="animated fadeIn">
    <div class="col-md-12">
        <div class="card">
            <div class="card-content">
                <table-component (onEdit)="onEdit($event)"
                                 (onRemove)="onRemove($event)"
                                 (onAdd)="onAdd()"></table-component>
            </div>
        </div>
    </div>
</div>
<categories-edit-component></categories-edit-component>
<depends-on-edit-component #dependsOnEditComponent></depends-on-edit-component>
<process-edit-component #processEditComponent (onResult)="addProcess($event)"></process-edit-component>