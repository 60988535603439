<base-modal-component class="base-modal" (onConfirm)="save()">
    <div class="form-group row">
        <div class="col-md-12">
            <select hidden
                    id="selet_tag_modal"
                    data-style="select-btn select-btn-grey"
                    title="Select Category"
                    data-size="7"
                    [(ngModel)]="selectedTag">

                <option [ngValue]="visionTag" selected="selected"
                        *ngFor="let visionTag of visionTags">{{visionTag}}
                </option>
            </select>

        </div>
    </div>

    <div *ngIf="imageBase64Data" style="position: relative;">

        <image-cropper *ngIf="imageCropperReady"
                       #imageCropper
                       [imageBase64]="imageBase64Data"
                       [maintainAspectRatio]="false"
                       format="jpg"
        ></image-cropper>

        <img [src]="getBackground()"
             style="position: absolute; top: 0; left: 0; padding: 5px; margin: auto; bottom: 0; right: 0; max-width: 100%; max-height: 100%"/>

    </div>

    <!--<img [src]="croppedImage"/>-->

</base-modal-component>
