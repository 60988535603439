<div class="animated fadeIn">
    <div class="col-md-12">

        <div class="row">

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="select_kiosk">Location</label>
                        <div class="col-md-10">
                            <select (change)="onKioskChange()"
                                    [(ngModel)]="selectedKiosk"
                                    [compareWith]="compareBeans" [disabled]="kiosks.length==0"
                                    class="form-control"
                                    data-size="7"
                                    data-style="select-btn"
                                    id="select_kiosk"
                                    title="Select Location">
                                <option [ngValue]="allKiosks" selected="selected">All Locations</option>
                                <option *ngFor="let kiosk of kiosks" [ngValue]="kiosk">{{kiosk.name}}</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="from_date">By The Date</label>
                        <div class="col-md-10">
                            <input type="text" id="from_date" class="form-control datetimepicker"
                                   placeholder="Date"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div class="content">
            <div class="row">
                <div class="col-lg-4 col-sm-6" style="height: 200px">
                    <div class="card">
                        <div class="card-content">
                            <h6 class="big-title">total earnings - <span class="text-primary">TODAY</span></h6>

                            <div style="display: block;" *ngIf="todayEarningsChart">
                                <canvas baseChart
                                        [datasets]="todayEarningsChart.data"
                                        [labels]="todayEarningsChart.labels"
                                        [options]="lineChartOptions"
                                        [colors]="todayEarningsChart.colors"
                                        [legend]="true"
                                        [chartType]="'line'">
                                </canvas>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="card">
                        <div class="card-content">
                            <h6 class="big-title">total orders - <span class="text-primary">TODAY</span></h6>

                            <div style="display: block;" *ngIf="todayOrdersChart">
                                <canvas baseChart
                                        [datasets]="todayOrdersChart.data"
                                        [labels]="todayOrdersChart.labels"
                                        [options]="lineChartOptions"
                                        [colors]="todayOrdersChart.colors"
                                        [legend]="true"
                                        [chartType]="'line'">
                                </canvas>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="card">
                        <div class="card-content">
                            <h6 class="big-title">total Maintenance Time - <span class="text-primary">TODAY</span></h6>

                            <div style="display: block;" *ngIf="todayErrorsChart">
                                <canvas baseChart
                                        [datasets]="todayErrorsChart.data"
                                        [labels]="todayErrorsChart.labels"
                                        [options]="lineChartOptions"
                                        [colors]="todayErrorsChart.colors"
                                        [legend]="true"
                                        [chartType]="'line'">
                                </canvas>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col-lg-4 col-sm-6" style="height: 200px">
                    <div class="card">
                        <div class="card-content">
                            <h6 class="big-title">total earnings - <span class="text-primary">WEEKLY</span></h6>

                            <div style="display: block;" *ngIf="earningsChart">
                                <canvas baseChart
                                        [datasets]="earningsChart.data"
                                        [labels]="earningsChart.labels"
                                        [options]="lineChartOptions"
                                        [colors]="earningsChart.colors"
                                        [legend]="true"
                                        [chartType]="'line'">
                                </canvas>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="card">
                        <div class="card-content">
                            <h6 class="big-title">total orders - <span class="text-primary">WEEKLY</span></h6>

                            <div style="display: block;" *ngIf="ordersChart">
                                <canvas baseChart
                                        [datasets]="ordersChart.data"
                                        [labels]="ordersChart.labels"
                                        [options]="lineChartOptions"
                                        [colors]="ordersChart.colors"
                                        [legend]="true"
                                        [chartType]="'line'">
                                </canvas>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="card">
                        <div class="card-content">
                            <h6 class="big-title">total Maintenance Time - <span class="text-primary">WEEKLY</span></h6>

                            <div style="display: block;" *ngIf="errorsChart">
                                <canvas baseChart
                                        [datasets]="errorsChart.data"
                                        [labels]="errorsChart.labels"
                                        [options]="lineChartOptions"
                                        [colors]="errorsChart.colors"
                                        [legend]="true"
                                        [chartType]="'line'">
                                </canvas>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-lg-4 col-sm-6">
                    <div class="card">
                        <div class="card-content">
                            <h6 class="big-title">total earnings - <span class="text-primary">month by month</span></h6>

                            <div style="display: block;" *ngIf="monthlyEarningsChart">
                                <canvas baseChart
                                        [datasets]="monthlyEarningsChart.data"
                                        [labels]="monthlyEarningsChart.labels"
                                        [options]="lineChartOptions"
                                        [colors]="monthlyEarningsChart.colors"
                                        [legend]="true"
                                        [chartType]="'line'">
                                </canvas>
                            </div>
                            <div class="card-footer">
                                <hr>
                                <div class="footer-title">
                                    <button class="btn btn-simple" (click)="openEarningsByDayChartModal()">
                                        Analytics
                                    </button>
                                </div>
                                <div class="pull-right">
                                    <button class="btn btn-success btn-fill btn-icon btn-sm"
                                            (click)="openEarningsByDayChartModal()">
                                        <i class="ti-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="card">
                        <div class="card-content">
                            <h6 class="big-title">total orders - <span class="text-primary">month by month</span></h6>

                            <div style="display: block;" *ngIf="monthlyOrdersChart">
                                <canvas baseChart
                                        [datasets]="monthlyOrdersChart.data"
                                        [labels]="monthlyOrdersChart.labels"
                                        [options]="lineChartOptions"
                                        [colors]="monthlyOrdersChart.colors"
                                        [legend]="true"
                                        [chartType]="'line'">
                                </canvas>
                            </div>
                            <div class="card-footer">
                                <hr>
                                <div class="footer-title">
                                    <button class="btn btn-simple" (click)="openOrdersByDayChartModal()">
                                        View more details
                                    </button>
                                </div>
                                <div class="pull-right">
                                    <button class="btn btn-success btn-fill btn-icon btn-sm"
                                            (click)="openOrdersByDayChartModal()">
                                        <i class="ti-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="card">
                        <div class="card-content">
                            <h6 class="big-title">total Maintenance Time - <span
                                    class="text-primary">month by month</span></h6>

                            <div style="display: block;" *ngIf="monthlyErrorsChart">
                                <canvas baseChart
                                        [datasets]="monthlyErrorsChart.data"
                                        [labels]="monthlyErrorsChart.labels"
                                        [options]="lineChartOptions"
                                        [colors]="monthlyErrorsChart.colors"
                                        [legend]="true"
                                        [chartType]="'line'">
                                </canvas>
                            </div>
                            <div class="card-footer">
                                <hr>
                                <div class="footer-title">
                                    <button class="btn btn-simple" (click)="openErrors()">View all errors
                                    </button>
                                </div>
                                <div class="pull-right">
                                    <button class="btn btn-default btn-fill btn-icon btn-sm">
                                        <i class="ti-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
