import {publishReplay, refCount} from 'rxjs/operators';
import {BaseRequestService} from './base-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MealPlanBean, ResponseBean, ResponseItemBean} from '../model/model';

@Injectable()
export class MealplanService extends BaseRequestService {

    private mealplans: Observable<any>;

    addMealplan(mealplan: MealPlanBean): Observable<ResponseBean> {
        this.mealplans = null;
        return this.post('admin/mealplan/add', mealplan);
    }

    updateMealplan(mealplan: MealPlanBean): Observable<ResponseBean> {
        this.mealplans = null;
        return this.put('admin/mealplan/' + mealplan.id + '/update', mealplan);
    }

    deleteMealplan(mealplan: MealPlanBean): Observable<ResponseBean> {
        this.mealplans = null;
        return this.del('admin/mealplan/' + mealplan.id + '/delete');
    }

    getMealplans(): Observable<ResponseItemBean<MealPlanBean[]>> {
        if (!this.mealplans) {
            this.mealplans = this.get('admin/mealplan/list').pipe(
                publishReplay(1),
                refCount());
        }
        return this.mealplans;
    }

    clear() {
        this.mealplans = null;
    }

}
