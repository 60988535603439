<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <fields-list-component [fields]="fields" name="device_process_edit" [value]="process"></fields-list-component>

    <div class="form-horizontal">
        <div class="card-content">


            <div class="form-group" *ngFor="let param of process.parameters; let i = index">
                <label for="control_params" class="col-md-3 control-label">Parameter #{{i + 1}}
                    <span class="text-danger">*</span></label>
                <div class="col-md-8">

                    <input
                            type="text"
                            [(ngModel)]="process.parameters[i]"
                            class="form-control form-control-low" required="required"
                            id="control_params"/>
                </div>

                <div class="col-md-1">
                    <button type="button" class="btn btn-link btn-sm mt-0 black"
                            (click)="removeParameter(i)"><i class="fa fa-trash"></i></button>
                </div>

            </div>


            <div class="form-group">
                <label for="control_params_add" class="col-md-3 control-label">Add Parameter</label>
                <div class="col-md-8">

                    <input
                            type="text"
                            [(ngModel)]="nextParameter"
                            class="form-control form-control-low"
                            id="control_params_add"/>
                </div>

                <div class="col-md-1">
                    <button type="button" class="btn btn-link btn-sm mt-0 black"
                            (click)="addParameter()"><i class="fa fa-plus"></i></button>
                </div>
            </div>

        </div>
    </div>
</base-modal-component>
