import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {DataService} from '../services/data.service';
import {isUndefined} from 'util';
import {Rights, RightsGroup} from './rights';
import {DASHBOARD_MODULE_ROUTES} from '../dashboard/dashboard.routes';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private dataService: DataService) {
    }

    canActivate() {
        if (this.dataService.getUserInfo() != null) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}

@Injectable()
export class RightsGuard implements CanActivate {

    public static isSuperAdmin() {

        if (!DataService.userInfo) {
            return false;
        }
        return (DataService.userInfo.user.rights == Rights.SUPER_ADMIN);
    }

    public static isAdmin() {

        if (!DataService.userInfo) {
            return false;
        }
        return (DataService.userInfo.user.rights == RightsGroup.ADMIN);
    }

    public static isDeveloper() {

        if (!DataService.userInfo) {
            return false;
        }
        return (DataService.userInfo.user.rights == RightsGroup.DEVELOPER);
    }

    static isBusinessOps() {
        if (!DataService.userInfo) {
            return false;
        }
        return (DataService.userInfo.user.rights == RightsGroup.BUSINESS_OPS);
    }

    constructor(private router: Router) {
    }

    public static userAccess(rights: number[]) {

        if (isUndefined(rights) || !DataService.userInfo) {
            return false;
        }
        if (RightsGuard.isSuperAdmin()) {
            return true;
        }

        for (const right of rights) {
            if ((DataService.userInfo.user.rights & right) == right || DataService.userInfo.user.rights == right) {
                return true;
            }
        }
        return false;
    }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        if (RightsGuard.userAccess(route.data['rights'])) {
            return true;
        } else {

            if (route.url.length > 0 && route.url[0].path == 'home') {
                for (const child of DASHBOARD_MODULE_ROUTES[0].children) {

                    if (child.data) {
                        if (RightsGuard.userAccess(child.data['rights'])) {
                            this.router.navigate([child.path]);
                            break;
                        }

                    } else if (child.children) {
                        for (const childRoute of child.children) {
                            if (childRoute.data) {
                                if (RightsGuard.userAccess(childRoute.data['rights'])) {
                                    this.router.navigate([child.path]);
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

}
