<div class="form-horizontal">
    <div class="card-content">

        <div class="form-group">
            <label class="col-md-3 control-label" for="sensors_time_range">Date Range</label>
            <div class="col-md-9">
                <input (bsValueChange)="loadCharts()" [(ngModel)]="sensorsDateRange"
                       class="form-control"
                       id="sensors_time_range" bsDaterangepicker type="text"/>
            </div>
        </div>

        <div class="form-group">
            <label class="col-md-3 control-label" for="radio1">Scale</label>
            <div class="col-md-9">

                <div class="radio">
                    <input type="radio" name="radio1" id="radio1" value="option1" checked="" [value]="true"
                           (change)="scaleChange($event)"
                           [(ngModel)]="isCelsius">
                    <label for="radio1">
                        Celsius
                    </label>
                </div>
                <div class="radio">
                    <input type="radio" name="radio1" id="radio2" value="option2" [value]="false"
                           [(ngModel)]="isCelsius" (change)="scaleChange($event)">
                    <label for="radio2">
                        Fahrenheit
                    </label>
                </div>

            </div>
        </div>

    </div>
</div>


<div class="form-horizontal" *ngIf="this.stats">

    <button type="button" class="btn btn-outline-primary" style="float: right"
            (click)="download()">Download CSV
    </button>&nbsp;

    <div class="card-content">

        <div style="display: block;" *ngIf="statsChartData">
            <canvas baseChart
                    [datasets]="statsChartData"
                    [labels]="statsChartLabels"
                    [options]="lineChartOptions"
                    [legend]="true"
                    (chartClick)="selectData($event)"
                    [chartType]="'line'">
            </canvas>
        </div>
    </div>
</div>
