import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {UserBean} from '../../../../model/model';
import {Helper} from '../../../../common/helper';
import {TableComponent} from '../../../../components/table.component';
import {Column} from '../../../../common/column';
import {KioskTestersEditComponent} from './kiosk-testers.edit.component';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-testers-component',
    templateUrl: 'kiosk-testers.component.html'
})
export class KioskTestersComponent implements AfterViewInit {

    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskTestersEditComponent, {static: false}) modal: KioskTestersEditComponent;

    fields: Column[] = [
        new Column('email', 'Email'),
    ];
    testers: string[];
    kioskId: number;

    constructor(private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;
        this.kioskManageService.getTesterEmails(kioskId).subscribe(response => {
            if (response.success) {
                this.testers = response.value;
                this.updateTable();

            }
        });
    }

    onEdit(items: UserBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Email');
        }
    }

    onRemove(items) {
        for (const step of items) {
            const index = this.testers.indexOf(step.email);
            this.testers.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Email');
    }

    addUser(initUser: UserBean, user: UserBean) {

        if (this.testers == null) {
            this.testers = [];
        }
        if (initUser.email == null) {
            this.testers.push(user.email);

        } else {

            let ind = -1;
            let i = 0;
            for (const email of this.testers) {
                if (email == initUser.email) {
                    ind = i;
                }
                i++;
            }

            if (ind >= 0) {
                this.testers.splice(ind, 1);
                this.testers.splice(ind, 0, user.email);
            }
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {

            const users: UserBean[] = [];
            if (this.testers) {
                for (const email of this.testers) {
                    const user: UserBean = {} as UserBean;
                    user.email = email;
                    users.push(user);
                }
            }

            this.table.setData(users, this.fields);
        }
    }

    save() {

        this.kioskManageService.setTesterEmails(this.kioskId, this.testers).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
