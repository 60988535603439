import {AfterViewInit, Component, forwardRef, Inject, Injectable, ViewChild} from '@angular/core';
import {Field} from '../../common/field';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {KioskModelService} from '../../services/kiosk-model.service';
import {DeviceTemplateService} from '../../services/device-template.service';
import {KioskTemplateService} from '../../services/kiosk-template.service';
import {ActivatedRoute, ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot} from '@angular/router';
import {ProcessEditComponent} from '../../components/process.edit.component';
import {DependsOnEditComponent} from '../../components/depends-on.edit.component';
import {DeviceModelEditComponent} from './device-model.edit.component';
import {TableComponent} from '../../components/table.component';
import {Column} from '../../common/column';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {ModelPresentationComponent} from './presentation/model-presentation.component';
import {DiagnosticsStepService} from '../../services/diagnosics-step.service';
import {DiagnosticsStepSequenceEditComponent} from './diagnostics-steps-sequence.edit.component';
import {DiagnosticsStepEditComponent} from '../../components/diagnostics-step.edit.component';
import {KioskDataService} from '../../services/kiosk-data.service';
import {RobotCommandEditComponent} from './robot-command.edit.component';
import {RobotCommandService} from '../../services/robot-command.service';
import {Helper} from '../../common/helper';
import {
    DeviceModelBean,
    DeviceTemplateBean,
    DiagnosticsStepSequenceBean,
    Entry,
    IdBean,
    KioskModelBean,
    KioskTemplateBean,
    ProcessParameterValueBean,
    RecipeStepBean,
    RobotCommandBean
} from '../../model/model';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {KioskModelQuestionsComponent} from './questions/kiosk-model-questions.component';
import {ModelNotificationEmailsComponent} from './model-notification-emails/model-notification-emails.component';

@Injectable()
export class CanDeactivateKioskModelDetails implements CanDeactivate<KioskModelDetailsComponent> {
    constructor() {
    }

    canDeactivate(component: KioskModelDetailsComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot) {

        const isUnsaved = component.isChanged();
        if (isUnsaved) {
            component.showSaveConfirmation(nextState.url);
        }
        return !isUnsaved;
    }
}

@Component({
    selector: 'kiosk-models-edit-component',
    templateUrl: 'kiosk-model-details.component.html',
})

export class KioskModelDetailsComponent implements AfterViewInit {

    @ViewChild(ModelPresentationComponent, {static: false}) modelPresentationComponent: ModelPresentationComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    @ViewChild(KioskModelQuestionsComponent, {static: false}) kioskModelQuestionsComponent: KioskModelQuestionsComponent;

    @ViewChild('processEditComponent', {static: false}) processEditComponent: ProcessEditComponent;
    @ViewChild('diagnosticsStepEditComponent', {static: false}) diagnosticsStepEditComponent: DiagnosticsStepEditComponent;

    @ViewChild('dependsOnEditComponent', {static: false}) dependsOnEditComponent: DependsOnEditComponent;
    @ViewChild('deviceModelEditComponent', {static: false}) deviceModelEditComponent: DeviceModelEditComponent;
    @ViewChild('diagnosticStepsSequenceEditComponent', {static: false}) diagnosticStepsSequenceEditComponent: DiagnosticsStepSequenceEditComponent;
    @ViewChild('robotCommandEditComponent', {static: false}) robotCommandEditComponent: RobotCommandEditComponent;

    @ViewChild('devicesTable', {static: false}) devicesTable: TableComponent;
    @ViewChild('diagnosticsStepsTable', {static: false}) diagnosticsStepsTable: TableComponent;
    @ViewChild('robotCommandsTable', {static: false}) robotCommandsTable: TableComponent;

    kioskModel: KioskModelBean = {} as KioskModelBean;
    @ViewChild(ModelNotificationEmailsComponent, {static: false}) changeUsersComponent: ModelNotificationEmailsComponent;

    fields: Field[] = [
        new Field('id', 'Id').disable(),
        new Field('name', 'Name').require(),
        new Field('robotProgram', 'Robot program').require(),
        new Field('volumeSolidToLiquid', 'Solid To Liquid'),
        new Field('targetedVolumeML.SMALL', 'Targeted Volume SMALL, ml'),
        new Field('targetedVolumeML.MEDIUM', 'Targeted Volume MEDIUM, ml'),
        new Field('targetedVolumeML.LARGE', 'Targeted Volume LARGE, ml'),

        new Field('firstRinseSettings.button', 'First Rinse Button'),
        new Field('firstRinseSettings.durationRinse', 'First Rinse Duration Rinse'),
        new Field('firstRinseSettings.durationBlend', 'First Rinse Duration Blend'),

        new Field('secondRinseSettings.button', 'Second Rinse Button'),
        new Field('secondRinseSettings.durationRinse', 'Second Rinse Duration Rinse'),
        new Field('secondRinseSettings.durationBlend', 'Second Rinse Duration Blend'),

    ];

    deviceFields: Column[] = [
        new Column('id', 'ID'),
        new Column('deviceTemplate', 'Device Template').data(this, this.formatDeviceTemplate),
        new Column('ingredientType', 'Ingredient Type'),
        new Column('deviceName', 'Device Name'),
        new Column('placeName', 'Place Name'),
        new Column('scale', 'Scale'),
        new Column('rotation', 'Rotation'),
        new Column('x', 'x').decimal(),
        new Column('y', 'y').decimal(),

    ];

    diagnosticStepFields: Column[] = [
        new Column('id', 'ID'),
        new Column('description', 'Description'),
        new Column('service', 'Service').checkbox(),
        new Column('kioskSetup', 'Kiosk Setup').checkbox(),
        new Column('kioskOperation', 'Kiosk Operation').checkbox(),
        new Column('kioskCleanup', 'Kiosk Cleanup').checkbox(),
        new Column('parallelExecution', 'Parallel Execution').checkbox(),
        new Column('allowForNOC', 'Allow for NOC').checkbox(),
    ];

    robotCommandsFields: Column[] = [
        new Column('id', 'ID'),
        new Column('device', 'Device').data(this, this.getDeviceById),
        new Column('process.name', 'Process'),
        new Column('parameters', 'Parameters').data(this, this.getParametersString),
    ];

    templates: KioskTemplateBean[];
    devices: DeviceTemplateBean[];
    availableDevices: DeviceTemplateBean[];

    modelId: number;
    selectedKioskTemplate: KioskTemplateBean;
    steps: RecipeStepBean[];
    origKioskModel: KioskModelBean;
    diagnosticSteps: DiagnosticsStepSequenceBean[];
    alternativeName: string;
    newName: string;
    changes: boolean;
    selectedStep: RecipeStepBean;

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private route: ActivatedRoute,
        private router: Router,
        private kioskDataService: KioskDataService,
        private kioskModelService: KioskModelService,
        private kioskTemplateService: KioskTemplateService,
        private deviceTemplateService: DeviceTemplateService,
        private diagnosticsStepService: DiagnosticsStepService,
        private robotCommandService: RobotCommandService,
        private coolDialogs: NgxCoolDialogsService,
        @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    formatDeviceTemplate(deviceModel: DeviceModelBean) {
        return deviceModel.deviceTemplate.name;
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.modelId = params['modelId'] != null ? params['modelId'] : 0;
            this.loadKioskTemplates();
            this.loadDevices();
        });

        // if (this.steps1) this.steps1.init();
        // if (this.steps2) this.steps2.init();
        // if (this.steps3) this.steps3.init();
        // if (this.steps4) this.steps4.init();

        this.onChangeTabSelected(null);
    }

    loadKioskTemplates() {
        this.kioskTemplateService.getKioskTemplates().subscribe(templates => {
            this.templates = [];
            for (const template of templates.list) {
                this.templates.push(Helper.copyObject(template));
            }
        });
    }

    loadDevices() {
        this.deviceTemplateService.getDeviceTemplates().subscribe(devices => {
            this.devices = [];
            for (const device of devices.list) {
                this.devices.push(Helper.copyObject(device));
            }
            this.loadModel();
        });
    }

    loadModel() {

        if (this.modelId != 0) {
            this.kioskModelService.getKioskModel(this.modelId).subscribe(response => {

                let model = response.value;
                this.kioskModel = Helper.copyObject(model);
                this.origKioskModel = Helper.copyObject(model);

                if (this.kioskModel.stepPhases) {
                    this.kioskModel.stepPhases.preparationSteps.sort((a, b) => {
                        return a.priorityNum - b.priorityNum;
                    });
                    this.kioskModel.stepPhases.cookingSteps.sort((a, b) => {
                        return a.priorityNum - b.priorityNum;
                    });
                    this.kioskModel.stepPhases.servingSteps.sort((a, b) => {
                        return a.priorityNum - b.priorityNum;
                    });
                    this.kioskModel.stepPhases.deliverySteps.sort((a, b) => {
                        return a.priorityNum - b.priorityNum;
                    });
                    if (this.kioskModel.stepPhases.cleaningSteps) {
                        this.kioskModel.stepPhases.cleaningSteps.sort((a, b) => {
                            return a.priorityNum - b.priorityNum;
                        });
                    }
                    if (this.kioskModel.stepPhases.wrapupSteps) {
                        this.kioskModel.stepPhases.wrapupSteps.sort((a, b) => {
                            return a.priorityNum - b.priorityNum;
                        });
                    }
                }

                for (const template of this.templates) {
                    if (template.id == this.kioskModel.kioskTemplate.id) {
                        this.selectedKioskTemplate = template;
                        this.getAvailableDevices();
                    }
                }

                this.devicesTable.setData(this.kioskModel.devices, this.deviceFields);
                this.layout.navbarComponent.setTitle(this.kioskModel.name);
            });
        } else {
            this.kioskModel = {firstRinseSettings: {}, secondRinseSettings: {}} as KioskModelBean;
            this.devicesTable.setData(this.kioskModel.devices, this.deviceFields);
            this.selectedKioskTemplate = this.templates[0];

            this.layout.navbarComponent.setTitle('New Kiosk Model');
        }

    }

    getAvailableDevices() {
        this.availableDevices = [];
        if (this.selectedKioskTemplate.deviceTemplates) {
            for (const id of this.selectedKioskTemplate.deviceTemplates) {
                for (const device of this.devices) {
                    if (device.id == id.id) {
                        this.availableDevices.push(device);
                    }
                }
            }
        }
    }

    save() {

        console.log(this.kioskModel);

        let ind;

        ind = 0;
        if (this.kioskModel.stepPhases) {
            for (const step of this.kioskModel.stepPhases.preparationSteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            for (const step of this.kioskModel.stepPhases.cookingSteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            for (const step of this.kioskModel.stepPhases.servingSteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            for (const step of this.kioskModel.stepPhases.deliverySteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            if (this.kioskModel.stepPhases.cleaningSteps) {
                for (const step of this.kioskModel.stepPhases.cleaningSteps) {
                    step.priorityNum = ind++;
                    delete step['orderReady'];
                }
            }
            ind = 0;
            if (this.kioskModel.stepPhases.wrapupSteps) {
                for (const step of this.kioskModel.stepPhases.wrapupSteps) {
                    step.priorityNum = ind++;
                    delete step['orderReady'];
                }
            }
        }

        this.kioskModel.kioskTemplate = {} as IdBean;
        this.kioskModel.kioskTemplate.id = this.selectedKioskTemplate.id;

        if (this.kioskModel.id == null) {

            this.kioskModelService.addKioskModel(this.kioskModel).subscribe(response => {

                if (response.success) {
                    this.app.showNotification('Added');
                    this.origKioskModel = this.kioskModel;

                } else {
                    this.app.showError('Can\'t create Kiosk Model');
                }
            }, error => {
                this.app.showError('Can\'t create Kiosk Model');
            });

        } else {

            this.kioskModelService.updateKioskModel(this.kioskModel).subscribe(response => {

                if (response.success) {
                    this.app.showNotification('Updated');
                    this.origKioskModel = this.kioskModel;

                } else {
                    this.app.showError('Can\'t update Kiosk Model');
                }
            }, error => {
                this.app.showError('Can\'t update Kiosk Model');
            });
        }
    }

    createStep(ev) {
        this.steps = ev.recipeSteps;
        this.processEditComponent.open(ev.step ? ev.step.step : null);

        this.selectedStep = ev.step;
        console.log(ev.step);
    }

    editDependsOn(ev) {
        this.dependsOnEditComponent.open(ev.recipeSteps, ev.step);
    }

    addProcess(ev) {

        const step: RecipeStepBean = this.selectedStep ? Helper.copyObject(this.selectedStep) : {} as RecipeStepBean;
        step.step = ev.process;

        console.log(ev);
        console.log(step);

        if (!ev.origProcess) {
            if (this.steps.length > 0) {
                step.dependsOn = [this.steps.length - 1];
            }
            this.steps.push(step);

        } else {

            let index = -1;

            for (let i = 0; i < this.steps.length; i++) {
                if (this.steps[i].step && this.steps[i].step == ev.origProcess) {

                    step.dependsOn = this.steps[i].dependsOn;
                    index = i;
                    break;
                }
            }

            if (index >= 0) {
                this.steps.splice(index, 1);
                this.steps.splice(index, 0, step);
            }
        }
    }

    onDeviceEdit(items: DeviceModelBean[]) {
        if (items && items.length > 0) {
            this.deviceModelEditComponent.open(this, this.availableDevices, items[0], 'Edit ' + items[0].deviceName);
        }
    }

    onDeviceRemove(items) {

        const index = this.kioskModel.devices.indexOf(items[0]);
        if (index >= 0) {
            this.kioskModel.devices.splice(index, 1);
        }

        this.devicesTable.setData(this.kioskModel.devices, this.deviceFields);
    }

    onDeviceAdd() {
        this.deviceModelEditComponent.open(this, this.availableDevices, null, 'New Device Model');
    }

    addDevice(origDeviceModel: DeviceModelBean, deviceModel: DeviceModelBean) {

        if (origDeviceModel == null) {

            this.kioskModel.devices.push(deviceModel);

        } else {

            const index = this.kioskModel.devices.indexOf(origDeviceModel);
            this.kioskModel.devices.splice(index, 1);
            this.kioskModel.devices.splice(index, 0, deviceModel);
        }

        this.devicesTable.setData(this.kioskModel.devices, this.deviceFields);
    }

    onPresentationTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.modelPresentationComponent.onShow(this.kioskModel, this.selectedKioskTemplate);
        }
    }

    onQuestionsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskModelQuestionsComponent.onShow(this.kioskModel);
        }
    }

    onDiagnosticStepsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.loadDiagnosticsSteps();
        }
    }

    public loadDiagnosticsSteps() {
        this.diagnosticsStepService.getStepsSequences(this.kioskModel.id).subscribe(diagnosticSteps => {
            this.diagnosticSteps = [];
            for (const ds of diagnosticSteps.list) {
                this.diagnosticSteps.push(Helper.copyObject(ds));
            }

            this.diagnosticSteps.sort((a, b) => (a.description > b.description ? 1 : ((b.description > a.description) ? -1 : 0)));
            this.diagnosticsStepsTable.setData(this.diagnosticSteps, this.diagnosticStepFields);
        });
    }

    onDiagnosticStepsSequenceEdit(items: DiagnosticsStepSequenceBean[]) {
        if (items && items.length > 0) {
            this.diagnosticStepsSequenceEditComponent.open(this.kioskModel.id, items[0], 'Edit ' + items[0].description);
        }
    }

    onDiagnosticStepsSequenceRemove(items) {

        for (const item of items) {
            this.diagnosticsStepService.deleteStepSequence(this.kioskModel.id, item).subscribe(response => {

                if (response.success) {
                    if (item == items.pop()) {
                        this.loadDiagnosticsSteps();
                    }

                } else {
                    this.app.showError('Can\'t delete steps');
                }
            }, error => {
                this.app.showError('Can\'t delete steps');
            });
        }
    }

    onDiagnosticStepsSequenceAdd() {
        this.diagnosticStepsSequenceEditComponent.open(this.kioskModel.id, null, 'New Steps Sequence');
    }

    onRobotCommandsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.loadRobotCommands();
        }
    }

    loadRobotCommands() {
        this.robotCommandService.getRobotCommands(this.kioskModel.id).subscribe(robotCommands => {
            this.robotCommandsTable.setData(robotCommands.list, this.robotCommandsFields);

        });
    }

    onRobotCommandEdit(items: RobotCommandBean[]) {
        if (items && items.length > 0) {
            this.robotCommandEditComponent.open(this.kioskModel, items[0], 'Edit Command');
        }
    }

    onRobotCommandRemove(items) {

        for (const item of items) {
            this.robotCommandService.deleteRobotCommand(this.kioskModel.id, item).subscribe(response => {

                if (response.success) {
                    if (item == items.pop()) {
                        this.loadRobotCommands();
                    }

                } else {
                    this.app.showError('Can\'t delete command');
                }
            }, error => {
                this.app.showError('Can\'t delete command');
            });
        }
    }

    onRobotCommandAdd() {
        this.robotCommandEditComponent.open(this.kioskModel, null, 'New Command');
    }

    addDiagnosticsStep(ev) {
        this.diagnosticStepsSequenceEditComponent.addDiagnosticsStep(ev.process, ev.origProcess);
    }

    openCreateDiagnosticsStepModal(entry: Entry) {
        this.diagnosticsStepEditComponent.open(entry, this.kioskModel.devices, this.diagnosticSteps);
    }

    isChanged() {
        let result = false;
        try {
            result = JSON.stringify(this.origKioskModel) != JSON.stringify(this.kioskModel);
        } catch (e) {
        }
        return result;
    }

    showSaveConfirmation(url: string) {

        this.coolDialogs.confirm('Save Model Configuration?', {
            okButtonText: 'Yes, Save changes',
            cancelButtonText: 'No, Discard changes',
        })
        .subscribe(res => {
            if (res) {
                this.save();

            } else {
                this.origKioskModel = this.kioskModel;
                this.router.navigate([url]);
            }
        });
    }

    getDeviceById(robotCommand: RobotCommandBean) {
        for (const device of this.kioskModel.devices) {
            if (device.id == robotCommand.deviceModelId) {
                return device.deviceName;
            }
        }
        return '-';
    }

    getParametersString(robotCommand: RobotCommandBean) {
        // return Helper.getStepParametersStringByParams(robotCommand.parameters, this.kioskModel.devices);

        let result: string = '';
        for (const key in robotCommand.parameters) {

            if (robotCommand.parameters.hasOwnProperty(key)) {

                if (key.trim().length == 0) {
                    continue;
                }
                const value: ProcessParameterValueBean = robotCommand.parameters[key];
                const device = Helper.getDeviceById(value.value, this.kioskModel.devices);

                switch (value.type) {
                    case 'INGREDIENT_QUANTITY':
                        result += (key + ': ' + 'INGREDIENT_QUANTITY(?); ');
                        break;
                    case 'INGREDIENT_QUANTITY_SECONDS':
                        result += (key + ': ' + 'INGREDIENT_QUANTITY_SECONDS(?); ');
                        break;
                    case 'NUMBER':
                        result += (key + ': ' + 'NUMBER(' + (value.value ? value.value : '?') + '); ');
                        break;
                    case 'TEXT':
                        result += (key + ': ' + 'TEXT(' + (value.stringValue ? value.stringValue : '?') + '); ');
                        break;
                    case 'DEVICE':
                        result += (key + ': ' + (device ? device.deviceName : '') + '; ');
                        break;
                    case 'DISPENSER':
                        result += (key + ': ' + 'Dispenser; ');
                        break;
                    case 'DEVICE_PLACE':
                        result += (key + ': ' + (device ? device.deviceName : '') + ' place; ');
                        break;
                    case 'DELIVERY_PLACE':
                        result += (key + ': ' + 'DELIVERY_PLACE(?); ');
                        break;
                    case 'BLEND_TIME':
                        result += (key + ': ' + 'BLEND_TIME(?); ');
                        break;
                }
            }
        }
        return result.trim();

    }

    copyServingSteps() {
        this.kioskModelService.copyServingSteps(this.kioskModel, this.alternativeName).subscribe(response => {
            if (response.success) {

                this.kioskModel = response.value;
                this.app.showNotification('Copied');
            }
        });
    }

    deleteServingStepAlternative(key: string) {

        this.coolDialogs.confirm('Are you sure you want to delete the steps list?', {
            okButtonText: `Yes`,
            cancelButtonText: 'Cancel',
        })
        .subscribe(res => {
            if (res) {
                this.changes = true;
                setTimeout(() => {
                    delete this.kioskModel.stepPhases.cleaningStepsVariants[key];
                    this.app.showNotification('Removed');
                    this.changes = false;
                }, 500);

            } else {
            }
        });
    }

    rename(key: string) {
        this.changes = true;

        setTimeout(() => {
            this.kioskModel.stepPhases.cleaningStepsVariants[this.newName] = this.kioskModel.stepPhases.cleaningStepsVariants[key];
            delete this.kioskModel.stepPhases.cleaningStepsVariants[key];

            console.log(this.kioskModel.stepPhases.cleaningStepsVariants);
            this.changes = false;
            this.newName = '';
        }, 500);
    }

    onChangeTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.changeUsersComponent.onShow(this.kioskModel);
        }
    }

    clone() {
        this.coolDialogs.prompt('', {
            okButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            defaultText: '',
            title: 'Enter new model name'
        })
        .subscribe(res => {
            if (res['result']) {

                if (res['value'].length >= 3) {

                    this.kioskModelService.cloneModel(this.kioskModel, res['value']).subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('Clone successful');
                        } else {
                            this.app.showError('Clone failed');
                        }
                    }, error => {
                        this.app.showError('Clone failed');
                    });
                } else {
                    this.app.showError('Name is too short');
                }

            } else {
            }
        });
    }
}
