import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {StepTestService} from '../../../services/step-test.service';
import {KioskTestsComponent} from './kiosk-tests.component';
import {KioskBean, StepSequenceTestBean, StepSequenceTestResultBean} from '../../../model/model';

@Component({
    selector: 'kiosk-test-details-component',
    templateUrl: 'kiosk-test-details.component.html'
})

export class KioskTestDetailsComponent implements AfterViewInit {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    results: StepSequenceTestResultBean[];
    test: StepSequenceTestBean = {} as StepSequenceTestBean;

    constructor(private stepTestService: StepTestService,
        @Inject(forwardRef(() => KioskTestsComponent)) private parent: KioskTestsComponent) {
    }

    ngAfterViewInit(): void {
    }

    open(kioskId: number, operatorId: number, test: StepSequenceTestBean) {
        this.test = test;
        this.results = [];
        this.base.setFooterVisibility(false);
        this.base.show(test.stepSequence.description);

        this.stepTestService.getTestResults(kioskId, operatorId, test).subscribe(results => {
            this.results = results.list;
        });
    }

    save() {
    }

    openCycleDetails(result: StepSequenceTestResultBean) {
        this.parent.openCycleDetails(result, this.test.stepSequence.description + ': Cycle #' + result.cycle);
    }

}
