<button type="button" class="btn btn-outline-primary"
        (click)="getDetectionResults()">Refresh
</button>&nbsp;
<button type="button" class="btn btn-outline-primary"
        (click)="deleteDetectionResults()">Delete results
</button>
<br/>
<br/>

<div class="form-group row">


    <div class="form-horizontal">
        <div class="card-content">
            <div class="form-group">
                <label class="col-md-2 control-label" for="select_kiosk">Kiosk</label>
                <div class="col-md-10">
                    <select hidden
                            id="select_kiosk"
                            data-style="select-btn"
                            title="Select Kiokss"
                            data-size="7"
                            [disabled]="!kiosks"
                            [compareWith]="compareBeans"
                            [(ngModel)]="selectedKiosk"
                            (change)="onKioskChange()">
                        <option [ngValue]="allKiosks" selected="selected">All Locations</option>
                        <option [ngValue]="kiosk" selected="selected"
                                *ngFor="let kiosk of kiosks">{{kiosk.name}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
    </div>

    <div class="form-horizontal">
        <div class="card-content">
            <div class="form-group">
                <label class="col-md-2 control-label" for="select_detect_tag">Device</label>
                <div class="col-md-10">
                    <select hidden
                            id="select_detect_tag"
                            data-style="select-btn select-btn-grey"
                            title="Select Device"
                            [multiple]="true" data-size="7"
                            [(ngModel)]="selectedDeviceName"
                            (ngModelChange)="onDetectTagChange($event)">
                        <option [ngValue]="deviceName" selected="selected"
                                *ngFor="let deviceName of deviceNames">{{deviceName}}
                        </option>
                    </select>
                </div>
            </div>

        </div>
    </div>


    <div class="form-horizontal">
        <div class="card-content">
            <div class="form-group">
                <label class="col-md-2 control-label" for="select_detect_app">Application</label>
                <div class="col-md-10">
                    <select hidden
                            id="select_detect_app"
                            data-style="select-btn select-btn-grey"
                            title="Select Application"
                            data-size="7"
                            [(ngModel)]="selectedAppName"
                            (ngModelChange)="onAppChange($event)">
                        <option [ngValue]="null" selected="selected">All Applications
                        </option>

                        <option [ngValue]="'spill'">spill</option>
                        <option [ngValue]="'cup_detection'">cup_detection</option>
                        <option [ngValue]="'level_detection'">level_detection</option>
                    </select>
                </div>
            </div>

        </div>
    </div>

    <div class="form-horizontal">
        <div class="card-content">
            <div class="form-group">
                <label class="col-md-2 control-label" for="from_date">Date Range</label>
                <div class="col-md-10">
                    <input (bsValueChange)="onDateRangeChanged($event)" [(ngModel)]="selectedRange"
                           class="form-control"
                           id="from_date" bsDaterangepicker type="text"/>
                </div>
            </div>

        </div>
    </div>

    <div class="form-horizontal">
        <div class="card-content">
            <div class="form-group">
                <label class="col-md-2 control-label" for="select_detect_result">Result</label>
                <div class="col-md-10">
                    <select (ngModelChange)="onResultChange($event)"
                            [(ngModel)]="selectedResult"
                            data-size="7"
                            data-style="select-btn select-btn-grey"
                            hidden
                            id="select_detect_result"
                            title="All">
                        <option [ngValue]="null" selected="selected">All</option>
                        <option [ngValue]="true">True</option>
                        <option [ngValue]="false">False</option>
                    </select>
                </div>
            </div>

        </div>
    </div>

</div>

<table-component #resultsTable (onRemove)="onRemoveResults($event)" [searchText]="filter"
                 [buttons]="resultsButtons"></table-component>

<tag-select-component></tag-select-component>
