<div class="animated fadeIn">
    <div class="col-md-12" style="padding-left: 20px">

        <div class="card-content table-responsive table-full-width">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Step Id</th>
                    <th>Device</th>
                    <th>Step</th>
                    <th>State</th>
                    <th>Dispense quantity</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let step of steps">
                    <td>{{step.date | los_angeles: 'MM/dd/yyyy hh:mm:ss a'}}</td>
                    <td>{{step.stepId}}</td>
                    <td>{{step.device}}</td>
                    <td>{{step.step}}</td>
                    <td>{{step.state}}</td>
                    <td>{{step.dispenseQuantity ? JSON.stringify(step.dispenseQuantity) : ''}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
