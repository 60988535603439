<div class="row">

    <div class="col-md-12">
        <button type="button" class="btn btn-primary" (click)="restart()">Restart
        </button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="updateDeviceSW()">Update devices software
        </button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="updateConfig()">Configuration Update
        </button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="reset()">Reset
        </button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="testSpeed()">Test Network Speed
        </button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="pauseTakingOrders()">Pause Taking Orders
        </button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="resumeTakingOrders()">Resume Taking Orders
        </button>&nbsp;
    </div>

    <br/>
    <br/>
    <br/>
    <div class="row">
        <div class="col-md-2">
            <label class="col-form-label" for="input_repeat">Orders Count:</label>
            <input
                    id="input_repeat"
                    type="number"
                    class="btn btn-block" [(ngModel)]="ordersCount"
            />
        </div>
        <div class="col-md-2">
            <label class="col-form-label" for="select_thickness">Thickness:</label>
            <select class="form-control form-control-low show-menu-arrow selectx" id="select_thickness"
                    [(ngModel)]="ordersThickness">
                <option [ngValue]="'THIN'">Thin</option>
                <option [ngValue]="'NORMAL'">Normal</option>
                <option [ngValue]="'THICK'">Thick</option>
            </select>
        </div>

        <div class="col-md-2">
            <label class="col-form-label">&nbsp;</label>
            <button type="button" class="btn btn-outline-primary" style="width: 100%"
                    (click)="placeOrders()">Place
            </button>

        </div>
    </div>


    <br/>
    <br/>
    <br/>
    <div class="row">
        <div class="col-md-4">
            <label class="col-form-label" for="input_name">New Kiosk Name:</label>
            <input
                    id="input_name"
                    type="text"
                    class="btn btn-block" [(ngModel)]="copyKioskName"
            />
        </div>

        <div class="col-md-2">
            <label class="col-form-label">&nbsp;</label>
            <button type="button" class="btn btn-outline-primary" style="width: 100%"
                    (click)="copyKiosk()">Copy
            </button>

        </div>
    </div>

</div>
