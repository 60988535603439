import {AfterViewInit, Component, forwardRef, Inject, Input, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../../../common/field';
import {RecipeListService} from '../../../../services/recipe-list.service';
import {EntityNameBean, KioskBean, ManageKioskDescriptionBean, MealPlanBean, OperatorBean, RecipeListBean} from '../../../../model/model';
import {Observable} from 'rxjs';
import {AppComponent} from '../../../../app.component';
import {HttpClient} from '@angular/common/http';
import {FieldsListComponent} from '../../../../components/fields-list.component';
import {AppSettings} from '../../../../app.settings';
import {Helper} from '../../../../common/helper';
import {OperatorsService} from '../../../../services/operators.service';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {MealplanService} from '../../../../services/mealplan.service';
import {KioskModelService} from '../../../../services/kiosk-model.service';
import {KioskService} from '../../../../services/kiosk.service';
import {Router} from '@angular/router';

@Component({
    selector: 'kiosk-description-component',
    templateUrl: 'kiosk-info.component.html'
})
export class KioskInfoComponent implements AfterViewInit {

    modelNames: EntityNameBean[];
    mealplans: MealPlanBean[];

    @Input() adminMode: boolean = true;

    @ViewChild('kioskFieldsListComponent', {static: false}) fieldsListComponent: FieldsListComponent;

    fields = [
        new Field('name', 'Name').require(),
        new Field('state', 'State', FieldType.SELECT).setOptions(['OUT_OF_SERVICE', 'WAITING', 'OPERATIONAL', 'MAINTENANCE', 'PROVISIONING', 'DECOMMISSIONED']),
        new Field('shortName', 'Short Name'),

        new Field('addressLine1', 'Address Line #1').require(),
        new Field('addressLine2', 'Address Line #2'),
        new Field('country', 'Country').require(),
        new Field('countryCode', 'Country Code'),
        new Field('city', 'City').require(),
        // new Field('county', 'County'),
        // new Field('zipCode', 'Zip Code'),
        // new Field('phoneNumber', 'Phone Number', FieldType.TEL),

        new Field('contactName', 'Contact Name'),
        new Field('contactEmail', 'Contact Email'),

        new Field('contactPhone', 'Contact Phone', FieldType.TEL),
        new Field('textPhone', 'Text Phone', FieldType.TEL),
        new Field('launchDate', 'Launch Date', FieldType.DATE),

        new Field('usual', 'Usual Recipes', FieldType.CHECKBOX),
        new Field('popular', 'Popular Recipes', FieldType.CHECKBOX),
        new Field('promo', 'Promo', FieldType.CHECKBOX),
        new Field('promoImageUrl', 'Promo Image', FieldType.FILE).if(KioskInfoComponent.isPromo),
        new Field('promoDiscountPct', 'Promo Discount %', FieldType.NUMBER).if(KioskInfoComponent.isPromo),

        new Field('videoStreamId', 'Video Stream ID #1'),
        new Field('videoStreamId2', 'Video Stream ID #2'),
        new Field('tax', 'Tax', FieldType.NUMBER),

        new Field('squareLocationId', 'Square Location Id'),
        new Field('latitude', 'Latitude', FieldType.NUMBER).require(),
        new Field('longitude', 'Longitude', FieldType.NUMBER).require(),

        new Field('errorMsgKioskPaused', 'Kiosk Welcome Message'),
        new Field('errorMsgWorkingHours', 'Working Hours Error'),
        new Field('msgJumbotronIdle', 'JT Idle Message', FieldType.TEXT_AREA),
        new Field('msgJumbotronKioskOperational', 'JT Operational Message'),
        new Field('msgJumbotronMaintenance', 'JT Maintenance Message'),
        new Field('msgJumbotronDisclaimer', 'JT Disclaimer Message'),
        new Field('jumbotronPostStaticImageUrl', 'JT Post Static Image', FieldType.FILE),
        new Field('jumbotronPostDefaultImageUrl', 'JT Post Default Image', FieldType.FILE),
        new Field('jumbotronPostDefaultAutoMaintenanceImageUrl', 'JT Post Auto Maintenance Image', FieldType.FILE),
        new Field('jumbotronPostMaintenanceImageUrl', 'JT Post Maintenance Image', FieldType.FILE),
        new Field('jumbotronPostPopularRecipeTimeout', 'JT Most Popular Recipe Timeout', FieldType.NUMBER),
        new Field('externalAdsEnabled', 'JT External Ads Enabled', FieldType.CHECKBOX),

        new Field('jumbotronPostVideoUrl', 'JT Post Video Url'),
        new Field('msgTTSOrderReady', 'Speak when order is ready'),
        new Field('msgTTSOrderDelivery', 'Speak when order is delivering'),

        new Field('msgWorkingHours', 'Working Hours'),
        new Field('msgDisclaimers', 'Disclaimers'),
        new Field('msgKioskWelcome', 'Kiosk Welcome'),

        new Field('mealplanEnabled', 'Meal Plan (deprecated)', FieldType.CHECKBOX),
        new Field('mealplanUrl', 'Meal Plan URL (deprecated)').if(KioskInfoComponent.isMealplanEnabled),
        new Field('mealplanLoginUrl', 'Meal Plan Login URL (deprecated)').if(KioskInfoComponent.isMealplanEnabled),
        new Field('mealplanUsername', 'Meal Plan User Name (deprecated)').if(KioskInfoComponent.isMealplanEnabled),
        new Field('mealplanPassword', 'Meal Plan Password (deprecated)').if(KioskInfoComponent.isMealplanEnabled),
        new Field('mealplanCardDefaultName', 'Meal Plan Card Default Name (deprecated)').if(KioskInfoComponent.isMealplanEnabled),

        new Field('production', 'Production', FieldType.CHECKBOX),
        new Field('ingredientRatioAdjustmentLogicOnDevice', 'Ingredient Ratio Adjustment Logic On Device', FieldType.CHECKBOX),
        new Field('useRecipeMakerForOrderCustomisation', 'Use Recipe Maker For Order Customisation', FieldType.CHECKBOX),
        new Field('appOptionSurpriseMeEnabled', 'Surprise me', FieldType.CHECKBOX),
        new Field('appOptionMyCreationsEnabled', 'My creations', FieldType.CHECKBOX),
        new Field('storageOptionEnabled', 'Storage Option', FieldType.CHECKBOX),
        new Field('robotWaveTimeout', 'Robot Wave Timeout (seconds)', FieldType.NUMBER),
        new Field('defaultOrderExpirationSeconds', 'Default Order Expiration (seconds)', FieldType.NUMBER),

        new Field('longWaitTimeNotificationMinutes', 'Long Wait Time Notification (minutes)', FieldType.NUMBER),
        new Field('longPreparingTimeNotificationMinutes', 'Long Preparing Time Notification (minutes)', FieldType.NUMBER),

        new Field('pinCodeForRefund', 'Pin Code for Refund'),

        new Field('kioskSubnetAddress', 'Subnet Address'),
        new Field('remoteItSSHAlias', 'Remote.It SSH Alias'),
        new Field('kioskServiceURL', 'Kiosk Service URL'),
        new Field('robotIp', 'Robot IP Address'),

        new Field('nocContactsUrl', 'NOC Contacts Url'),
        new Field('nocBaChecklistUrl', 'NOC Ba Checklist Url'),
        new Field('nocL2ChecklistUrl', 'NOC L2 Checklist Url'),
    ];

    fieldsChef = [
        new Field('name', 'Name').require(),
        new Field('addressLine1', 'Address Line #1').require(),
        new Field('addressLine2', 'Address Line #2'),
        new Field('country', 'Country').require(),
        new Field('countryCode', 'Country Code'),
        new Field('city', 'City').require(),
        new Field('contactName', 'Contact Name'),
        new Field('contactEmail', 'Contact Email'),
        new Field('contactPhone', 'Contact Phone', FieldType.TEL),
        new Field('latitude', 'Latitude', FieldType.NUMBER).require(),
        new Field('longitude', 'Longitude', FieldType.NUMBER).require(),
        new Field('errorMsgKioskPaused', 'Kiosk Welcome Message'),
        new Field('errorMsgWorkingHours', 'Working Hours Error'),
        new Field('msgJumbotronIdle', 'JT Idle Message', FieldType.TEXT_AREA),
        new Field('msgWorkingHours', 'Working Hours'),
        new Field('msgDisclaimers', 'Disclaimers'),
        new Field('msgKioskWelcome', 'Kiosk Welcome'),

        new Field('robotWaveTimeout', 'Robot Wave Timeout (seconds)', FieldType.NUMBER),
    ];

    recipesLists: RecipeListBean[];
    compareBeans = Helper.compareBeans;
    operators: OperatorBean[];
    kioskId: number;

    kioskDescription: ManageKioskDescriptionBean;

    // initKioskDescription: ManageKioskDescriptionBean;

    constructor(private recipesListsService: RecipeListService,
        private http: HttpClient,
        private operatorsService: OperatorsService,
        private mealplanService: MealplanService,
        private kioskModelService: KioskModelService,
        private kioskManageService: KioskManageService,
        private kioskService: KioskService,
        private router: Router,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadRecipesLists();
    }

    loadRecipesLists() {
        this.recipesListsService.getAllRecipeLists().subscribe(recipesLists => {
            this.recipesLists = recipesLists.list;
        });
    }

    private static isPromo(kiosk: KioskBean) {
        return kiosk.promo;
    }

    getLocation() {

        this.findFromAddress().subscribe(response => {
            console.log(response);

            if (response.results.length > 0) {

                this.kioskDescription.latitude = response.results[0].geometry.location.lat;
                this.kioskDescription.longitude = response.results[0].geometry.location.lng;

            } else {
                this.app.showError('Can\'t get location');
            }
        });
    }

    findFromAddress(): Observable<any> {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + this.getAddress() +
            '&key=' + AppSettings.getFirebaseConfig().apiKey;
        return this.http.get(url);
    }

    getAddress() {
        return (this.kioskDescription.addressLine1 ? this.kioskDescription.addressLine1 : '') + '' +
            (this.kioskDescription.addressLine2 ? this.kioskDescription.addressLine2 : '') + '' +
            (this.kioskDescription.city ? this.kioskDescription.city : '') + '' +
            (this.kioskDescription.county ? this.kioskDescription.county : '') + '' +
            (this.kioskDescription.country ? this.kioskDescription.country : '');
    }

    private static isMealplanEnabled(kiosk: KioskBean) {
        return kiosk.mealplanEnabled;
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;

        if (!this.operators) {
            this.operatorsService.getOperators().subscribe(response => {
                this.operators = response;
                // Helper.selectPicker('select_operator');
            });
        }

        if (!this.mealplans) {
            this.mealplanService.getMealplans().subscribe(response => {
                this.mealplans = response.value;
                Helper.selectPicker('select_mealplans');
            });
        }

        if (!this.modelNames) {
            this.kioskModelService.getKioskModelNames().subscribe(response => {
                if (response.success) {
                    this.modelNames = response.value;
                }
            });
        }

        if (!this.kioskDescription) {

            if (this.kioskId == 0) {
                this.kioskDescription = {} as ManageKioskDescriptionBean;
            } else {
                this.kioskManageService.getDescription(this.kioskId).subscribe(response => {
                    if (response.success) {
                        this.kioskDescription = response.value;
                        // this.initKioskDescription = Helper.copyObject(this.kioskDescription);
                    }
                });
            }
        }
    }

    save() {
        if (!this.fieldsListComponent.valid()) {
            this.app.showError('Please check all required fields');
            return;
        }

        // const body = Helper.merge(this.initKioskDescription, this.kioskDescription) as ManageKioskDescriptionBean;
        // console.log(body);

        if (this.kioskId != 0) {

            this.kioskManageService.setDescription(this.kioskId, this.kioskDescription).subscribe(response => {
                if (response.success) {
                    this.app.showNotification('Updated');
                    // this.initKioskDescription = this.kioskDescription

                } else {
                    this.app.showError('Can\'t update kiosk');
                }
            }, error => {
                this.app.showError('Can\'t update kiosk');
            });

        } else {
            this.kioskService.addKiosk(this.kioskDescription as unknown as KioskBean).subscribe(response => {
                if (response.success) {
                    this.app.showNotification('Created');
                    this.router.navigate(['kiosks'], {queryParams: {states: 'OUT_OF_SERVICE'}});
                    // this.initKioskDescription = this.kioskDescription

                } else {
                    this.app.showError('Can\'t update kiosk');
                }
            }, error => {
                this.app.showError('Can\'t update kiosk');
            });

        }
    }
}
