<div class="animated fadeIn">
    <div class="col-md-12">
<!--        <div>-->
<!--            <select hidden multiple-->
<!--                    id="select_operator"-->
<!--                    data-style="select-btn"-->
<!--                    title="Select Operator"-->
<!--                    data-size="7"-->
<!--                    [(ngModel)]="selectedOperators"-->
<!--                    (change)="onOperatorChange()">-->
<!--                <option [ngValue]="0">All Operators</option>-->
<!--                <option [ngValue]="location.id"-->
<!--                        *ngFor="let location of operators">{{location.name}}-->
<!--                </option>-->
<!--            </select>-->
<!--        </div>-->
        <div>
            <select
                    multiple hidden
                    id="select_state"
                    data-style="select-btn" data-size="7"  (change)="onStateChange()"
                    [(ngModel)]="selectedStates">
                <option [ngValue]="'ACTIVE'">ACTIVE</option>
                <option [ngValue]="'PROVISIONING'">PROVISIONING</option>
                <option [ngValue]="'WAITING'">WAITING</option>
                <option [ngValue]="'INACTIVE'">INACTIVE</option>
            </select>
        </div>

        <div class="card">
            <div class="card-content">
                <table-component (onEdit)="onEdit($event)" (onClickRow)="onClickRow($event)"
                                 (onAdd)="onAdd()"></table-component>
            </div>
        </div>
    </div>
</div>
<operators-edit-component></operators-edit-component>
<operators-quickview-component></operators-quickview-component>
