<base-modal-component class="base-modal">

    <table class="table table-striped">
        <thead>
        <tr>
            <th>Date</th>
            <th>Message</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let message of result.messages">
            <td>{{message.date | los_angeles: 'MM/dd/yyyy hh:mm:ss a'}}</td>
            <td>{{message.msg}}</td>
        </tr>
        </tbody>
    </table>


</base-modal-component>
