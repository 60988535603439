import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {CouponCodeBean} from '../../../../model/model';
import {Helper} from '../../../../common/helper';
import {TableComponent} from '../../../../components/table.component';
import {Column} from '../../../../common/column';
import {KioskCouponsEditComponent} from './kiosk-coupons.edit.component';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-coupons-component',
    templateUrl: 'kiosk-coupons.component.html'
})
export class KioskCouponsComponent implements AfterViewInit {

    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskCouponsEditComponent, {static: false}) modal: KioskCouponsEditComponent;

    fields: Column[] = [
        new Column('id', 'Id'),
        new Column('name', 'Name'),
        new Column('promotion.name', 'Promotion'),
        new Column('discount', 'Discount'),
        new Column('subCategory', 'Category'),
        new Column('bypassPhoneNumberValidation', 'Bypass Phone Number Validation').checkbox(),
        new Column('oneTimePerUser', 'One Time Per User').checkbox(),
        new Column('startDate', 'Start Date').shortDate(),
        new Column('endDate', 'End Date').shortDate(),
        new Column('', 'Days of Week').data(this, this.getDays),
    ];

    days = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ];
    coupons: CouponCodeBean[];
    kioskId: number;

    constructor(private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    getDays(coupon: CouponCodeBean): string {
        let result: string = '';
        for (let i = 0; i < 7; i++) {
            if (coupon.workingHours[i] && coupon.workingHours[i].hourTo >
                coupon.workingHours[i].hourFrom) {
                result += (this.days[i] + ', ');
            }
        }
        if (result.length > 2) {
            result = result.slice(0, -2);
        }
        return result;
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;

        this.getCoupons();

    }

    onEdit(items: CouponCodeBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Coupon');
        }
    }

    onRemove(items) {
        for (const step of items) {
            const index = this.coupons.indexOf(step);
            this.coupons.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Coupon');
    }

    addCoupon(coupon: CouponCodeBean) {

        if (coupon.id == 0 || coupon.id == null) {
            this.coupons.push(coupon);

        } else {

            let ind = -1;
            let i = 0;
            for (const cp of this.coupons) {
                if (cp.id == coupon.id) {
                    ind = i;
                    break;
                }
                i++;
            }

            if (ind >= 0) {
                this.coupons.splice(ind, 1);
                this.coupons.splice(ind, 0, coupon);
            }
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {
            this.table.setData(this.coupons, this.fields);

            console.log(this.coupons.map(it => it.promotion));
        }
    }

    save() {
        this.coupons.forEach(it => {
            if (it.subCategory == 'PERCENT_OFF') {
                it.discount = +(it.discount / 100).toFixed(2);
            }
        });
        this.kioskManageService.setCoupons(this.kioskId, this.coupons).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
            this.getCoupons();

        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }

    private getCoupons() {
        this.kioskManageService.getCoupons(this.kioskId).subscribe(response => {
            if (response.success) {

                this.coupons = response.value;
                this.coupons.forEach(it => {
                    if (it.subCategory == 'PERCENT_OFF') {
                        it.discount = +(it.discount * 100).toFixed(2);
                    }
                });
                this.updateTable();
            }
        });
    }
}
