import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    new RouteInfo('', 'Dashboard', [
        new RouteInfo('/home', 'Summary'),
        new RouteInfo('/analytics', 'Analytics'),
        new RouteInfo('/business-ops', 'Business Ops'),
    ]
    ),

    new RouteInfo('/operators', 'Operators'),
    new RouteInfo('/kiosks', 'Locations'),
    new RouteInfo('/promotions', 'Promotions'),

    new RouteInfo('', 'Recipes', [
        new RouteInfo('/lists', 'Lists'),
        new RouteInfo('/categories', 'Categories'),
        new RouteInfo('/recipes', 'Recipes'),
        new RouteInfo('/ingredients', 'Ingredients'),
        new RouteInfo('/boosts', 'Boosts'),
        new RouteInfo('/custom-recipes', 'Custom Recipes'),
    ]
    ),
    new RouteInfo('', 'Monitor', [
        new RouteInfo('/orders', 'Orders'),
        new RouteInfo('/reviews', 'Reviews'),
        new RouteInfo('/service-events', 'Service Events')
    ]
    ),
    new RouteInfo(),
    new RouteInfo('', 'Profiles', [
        new RouteInfo('/profiles/admins', 'Super Admins'),
        new RouteInfo('/profiles/users', 'Users'),
        new RouteInfo('/profiles/customers', 'Clients'),
        new RouteInfo('/companies', 'Companies'),
    ]
    ),
    new RouteInfo('', 'Devices', [
        new RouteInfo('/devices', 'Abstract Devices'),
        new RouteInfo('/device-templates', 'Device Templates'),
        new RouteInfo('/device-provisioning', 'Device Provisioning')
    ]
    ),
    new RouteInfo('', 'Configuration', [
        new RouteInfo('/kiosk-templates', 'Templates'),
        new RouteInfo('/kiosk-models', 'Models'),
        new RouteInfo('/vision', 'Vision'),
        new RouteInfo('/macro-steps', 'Macro Steps'),
        new RouteInfo('/system-configuration', 'System'),
    ]
    ),
    new RouteInfo('', 'Payment Methods', [
        new RouteInfo('/payment-credentials', 'Payment Credentials'),
        new RouteInfo('/mealplans', 'Meal Plans'),
    ]
    ),
    new RouteInfo('/event-log', 'Event Log'),
    new RouteInfo('/app-configuration', 'App Configuration'),
    new RouteInfo('/notifications', 'App Notifications'),
    new RouteInfo('/documentation', 'Documentation'),
    new RouteInfo('/image-manager', 'Image Manager'),
];
