<div class="wrapper">
    <progress-loader></progress-loader>
    <div class="sidebar" data-background-color="brown" data-active-color="danger">
        <sidebar-cmp></sidebar-cmp>
    </div>

    <navbar-cmp></navbar-cmp>
    <div class="main-panel">


        <div class="content">
            <router-outlet></router-outlet>
        </div>
        <footer-cmp></footer-cmp>
    </div>
</div>
