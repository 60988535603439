import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../../components/base.modal.component';
import {FieldsListComponent} from '../../../../components/fields-list.component';
import {Field, FieldType} from '../../../../common/field';
import {KioskWorkingHoursBean, WorkingHoursBean} from '../../../../model/model';
import {AppComponent} from '../../../../app.component';
import {Helper} from '../../../../common/helper';
import {KioskHoursComponent} from './kiosk-hours.component';

@Component({
    selector: 'kiosk-hours-edit-component',
    templateUrl: 'kiosk-hours.edit.component.html'
})

export class KioskHoursEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    hours: KioskWorkingHoursBean = {} as KioskWorkingHoursBean;

    fields: Field[] = [
        new Field('ts', 'Date', FieldType.DATE).require(),
        new Field('hourOpen', 'Open'),
        new Field('hourClose', 'Close'),
        new Field('autoMaintenanceActivation', 'Auto Maintenance Activation', FieldType.CHECKBOX),
    ];
    initHours: KioskWorkingHoursBean;

    constructor(@Inject(forwardRef(() => KioskHoursComponent)) private parent: KioskHoursComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    open(hours: KioskWorkingHoursBean, title: string) {
        this.base.show(title);
        this.hours = hours ? Helper.copyObject(hours) : {} as WorkingHoursBean;
        this.initHours = hours ? Helper.copyObject(hours) : {} as WorkingHoursBean;
    }

    save() {
        this.parent.addHours(this.initHours, this.hours);
    }

}
