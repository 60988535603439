import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {DashboardModule} from './dashboard/dashboard.module';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {ProfileService} from './services/profile.service';
import {CompanyService} from './services/company.service';
import {OperatorsService} from './services/operators.service';
import {DataService} from './services/data.service';
import {CategoryService} from './services/category.service';
import {DeviceService} from './services/device.service';
import {IngredientService} from './services/ingredient.service';
import {UploadService} from './services/upload.service';
import {KioskService} from './services/kiosk.service';
import {KioskTemplateService} from './services/kiosk-template.service';
import {OrderService} from './services/order.service';
import {RecipeService} from './services/recipe.service';
import {ReviewService} from './services/review.service';
import {MacroStepService} from './services/macro-step.service';
import {EventLogService} from './services/event-log.service';
import {VisionService} from './services/vision.service';
import {AuthGuard, RightsGuard} from './common/auth.guard';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {DeviceTemplateService} from './services/device-template.service';
import {KioskModelService} from './services/kiosk-model.service';
import {ProgressService} from './file-uploader/progress.service';
import {DiagnosticsStepService} from './services/diagnosics-step.service';
import {KioskDataService} from './services/kiosk-data.service';
import {CanDeactivateKioskModelDetails} from './dashboard/kiosk-model-details/kiosk-model-details.component';
import {DashboardService} from './services/dashboard.service';
import {CanDeactivateMacroStepEdit} from './dashboard/macro-step-edit/macro-step-edit.component';
import {StepTestService} from './services/step-test.service';
import {RobotCommandService} from './services/robot-command.service';
import {ProgressLoaderService} from './services/loader.service';
import {RecipeListService} from './services/recipe-list.service';
import {HttpClientModule, ɵangular_packages_common_http_http_d as ImportedXhr} from '@angular/common/http';
import {CustomBrowserXhr} from './file-uploader/custom.xhr';
import {PaymentService} from './services/payment.service';
import {UnauthorizedModule} from './unauthorized/unauthorized.module';
import {StoreManagerService} from './services/store-manager.service';
import {ConfigurationService} from './services/configuration.service';
import {MealplanService} from './services/mealplan.service';
import {ToastrModule} from 'ngx-toastr';
import {PromotionsService} from './services/promotions.service';
import {KioskManageService} from './services/kiosk-manage.service';
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        DashboardModule,
        UnauthorizedModule,
        SharedModule,
        SocialLoginModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 15000,
            positionClass: 'toast-bottom-left',
            preventDuplicates: true,
            progressBar: true
        }),
        RouterModule,
        RouterModule.forRoot([]),
    ],
    exports: [],
    declarations: [
        AppComponent,
    ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: ImportedXhr, useClass: CustomBrowserXhr},
        CanDeactivateKioskModelDetails, CanDeactivateMacroStepEdit, ProgressLoaderService,
        NavbarComponent, AuthGuard, RightsGuard, ProfileService, CompanyService, OperatorsService, DataService, CategoryService,
        DeviceService, IngredientService, UploadService, KioskService, KioskTemplateService, OrderService,
        RecipeService, ReviewService, MacroStepService, EventLogService, VisionService, KioskDataService, KioskManageService,
        DeviceTemplateService, KioskModelService, ProgressService, DiagnosticsStepService, DashboardService, PromotionsService,
        StepTestService, RobotCommandService, RecipeListService, PaymentService, StoreManagerService, ConfigurationService, MealplanService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '515987041167-ib1cnrd18a6ugjbfrc8k6vug0kphe536.apps.googleusercontent.com'
                        )
                    }
                ]
            } as SocialAuthServiceConfig,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
