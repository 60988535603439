<div class="animated fadeIn">
    <div class="col-md-12">

        <select hidden
                id="select_kiosk"
                data-style="select-btn"
                title="Select Location"
                data-size="7"
                [compareWith]="compareBeans"
                [(ngModel)]="selectedKiosk"
                (change)="onKioskChange()">
            <option [ngValue]="kiosk" selected="selected"
                    *ngFor="let kiosk of kiosks">{{kiosk.name}}
            </option>
        </select>

        <select hidden
                id="select_recipe"
                data-style="select-btn"
                title="Select Recipe"
                data-size="7"
                [compareWith]="compareBeans"
                [(ngModel)]="selectedRecipe"
                (change)="onRecipeChange()">
            <option [ngValue]="allRecipes" selected="selected">All Recipes</option>
            <option [ngValue]="recipe" selected="selected"
                    *ngFor="let recipe of recipes">{{recipe.name}}
            </option>
        </select>

        <div class="card">
            <div class="card-content">

                <div class="row">
                    <div class="col-md-6">
                        <button (click)="download()" class="btn btn-outline-primary" style="float: right"
                                type="button">Download CSV
                        </button>&nbsp;
                    </div>
                    <div class="col-md-6" style="float: right">
                        <input [(ngModel)]="selectedRange"
                               bsDaterangepicker
                               class="form-control" id="from_date" type="text"/>
                    </div>
                </div>

                <table-component (onRemove)="onRemove($event)" (onView)="onView($event)"
                                 (onClickRow)="onView($event)"></table-component>
            </div>
        </div>
    </div>
</div>

<review-quickview-component></review-quickview-component>
