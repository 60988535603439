<div class="form-horizontal">
    <div class="card-content">

        <div class="form-group">
            <label for="history_start_date" class="col-md-3 control-label">Steps History since</label>
            <div class="col-md-9">
                <input type="text" id="history_start_date" class="form-control datetimepicker"
                       placeholder="Datetime Picker Here"/>
            </div>
        </div>

    </div>
</div>

<table-component (dataFunction)="getData($event)"></table-component>