<div class="animated fadeIn">
    <div class="col-md-12">
        <div class="card">
            <div class="card-content">
                <table-component (onEdit)="onEdit($event)"
                                 (onRemove)="onRemove($event)"
                                 (onAdd)="onAdd()"></table-component>
            </div>
        </div>
    </div>
</div>
<kiosk-templates-edit-component #kioskTemplatesEditComponent></kiosk-templates-edit-component>
