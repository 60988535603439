import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {AppComponent} from '../../app.component';
import {ProfileService} from '../../services/profile.service';
import {Helper} from '../../common/helper';

@Component({
    templateUrl: 'password-reset.component.html'
})

export class PasswordResetComponent implements AfterViewInit {

    currentDate: Date;
    key: any;

    constructor(public router: Router,
        private userService: ProfileService,
        private dataService: DataService,
        private activatedRoute: ActivatedRoute,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {

        this.currentDate = new Date();
    }

    ngAfterViewInit() {

        this.activatedRoute.params.subscribe((params: Params) => {
            this.key = params['key'];
        });
    }

    resetPassword(p1: string, p2: string) {

        if (p1 == p2) {

            if (p1.length >= 6) {

                console.log(this.key);
                console.log(p1);
                this.userService.resetPassword(this.key, Helper.encodePassword(p1)).subscribe(response => {
                    console.log(response);
                    if (response.success) {
                        this.app.showNotification('Successful');
                        setTimeout(() => {
                            this.router.navigate(['/login']);
                        }, 1000);
                    } else {
                        this.app.showError('Can\'t change password. Please check your data');
                    }
                });

            } else {
                this.app.showError('The password is too short.');
            }
        } else {
            this.app.showError('Passwords are different');
        }
    }

}
