import {AfterViewInit, Component, forwardRef, Inject, OnDestroy} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {RobotView} from '../../../presentation/robot.view';
import {KioskModelService} from '../../../services/kiosk-model.service';
import {KioskTemplateService} from '../../../services/kiosk-template.service';
import {IngredientDispenserView} from '../../../presentation/ingredient-dispenser.view';
import {Helper} from '../../../common/helper';
import {KioskDefines} from '../../../presentation/kiosk.defines';
import {JarView} from '../../../presentation/jar.view';
import {BlenderView} from '../../../presentation/blender.view';
import {CupView} from '../../../presentation/cup.view';
import '../../../common/fabric';
import {AppComponent} from '../../../app.component';
import {AppSettings} from '../../../app.settings';
import {DataService} from '../../../services/data.service';
import {
    DeviceBean,
    DeviceIdBean,
    DeviceModelBean,
    KioskBean,
    KioskModelBean,
    KioskSnapshotBean,
    KioskTemplateBean,
    StepSnapshotBean
} from '../../../model/model';
import {DataItem, IdType} from 'vis';
import {DeviceModelViewBean} from '../../../model/custom-model';
import {Options} from 'ng5-slider';
import {TimelineOptions, VisTimelineService} from 'ngx-vis';

class Opt implements TimelineOptions {
    maxHeight = 300;
    minHeight = 300;
}

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

@Component({
    selector: 'kiosk-presentation-component',
    templateUrl: 'kiosk-presentation.component.html'
})
export class KioskPresentationComponent implements AfterViewInit, OnDestroy {

    requestIntervalPlayback = 100;
    preloadTime = 10 * 60 * 1000;
    preloadUpdateTime = 2 * 1000;

    kiosk: KioskBean = {} as KioskBean;
    dateFrom: Date = new Date();
    dateTo: Date = new Date();
    visTimeline = 'timelineId1';
    visTimelineItems: DataItem[];
    locationId: number;
    kioskSnapshots: KioskSnapshotBean;
    keySnapshots: StepSnapshotBean[];
    ids: number[];
    speed = 8;
    visInitialized = false;

    canvas: fabric.Canvas;
    devices: DeviceBean[] = [];
    mRequestedTime: number;
    mPlaybackIntervalId: NodeJS.Timer;
    mSnapshotIds: number[];
    lastTimelineUpdateTime: number;
    robot: RobotView;

    scale: number = 768.0 / 698.0;
    width: number;
    height: number;
    model: KioskModelBean;
    template: KioskTemplateBean;
    pauseDisabled = true;
    paused = false;
    playbackTime: Date;
    cups: CupView[] = [];
    visIds: {[index: string]: StepSnapshotBean} = {};

    options: Options = {
        floor: 1,
        ceil: 12
    };

    constructor(private kioskService: KioskService,
        private dataService: DataService,
        private kioskModelService: KioskModelService,
        private kioskTemplateService: KioskTemplateService,
        private visTimelineService: VisTimelineService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.dateFrom.setDate(this.dateFrom.getDate() - 1);

        $('#presentation_from_date').datetimepicker({
            icons: Helper.faIcons,
            date: this.dateFrom
        }).on('dp.change', (e) => {
            this.dateFrom = new Date(e.date);
        });

        $('#presentation_to_date').datetimepicker({
            icons: Helper.faIcons,
            date: this.dateTo
        }).on('dp.change', (e) => {
            this.dateTo = new Date(e.date);
        });

        this.visTimelineItems = [];

        this.canvas = new fabric.Canvas('kioskCanvas', {
            hoverCursor: 'pointer',
            selection: false,
        });
    }

    ngOnDestroy() {
        try {
            firebase.app().delete();
        } catch (error) {
        }

        clearInterval(this.mPlaybackIntervalId);
    }

    onShow(kiosk: KioskBean, locationId: number) {

        this.kiosk = kiosk;
        this.locationId = locationId;

        this.initializeTimeline();
        this.loadKioskDevices();
    }

    loadSnapshots() {
        this.kioskService.getSnapshots(this.locationId, this.kiosk.id, this.dateFrom.getTime(), this.dateTo.getTime())
            .subscribe(kioskSnapshots => {

                this.kioskSnapshots = kioskSnapshots.value;
                this.ids = [];
                this.keySnapshots = [];
                this.visTimelineItems = [];

                for (const snapshot of this.kioskSnapshots.snapshots) {
                    if (this.ids.indexOf(snapshot.orderId) >= 0) {
                        continue;
                    }
                    if (snapshot.orderId == 0) {
                        continue;
                    }

                    this.visTimelineItems.push({
                        id: snapshot.id, content: '#' + snapshot.orderId, start: snapshot.date
                    });

                    this.visIds[snapshot.id] = snapshot;

                    this.keySnapshots.push(snapshot);
                    this.ids.push(snapshot.orderId);
                }

                if (this.kioskSnapshots.snapshots.length > 0) {
                    const first = this.kioskSnapshots.snapshots[0];
                    const last = this.kioskSnapshots.snapshots[this.kioskSnapshots.snapshots.length - 1];
                    this.visTimelineService.setWindow(this.visTimeline, first.date, last.date);
                }
            });
    }

    loadKioskDevices() {

        this.kioskService.getDevices(this.kiosk.id).subscribe(devices => {
            this.devices = devices.list;
            this.loadModel();
        });
    }

    loadModel() {

        this.kioskModelService.getKioskModel(this.kiosk.kioskModelId).subscribe(response => {
            this.model = response.value;
            this.loadTemplate();
        });
    }

    loadTemplate() {

        this.kioskTemplateService.getKioskTemplate(this.model.kioskTemplate.id).subscribe(response => {
            this.template = response.value;
            this.loadSnapshots();
            this.initializePresentation();
        });
    }

    initializePresentation() {

        this.canvas.clear();
        const img = new Image();
        img.onload = () => {

            this.width = img.width;
            this.height = img.height;

            const rect = {width: img.width * this.scale, height: img.height * this.scale};
            this.canvas.setBackgroundImage(img.src, this.canvas.renderAll.bind(this.canvas), rect);
            this.canvas.setDimensions(rect);

            for (const device of this.model.devices) {
                this.addDevice(device as DeviceModelViewBean);
            }

            this.startDevicesWaiter();
        };
        img.src = this.template.bgImage;

    }

    addDevice(device: DeviceModelViewBean) {

        const type = device.deviceTemplate.abstractDevice.name;
        switch (type) {
            case 'Robot':
                this.robot = new RobotView(this.getPlace(device.x, device.y), this.canvas, objects => {
                    device.views = objects;
                });
                device.presentation = this.robot;
                break;
            case 'Ingredient Dispenser':
                const dispenser = new IngredientDispenserView(device, this.getIngredient(device),
                    this.getPlace(device.x, device.y), this.canvas, objects => {
                        device.views = objects;
                    });
                device.presentation = dispenser;
                break;
            case 'Jar':
                const jar = new JarView(device, this.getPlace(device.x, device.y), this.canvas, objects => {
                    device.views = objects;
                });
                device.presentation = jar;
                break;

            case 'Blender':
                const blender = new BlenderView(device, this.getPlace(device.x, device.y), this.canvas, objects => {
                    device.views = objects;
                });
                device.presentation = blender;
                break;

            default:
                this.createDeviceView(device);
        }

    }

    getIngredient(deviceModel: DeviceModelBean) {
        const device = this.getDevice(deviceModel);
        return device && device.canDispenseIngredients && device.canDispenseIngredients.length ? device.canDispenseIngredients[0].name : '';
    }

    getDevice(deviceModel: DeviceModelBean) {
        return this.getDeviceById(deviceModel.id);
    }

    getDeviceById(deviceModelId: number): DeviceBean {
        for (const device of this.devices) {
            if (device.deviceModelId == deviceModelId) {
                return device;
            }
        }
        return null;
    }

    getDeviceByName(name: string): DeviceModelViewBean {
        for (const device of this.model.devices) {
            if (device.deviceName == name) {
                return device as DeviceModelViewBean;
            }
        }
        return null;
    }

    getDeviceModel(deviceInfo: DeviceIdBean): DeviceModelViewBean {
        if (!deviceInfo) {
            return null;
        }

        for (const device of this.model.devices) {
            if (device.id == deviceInfo.deviceModelId) {
                return device as DeviceModelViewBean;
            }
        }

        for (const device of this.model.devices) {
            if (device.deviceName == deviceInfo.name) {
                return device as DeviceModelViewBean;
            }
        }
        return null;
    }

    createDeviceView(device: DeviceModelViewBean) {
        fabric.Image.fromURL(device.deviceTemplate.image1, img => {

            const xy = this.getPlace(device.x, device.y);
            img.set({
                hasControls: false,
                evented: false,
                originY: 'center',
                originX: 'center',
                left: xy[0],
                top: xy[1],
            });

            img.setFlipX(device.rotation < 0);

            device.views = [img];
        });
    }

    getPlace(x: number, y: number): [number, number] {
        const dx = (this.width * this.scale - this.width) / 2;
        const dy = (this.height * this.scale - this.height) / 2;
        return [this.width / 2 + x - this.template.x0 + dx, this.height / 2 - y - this.template.y0 + dy];
    }

    startDevicesWaiter() {
        const waiter = setInterval(() => {

            for (const device of this.model.devices) {
                const deviceView = device as DeviceModelViewBean;
                if (!deviceView.views) {
                    return;
                }
            }

            this.model.devices.sort((a: DeviceModelBean, b: DeviceModelBean) => {
                const aView = a as DeviceModelViewBean;
                const bView = b as DeviceModelViewBean;
                return bView.views[0].getFlipX() ? 1 : bView.views[0].left - aView.views[0].left;
            });

            /*move Robot to the end*/
            for (let i = 0; i < this.model.devices.length; i++) {
                if (this.model.devices[i].deviceTemplate.abstractDevice.name == 'Robot') {
                    this.model.devices.splice(this.model.devices.length, 0, this.model.devices[i]);
                    this.model.devices.splice(i, 1);
                    break;
                }
            }

            this.model.devices.forEach(item => {
                const itemView = item as DeviceModelViewBean;
                for (const view of itemView.views) {
                    this.canvas.add(view);
                }
            });

            clearInterval(waiter);
        }, 100
        );
    }

    initializeTimeline(): void {

        if (this.visInitialized) {
            return;
        }
        this.visInitialized = true;

        this.visTimelineService.on(this.visTimeline, 'click');
        this.visTimelineService.click
            .subscribe((eventData: any) => {

                setTimeout(() => {
                    this.addStepsToTimeline(eventData[1].item);
                }, 1);
            });

        this.visTimelineService.setOptions(this.visTimeline, new Opt());
    }

    goLive() {

        if (!firebase.apps.length) {
            firebase.initializeApp(AppSettings.getFirebaseConfig());
        }

        firebase.auth().signInWithEmailAndPassword(AppSettings.FB_USER, AppSettings.FB_PASSWORD).then(response => {
            const channel = firebase.database()
                .ref('company')
                .child('' + this.dataService.getCompanyId())
                .child('kiosk')
                .child('' + this.kiosk.id)
                .child('step_snapshot');

            channel.on('child_changed', data => {
                this.acceptMessage(Helper.copyObject(data.val()));
            });
        });
    }

    acceptMessage(snapshot: StepSnapshotBean) {
        if (snapshot.stepState == 'STARTED') {

            for (const device of this.model.devices) {
                if (device.id == snapshot.deviceModelId) {
                    const deviceView = device as DeviceModelViewBean;
                    this.updateDevice(deviceView, snapshot, snapshot.stepExecutionTime);
                    break;
                }
            }
        }
    }

    togglePause() {
        this.paused = !this.paused;
    }

    addStepsToTimeline(item: IdType) {

        let keySnapshot: StepSnapshotBean;
        for (const id in this.visIds) {

            if (id == item) {
                keySnapshot = this.visIds[id];
                break;
            }
        }

        if (!keySnapshot) {
            return;
        }

        this.startPlayback(keySnapshot.date);

        let num = 0;
        this.visTimelineItems = [];

        for (const snapshot of this.kioskSnapshots.snapshots) {
            if (snapshot.stepState == 'STARTED') {

                const id = snapshot.id + ++num;
                if (this.ids.indexOf(id) >= 0) {
                    continue;
                }

                const time = snapshot.date;
                if (time >= keySnapshot.date && time < keySnapshot.date + this.preloadTime) {

                    const color = (snapshot.orderId == keySnapshot.orderId ? 'pink' : 'white');
                    const stepInfo = (this.getDeviceById(snapshot.deviceModelId).name + ': ' + snapshot.step);

                    console.log(id + ' ' + time);

                    this.visTimelineItems.push({
                        id,
                        content: stepInfo,
                        start: time,
                        style: 'background-color: ' + color + ';'
                    });

                    this.ids.push(id);
                }
            }
        }

        this.visTimelineService.setWindow(this.visTimeline, keySnapshot.date, keySnapshot.date + this.preloadTime);
    }

    startPlayback(ts: number) {

        this.pauseDisabled = false;
        this.mRequestedTime = ts;
        this.mSnapshotIds = [];

        clearInterval(this.mPlaybackIntervalId);

        this.mPlaybackIntervalId = setInterval(() => {
            if (this.paused) {
                return;
            }

            const now = this.mRequestedTime;
            if (now - this.lastTimelineUpdateTime > this.preloadUpdateTime) {
                this.lastTimelineUpdateTime = this.mRequestedTime;
            }

            this.playbackTime = new Date(this.mRequestedTime);

            this.readSnapshots();
            this.mRequestedTime += this.requestIntervalPlayback * this.speed;

            try {
                this.visTimelineService.removeCustomTime(this.visTimeline, 'pt');
            } catch (err) {
            }
            this.visTimelineService.addCustomTime(this.visTimeline, this.playbackTime, 'pt');
        }, this.requestIntervalPlayback);
    }

    readSnapshots() {

        this.kioskSnapshots.snapshots.forEach(snapshot => {

            const dt = this.mRequestedTime - snapshot.date;
            if (dt >= 0) {
                if (dt > this.requestIntervalPlayback * this.speed) {
                    return;
                }

                if (snapshot.stepState == 'STARTED') {

                    const id = Helper.hashCode(JSON.stringify(snapshot));
                    if (this.mSnapshotIds.indexOf(id) >= 0) {
                        return;
                    }
                    this.mSnapshotIds.push(id);

                    this.playStep(snapshot);
                }
            }
        });
    }

    playStep(snapshot: StepSnapshotBean) {

        for (const device of this.model.devices) {
            if (device.id == snapshot.deviceModelId) {

                const finishedTs = this.getFinishedStepTime(snapshot.stepId, snapshot.orderId, snapshot.deviceId);
                const duration = (finishedTs - snapshot.date);

                const deviceView = device as DeviceModelViewBean;
                this.updateDevice(deviceView, snapshot, duration);
                break;
            }
        }
    }

    updateDevice(deviceModelViewBean: DeviceModelViewBean, snapshot: StepSnapshotBean, duration) {

        if (!snapshot) {
            return;
        }

        const deviceName = deviceModelViewBean.deviceTemplate.abstractDevice.name;
        const stepName = snapshot.processName;
        let animationDuration = KioskDefines.getAnimationTime(stepName, this.speed);
        if (duration) {
            animationDuration = duration / this.speed;
        }

        console.log(snapshot);

        // let whatDevice: DeviceModelViewBean = snapshot.deviceParameters ? this.getDeviceModel(snapshot.deviceParameters.what) : null;
        // let toDevice: DeviceModelViewBean = snapshot.deviceParameters ? this.getDeviceModel(snapshot.deviceParameters.to) : null;
        // let fromDevice: DeviceModelViewBean = snapshot.deviceParameters ? this.getDeviceModel(snapshot.deviceParameters.from) : null;
        // let atDevice: DeviceModelViewBean = snapshot.deviceParameters ? this.getDeviceModel(snapshot.deviceParameters.at) : null;

        let device: DeviceModelViewBean = snapshot.deviceParameters ? this.getDeviceModel(snapshot.deviceParameters.device) : null;
        if (!device) {
            device = snapshot.deviceParameters ? this.getDeviceModel(snapshot.deviceParameters.from) : null;
        }

        if (deviceName == 'Robot') {

            if (stepName == 'Wave') {
                this.robot.wave(animationDuration);

            } else if (stepName == 'Home') {
                this.robot.home(animationDuration);

            } else if (stepName == 'Pick') {

                if (device != null) {
                    if (this.isFinishedProduct(device)) {

                        const cup = this.getCup(snapshot.orderId);
                        this.robot.pick(cup.getDeviceModel(), animationDuration);
                    } else {

                        const jar: DeviceModelViewBean = this.getDeviceByName(snapshot.jarName);
                        jar.views[0].bringToFront();

                        this.robot.pick(jar, animationDuration);
                    }
                }

            } else if (stepName == 'Place') {

                if (device != null) {

                    // let tx = 0, ty = 0;
                    // let place = snapshot.deviceParameters.to.place;
                    // if (toDevice == device) place = true; // venki fix
                    // if (toDevice) {

                    // }
                    // this.robot.place(!place ? toDevice : null, device, tx, ty, animationDuration);

                    if (this.isDeliverySystem(device)) {
                        const cup = this.getCup(snapshot.orderId);
                        this.robot.place(cup.getDeviceModel(), device, animationDuration);

                    } else {
                        const jar: DeviceModelViewBean = this.getDeviceByName(snapshot.jarName);
                        if (jar.id == device.id) {
                            const place = this.getPlace(jar.x, jar.y);
                            this.robot.placeToCoordinate(jar, place[0], place[1], animationDuration);

                        } else {
                            this.robot.place(jar, device, animationDuration);
                        }
                    }
                }
            } else if (stepName == 'Pour') {

                const jar: DeviceModelViewBean = this.getDeviceByName(snapshot.jarName);
                const cup = this.getCup(snapshot.orderId);
                this.robot.place(jar, cup.getDeviceModel(), animationDuration / 2);

                setTimeout(() => {
                    ((jar.presentation) as JarView).pour(animationDuration / 2);
                    cup.fill(((jar.presentation) as JarView), animationDuration / 2);
                }, animationDuration / 2);

            } else if (stepName == 'Drain') {

                const jar: DeviceModelViewBean = this.getDeviceByName(snapshot.jarName);
                const place = this.getPlace(70, 40);
                this.robot.placeToCoordinate(jar, place[0], place[1], animationDuration / 2);

                setTimeout(() => {
                    ((jar.presentation) as JarView).drain(animationDuration / 2);
                }, animationDuration / 2);

            } else if (stepName == 'DispenseCup') {
                animationDuration = 2000 / this.speed;
                this.robot.pick(device, animationDuration / 2);
                setTimeout(() => {
                    this.dispenseCup(snapshot.orderId, device, animationDuration / 2);
                }, animationDuration / 2);

            } else if (stepName == 'GetWater') {
                // if (this.robot.getHandledDevice()) {
                //     this.robot.place(atDevice, this.robot.getHandledDevice(),
                //     atDevice.views[0].left, atDevice.views[0].top, animationDuration);
                // }
            }

        } else if (deviceName == 'Ingredient Dispenser') {

            if (stepName == 'Dispense') {

                const jar: DeviceModelViewBean = this.getDeviceByName(snapshot.jarName);
                const jarPlace: DeviceModelViewBean = this.getDeviceByName(snapshot.jarPlace);

                this.highlightDevice(jarPlace, this.canvas, animationDuration);
                ((jar.presentation) as JarView).dispense(this.getIngredient(deviceModelViewBean), animationDuration);
            }

        } else if (deviceName == 'Blender') {
            const jar: DeviceModelViewBean = this.getDeviceByName(snapshot.jarName);

            if (stepName == 'On') {
                ((jar.presentation) as JarView).blend(this.speed);

            } else if (stepName == 'Off') {
                ((jar.presentation) as JarView).stopBlending();
            } else if (stepName == 'Pulse') {
                ((jar.presentation) as JarView).pulse(animationDuration);
            } else if (stepName == 'Rinse') {
                ((jar.presentation) as JarView).pulse(animationDuration);
            }
        }
    }

    private isFinishedProduct(device: DeviceModelViewBean) {
        return device.deviceTemplate.abstractDevice.id == 5764144745676800;
    }

    private isDeliverySystem(device: DeviceModelViewBean) {
        return device.deviceTemplate.abstractDevice.id == 5171793593630720;
    }

    getCup(orderId: number): CupView {
        for (const cup of this.cups) {
            if (cup.orderId == orderId) {
                return cup;
            }
        }
        return null;
    }

    dispenseCup(orderId: number, dispenser: DeviceModelViewBean, animationDuration) {

        const cup = new CupView(orderId, [dispenser.views[0].left, dispenser.views[0].top], this.canvas, objects => {
            const cupModel = {} as DeviceModelViewBean;
            cupModel.views = objects;
            cup.setDeviceModel(cupModel);

            const pos = this.getPlace(-200 + 35 + 25 * this.cups.length, -100 + 20 + 15 * this.cups.length);
            this.robot.placeToCoordinate(cupModel, pos[0], pos[1], animationDuration);
        });
        this.cups.push(cup);
    }

    highlightDevice(device: DeviceModelViewBean, canvas, duration) {

        device.views[0].animate({top: device.views[0].top - 10}, {
            duration: duration / 2,
            onChange: canvas.renderAll.bind(canvas),
            onComplete() {
                device.views[0].animate({top: device.views[0].top + 10}, {
                    duration: duration / 2,
                    onChange: canvas.renderAll.bind(canvas)
                });
            }
        });
    }

    getFinishedStepTime(stepId, orderId, deviceId) {
        for (const snapshot of this.kioskSnapshots.snapshots) {
            if (snapshot.stepState == 'FINISHED') {
                if (snapshot.stepId == stepId && snapshot.orderId == orderId && snapshot.deviceId == deviceId) {
                    return snapshot.date;
                }
            }
        }
        return 0;
    }
}
