<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()" [autoHide]="false">
    <fields-list-component [fields]="fields" name="image_edit"
                           [value]="image"></fields-list-component>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="uploader" class="col-md-3 control-label">Image</label>
                <div class="col-md-9">
                    <file-uploader
                            id="uploader" height="400" [name]="image.id"
                            [imageSrc]="image['image']">
                    </file-uploader>
                </div>
            </div>
        </div>
    </div>

    <div class="form-horizontal" *ngIf="image.previousVersions && image.previousVersions.length > 0">
        <div class="card-content">

            <div class="form-group">
                <label for="versions" class="col-md-3 control-label">Previous Versions</label>
                <div class="col-md-9">
                    <div class="row" id="versions">
                        <div class="col-md-3 col-sm-4" *ngFor="let image of image.previousVersions"
                             (click)="select(image)">
                            <img class="img-thumbnail"
                                 [ngClass]="(selectedImage==image)?'thumb-img-selected':'thumb-img'"
                                 [src]="getThumbnail(image)" alt="" width="128px" height="128px"/>
                        </div>
                    </div>

                    <button type="button" class="btn btn-simple" style="margin-top: 20px; margin-left: 20px;" [disabled]="!selectedImage" id="button_set"
                            (click)="onRestoreClicked()">Restore Selected
                    </button>
                </div>
            </div>
        </div>
    </div>


</base-modal-component>
