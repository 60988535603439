import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {KioskModelBean, UserBean} from '../../../model/model';
import {Helper} from '../../../common/helper';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {ModelNotificationEmailsEditComponent} from './model-notification-emails.edit.component';

@Component({
    selector: 'model-notification-emails-component',
    templateUrl: 'model-notification-emails.component.html'
})
export class ModelNotificationEmailsComponent implements AfterViewInit {

    category: KioskModelBean = {} as KioskModelBean;
    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(ModelNotificationEmailsEditComponent, {static: false}) modal: ModelNotificationEmailsEditComponent;

    fields: Column[] = [
        new Column('email', 'Email'),
    ];

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskModelBean) {
        this.category = kiosk;
        this.updateTable();
    }

    onEdit(items: UserBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Email');
        }
    }

    onRemove(items) {

        for (const step of items) {
            const index = this.category.changeNotificationEmails.indexOf(step.email);
            this.category.changeNotificationEmails.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Email');
    }

    addUser(initUser: UserBean, user: UserBean) {

        if (this.category.changeNotificationEmails == null) {
            this.category.changeNotificationEmails = [];
        }
        if (initUser.email == null) {
            this.category.changeNotificationEmails.push(user.email);

        } else {

            let ind = -1;
            let i = 0;
            for (const email of this.category.changeNotificationEmails) {
                if (email == initUser.email) {
                    ind = i;
                }
                i++;
            }

            if (ind >= 0) {
                this.category.changeNotificationEmails.splice(ind, 1);
                this.category.changeNotificationEmails.splice(ind, 0, user.email);
            }
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {

            const users: UserBean[] = [];
            if (this.category.changeNotificationEmails) {
                for (const email of this.category.changeNotificationEmails) {
                    const user: UserBean = {} as UserBean;
                    user.email = email;
                    users.push(user);
                }
            }

            this.table.setData(users, this.fields);
        }
    }
}
