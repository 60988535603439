export class Rights {
    public static CUSTOMER = 0x1;
    public static MANAGER = 0x8 | Rights.CUSTOMER;
    public static MANAGE_OPERATORS = 0x10 | Rights.MANAGER;
    public static MANAGE_KIOSKS = 0x20 | Rights.MANAGER;
    public static MANAGE_CUSTOMERS = 0x40 | Rights.MANAGER;
    public static MANAGE_USERS = 0x80 | Rights.MANAGER;
    public static MANAGE_CATEGORIES = 0x100 | Rights.MANAGER;
    public static MANAGE_RECIPES = 0x200 | Rights.MANAGER;
    public static MANAGE_INGREDIENTS = 0x400 | Rights.MANAGER;
    public static MANAGE_DEVICES = 0x800 | Rights.MANAGER;
    public static MANAGE_REVIEWS = 0x1000 | Rights.MANAGER;
    public static MANAGE_ORDERS = 0x2000 | Rights.MANAGER;
    public static MANAGE_IMAGES = 0x20000 | Rights.MANAGER;
    public static MANAGE_ALL_STORES = 0x40000 | Rights.MANAGER;
    public static MANAGE_SELECTED_STORES = 0x4000 | Rights.MANAGER;
    public static MANAGE_HW_TESTS = 0x8000 | Rights.MANAGER;
    public static MANAGE_DASHBOARD = 0x10000 | Rights.MANAGER;

    public static SUPER_ADMIN = 0xFFFFFFF;
}

export class RightsGroup {
    public static ADMIN =
        Rights.MANAGE_OPERATORS |
        Rights.MANAGE_KIOSKS |
        Rights.MANAGE_CATEGORIES |
        Rights.MANAGE_RECIPES |
        Rights.MANAGE_INGREDIENTS |
        Rights.MANAGE_DEVICES |
        Rights.MANAGE_REVIEWS |
        Rights.MANAGE_ORDERS |
        Rights.MANAGE_HW_TESTS |
        Rights.MANAGE_DASHBOARD |
        Rights.MANAGE_IMAGES |
        Rights.MANAGE_ALL_STORES |
        Rights.MANAGE_CUSTOMERS;

    public static DEVELOPER =
        Rights.MANAGE_OPERATORS |
        Rights.MANAGE_KIOSKS |
        Rights.MANAGE_CATEGORIES |
        Rights.MANAGE_RECIPES |
        Rights.MANAGE_INGREDIENTS |
        Rights.MANAGE_DEVICES |
        Rights.MANAGE_REVIEWS |
        Rights.MANAGE_ORDERS |
        Rights.MANAGE_HW_TESTS |
        Rights.MANAGE_DASHBOARD |
        Rights.MANAGE_IMAGES |
        Rights.MANAGE_ALL_STORES;

    public static CHEF =
        Rights.MANAGE_CATEGORIES |
        Rights.MANAGE_RECIPES |
        Rights.MANAGE_ORDERS |
        Rights.MANAGE_DASHBOARD |
        Rights.MANAGE_IMAGES |
        Rights.MANAGE_ALL_STORES;

    public static BUSINESS_OPS =
        Rights.MANAGE_REVIEWS |
        Rights.MANAGE_ORDERS |
        Rights.MANAGE_ORDERS |
        Rights.MANAGE_DASHBOARD |
        Rights.MANAGE_ALL_STORES;

    public static STORE_MANAGER =
        Rights.MANAGE_DASHBOARD |
        // Rights.MANAGE_ORDERS |
        Rights.MANAGE_SELECTED_STORES;
}

export class RightsManager {
    public title: string;
    public right: number;

    constructor(title: string, right: number) {
        this.title = title;
        this.right = right;
    }
}
