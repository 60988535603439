import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {CompanyBean, RecipeBean} from '../../model/model';
import {CustomRecipesComponent} from './custom-recipes.component';
import {RecipeService} from '../../services/recipe.service';

@Component({
    selector: 'custom-recipes-edit-component',
    templateUrl: 'custom-recipes.edit.component.html'
})

export class CustomRecipesEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    recipe: RecipeBean = {} as RecipeBean;

    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('title', 'Title').require(),
    ];
    published: boolean;

    constructor(private recipeService: RecipeService,
        @Inject(forwardRef(() => CustomRecipesComponent)) private parent: CustomRecipesComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    open(recipe: RecipeBean, published: boolean, title: string) {
        this.recipe = (recipe ? Helper.copyObject(recipe) : {} as CompanyBean);
        this.base.show(title);
    }

    save() {

        this.recipeService.publishCustomRecipe(this.recipe).subscribe(response => {

            if (response.success) {
                this.parent.loadCustomRecipes();
            } else {
                this.app.showError('Can\'t publish recipe');
            }
        }, error => {
            this.app.showError('Can\'t publish recipe');
        });
    }

}
