<div class="logo">
    <a href="#" class="simple-text logo-normal">
        <!--<strong style="color: #EB5E28">6d</strong> <strong>Bytes</strong>-->
        <img src="../../assets/img/img-logo.png" style="width: 110px;" alt=""/> <br/> <br/>
        {{isImageManager ? 'ROBOTIC RESTAURANT MANAGEMENT SYSTEM' : 'RESTAURANT MANAGEMENT SYSTEM'}}
        <br/>
        POWERED BY foodOS™
    </a>
</div>
<div class="sidebar-wrapper">
    <div class="user">
        <div class="photo">
            <img src="{{getUserImage()}}"/>
        </div>
        <div class="info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                <span class="text-uppercase">
                    <b>{{userInfo.profile.displayName}}</b>
                    <b class="caret"></b>
                </span>
            </a>
            <div class="clearfix" style="padding-top: 0px"></div>

            <div class="collapse" id="collapseExample">
                <ul class="nav" style="padding-top: 10px">
                    <li>
                        <a (click)="editProfile()" style="padding-left: 40px; cursor: pointer">
                            <!--<span class="sidebar-mini"><i class="ti-pencil-alt"></i></span>-->
                            <span class="sidebar-normal">Edit Profile</span>
                        </a>
                    </li>
                    <li *ngIf="isRootAdmin()">
                        <a (click)="selectCompany()" style="padding-left: 40px; cursor: pointer">
                            <!--<span class="sidebar-mini"><i class="ti-support"></i></span>-->
                            <span class="sidebar-normal">Select Company</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="logout()" style="padding-left: 40px; cursor: pointer">
                            <!--<span class="sidebar-mini"><i class="ti-lock"></i></span>-->
                            <span class="sidebar-normal">Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <ul class="nav navx">

        <li *ngFor="let item of menuItems; let i = index" routerLinkActive="active">

            <a *ngIf="haveAccess(item)  && !item.children" [routerLink]="item.path">
                <!--<i class="{{item.icon}}"></i>-->
                <p>{{item.title}}</p>
            </a>

            <a *ngIf="item.children && childrenAccess(item)" data-toggle="collapse" href="#collapse{{i}}">
                <!--<i class="{{item.icon}}"></i>-->
                <p>{{item.title}}
                    <b class="caret"></b>
                </p>
            </a>
            <div *ngIf="item.children && childrenAccess(item)" class="collapse" id="collapse{{i}}">
                <ul class="nav">
                    <li *ngFor="let sub of item.children; let i = index" routerLinkActive="active">
                        <a *ngIf="haveAccess(sub)" [routerLink]="sub.path" style="padding-left: 40px">
                            <!--<span class="sidebar-mini"><i class="{{sub.icon}}"></i></span>-->
                            <span class="sidebar-normal">{{sub.title}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>

<companies-select-component></companies-select-component>
<user-edit-component></user-edit-component>
