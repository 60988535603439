<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <tabset>
        <tab heading='Description' [active]="true">
            <fields-list-component [fields]="fields" name="devices_edit" [value]="device"></fields-list-component>

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-3 control-label"></label>
                        <div class="col-md-9">

                            <div class="checkbox">
                                <input type="checkbox" class="custom-control-input" id="cb_shared"
                                       [(ngModel)]="device.shareable"
                                       name="cb_shared"/>
                                <label for="cb_shared">Can be shared between multiple orders</label>
                            </div>
                            <div class="checkbox">
                                <input type="checkbox" class="custom-control-input" id="cb_lock"
                                       [disabled]="!device.shareable"
                                       name="cb_lock" [checked]="device.unlockerDevice"
                                       (change)="setUnlocker($event)"/>
                                <label for="cb_lock">Lock the device for current order until process called</label>
                            </div>

                            <div class="row">
                                <div class="form-group col-form-label col-md-5">
                                    <label for="locker_device" class="col-form-label">Device</label>
                                    <select id="locker_device" [disabled]="!device.unlockerDevice"
                                            name="locker_device"
                                            class="form-control form-control-low selectx"
                                            [compareWith]="compareBeans"
                                            [(ngModel)]="device.unlockerDevice"
                                            size="1">
                                        <option [ngValue]="d" *ngFor="let d of devices">{{d.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="form-group col-form-label col-md-5">
                                    <label for="locker_process" class="col-form-label">Process</label>
                                    <select id="locker_process" [disabled]="!device.unlockerDevice"
                                            name="locker_process"
                                            class="form-control form-control-low selectx"
                                            [(ngModel)]="device.unlockerProcessName"
                                            size="1">
                                        <option value="{{p.name}}"
                                                *ngFor="let p of (device.unlockerDevice ? device.unlockerDevice.processes: [])">
                                            {{p.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="checkbox">
                                <input type="checkbox" class="custom-control-input" id="cb_when_process"
                                       [disabled]="!device.unlockerDevice" (change)="setLocker($event)"
                                       name="cb_when_process" [checked]="device.lockerDevice"/>
                                <label for="cb_when_process">Lock the device when process executed</label>
                            </div>

                            <div class="row">
                                <div class="form-group col-form-label col-md-5">
                                    <label for="locker_device_only" class="col-form-label">Device</label>
                                    <select id="locker_device_only" [disabled]="!device.lockerDevice"
                                            name="locker_device_only"
                                            class="form-control form-control-low selectx"
                                            [(ngModel)]="device.lockerDevice"
                                            [compareWith]="compareBeans"
                                            size="1">
                                        <option [ngValue]="d" *ngFor="let d of devices">{{d.name}}</option>
                                    </select>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="form-group col-form-label col-md-5">
                                    <label for="locker_process_only" class="col-form-label">Process</label>
                                    <select id="locker_process_only" [disabled]="!device.lockerDevice"
                                            name="locker_process_only" [(ngModel)]="device.lockerProcessName"
                                            class="form-control form-control-low selectx"
                                            size="1">
                                        <option [selected]="p.name==device.lockerProcessName" value="{{p.name}}"
                                                *ngFor="let p of (device.lockerDevice ? device.lockerDevice.processes : [])">
                                            {{p.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="checkbox">
                                <input type="checkbox" class="custom-control-input"
                                       id="checkbox_dispenser"
                                       [checked]="isDispenser(device)" (change)="setDispenser($event)"
                                       name="checkbox_header"/>
                                <label for="checkbox_dispenser">Dispenser</label>
                            </div>

                            <div class="row">
                                <div class="form-group col-form-label col-md-5" [hidden]="!isDispenser(device)">
                                    <select id="dispenser"
                                            name="dispenser"
                                            class="form-control selectx"
                                            [(ngModel)]="device.dispenserType"
                                            size="1">
                                        <option [ngValue]="'INGREDIENT'">INGREDIENT</option>
                                        <option [ngValue]="'BOOST'">BOOST</option>
                                        <option [ngValue]="'DEVICE'">DEVICE</option>
                                        <option [ngValue]="'VENDING_MACHINE'">VENDING_MACHINE</option>
                                    </select>
                                    <label for="dispenser" class="col-form-label"></label>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="form-group col-form-label col-md-5" [hidden]="!isDispenserDevice(device)">
                                    <select id="dispenser_device"
                                            name="dispenser_device"
                                            class="form-control selectx"
                                            [(ngModel)]="device.canDispenseDevice"
                                            [compareWith]="compareBeans"
                                            size="1">
                                        <option [ngValue]="device"
                                                *ngFor="let device of devices">{{device.name}}
                                        </option>
                                    </select>
                                    <label for="dispenser_device" class="col-form-label"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
        <tab heading='Processes'>

            <table-component (onEdit)="onEdit($event)"
                             (onRemove)="onRemove($event)"
                             (onAdd)="onAdd()"></table-component>

        </tab>
    </tabset>
</base-modal-component>
