import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {EntityNameBean, PromotionBean, PromotionEditHistoryBean} from '../../model/model';
import {PromotionsComponent} from './promotions.component';
import {PromotionsService} from '../../services/promotions.service';
import {RecipeListService} from '../../services/recipe-list.service';
import {RecipeService} from '../../services/recipe.service';

@Component({
    selector: 'promotions-edit-component',
    templateUrl: 'promotions.edit.component.html'
})

export class PromotionsEditComponent {

    promotion: PromotionBean = {} as PromotionBean;
    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    compareBeans = Helper.compareBeans;

    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('name', 'Name').require(),
        new Field('description', 'Description', FieldType.TEXT_AREA),
        new Field('startDate', 'Start Date', FieldType.DATE).require(),
        new Field('endDate', 'End Date', FieldType.DATE).require(),
        new Field('state', 'State', FieldType.SELECT).setOptions([
            'IN_WORKS', 'PUBLISHED', 'ARCHIVED'
        ]),
        new Field('category', 'Category', FieldType.SELECT).change(this, this.onCategoryChanged),
        new Field('subCategory', 'Sub Category', FieldType.SELECT),
        new Field('priceDiscountValue', 'Discount Value', FieldType.NUMBER),

        new Field('jumbotronPreImageEnabled', 'Pre Image Enabled', FieldType.CHECKBOX),
        new Field('jumbotronPreImageUrl', 'Pre Image', FieldType.FILE).if(PromotionsEditComponent.isJumbotronPreImageEnabled),
        new Field('jumbotronPreImageTimeoutSeconds', 'Pre Image Timeout', FieldType.NUMBER),
        new Field('jumbotronPostImageEnabled', 'Post Image Enabled', FieldType.CHECKBOX),
        new Field('jumbotronPostImageUrl', 'Post Image', FieldType.FILE).if(PromotionsEditComponent.isJumbotronPostImageEnabled),
        new Field('jumbotronPostImageTimeoutSeconds', 'Post Image Timeout', FieldType.NUMBER),
    ];

    options: Array<string[]>;
    kiosks: EntityNameBean[];
    recipesList: {id: number, listId: number, listName: string, name: string}[];
    selectedRecipes: {id: number, listId: number, listName: string, name: string}[] = [];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private promotionsService: PromotionsService,
        private recipeListService: RecipeListService,
        private recipeService: RecipeService,
        @Inject(forwardRef(() => PromotionsComponent)) private parent: PromotionsComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(event: PromotionBean, kiosks: EntityNameBean[], options: Array<string[]>, title: string) {

        this.kiosks = kiosks;
        this.options = options;
        this.promotion = (event ? Helper.copyObject(event) : {} as PromotionBean);
        this.base.show(title);

        this.getFiled('category').setOptions(Object.keys(this.options));
        this.getFiled('subCategory').setOptions(this.options[this.promotion.category]);

        Helper.selectPicker('select_kiosks');

        this.loadRecipes();
    }

    onCategoryChanged() {
        this.promotion.subCategory = null;
        this.getFiled('subCategory').setOptions(this.options[this.promotion.category]);
    }

    getFiled(name: string): Field {
        for (let field of this.fields) {
            if (field.field == name) {
                return field;
            }
        }
        return {} as Field;
    }

    save() {

        this.promotion.targetRecipes = {};
        for (let recipe of this.selectedRecipes) {
            if (!recipe.id && recipe.listName) {

                for (let item of this.recipesList) {
                    if (item.listName == recipe.listName) {
                        if (!this.promotion.targetRecipes[item.listId]) {
                            this.promotion.targetRecipes[item.listId] = [];
                        }
                        this.promotion.targetRecipes[item.listId].push(item);
                    }
                }

            } else {
                for (let item of this.recipesList) {
                    if (item.listName == recipe.listName && item.id == recipe.id) {
                        if (!this.promotion.targetRecipes[item.listId]) {
                            this.promotion.targetRecipes[item.listId] = [];
                        }
                        this.promotion.targetRecipes[item.listId].push(item);
                    }
                }
            }
        }

        if (!this.promotion.id) {
            this.promotionsService.addPromotion(this.promotion).subscribe(response => {

                if (response.success) {
                    this.parent.loadPromotions();
                } else {
                    this.app.showError('Can\'t create promotion');
                }
            }, error => {
                this.app.showError('Can\'t create promotion');
            });

        } else {
            this.promotionsService.updatePromotion(this.promotion).subscribe(response => {

                if (response.success) {
                    this.parent.loadPromotions();
                } else {
                    this.app.showError('Can\'t update promotion');
                }
            }, error => {
                this.app.showError('Can\'t update promotion');
            });
        }
    }

    private static isJumbotronPostImageEnabled(promotion: PromotionBean) {
        return promotion.jumbotronPostImageEnabled;
    }

    private static isJumbotronPreImageEnabled(promotion: PromotionBean) {
        return promotion.jumbotronPreImageEnabled;
    }

    compareRecipes = (item, selected) => {
        return item.name === selected.name && item.listName === selected.listName;
    };

    private loadRecipes() {
        this.recipeService.getAllRecipeNames().subscribe(response => {

            if (response.success) {
                let recipes = response.value;
                this.recipeListService.getAllRecipeLists().subscribe(response => {
                    let lists = response.list;

                    this.recipesList = [];
                    for (let list of lists) {
                        for (let id of list.recipeIds) {

                            for (let item of recipes) {
                                if (item.id == id) {
                                    this.recipesList.push({id: id, listName: list.name, listId: list.id, name: item.name});
                                }
                            }
                        }
                    }

                    let selectedRecipes = [];
                    for (let key in this.promotion.targetRecipes) {
                        for (let list of lists) {
                            let foundInList = [];
                            for (let recipe of this.promotion.targetRecipes[key]) {
                                if (list.id == +key) {
                                    foundInList.push({id: recipe.id, listName: list.name, listId: list.id, name: recipe.name});
                                }
                            }
                            if (foundInList.length > 0) {
                                if (foundInList.length != list.recipeIds.length) {
                                    selectedRecipes = selectedRecipes.concat(foundInList);
                                } else {
                                    // @ts-ignore
                                    selectedRecipes.push({listName: list.name});
                                }
                            }
                        }
                    }

                    this.selectedRecipes = selectedRecipes;
                });
            }
        });
    }

    getLabelClass(history: PromotionEditHistoryBean) {
        switch (history.eventType) {
            case 'ARCHIVED':
                return 'label-danger';
            case 'CREATED':
                return 'label-info';
            case 'IMAGE_UPDATED':
                return 'label-warning';
            case 'MODIFIED':
                return 'label-primary';
            case 'PUBLISHED':
                return 'label-warning';

        }
    }

    getColorClass(history: PromotionEditHistoryBean) {
        switch (history.eventType) {
            case 'ARCHIVED':
                return 'danger';
            case 'CREATED':
                return 'info';
            case 'IMAGE_UPDATED':
                return 'warning';
            case 'MODIFIED':
                return 'primary';
            case 'PUBLISHED':
                return 'warning';

        }
    }

    getIconClass(history: PromotionEditHistoryBean) {
        switch (history.eventType) {
            case 'ARCHIVED':
                return 'ti-check-box';
            case 'CREATED':
                return 'ti-check-box';
            case 'IMAGE_UPDATED':
                return 'ti-gallery';
            case 'MODIFIED':
                return 'ti-credit-card';
            case 'PUBLISHED':
                return 'ti-check-box';

        }
    }
}
