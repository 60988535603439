<div class="container">
    <div class="row">
        <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form role="form" (submit)="login($event, username.value, password.value)">
                <div class="card" data-background="color" data-color="blue">
                    <div class="card-header">
                        <h3 class="card-title">Login</h3>
                    </div>
                    <div class="card-content">
                        <div class="form-group">
                            <label>Email address</label>
                            <input #username type="email" placeholder="Enter email"
                                   class="form-control input-no-border">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input #password type="password" placeholder="Password"
                                   class="form-control input-no-border">
                        </div>
                    </div>
                    <div class="card-footer text-center">
                        <button type="submit" class="btn btn-fill btn-wd ">Sign In</button>
                        <br/> <br/>
                        <div class="forgot">
                            <a (click)="forgotPassword()">Forgot your password?</a>
                        </div>

                        <button (click)="signInWithGoogle()" class="btn btn-primary btn-wd " style="margin-top: 30px" type="button">Sign In with Google</button>

                    </div>
                </div>
            </form>

        </div>
    </div>
</div>

<forgot-password-component></forgot-password-component>
