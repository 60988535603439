import {Component, EventEmitter, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../../components/base.modal.component';
import {FieldsListComponent} from '../../../../components/fields-list.component';
import {Field, FieldType} from '../../../../common/field';
import {CouponCodeBean} from '../../../../model/model';
import {KioskCouponsComponent} from './kiosk-coupons.component';
import {AppComponent} from '../../../../app.component';
import {Helper} from '../../../../common/helper';
import {Options} from 'ng5-slider';

@Component({
    selector: 'kiosk-coupons-edit-component',
    templateUrl: 'kiosk-coupons.edit.component.html'
})

export class KioskCouponsEditComponent {

    coupon: CouponCodeBean = {} as CouponCodeBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];
    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('name', 'Name').require(),
        new Field('discount', 'Discount Percent', FieldType.NUMBER).require(),
        new Field('bypassPhoneNumberValidation', 'Bypass Phone Number Validation', FieldType.CHECKBOX),
        new Field('oneTimePerUser', 'One Time Per User', FieldType.CHECKBOX),
        new Field('startDate', 'Start Date', FieldType.DATE),
        new Field('endDate', 'End Date', FieldType.DATE),
    ];
    options: Options = {
        floor: 0,
        ceil: 24,
        step: 1,
        translate: (value: number): string => {

            if (value == 0 || value == 24) {
                return '12 a.m.';
            }
            if (value == 12) {
                return '12 p.m.';
            }

            const amOrPm = (value < 12) ? ' a.m.' : ' p.m.';
            const hour = (value < 12) ? value : value - 12;

            return hour + amOrPm;
        }
    };
    manualRefresh: EventEmitter<void> = new EventEmitter<void>();
    private intervalID: NodeJS.Timeout;

    constructor(@Inject(forwardRef(() => KioskCouponsComponent)) private parent: KioskCouponsComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    open(coupon: CouponCodeBean, title: string) {
        this.base.show(title);
        this.coupon = coupon ? Helper.copyObject(coupon) : {} as CouponCodeBean;
        if (!this.coupon.workingHours) {
            this.coupon.workingHours = [];
        }

        for (let i = 0; i < 7; i++) {
            if (!this.coupon.workingHours[i]) {
                this.coupon.workingHours[i] = {dayOfWeek: i, hourFrom: 0, hourTo: 24};
            } else {
                this.coupon.workingHours[i].dayOfWeek = i;
            }
        }
    }

    save() {
        this.parent.addCoupon(this.coupon);
    }

    onShown() {
        this.intervalID = setInterval(() => {
            this.manualRefresh.emit();
        }, 100);
    }

    onHidden() {
        clearInterval(this.intervalID);
    }
}
