import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {MealplanEditComponent} from './mealplan.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {MealPlanBean} from '../../model/model';
import {MealplanService} from '../../services/mealplan.service';

@Component({
    templateUrl: 'mealplans.component.html',
})

export class MealplansComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(MealplanEditComponent, {static: false}) modal: MealplanEditComponent;

    fields: Column[] = [
        new Column('name', 'Name'),
        new Column('type', 'Type'),
        new Column('url', 'URL').hide(),
        new Column('loginUrl', 'Login URL').hide(),
        new Column('username', 'User Name').hide(),
        new Column('password', 'Password').hide(),
        new Column('cardDefaultName', 'Card Default Name'),
    ];

    constructor(private mealplanService: MealplanService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadMealplans();
    }

    loadMealplans() {
        this.mealplanService.getMealplans().subscribe(mealplans => {
            this.table.setData(mealplans.value, this.fields);
        });
    }

    onEdit(items: MealPlanBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const mealplan of items) {

            this.mealplanService.deleteMealplan(mealplan).subscribe(response => {
                if (response.success) {
                    if (mealplan == items.pop()) {
                        this.loadMealplans();
                    }

                } else {
                    this.app.showError('Can\'t delete Meal Plan');
                }
            }, error => {
                this.app.showError('Can\'t delete Meal Plan');
            });
        }
    }

    onAdd() {
        this.modal.open(null, 'New Meal Plan');
    }
}
