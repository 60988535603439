import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {ProfilesEditComponent} from './profiles.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ProfileService} from '../../services/profile.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {UserBean} from '../../model/model';
import {Helper} from '../../common/helper';
import {UserType} from '../../app.constants';
import {RightsGuard} from '../../common/auth.guard';
import {ProfilesNotificationComponent} from './profiles.notification.component';
import {CustomerEditComponent} from './customer.edit.component';
import * as FileSaver from 'file-saver';

@Component({
    templateUrl: 'profiles.component.html',
})

export class ProfilesComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(ProfilesEditComponent, {static: false}) profilesEditComponent: ProfilesEditComponent;
    @ViewChild(CustomerEditComponent, {static: false}) customerEditComponent: CustomerEditComponent;

    @ViewChild(ProfilesNotificationComponent, {static: false}) profilesNotificationComponent: ProfilesNotificationComponent;

    columnsAdmin: Column[] = [
        new Column('displayName', 'Name'),
        new Column('id', 'Id'),
        new Column('email', 'Email'),
        new Column('', 'Rights').data(this, this.getProfileType),
        new Column('memberSinceDate', 'Member since Date').date(),
        new Column('ordersCount', 'Orders Count'),
        new Column('lastOrderDate', 'Most recent Order Date').date(),
        new Column('rewardPoints', 'Reward Points')
    ];

    columnsUser: Column[] = [
        new Column('id', 'Id'),
        new Column('memberSinceDate', 'Member since Date').date(),
        new Column('ordersCount', 'Orders Count')
    ];

    userType: UserType = 'CUSTOMER';
    userTypeString: string;
    pageNumber: number;
    isByMemberSinceDate = false;
    profileTypes = Helper.profileTypes;

    constructor(private profileService: ProfileService,
        private route: ActivatedRoute,
        private router: Router,
        private dataService: DataService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.route.data.subscribe(data => {

            this.userType = data['userType'];
            this.userTypeString = Helper.getUserTypeString(this.userType);
        });

        this.route.queryParams.subscribe(params => {

            this.pageNumber = params['page'] != null ? params['page'] : 1;

            setTimeout(() => {
                this.load();
            }, 1);
        });
    }

    load() {
        if (this.table) {
            this.table.setData([], RightsGuard.isSuperAdmin() ? this.columnsAdmin : this.columnsUser);
        }
    }

    public loadAdmins(params) {
        this.profileService.getAdmins(params.data.limit, params.data.offset).subscribe(profiles => {
            params.success({
                total: profiles.pagesCount * params.data.limit,
                rows: profiles.list
            });
            params.complete();
        });
    }

    public loadCustomers(params) {
        if (!RightsGuard.isSuperAdmin()) {
            this.table.disableEditing();
        }
        this.table.disableAdding();

        this.profileService.getCustomers(params.data.limit, params.data.offset, !this.isByMemberSinceDate).subscribe(profiles => {
            params.success({
                total: profiles.pagesCount * params.data.limit,
                rows: profiles.list
            });
            params.complete();
        });
    }

    public loadUsers(params) {
        this.profileService.getUsers(params.data.limit, params.data.offset).subscribe(profiles => {
            profiles.list.sort((u1: UserBean, u2: UserBean) => {
                return u1.displayName.localeCompare(u2.displayName);
            });
            params.success({
                total: profiles.pagesCount * params.data.limit,
                rows: profiles.list
            });
            params.complete();
        });
    }

    getData(params) {

        switch (this.userType) {
            case 'ADMIN':
                this.loadAdmins(params);
                break;
            case 'CUSTOMER':
                this.loadCustomers(params);
                break;
            case 'USER':
                this.loadUsers(params);
                break;
        }
    }

    onEdit(items: UserBean[]) {
        if (items && items.length > 0) {
            if (this.userType == 'CUSTOMER') {
                this.customerEditComponent.open(items[0], this.dataService.getCompanyId(), 'Edit Customer');

            } else {
                this.profilesEditComponent.open(items[0], this.userType == 'ADMIN' ? 0 : this.dataService.getCompanyId(),
                    this.userType == 'ADMIN', 'Edit Profile');
            }
        }
    }

    onView(items: UserBean[]) {
        if (items && items.length > 0) {
            this.router.navigate(['/orders'], {queryParams: {query: items[0].id}});
        }
    }

    onRemove(items) {
        for (const profile of items) {

            this.profileService.deleteUser(this.userType == 'ADMIN' ? 0 : this.dataService.getCompanyId(), profile.id)
                .subscribe(response => {
                    if (response.success) {
                        this.load();

                    } else {
                        this.app.showError('Can\'t delete profile');
                    }
                }, error => {
                    this.app.showError('Can\'t delete profile');
                });
        }
    }

    onExtra(items) {
        this.profilesNotificationComponent.open(items);
    }

    onAdd() {
        this.profilesEditComponent.open(null, this.userType == 'ADMIN' ? 0 : this.dataService.getCompanyId(),
            this.userType == 'ADMIN', 'New Profile');
    }

    sortChange($event) {
        this.load();
    }

    getProfileType(user: UserBean) {
        for (let profileType of this.profileTypes) {
            if (user.rights == profileType.right) {
                return profileType.title;
            }
        }

        return "";
    }

    download() {
        this.profileService.downloadUsers().subscribe(response => {
            FileSaver.saveAs(response, 'Users.csv');
        });
    }
}
