import {AfterViewInit, Component, forwardRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {DeviceBean, HardwareInfoBean, KioskBean, KioskState} from '../../model/model';
import {SafeResourceUrl} from '@angular/platform-browser';
import {AppSettings} from '../../app.settings';
import {DataService} from '../../services/data.service';
import {DeviceSnapshot, KioskNOC, OrderSnapshot} from '../../model/custom-model';
import {KioskService} from '../../services/kiosk.service';
import {AppComponent} from '../../app.component';
import {Helper} from '../../common/helper';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {StoreManagerService} from '../../services/store-manager.service';
import {KioskNocComponent} from '../noc/kiosk-noc.component';
import {StoreManagerKioskDevicesEditComponent} from './store-manager-kiosk-devices.edit.component';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {KioskRecipesComponent} from '../kiosk-details/description/recipes/kiosk-recipes.component';
import {KioskDiagnosticsComponent} from '../kiosk-details/diagnostics/kiosk-diagnostics.component';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import * as moment from 'moment-timezone';
import {ServiceEventsComponent} from '../service-events/service-events.component';

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

@Component({
    templateUrl: 'store-manager-kiosk-view.component.html',
})
export class StoreManagerKioskViewComponent implements AfterViewInit, OnDestroy {

    @ViewChild(StoreManagerKioskDevicesEditComponent, {static: false}) modal: StoreManagerKioskDevicesEditComponent;
    @ViewChild(KioskRecipesComponent, {static: false}) kioskRecipesComponent: KioskRecipesComponent;
    @ViewChild(KioskDiagnosticsComponent, {static: false}) kioskDiagnosticsComponent: KioskDiagnosticsComponent;
    @ViewChild(ServiceEventsComponent, {static: false}) serviceEventsComponent: ServiceEventsComponent;

    notConnectedDevices: HardwareInfoBean[];

    constructor(private dataService: DataService,
        private route: ActivatedRoute,
        private router: Router,
        private kioskService: KioskService,
        private storeManagerService: StoreManagerService,
        private coolDialogs: NgxCoolDialogsService,
        @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {

        setInterval(() => {
            this.now = (new Date()).getTime();
        }, 1);
    }

    kiosk: KioskBean = {} as KioskBean;
    url: SafeResourceUrl;
    channel: any;
    kioskNOC: KioskNOC;
    kioskState: KioskState;
    kioskId: number;
    operatorId: number;
    devices: DeviceBean[];
    now: number;

    getIngredientPercent = KioskNocComponent.getIngredientPercent;

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.kioskId = params['kioskId'] != null ? params['kioskId'] : 0;
            this.operatorId = params['operatorId'] != null ? params['operatorId'] : 0;
            this.getKiosk();
        });
    }

    getKiosk() {
        this.kioskService.getKiosk(this.operatorId, this.kioskId).subscribe(response => {
            if (response.success) {
                this.kiosk = Helper.copyObject(response.value);
                this.layout.navbarComponent.setTitle('Store Manager: ' + this.kiosk.name);
                this.initFirebase();
                this.getNotConnectedDevices();
            } else {
                this.layout.navbarComponent.setTitle('Store Manager: Access Denied');
            }
        });
    }

    initFirebase() {

        if (!firebase.apps.length) {
            firebase.initializeApp(AppSettings.getFirebaseConfig());
        }

        firebase.auth().signInWithEmailAndPassword(AppSettings.FB_USER, AppSettings.FB_PASSWORD).then(response => {
            this.channel = firebase.database()
                .ref('company')
                .child('' + this.dataService.getCompanyId())
                .child('kiosk')
                .child('' + this.kioskId);

            this.channel.on('value', data => {

                this.kioskNOC = data.val();
                this.kioskState = this.kioskNOC.kiosk_state.value;

                if (this.kioskNOC.devices_snapshot) {

                    this.kioskNOC.devices_snapshot.value.devices.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                }

                // let sorted = Object.keys(this.kioskNOC.orders)
                //     .sort((a, b) => {
                //         if (!this.kioskNOC.orders[a].value.start_date && this.kioskNOC.orders[b].value.start_date) {
                //             return 1;
                //         }
                //         if (!this.kioskNOC.orders[a].value.start_date && !this.kioskNOC.orders[b].value.start_date) {
                //             return this.kioskNOC.orders[a].value.state_change_date - this.kioskNOC.orders[b].value.state_change_date;
                //         }
                //
                //         return this.kioskNOC.orders[a].value.start_date - this.kioskNOC.orders[b].value.start_date;
                //     })
                //     .reduce((acc, key) => ({
                //         ...acc, [key]: this.kioskNOC.orders[key]
                //     }), {});

            });
        }, error => {
            console.log(error);
        });
    }

    ngOnDestroy() {
        try {
            firebase.app().delete();
        } catch (error) {
        }
    }

    refill(device: DeviceSnapshot) {
        this.storeManagerService.refillIngredient(this.kioskId, device.id).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Refilled');

            } else {
                this.app.showError('Can\'t refill');
            }
        }, error => {
            this.app.showError('Can\'t refill');
        });
    }

    unlock(deviceId: number) {
        this.kioskService.unlockDevice(this.kioskId, deviceId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Unlocked');

            } else {
                this.app.showError('Can\'t unlock');
            }
        }, error => {
            this.app.showError('Can\'t unlock');
        });
    }

    switchKioskState() {

        this.coolDialogs.confirm('Are you sure you want to switch kiosk state to '
            + (this.kioskState != 'MAINTENANCE' ? 'MAINTENANCE' : 'OPERATIONAL') + '?', {
            okButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        })
            .subscribe(res => {
                if (res) {
                    this.storeManagerService.setKioskMaintenance(this.kioskId, this.kioskState != 'MAINTENANCE').subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('State changed');

                        } else {
                            this.app.showError('Can\'t change state');
                        }
                    }, error => {
                        this.app.showError('Can\'t change state');
                    });
                } else {
                }
            });

    }

    setDeviceDisabled(device: DeviceSnapshot) {

        this.coolDialogs.confirm('Are you sure you want to ' + (device.disabled ? 'ENABLE' : 'DISABLE') + ' device?', {
            okButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        })
            .subscribe(res => {
                if (res) {
                    this.storeManagerService.setDeviceDisabled(this.kioskId, device.id, !device.disabled).subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('State changed');

                        } else {
                            this.app.showError('Can\'t change state');
                        }
                    }, error => {
                        this.app.showError('Can\'t change state');
                    });
                } else {
                }
            });

    }

    editDevice(device: DeviceSnapshot) {

        this.kioskService.getDevices(this.kioskId).subscribe(devices => {

            this.devices = devices.list;
            this.openEditModal(device);
        });
    }

    cancelOrder(order: OrderSnapshot) {

        this.coolDialogs.confirm('Are you sure you want to cancel order?', {
            okButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        })
            .subscribe(res => {
                if (res) {
                    this.storeManagerService.cancelOrder(order.id).subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('Cancelled');

                        } else {
                            this.app.showError('Can\'t cancel order');
                        }
                    }, error => {
                        this.app.showError('Can\'t cancel order');
                    });
                } else {
                }
            });
    }

    isMaintenance() {
        return this.kioskState == 'MAINTENANCE';
    }

    startNow(order: OrderSnapshot) {

        this.coolDialogs.confirm('Are you sure you want to reschedule order?', {
            okButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        })
            .subscribe(res => {
                if (res) {
                    this.storeManagerService.startNow(order.id).subscribe(response => {
                        if (response.success) {
                            this.app.showNotification('Rescheduled');

                        } else {
                            this.app.showError('Can\'t reschedule order');
                        }
                    }, error => {
                        this.app.showError('Can\'t reschedule order');
                    });
                } else {
                }
            });

    }

    resetKiosk() {
        this.kioskService.resetKiosk(this.kioskId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Resetting...');
            } else {
                this.app.showNotification('Can\'t reset Kiosk');
            }
        }, error => {
            this.app.showNotification('Can\'t reset Kiosk');
        });
    }

    updateConfig() {
        this.kioskService.updateConfig(this.kioskId, this.operatorId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updating app configuration...');
            } else {
                this.app.showNotification('Can\'t update app configuration');
            }
        }, error => {
            this.app.showNotification('Can\'t update app configuration');
        });
    }

    onRecipesTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskRecipesComponent.onShow(this.kioskId, this.operatorId);
        }
    }

    onDiagnosticsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskDiagnosticsComponent.onShow(this.kioskId, this.operatorId, this.kiosk.kioskModelId);
        }
    }

    onServiceEventsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.serviceEventsComponent.onShow(this.kiosk, true);
        }
    }

    save() {

        this.kioskService.updateKiosk(this.kiosk).subscribe(response => {
            if (response.success) {

                this.app.showNotification('Updated');
            } else {

                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }

    unlockJars() {
        this.storeManagerService.unlockJars(this.kiosk).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Unlocking...');
            } else {
                this.app.showNotification('Can\'t unlock jars');
            }
        }, error => {
            this.app.showNotification('Can\'t unlock jars');
        });
    }

    private openEditModal(device: DeviceSnapshot) {
        for (const d of this.devices) {
            if (d.id == device.id) {
                this.modal.open(this.kiosk, d);
                break;
            }
        }
    }

    lowerCaseAllWordsExceptFirstLetters(string) {
        return string.replace(/\w\S*/g, word => word.charAt(0) + word.slice(1).toLowerCase());
    }

    private getNotConnectedDevices() {
        this.storeManagerService.getNotConnectedDevicesForKiosk(this.kioskId).subscribe(resonse => {
            if (resonse.success) {
                this.notConnectedDevices = resonse.value;
            }
        });
    }

    formatDate(orig: number) {
        return moment(orig).tz('America/Los_Angeles').format('MM/DD/YY hh:mm A');
    }
}
