<base-modal-component class="base-modal" (onConfirm)="save()" [large]="true">

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="edit_1" class="col-md-3 control-label">Description</label>
                <div class="col-md-9">
                    <input type="text"
                           [(ngModel)]="process.description"
                           class="form-control form-control-low"
                           id="edit_1"
                           name="edit_1"/>
                </div>
            </div>

            <div class="form-group">
                <label for="edit_2" class="col-md-3 control-label">Device</label>
                <div class="col-md-9">
                    <select
                            id="edit_2"
                            name="edit_2"
                            class="form-control form-control-low selectx"
                            size="1"
                            (change)="onDeviceChange($event.target)"
                            [compareWith]="compareBeans"
                            [(ngModel)]="process.device">
                        <option [ngValue]="d" *ngFor="let d of devicesWithProcesses">{{d.name}}</option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label for="edit_3" class="col-md-3 control-label">Process</label>
                <div class="col-md-9">
                    <select
                            id="edit_3"
                            name="edit_3"
                            class="form-control form-control-low selectx"
                            size="1"
                            [compareWith]="compareBeans"
                            (change)="onProcessChange($event.target)"
                            [(ngModel)]="process.process">
                        <option [ngValue]="p" *ngFor="let p of (process.device ? process.device.processes : [])">
                            {{p.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="form-horizontal">
        <div class="card-content">
            <div class="form-group">
                <label class="col-md-3 control-label">Options</label>

                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="checkbox">
                                <input type="checkbox" class="custom-control-input" id="cb_optional"
                                       name="checkbox_header" value="checkbox_header"
                                       [(ngModel)]="process.optional"/>
                                <label for="cb_optional">Optional Step</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="checkbox">

                                <input type="checkbox" class="custom-control-input" id="cb_wait"
                                       name="checkbox_header" value="checkbox_header"
                                       [(ngModel)]="process.wait"/>
                                <label for="cb_wait">Wait Step</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="checkbox">

                                <input type="checkbox" class="custom-control-input" id="cb_ready"
                                       name="checkbox_header" value="checkbox_header"
                                       [(ngModel)]="process.orderReady"/>
                                <label for="cb_ready">Order Ready</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="haveParameters()">
        <div class="form-horizontal">
            <div class="card-content">
                <div class="form-group">
                    <label class="col-md-3 control-label">Parameters</label>

                    <div class="col-md-9" style="padding-top: 12px">
                        <div class="row" *ngFor="let parameter of process.parameters | dictionaryValues; let i = index;">
                            <div class="col-md-2">
                                <label class="col-form-label">{{parameter.key}}</label>
                            </div>
                            <div class="col-md-5">
                                <label for="edit_type_{{i}}" class="col-form-label">Value Type
                                    <select
                                            id="edit_type_{{i}}"
                                            name="edit_type_{{i}}"
                                            class="form-control form-control-low selectx"
                                            [(ngModel)]="parameter.value.type"
                                            size="1">
                                        <option [ngValue]="'INGREDIENT_QUANTITY'">INGREDIENT_QUANTITY</option>
                                        <option [ngValue]="'INGREDIENT_QUANTITY_SECONDS'">INGREDIENT_QUANTITY_SECONDS</option>
                                        <option [ngValue]="'NUMBER'">NUMBER</option>
                                        <option [ngValue]="'TEXT'">TEXT</option>
                                        <option [ngValue]="'DEVICE'">DEVICE</option>
                                        <option [ngValue]="'DISPENSER'">DISPENSER</option>
                                        <option [ngValue]="'DEVICE_PLACE'">DEVICE_PLACE</option>
                                        <option [ngValue]="'DELIVERY_PLACE'">DELIVERY_PLACE</option>
                                        <option [ngValue]="'BLEND_TIME'">BLEND_TIME</option>
                                        <option [ngValue]="'VENDING_MACHINE_PRODUCT'">VENDING_MACHINE_PRODUCT</option>
                                    </select>
                                </label>
                            </div>
                            <div class="col-md-5" [hidden]="!isNumberType(parameter.value.type, parameter.key)">
                                <label for="edit_value_{{i}}" class="col-form-label">Value
                                    <input type="text"
                                           [(ngModel)]="parameter.value.value"
                                           class="form-control form-control-low "
                                           id="edit_value_{{i}}"
                                           name="edit_value_{{i}}"/>
                                </label>
                            </div>
                            <div class="col-md-5" [hidden]="!isTextType(parameter.value.type, parameter.key)">
                                <label for="edit_value_{{i}}" class="col-form-label">Value
                                    <input type="text"
                                           [(ngModel)]="parameter.value.stringValue"
                                           class="form-control form-control-low "
                                           id="edit_text_value_{{i}}"
                                           name="edit_value_{{i}}"/>
                                </label>
                            </div>
                            <div class="col-md-5" [hidden]="!isDeviceOrPlace(parameter.value.type, parameter.key)">
                                <label for="edit_device_{{i}}" class="col-form-label">Value
                                    <select
                                            id="edit_device_{{i}}"
                                            name="edit_device_{{i}}"
                                            class="form-control form-control-low selectx"
                                            [(ngModel)]="parameter.value.value"
                                            size="1">
                                        <option [ngValue]="device.id" *ngFor="let device of devices">{{device.name}}
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</base-modal-component>
