<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">

    <tabset>

        <tab heading="Data">
            <fields-list-component [fields]="fields" name="operators" [value]="promotion"></fields-list-component>

            <div class="form-horizontal">
                <div class="card-content">

                    <div class="form-group">
                        <label for="select_kiosks" class="col-md-3 control-label">Target Kiosks</label>
                        <div class="col-md-9">
                            <select
                                    multiple [disabled]="!kiosks"
                                    id="select_kiosks"
                                    data-style="select-btn select-btn-grey"
                                    data-size="7" [compareWith]="compareBeans"
                                    [(ngModel)]="promotion.targetKiosks">
                                <option [ngValue]="kiosk" *ngFor="let kiosk of kiosks">
                                    {{kiosk.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="select_recipe_lists" class="col-md-3 control-label">Recipe Lists</label>
                        <div class="col-md-9">

                            <ng-select [items]="recipesList"
                                       id="select_recipe_lists"
                                       bindLabel="name"
                                       groupBy="listName"
                                       [multiple]="true"
                                       [compareWith]="compareRecipes"
                                       [selectableGroup]="true" [loading]="!recipesList"
                                       loadingText="Loading Recipes..."
                                       [(ngModel)]="selectedRecipes">
                                <ng-template ng-optgroup-tmp let-item="item">
                                    {{item.listName}}
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                </div>
            </div>

        </tab>

        <tab heading="History">
            <div class="card card-timeline card-plain">
                <div class="card-content">
                    <ul class="timeline">
                        <ng-container *ngFor="let history of promotion.editHistory?.slice().reverse()">
                            <li [className]="history.eventType != 'MODIFIED' ? 'timeline-badge' : 'timeline-inverted'">
                                <div class="timeline-badge" [className]="getColorClass(history)">
                                    <i [className]="getIconClass(history)"></i>
                                </div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <span class="label"
                                              [className]="getLabelClass(history)">{{history.eventType}}</span>
                                    </div>
                                    <div class="timeline-body">
                                        <p>{{history.date | date}} by {{history.userName}}</p>
                                    </div>
                                    <h6>
                                        {{history.message}}
                                    </h6>
                                    <h6>
                                        <i class="ti-time"></i>
                                        {{history.date | date: 'shortTime'}}
                                    </h6>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div> <!-- card content -->
            </div>
        </tab>
    </tabset>

</base-modal-component>
