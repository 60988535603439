<div class="animated fadeIn" [hidden]="!maintenanceOptions">

    <table-component (onEdit)="onEdit($event)"
                     (onAdd)="onAdd()"
                     (onRemove)="onRemove($event)"></table-component>

    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
    <br/>
</div>

<kiosk-maintenance-options-edit-component></kiosk-maintenance-options-edit-component>
