<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <fields-list-component #fieldsListComponent [fields]="fields" name="devices_edit"
                           [value]="device"></fields-list-component>

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group" *ngIf="deviceTemplate">
                <label for="select_hw" class="col-md-3 control-label">Hardware Version</label>
                <div class="col-md-9">
                    <select
                            hidden
                            id="select_hw"
                            data-style="select-btn" data-size="7"
                            [(ngModel)]="device.hwVersion">

                        <option [ngValue]="hw.version" *ngFor="let hw of deviceTemplate.hwVersions">
                            {{hw.version}}
                        </option>
                    </select>
                </div>
            </div>

            <div *ngIf="isVision()">

                <div class="form-group" *ngIf="deviceTemplate">
                    <label for="select_hw" class="col-md-3 control-label">Vision</label>
                    <div class="col-md-9">

                        <div *ngFor="let obj of device.visionObjectAreas">
                            {{obj.visionTag}} - {{obj.objectName}}: [{{obj.x1}}, {{obj.y1}}], [{{obj.x2}}, {{obj.y2}}]
                            <div class="table-icons">
                                <a rel="tooltip" title="Edit"
                                   class="btn btn-simple btn-icon table-action btn-warning edit"
                                   (click)="editRect(obj)">
                                    <i class="ti-pencil-alt"></i>
                                </a><a rel="tooltip" title="Delete"
                                       class="btn btn-simple btn-icon table-action btn-danger remove"
                                       (click)="removeRect(obj)">
                                <i class="ti-close"></i>
                            </a>
                            </div>
                        </div>


                        <button type="button" class="btn btn-primary" id="button_add_rect"
                                (click)="addRect()">Add Rect
                        </button>

                    </div>

                </div>


            </div>

            <div *ngIf="isTemperatureSensor()">
                <div class="form-group">
                    <label class="col-md-3 control-label" for="sensor_name">Sensor Name</label>

                    <div class="col-md-9">
                        <input [(ngModel)]="device.sensorName"
                               class="form-control"
                               id="sensor_name"
                               type="text"/>
                    </div>

                </div>
            </div>


            <div *ngIf="isStorageAndDelivery()">
                <div *ngFor="let i of range(device.numberOfStorageLocations)" class="form-group">
                    <label for="select_disabled_storage{{i}}" class="col-md-3 control-label">Storage Location #{{i + 1}}
                        Disabled</label>
                    <div class="col-md-9">

                        <div class="checkbox">
                            <input id="select_disabled_storage{{i}}"
                                   autocomplete="off" [(ngModel)]="disabledStorageLocations[i]"
                                   type="checkbox"/>
                            <label></label>
                        </div>

                    </div>
                </div>
            </div>


            <div *ngIf="isIngredient() || isVendingMachine()">
                <div class="form-group" *ngFor="let i of range(device.numberOfIngredients)">
                    <label for="select_ingredient{{i}}" class="col-md-3 control-label">Sub Ingredient #{{i}}</label>
                    <div class="col-md-9">
                        <select
                                hidden
                                id="select_ingredient{{i}}"
                                data-style="select-btn" data-size="7"
                                [compareWith]="compareBeans"
                                [(ngModel)]="canDispenseIngredientsOrRecipe[i]">

                            <option [ngValue]="null">
                                Select Ingredient
                            </option>

                            <option [ngValue]="ingredient"
                                    *ngFor="let ingredient of getIngredients(isVendingMachine() ? null : device.ingredientType)">
                                {{ingredient.name}}
                            </option>

                            <ng-container *ngIf="isVendingMachine()">
                                <option *ngFor="let recipe of vendingMachineRecipes"
                                        [ngValue]="recipe">
                                    {{recipe.name}}
                                </option>
                            </ng-container>

                        </select>

                        <div class="form-group">
                            <label for="edit_max_amount{{i}}" class="col-md-3 control-label">Max amount</label>
                            <div class="col-md-9">
                                <input type="number"
                                       [(ngModel)]="device.maxAmount[i]"
                                       class="form-control"
                                       id="edit_max_amount{{i}}"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="edit_current_amount{{i}}" class="col-md-3 control-label">Current amount</label>
                            <div class="col-md-9">
                                <input type="number"
                                       [(ngModel)]="device.currentAmount[i]"
                                       class="form-control"
                                       id="edit_current_amount{{i}}"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</base-modal-component>
