import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {ServiceEventBean} from '../../model/model';
import {KioskService} from '../../services/kiosk.service';
import {ServiceEventsComponent} from './service-events.component';

@Component({
    selector: 'service-events-edit-component',
    templateUrl: 'service-events.edit.component.html'
})

export class ServiceEventsEditComponent {

    event: ServiceEventBean = {} as ServiceEventBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('startDate', 'Start Date', FieldType.DATE_TIME).disable(ServiceEventsEditComponent.isNotEmpty),
        new Field('endDate', 'End Date', FieldType.DATE_TIME).disable(ServiceEventsEditComponent.isNotEmpty),
        new Field('durationSeconds', 'Duration', FieldType.DURATION).disable().if(ServiceEventsEditComponent.isNotEmpty),
        new Field('originalCategory', 'Original Category').disable().if(ServiceEventsEditComponent.isNotEmpty),
        new Field('category', 'Actual Category', FieldType.SELECT),
        new Field('component1', 'Component 1', FieldType.SELECT),
        new Field('component2', 'Component 2', FieldType.SELECT),
        new Field('analysis', 'Analysis', FieldType.TEXT_AREA),
    ];
    kioskId: number;

    private static isNotEmpty(eventBean: ServiceEventBean) {
        return (eventBean && eventBean.durationSeconds);
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private kioskService: KioskService,
        @Inject(forwardRef(() => ServiceEventsComponent)) private parent: ServiceEventsComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(event: ServiceEventBean, kioskId: number, title: string) {

        this.kioskId = kioskId;
        this.event = (event ? Helper.copyObject(event) : {} as ServiceEventBean);
        this.base.show(title);

        // this.getFiled('category').setOptions(this.options.categories);
        // this.getFiled('component1').setOptions(Object.keys(this.options.components));
        // this.getFiled('component2').setOptions(this.options.components);
    }

    getFiled(name: string): Field {
        for (let field of this.fields) {
            if (field.field == name) {
                return field;
            }
        }
        return {} as Field;
    }

    save() {

        if (this.event.id) {
            this.kioskService.updateServiceEvent(this.kioskId, this.event).subscribe(response => {

                if (response.success) {
                    this.parent.loadEvents();
                } else {
                    this.app.showError('Can\'t update event');
                }
            }, error => {
                this.app.showError('Can\'t update event');
            });
        } else {
            this.kioskService.addServiceEvent(this.kioskId, this.event).subscribe(response => {

                if (response.success) {
                    this.parent.loadEvents();
                } else {
                    this.app.showError('Can\'t add event');
                }
            }, error => {
                this.app.showError('Can\'t add event');
            });
        }
    }

    onHidden() {
        this.event = {} as ServiceEventBean;
    }
}
