<tabset>
    <tab heading='Dispensing' (selectTab)="onDispensersTabSelected($event)">

        <div class="form-horizontal">
            <div class="card-content">

                <div class="form-group">
                    <label class="col-md-2 control-label" for="dispensing_time_range">Date Range</label>
                    <div class="col-md-10">
                        <div class="row">
                            <input (bsValueChange)="loadCharts()" [(ngModel)]="dispensingDateRange"
                                   class="form-control"
                                   id="dispensing_time_range" bsDaterangepicker type="text"/>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="kiosk">
                    <label class="col-md-2 control-label" for="select_recipes">Recipe</label>
                    <div class="col-md-10">
                        <select class="form-control form-control-low show-menu-arrow selectx" id="select_recipes"
                                (change)="loadCharts()"
                                [(ngModel)]="recipeId">
                            <ng-container *ngFor="let recipe of kiosk.recipeStates">
                                <option *ngIf="recipe.state=='PROVISIONED'" [value]="recipe.id">
                                    {{recipe.name}}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </div>

            </div>
        </div>


        <div class="form-horizontal" *ngIf="stats">

            <div class="card-content">

                <button type="button" class="btn btn-outline-primary" style="float: right"
                        (click)="download()">Download CSV
                </button>&nbsp;

                <div style="display: block; height: 500px" *ngIf="statsChartData">
                    <canvas baseChart
                            [datasets]="statsChartData"
                            [labels]="statsChartLabels"
                            [options]="lineChartOptions"
                            [legend]="true"
                            (chartClick)="selectData($event)"
                            [chartType]="'line'">
                    </canvas>
                </div>
            </div>
        </div>
    </tab>


    <tab heading='Recipes' (selectTab)="onRecipesTabSelected($event)">
        <div class="form-horizontal" *ngIf="recipesStats">
            <div class="card-content">

                <div class="form-group">
                    <div class="col-md-9">
                        <select class="form-control form-control-low show-menu-arrow selectx"
                                (change)="loadRecipesStats()"
                                [(ngModel)]="recipesDuration">
                            <option value="DAILY">DAILY</option>
                            <option value="WEEKLY">WEEKLY</option>
                            <option value="MONTHLY">MONTHLY</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <button type="button" class="btn btn-outline-primary"
                                (click)="downloadRecipes()">Download CSV
                        </button>&nbsp;
                    </div>
                </div>


                <div style="display: block; height: 500px" *ngIf="recipesChartData">
                    <canvas baseChart
                            [datasets]="recipesChartData"
                            [labels]="recipesChartLabels"
                            [options]="stackedChartOptions"
                            [legend]="true"
                            [chartType]="'bar'">
                    </canvas>
                </div>
            </div>
        </div>
    </tab>

    <tab heading='Ingredients' (selectTab)="onIngredientsTabSelected($event)">
        <div class="form-horizontal" *ngIf="ingredientsStats">

            <div class="form-group">
                <div class="col-md-9">
                    <select class="form-control form-control-low show-menu-arrow selectx"
                            (change)="loadIngredientsStats()"
                            [(ngModel)]="ingredientsDuration">
                        <option value="DAILY">DAILY</option>
                        <option value="WEEKLY">WEEKLY</option>
                        <option value="MONTHLY">MONTHLY</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <button type="button" class="btn btn-outline-primary"
                            (click)="downloadIngredients()">Download CSV
                    </button>&nbsp;
                </div>
            </div>


            <br/><br/>
            <b>Solid</b>
            <div class="card-content">

                <div style="display: block; height: 300px; margin-top: 40px;" *ngIf="solidIngredientsChartData">
                    <canvas baseChart
                            [datasets]="solidIngredientsChartData"
                            [labels]="solidIngredientsChartLabels"
                            [options]="stackedChartOptions"
                            [legend]="true"
                            [chartType]="'bar'">
                    </canvas>
                </div>
            </div>

            <br/><br/>
            <b>Liquid</b>
            <div class="card-content">

                <div style="display: block; height: 300px; margin-top: 40px;" *ngIf="liquidIngredientsChartData">
                    <canvas baseChart
                            [datasets]="liquidIngredientsChartData"
                            [labels]="liquidIngredientsChartLabels"
                            [options]="stackedChartOptions"
                            [legend]="true"
                            [chartType]="'bar'">
                    </canvas>
                </div>
            </div>

            <br/><br/>
            <b>Powder</b>
            <div class="card-content">

                <div style="display: block; height: 300px; margin-top: 40px;" *ngIf="powderIngredientsChartData">
                    <canvas baseChart
                            [datasets]="powderIngredientsChartData"
                            [labels]="powderIngredientsChartLabels"
                            [options]="stackedChartOptions"
                            [legend]="true"
                            [chartType]="'bar'">
                    </canvas>
                </div>
            </div>
        </div>
    </tab>

    <tab heading='Customizations' (selectTab)="onCustomizationTabSelected($event)">
        <div class="form-horizontal" *ngIf="customizationStats">

            <div class="form-group">
                <div class="col-md-9">
                    <select class="form-control form-control-low show-menu-arrow selectx"
                            (change)="loadCustomizationStats()"
                            [(ngModel)]="customizationDuration">
                        <option value="DAILY">DAILY</option>
                        <option value="WEEKLY">WEEKLY</option>
                        <option value="MONTHLY">MONTHLY</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <button type="button" class="btn btn-outline-primary"
                            (click)="downloadCustomization()">Download CSV
                    </button>&nbsp;
                </div>
            </div>

            <div class="card-content">

                <div style="display: block; height: 300px; margin-top: 40px;" *ngIf="customizationChartData">
                    <canvas baseChart
                            [datasets]="customizationChartData"
                            [labels]="customizationChartLabels" [colors]="colorsArray"
                            [options]="stackedChartOptions"
                            [legend]="false"
                            [chartType]="'bar'">
                    </canvas>
                </div>
            </div>

            <br/>
            <br/>

            <div class="form-group" *ngIf="kiosk">
                <label for="select_recipes_2" class="col-md-3 control-label">Recipe</label>
                <div class="col-md-9">
                    <select class="form-control form-control-low show-menu-arrow selectx" id="select_recipes_2"
                            (change)="loadCustomizationIngredientsChart()"
                            [(ngModel)]="customizationRecipeId">
                        <ng-container *ngFor="let recipe of kiosk.recipeStates">
                            <option *ngIf="recipe.state=='PROVISIONED'" [value]="recipe.id">
                                {{recipe.name}}
                            </option>
                        </ng-container>
                    </select>
                </div>
            </div>


            <div class="card-content" *ngIf="customizationIngredientsStats">

                <button type="button" class="btn btn-outline-primary" style="float: right"
                        (click)="downloadCustomizationIngredients()">Download CSV
                </button>&nbsp;

                <div style="display: block; height: 300px; margin-top: 40px;" *ngIf="customizationIngredientsChartData">
                    <canvas baseChart
                            [datasets]="customizationIngredientsChartData"
                            [labels]="customizationIngredientsChartLabels" [colors]="colorsArray"
                            [options]="stackedChartOptions"
                            [legend]="false"
                            [chartType]="'bar'">
                    </canvas>
                </div>

            </div>

            <div class="card-content center-block text-center" *ngIf="!customizationIngredientsStats">No data</div>

        </div>
    </tab>

    <tab heading='Maintenance' (selectTab)="onMaintenanceTabSelected($event)">
        <div class="form-horizontal" *ngIf="maintenanceStats">
            <div class="card-content">

                <div class="form-group">
                    <div class="col-md-9">
                        <select class="form-control form-control-low show-menu-arrow selectx"
                                (change)="loadMaintenanceStats()"
                                [(ngModel)]="maintenanceDuration">
                            <option value="DAILY">DAILY</option>
                            <option value="WEEKLY">WEEKLY</option>
                            <option value="MONTHLY">MONTHLY</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <button type="button" class="btn btn-outline-primary"
                                (click)="downloadMaintenance()">Download CSV
                        </button>&nbsp;
                    </div>
                </div>


                <div style="display: block; height: 500px" *ngIf="maintenanceChartData">
                    <canvas baseChart
                            [datasets]="maintenanceChartData"
                            [labels]="maintenanceChartLabels"
                            [options]="lineChartOptions"
                            [legend]="true"
                            [chartType]="'bar'">
                    </canvas>
                </div>
            </div>
        </div>
    </tab>

    <tab heading='Distribution' (selectTab)="onTimeTabSelected($event)">
        <div class="form-horizontal">
            <div class="card-content">

                <div class="form-group">
                    <label class="col-md-2 control-label" for="distribution_time_range">Date Range</label>
                    <div class="col-md-10">
                        <div class="row">
                            <input (bsValueChange)="loadTimeStats()" [(ngModel)]="distributionDateRange"
                                   class="form-control"
                                   id="distribution_time_range" bsDaterangepicker type="text"/>
                        </div>
                    </div>
                </div>

                <div style="display: block; height: 300px; margin-top: 40px;" *ngIf="processingTimeChartData">
                    <canvas baseChart
                            [datasets]="processingTimeChartData"
                            [labels]="processingTimeChartLabels"
                            [options]="lineChartOptions"
                            [legend]="true"
                            [chartType]="'bar'">
                    </canvas>
                </div>

                <div style="display: block; height: 300px; margin-top: 40px;" *ngIf="waitingTimeChartData">
                    <canvas baseChart
                            [datasets]="waitingTimeChartData"
                            [labels]="waitingTimeChartLabels"
                            [options]="lineChartOptions"
                            [legend]="true"
                            [chartType]="'bar'">
                    </canvas>
                </div>

                <ng-container *ngFor="let data of ingredientErrorsChartData; let i=index">
                    <div style="display: block; height: 300px; margin-top: 40px;">
                        <canvas baseChart
                                [datasets]="ingredientErrorsChartData[i]"
                                [options]="lineChartOptions"
                                [chartType]="'scatter'"
                                [legend]="true"
                                [colors]="colorsArray">
                        </canvas>
                        <span style="font-size: 12px;">{{equation[i]}}; r2 = {{r2[i]}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </tab>

    <tab heading='Orders' (selectTab)="onOrdersTabSelected($event)">

        <div class="form-horizontal">
            <div class="card-content">

                <div class="form-group">
                    <label class="col-md-2 control-label" for="orders_time_range">Date Range</label>
                    <div class="col-md-10">
                        <div class="row">
                            <input [(ngModel)]="ordersDateRange"
                                   class="form-control"
                                   id="orders_time_range" bsDaterangepicker type="text"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-outline-primary"
                (click)="downloadOrders()">Download CSV
        </button>&nbsp;
    </tab>

</tabset>


<orders-quickview-component></orders-quickview-component>
