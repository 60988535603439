import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {MealplansComponent} from './mealplans.component';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {MealPlanBean} from '../../model/model';
import {MealplanService} from '../../services/mealplan.service';
import {Helper} from '../../common/helper';

@Component({
    selector: 'mealplan-edit-component',
    templateUrl: 'mealplan.edit.component.html'
})

export class MealplanEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    mealplan: MealPlanBean = {} as MealPlanBean;

    fields: Field[] = [

        new Field('id', 'Id').disable(),
        new Field('type', 'Type', FieldType.SELECT).setOptions(['USF', 'SONOMA', 'BLACKBOARD']),
        new Field('name', 'Name').require(),
        new Field('url', 'URL'),
        new Field('loginUrl', 'Login URL'),
        new Field('username', 'User Name'),
        new Field('password', 'Password'),
        new Field('cardDefaultName', 'Card Default Name'),
        new Field('institutionId', 'Institution Id'),
        new Field('institutionRouteId', 'Institution Route Id'),
    ];

    constructor(private mealplanService: MealplanService,
        @Inject(forwardRef(() => MealplansComponent)) private parent: MealplansComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    open(mealplan: MealPlanBean, title: string) {
        this.mealplan = (mealplan ? Helper.copyObject(mealplan) : {} as MealPlanBean);
        this.base.show(title);
    }

    save() {

        if (this.mealplan.id == null) {

            this.mealplanService.addMealplan(this.mealplan).subscribe(response => {

                if (response.success) {
                    this.parent.loadMealplans();
                } else {
                    this.app.showError('Can\'t create Meal Plan');
                }
            }, error => {
                this.app.showError('Can\'t create Meal Plan');
            });

        } else {

            this.mealplanService.updateMealplan(this.mealplan).subscribe(response => {

                if (response.success) {
                    this.parent.loadMealplans();
                } else {
                    this.app.showError('Can\'t update Meal Plan');
                }
            }, error => {
                this.app.showError('Can\'t update Meal Plan');
            });
        }
    }

}
