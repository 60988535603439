import {AfterViewInit, Component, forwardRef, Inject, LOCALE_ID} from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';
import {ActivatedRoute} from '@angular/router';
import {AnalyticsBean, DashboardDynamicsBean, DeviceMessageBean, EntityNameBean, StatusBean} from '../../model/model';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {Helper} from '../../common/helper';
import {OperatorsService} from '../../services/operators.service';
import {KioskService} from '../../services/kiosk.service';
import {formatDate, Location} from '@angular/common';
import * as math from 'mathjs';
import * as moment from 'moment-timezone';

@Component({
    templateUrl: 'business-ops.component.html'
})
export class BusinessOpsComponent implements AfterViewInit {

    messages: DeviceMessageBean[];
    operationsDynamicOrders: DashboardDynamicsBean[];

    compareBeans = Helper.compareBeans;
    status: StatusBean;
    kiosks: EntityNameBean[] = [];
    selectedKiosks: EntityNameBean[];

    selectedRange = [
        moment().startOf('day').subtract(4, 'week').toDate(),
        moment().endOf('day').toDate()
    ];
    range = 'current';
    data: AnalyticsBean[];

    constructor(private dashboardService: DashboardService,
        private operatorsService: OperatorsService,
        private kioskService: KioskService,
        @Inject(LOCALE_ID) private locale: string,
        @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
        private route: ActivatedRoute,
        private location: Location) {
    }

    ngAfterViewInit() {
        Helper.selectPicker('select_kiosk', 'select_mode');

        this.route.queryParams.subscribe(params => {

            this.selectedKiosks = [];
            let ids = params['kioskId'];
            if (ids) {
                if (ids instanceof Array) {
                    for (let id of ids) {
                        this.selectedKiosks.push({id: id, name: ''});
                    }
                } else {
                    this.selectedKiosks.push({id: ids, name: ''});
                }
            }
            this.loadKiosks();
            this.getOperations();
            this.getStatus();
        });
    }

    loadKiosks() {
        this.kioskService.getActiveKiosksNames(true).subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks: EntityNameBean[]) {

        this.kiosks = kiosks;
        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKiosks || this.selectedKiosks.length == 0) {
            this.selectedKiosks = [];
            for (const kiosk of this.kiosks) {
                if (kiosk.name == 'Blendid') {
                    this.selectedKiosks.push(kiosk);
                }

            }
        }

        for (const kiosk of this.kiosks) {
            for (let selectedKiosk of this.selectedKiosks) {
                if (selectedKiosk.id == kiosk.id) {
                    selectedKiosk.name = kiosk.name;
                }
            }
        }

        Helper.selectPicker('select_kiosk');
        this.getBusinessOps();
    }

    onKioskChange() {
        this.getBusinessOps();
        this.updateLocation();
    }

    updateLocation() {
        let url = '/business-ops?';

        for (let kiosk of this.selectedKiosks) {
            url += ('kioskId=' + kiosk.id + '&');
        }
        url = url.replace(/&\s*$/, '');

        this.location.replaceState(url);
    }

    private getStatus() {
        this.dashboardService.getStatus(true).subscribe(response => {
            if (response) {
                this.status = response.value;
            }
        });
    }

    sum(map: {[p: string]: number}) {
        let result = 0;
        for (const key in map) {
            result += map[key];
        }
        return result;
    }

    private getOperations() {
        this.dashboardService.getOperationsDynamic().subscribe(response => {
            if (response) {
                this.operationsDynamicOrders = response.value;
            }
        });
    }

    rangeChange($event: Event) {
        this.getBusinessOps();
    }

    onDateRangeChanged() {
        this.getBusinessOps();
    }

    private getBusinessOps() {

        let date: Date = new Date();

        let dateFrom = this.selectedRange[0];
        let dateTo = this.selectedRange[1];

        if (this.range == 'current') {
            dateTo = new Date(date);
            dateTo.setDate(dateTo.getDate() - dateTo.getDay() - 1);

            dateFrom = new Date(dateTo);
            dateFrom.setDate(dateFrom.getDate() - 21);
        }

        let start = Helper.startOf(dateFrom);
        let end = Helper.endOf(dateTo);

        this.dashboardService.getAnalytics(this.selectedKiosks, start, end,
            this.range == 'monthly' ? 'monthly' : 'weekly').subscribe(response => {
                if (response && response.success) {

                    let analytics = response.value;
                    let len = analytics.length;

                    let average = {} as AnalyticsBean;

                    average.revenueTotal = analytics.reduce((total, next) => total + next.revenueTotal, 0) / len;
                    average.revenueIOS = analytics.reduce((total, next) => total + next.revenueIOS, 0) / len;
                    average.revenueAndroid = analytics.reduce((total, next) => total + next.revenueAndroid, 0) / len;
                    average.revenueKiosk = analytics.reduce((total, next) => total + next.revenueKiosk, 0) / len;
                    average.revenueWeb = analytics.reduce((total, next) => total + next.revenueWeb, 0) / len;

                    average.revenueMealPlan = analytics.reduce((total, next) => total + next.revenueMealPlan, 0) / len;
                    average.ordersTotal = analytics.reduce((total, next) => total + next.ordersTotal, 0) / len;
                    average.ordersIOS = analytics.reduce((total, next) => total + next.ordersIOS, 0) / len;
                    average.ordersAndroid = analytics.reduce((total, next) => total + next.ordersAndroid, 0) / len;
                    average.ordersKiosk = analytics.reduce((total, next) => total + next.ordersKiosk, 0) / len;
                    average.ordersWeb = analytics.reduce((total, next) => total + next.ordersWeb, 0) / len;
                    average.ordersMealPlan = analytics.reduce((total, next) => total + next.ordersMealPlan, 0) / len;
                    average.ordersFree = analytics.reduce((total, next) => total + next.ordersFree, 0) / len;
                    average.ordersPromo = analytics.reduce((total, next) => total + next.ordersPromo, 0) / len;
                    average.customizedIOS = analytics.reduce((total, next) => total + next.customizedIOS, 0) / len;
                    average.customizedAndroid = analytics.reduce((total, next) => total + next.customizedAndroid, 0) / len;
                    average.customizedKiosk = analytics.reduce((total, next) => total + next.customizedKiosk, 0) / len;
                    average.customizedMealPlan = analytics.reduce((total, next) => total + next.customizedMealPlan, 0) / len;
                    average.ordersCancelled = analytics.reduce((total, next) => total + next.ordersCancelled, 0) / len;
                    average.ordersCancelledBeforeStart = analytics.reduce((total, next) => total + next.ordersCancelledBeforeStart, 0) / len;

                    average.ordersReordered = analytics.reduce((total, next) => total + next.ordersReordered, 0) / len;
                    average.maintenanceRegular = analytics.reduce((total, next) => total + next.maintenanceRegular, 0) / len;
                    average.maintenanceCritical = analytics.reduce((total, next) => total + next.maintenanceCritical, 0) / len;
                    average.maintenanceTotal = analytics.reduce((total, next) => total + next.maintenanceTotal, 0) / len;
                    average.maintenanceUserScheduled = analytics.reduce((total, next) => total + next.maintenanceUserScheduled, 0) / len;
                    average.maintenanceUserCritical = analytics.reduce((total, next) => total + next.maintenanceUserCritical, 0) / len;
                    average.reviewOverall = analytics.reduce((total, next) => total + next.reviewOverall, 0) / len;
                    average.reviewFreshness = analytics.reduce((total, next) => total + next.reviewFreshness, 0) / len;
                    average.reviewWaitTime = analytics.reduce((total, next) => total + next.reviewWaitTime, 0) / len;
                    average.uptime = analytics.reduce((total, next) => total + next.uptime, 0) / len;
                    average.waitTimeMaximum = analytics.reduce((total, next) => total + next.waitTimeMaximum, 0) / len;
                    average.engagementLoyalists = analytics.reduce((total, next) => total + next.engagementLoyalists, 0) / len;
                    average.engagementRecurring = analytics.reduce((total, next) => total + next.engagementRecurring, 0) / len;
                    average.paidOrderNumber = analytics.reduce((total, next) => total + next.paidOrderNumber, 0) / len;
                    average.totalUniqueCustomers = analytics.reduce((total, next) => total + next.totalUniqueCustomers, 0) / len;
                    average.loyalistsOrdersNumber = analytics.reduce((total, next) => total + next.loyalistsOrdersNumber, 0) / len;
                    average.recurringOrdersNumber = analytics.reduce((total, next) => total + next.recurringOrdersNumber, 0) / len;
                    average.processingTimeMaximum = analytics.reduce((total, next) => total + next.processingTimeMaximum, 0) / len;
                    average.reviewOverallCount = analytics.reduce((total, next) => total + next.reviewOverallCount, 0) / len;
                    average.reviewFreshnessCount = analytics.reduce((total, next) => total + next.reviewFreshnessCount, 0) / len;
                    average.reviewWaitTimeCount = analytics.reduce((total, next) => total + next.reviewWaitTimeCount, 0) / len;
                    average.scheduledUptimeMinutes = analytics.reduce((total, next) => total + next.scheduledUptimeMinutes, 0) / len;

                    average.ordersOnUs = analytics.reduce((total, next) => total + next.ordersOnUs, 0) / len;
                    average.ordersEmployee = analytics.reduce((total, next) => total + next.ordersEmployee, 0) / len;
                    average.ordersTest = analytics.reduce((total, next) => total + next.ordersTest, 0) / len;
                    average.revenueOnUs = analytics.reduce((total, next) => total + next.revenueOnUs, 0) / len;
                    average.revenueEmployee = analytics.reduce((total, next) => total + next.revenueEmployee, 0) / len;
                    average.revenueTest = analytics.reduce((total, next) => total + next.revenueTest, 0) / len;

                    let waitTimeSecondsSum = [];
                    let processingTimeSecondsSum = [];
                    let customerDeliveryTimeSecondsSum = [];

                    average.processingTimeMinimum = Number.MAX_SAFE_INTEGER;
                    average.errorsComputed = {};

                    for (let item of analytics) {

                        let waitTimeSeconds = [];
                        for (let key in item.waitTimeSeconds) {
                            waitTimeSeconds.push(item.waitTimeSeconds[key]);
                        }

                        let processingTimeSeconds = [];
                        for (let key in item.processingTimeSeconds) {
                            processingTimeSeconds.push(item.processingTimeSeconds[key]);
                        }

                        let customerDeliveryTimeSeconds = [];
                        for (let key in item.customerDeliveryTimeSeconds) {
                            customerDeliveryTimeSeconds.push(item.customerDeliveryTimeSeconds[key]);
                        }

                        waitTimeSecondsSum = waitTimeSecondsSum.concat(waitTimeSeconds);
                        processingTimeSecondsSum = processingTimeSecondsSum.concat(processingTimeSeconds);
                        customerDeliveryTimeSecondsSum = customerDeliveryTimeSecondsSum.concat(customerDeliveryTimeSeconds);

                        if (item.processingTimeMinimum < average.processingTimeMinimum) {
                            average.processingTimeMinimum = item.processingTimeMinimum;
                        }

                        if (item.waitTimeMaximum > average.waitTimeMaximum) {
                            average.waitTimeMaximum = item.waitTimeMaximum;
                        }
                        if (item.processingTimeMaximum > average.processingTimeMaximum) {
                            average.processingTimeMaximum = item.processingTimeMaximum;
                        }

                        average.errorsUser = this.sumObjectsByKey(average.errorsUser, item.errorsUser);
                        average.errorsComputed = this.sumObjectsByKey(average.errorsComputed, item.errorsComputed);
                    }

                    for (let key in average.errorsUser) {
                        average.errorsUser[key] /= len;
                    }
                    for (let key in average.errorsComputed) {
                        average.errorsComputed[key] /= len;
                    }

                    if (waitTimeSecondsSum.length > 0) {
                        average.waitTimeMedian = math.median(waitTimeSecondsSum);
                    }
                    if (processingTimeSecondsSum.length > 0) {
                        average.processingTimeMedian = math.median(processingTimeSecondsSum);
                    }
                    if (customerDeliveryTimeSecondsSum.length > 0) {
                        average.customerDeliveryTimeMedian = math.median(customerDeliveryTimeSecondsSum);
                    }

                    this.data = null;

                    switch (this.range) {
                        case 'current':
                            this.data = analytics.slice(2, 4).reverse();
                            average['title'] = '4-Week Average';
                            this.data.push(average);

                            this.data[0]['title'] = 'Current Week (' + formatDate(this.data[0].ts, 'MM/dd/yyyy', this.locale) + ')';
                            this.data[1]['title'] = 'Previous Week (' + formatDate(this.data[1].ts, 'MM/dd/yyyy', this.locale) + ')';

                            break;

                        case 'weekly':
                        case 'monthly':

                            this.data = analytics;
                            average['title'] = 'Average';

                            for (let item of this.data) {
                                item['title'] = formatDate(item.ts, 'MM/dd/yyyy', this.locale);
                            }
                            this.data.push(average);
                            break;
                    }
                }
            });
    }

    sumObjectsByKey(...objs) {
        return objs.reduce((a, b) => {
            for (let k in b) {
                if (b.hasOwnProperty(k)) {
                    a[k] = (a[k] || 0) + b[k];
                }
            }
            return a;
        }, {});
    }

    isSelected(kiosk: DashboardDynamicsBean) {
        for (let item of this.selectedKiosks) {
            if (item.name == kiosk.kioskName) {
                return true;
            }
        }
    }
}
