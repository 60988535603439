<base-modal-component class="base-modal" confirmButtonText="Close">

    <div class="form-group">
        <button type="button" class="btn btn-primary"
                (click)="call('reboot')">
            Reboot
        </button>&nbsp;
        <button type="button" class="btn btn-primary"
                (click)="call('restart')">
            Restart
        </button>&nbsp;
        <button (click)="call('password')" *ngIf="device.abstractDeviceName!='Robot'" class="btn btn-primary"
                type="button">
            Update Password
        </button>&nbsp;
    </div>

    <div *ngIf="device.abstractDeviceName=='Robot'" class="form-group">
        <button type="button" class="btn btn-primary" (click)="call('play')">
            Play
        </button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="call('unlock')">
            Unlock
        </button>&nbsp;
        <button type="button" class="btn btn-primary"
                (click)="call('unlock_resume')">Unlock Resume
        </button>&nbsp;
        <button type="button" class="btn btn-primary" (click)="call('state')">
            State
        </button>&nbsp;
    </div>
    <br/>
    <div *ngIf="device.abstractDeviceName=='Robot'" class="form-group">
        <label for="query_text" class="col-md-1 control-label">Set IP</label>
        <div class="col-md-9">
            <div class="row">
                <input type="text" id="query_text" class="form-control col-md-6" style="width: 60%"
                       [(ngModel)]="ip"
                       placeholder="IP"/>

                <button type="button" class="btn btn-outline-primary col-md-4"
                        style="height: 40px; margin-left: 10px"
                        (click)="setRobotIP()">Set
                </button>
            </div>
        </div>
    </div>

    <br/><br/>
    <textarea disabled class="form-control" rows="12">{{log}}</textarea>

    <!--    play unlock unlock_resume state set_ip-->

</base-modal-component>
