import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {OrderService} from '../../services/order.service';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {OperatorsService} from '../../services/operators.service';
import {KioskService} from '../../services/kiosk.service';
import {Helper} from '../../common/helper';
import {KioskInfoBean, OrderBean, OrderState} from '../../model/model';
import {OrdersQuickViewComponent} from './orders.quickview.component';
import {RightsGuard} from '../../common/auth.guard';
import * as moment from 'moment-timezone';
import {SmsModalComponent} from './sms.modal.component';

@Component({
    templateUrl: 'orders.component.html',
})

export class OrdersComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(OrdersQuickViewComponent, {static: false}) ordersQuickViewComponent: OrdersQuickViewComponent;
    @ViewChild(SmsModalComponent, {static: false}) smsModalComponent: SmsModalComponent;

    columnsAdmin: Column[] = [
        new Column('date', 'Date').dateTime(),
        new Column('id', 'Id').hide(),
        new Column('operatorName', 'Operator').hide(),
        new Column('kioskName', 'Location'),
        new Column('transactionId', 'Transaction Id').hide(),
        new Column('customerId', 'Customer Id').hide(),
        new Column('stateCopy', 'State'),
        new Column('clientType', 'Client'),
        new Column('recipeName', 'Recipe'),
        new Column('customized', 'Customized').checkbox(),
        new Column('userName', 'User'),
        new Column('volume', 'Volume (oz)').decimal(),
        new Column('coupon.name', 'Coupon').hide(),
        new Column('startDate', 'Start').dateTime(),
        new Column('waitTime', 'Wait').shortTime(),
        new Column('storage', 'Storage').data(this, this.getStorage),
        new Column('email', 'Email').hide(),
        new Column('phoneNumber', 'Phone Number').hide(),
        new Column('processTime', 'Complete Time').shortTime().hide(),
        new Column('deliveryTime', 'Delivery Time').dateTime().hide(),
        new Column('finishDate', 'Finish').time().hide(),
        // new Column('completeTime', 'Complete Time').data(this, this.getCompleteTime),
    ];

    columnsUser: Column[] = [
        new Column('date', 'Date').dateTime(),
        new Column('id', 'Id').hide(),
        new Column('operatorName', 'Operator').hide(),
        new Column('kioskName', 'Location'),
        new Column('transactionId', 'Transaction Id').hide(),
        new Column('stateCopy', 'State'),
        new Column('clientType', 'Client'),
        new Column('recipeName', 'Recipe'),
        new Column('customized', 'Customized').checkbox(),
        new Column('volume', 'Volume (oz)').decimal(),
        new Column('coupon.name', 'Coupon'),
        new Column('startDate', 'Start').dateTime(),
        new Column('waitTime', 'Wait').shortTime(),
        new Column('processTime', 'Complete Time').shortTime().hide(),
        new Column('deliveryTime', 'Delivery Time').dateTime(),
        new Column('finishDate', 'Finish').time().hide(),
        // new Column('completeTime', 'Complete Time').data(this, this.getCompleteTime),
    ];

    kiosks: KioskInfoBean[] = [];
    allKiosks: KioskInfoBean = {kioskId: 0} as KioskInfoBean;
    selectedKiosk: KioskInfoBean = {kioskId: 0} as KioskInfoBean;
    selectedKioskId: number;
    compareBeans = Helper.compareBeans;

    selectedState: OrderState;
    query: string;

    selectedRange = [
        moment().startOf('day').subtract(7, 'day').toDate(),
        moment().endOf('day').toDate()
    ];
    isMealplanMobile: boolean;

    constructor(private orderService: OrderService,
        private operatorsService: OperatorsService,
        private kioskService: KioskService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        Helper.selectPicker('select_kiosk', 'select_state');

        // this.allOperators.id = 0;
        this.route.queryParams.subscribe(params => {
            // this.selectedOperatorId = params['location'] != null ? params['location'] : 0;
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;

            if (params['state']) {
                this.selectedState = params['state'];
            } else {
                this.selectedState = null;
            }

            if (params['query']) {
                this.query = params['query'];
            } else {
                this.query = null;
            }

            this.loadKiosks();
        });
    }

    loadOrders() {
        if (this.table) {
            this.table.setData([], RightsGuard.isSuperAdmin() || RightsGuard.isAdmin() ? this.columnsAdmin : this.columnsUser);
        }
    }

    onKioskChange() {
        this.loadOrders();
        this.updateLocation();
    }

    updateLocation() {
        let url = '/orders';
        if (this.selectedKiosk != this.allKiosks && this.selectedKiosk.kioskId) {
            url += ('?kiosk=' + this.selectedKiosk.kioskId); // &
            // }
            if (this.selectedState != null) {
                url += ('&state=' + this.selectedState);
            }
        } else {
            if (this.selectedState != null) {
                url += ('?state=' + this.selectedState);
            }
        }

        this.location.replaceState(url);
    }

    onStateChange() {
        this.updateLocation();
        this.loadOrders();
    }

    loadKiosks() {

        this.kioskService.getKiosksInfo().subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    getStorage(item: OrderBean) {
        return item.storageDevice ? (item.storageDevice + ' [' + item.storageLocation + ']') : '';
    }

    setKiosks(kiosks: KioskInfoBean[]) {

        this.kiosks = kiosks;
        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKioskId) {
            this.selectedKiosk = this.allKiosks;
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.kioskId == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }
        Helper.selectPicker('select_kiosk', 'select_state');
        this.loadOrders();
    }

    onView(items: OrderBean[]) {
        if (items && items.length > 0) {
            this.router.navigate(['/order-details/'], {queryParams: {id: items[0].id}});
        }
    }

    onRemove(items) {
        const ids = [];
        for (const order of items) {
            ids.push(order.id);
        }

        this.orderService.cancelOrders(ids).subscribe(response => {
            if (response.success) {
                this.loadOrders();

            } else {
                this.app.showError('Can\'t cancel order');
            }
        }, error => {
            this.app.showError('Can\'t cancel order');
        });
    }

    onDateRangeChanged(range) {
        this.loadOrders();
    }

    getData(params) {

        let start = Helper.startOf(this.selectedRange[0]);
        let end = Helper.endOf(this.selectedRange[1]);

        this.orderService.getOrders(this.selectedState, this.query, this.isMealplanMobile, start, end,
            this.selectedKiosk.kioskId,
            params.data.limit,
            params.data.offset)
            .subscribe(response => {

                const orders = [];
                for (const order of response.list) {
                    // for (const state of this.selectedStates) {
                    //     if (state == order.state) {
                    order['stateCopy'] = order.state;
                    orders.push(order);
                    // }
                    // }
                }

                params.success({
                    total: response.pagesCount * params.data.limit,
                    rows: orders
                });
                params.complete();
            });
    }

    onClickRow(item: OrderBean) {
        this.ordersQuickViewComponent.open(item);
    }

    // private getCompleteTime(order: OrderBean) {
    //     console.log(order.finishDate);
    //     if (!order.finishDate || !order.startDate) {
    //         return '-';
    //     }
    //     let orig = order.finishDate - order.startDate;
    //     return (orig && orig != 0) ? moment.utc(orig).format('HH:mm:ss') : '-';
    // }

    onExtra(items: OrderBean[]) {
        this.smsModalComponent.open("Contact User", items[0], items[0].kioskId);

    }
}
