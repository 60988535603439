export class LineChartData {
    data: Array<{data: Array<any>; label: string; fill: boolean}>;
    labels: Array<any>;

    colors: Array<any> = [
        {borderColor: '#DC3912'},
        {borderColor: '#65BE91'},
        {borderColor: '#3366CC'},
        {borderColor: '#FF9900'},
        {borderColor: '#990099'},
        {borderColor: '#3B3EAC'},
        {borderColor: '#0099C6'},
        {borderColor: '#DD4477'},
        {borderColor: '#66AA00'},
        {borderColor: '#B82E2E'},
        {borderColor: '#316395'},
        {borderColor: '#994499'},
        {borderColor: '#22AA99'},
        {borderColor: '#AAAA11'},
        {borderColor: '#6633CC'},
        {borderColor: '#E67300'},
        {borderColor: '#8B0707'},
        {borderColor: '#329262'},
        {borderColor: '#5574A6'},
        {borderColor: '#3B3EAC'},
        {borderColor: '#DC3912'},
        {borderColor: '#65BE91'},
        {borderColor: '#3366CC'},
        {borderColor: '#FF9900'},
        {borderColor: '#990099'},
        {borderColor: '#3B3EAC'},
        {borderColor: '#0099C6'},
        {borderColor: '#DD4477'},
        {borderColor: '#66AA00'},
        {borderColor: '#B82E2E'},
        {borderColor: '#316395'},
        {borderColor: '#994499'},
        {borderColor: '#22AA99'},
        {borderColor: '#AAAA11'},
        {borderColor: '#6633CC'},
        {borderColor: '#E67300'},
        {borderColor: '#8B0707'},
        {borderColor: '#329262'},
        {borderColor: '#5574A6'},
        {borderColor: '#3B3EAC'},
    ];

    constructor(labels: Array<string>) {

        this.data = [];
        this.labels = [];

        for (const label of labels) {
            this.data.push({data: [], label, fill: false});
        }
    }

    public static lineChartOptions: any = {
        animation: {
            duration: 0 // general animation time
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        legend: {
            display: true,
            position: 'bottom'
        },
        responsiveAnimationDuration: 0,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    min: 0,
                    beginAtZero: true
                }
            }]
        }
    };

    public static lineChartOptionsNoZero: any = {
        animation: {
            duration: 0 // general animation time
        },
        legend: {
            display: true,
            position: 'bottom'
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0,
        responsive: true,
        maintainAspectRatio: false,
    };

    public static stackedChartOptions: any = {
        responsive: true,
        animation: {
            duration: 0 // general animation time
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        legend: {
            display: true,
            position: 'bottom'
        },
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true
            }]
        }
    };

    static colorGreen = {
        backgroundColor: 'rgba(100,190,145,0.8)',
        borderColor: 'rgba(100,190,145,1)',
    };
    static colorRed = {
        backgroundColor: 'rgba(255,90,90,0.8)',
        borderColor: 'rgba(255,90,90,1)',
    };
}
