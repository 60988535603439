<div class="animated fadeIn">
    <div class="col-md-12">

        <div class="row">

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="select_kiosk">Location</label>
                        <div class="col-md-10">
                            <select (change)="onKioskChange()"
                                    [(ngModel)]="selectedKiosk"
                                    [compareWith]="compareBeans" [disabled]="kiosks.length==0"
                                    class="form-control"
                                    data-size="7"
                                    data-style="select-btn"
                                    id="select_kiosk"
                                    title="Select Location">
                                <option [ngValue]="allKiosks" selected="selected">All Locations</option>
                                <option *ngFor="let kiosk of kiosks" [ngValue]="kiosk"
                                        selected="selected">{{kiosk.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-horizontal">
                <div class="card-content">

                    <div class="form-group">
                        <label class="col-md-2 control-label" for="select_state">States</label>
                        <div class="col-md-10">
                            <select
                                    (change)="onStateChange()" [(ngModel)]="selectedState"
                                    class="form-control"
                                    data-size="7" data-style="select-btn" hidden
                                    id="select_state">
                                <option [ngValue]="null">ALL</option>
                                <option [ngValue]="'WAITING'">WAITING</option>
                                <option [ngValue]="'ACCEPTED'">ACCEPTED</option>
                                <option [ngValue]="'PREPARING'">PREPARING</option>
                                <option [ngValue]="'READY'">READY</option>
                                <option [ngValue]="'PICKING_UP'">PICKING_UP</option>
                                <option [ngValue]="'COMPLETED'">COMPLETED</option>
                                <option [ngValue]="'CANCELLED'">CANCELLED</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="query_text">Date Range</label>
                        <div class="col-md-10">
                            <div class="row">
                                <input (bsValueChange)="loadOrders()" [(ngModel)]="selectedRange"
                                       class="form-control"
                                       id="working_hours_range" bsDaterangepicker type="text"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-horizontal">
                <div class="card-content">

                    <div class="form-group">
                        <label class="col-md-2 control-label" for="mealplan_text">Meal Plan Mobile</label>
                        <div class="col-md-10">
                            <div class="checkbox">
                                <input
                                        (change)="loadOrders()" [(ngModel)]="isMealplanMobile"
                                        autocomplete="off" id="mealplan_text"
                                        type="checkbox"/>

                                <label for="mealplan_text"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="form-horizontal">
                <div class="card-content">

                    <div class="form-group">
                        <label class="col-md-2 control-label" for="query_text">Filter</label>
                        <div class="col-md-10">
                            <div class="row">
                                <input [(ngModel)]="query" class="form-control col-md-10" id="query_text"
                                       placeholder="Query"
                                       style="width: 80%"
                                       type="text"/>

                                <button (click)="loadOrders()" class="btn btn-outline-primary col-md-2"
                                        style="width: calc(20% - 10px); height: 40px; margin-left: 10px"
                                        type="button">Load Orders
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/>


        <div class="card">
            <div class="card-content">
                <table-component (dataFunction)="getData($event)" (onEdit)="onExtra($event)" (onView)="onView($event)"
                                 (onClickRow)="onClickRow($event)" removeText="Cancel"
                                 (onRemove)="onRemove($event)"></table-component>
            </div>
        </div>
    </div>
</div>

<orders-quickview-component></orders-quickview-component>
<app-sms-modal-component></app-sms-modal-component>
