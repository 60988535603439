<base-modal-component class="base-modal" (onConfirm)="save()">
    <fields-list-component #fieldsListComponent [fields]="fields"
                           [value]="user"></fields-list-component>
    <fields-list-component #profileFieldsListComponent [fields]="profileFields"
                           [value]="userProfile"></fields-list-component>


    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label class="col-md-3 control-label">Action
                </label>
                <div class="col-md-9">
                    <button (click)="resetPhoneNumberValidation()" class="btn btn-primary" id="button_save"
                            type="button">Reset Phone Number Validation
                    </button>
                </div>
            </div>
        </div>

    </div>

</base-modal-component>
