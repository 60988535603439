import {AfterViewInit, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {
    AbstractDeviceBean,
    DeviceModelBean,
    DiagnosticsStepBean,
    DiagnosticsStepSequenceBean,
    Entry,
    ProcessParameterValueBean
} from '../model/model';
import {BaseModalComponent} from './base.modal.component';
import {KioskDataService} from '../services/kiosk-data.service';
import {Helper} from '../common/helper';

@Component({
    selector: 'diagnostics-step-edit-component',
    templateUrl: 'diagnostics-step.edit.component.html'
})

export class DiagnosticsStepEditComponent implements AfterViewInit {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @Output() onResult = new EventEmitter();

    entry: Entry = {} as Entry;
    origEntry: Entry;
    deviceModels: DeviceModelBean[];
    deviceModelsWithParams: DeviceModelBean[];

    abstractDevices: AbstractDeviceBean[];
    selectedDeviceModel: DeviceModelBean;

    compareBeans = Helper.compareBeans;

    isProcess: boolean;
    sequences: DiagnosticsStepSequenceBean[];
    selectedSequence: DiagnosticsStepSequenceBean;

    constructor(private kioskDataService: KioskDataService) {
    }

    range = (value) => {
        const a = [];
        for (let i = 0; i < value; ++i) {
            a.push(i);
        }
        return a;
    };

    ngAfterViewInit(): void {
        this.kioskDataService.getData().subscribe(kioskData => {
            this.abstractDevices = kioskData.value.devices;
        });
    }

    open(entry: Entry, deviceModels: DeviceModelBean[], sequences: DiagnosticsStepSequenceBean[]) {

        this.sequences = sequences;
        this.origEntry = entry;
        this.deviceModels = deviceModels;

        this.deviceModelsWithParams = [];
        for (const device of this.deviceModels) {
            const abstractDevice = this.getAbstractDeviceBean(device);
            if (abstractDevice && abstractDevice.processes.length > 0) {
                this.deviceModelsWithParams.push(device);
            }
        }

        if (entry) {
            this.entry = Helper.copyObject(entry);
            this.isProcess = this.entry.step != null;

            if (!this.entry.step) {
                this.entry.step = {} as DiagnosticsStepBean;
            }
            if (!this.entry.sequence) {
                this.entry.sequence = {} as DiagnosticsStepSequenceBean;
            }

            this.selectedDeviceModel = this.getDeviceModel(this.entry.step.deviceId);
            this.selectedSequence = this.entry.sequence;

        } else {
            this.entry = {} as Entry;
            if (!this.entry.step) {
                this.entry.step = {} as DiagnosticsStepBean;
            }
            if (!this.entry.sequence) {
                this.entry.sequence = {} as DiagnosticsStepSequenceBean;
            }

            this.isProcess = true;
            this.onDeviceChange(null);
        }

        this.base.show(entry == null ? 'Create New Step' : 'Edit Step');
    }

    onDeviceChange(value) {
        if (this.getSelectedAbstractDeviceBean() && this.entry.step) {
            this.entry.step.process = this.getSelectedAbstractDeviceBean().processes[0];
        }

        this.onProcessChange(null);
    }

    getSelectedAbstractDeviceBean(): AbstractDeviceBean {
        return this.getAbstractDeviceBean(this.selectedDeviceModel);
    }

    getAbstractDeviceBean(deviceModel: DeviceModelBean): AbstractDeviceBean {
        if (this.abstractDevices && deviceModel) {
            for (const device of this.abstractDevices) {
                if (device.id == deviceModel.deviceTemplate.abstractDevice.id) {
                    return device;
                }
            }
        }
        return null;
    }

    onProcessChange(value) {
        this.entry.step.parameters = {};
        if (this.entry.step && this.entry.step.process) {
            for (const param of this.entry.step.process.parameters) {
                this.entry.step.parameters[param] = {} as ProcessParameterValueBean;
                this.entry.step.parameters[param].type = 'NUMBER';
            }
        }
    }

    isTextType(value, key) {
        return value == 'TEXT';
    }

    isNumberType(value, key) {
        return value == 'NUMBER';
    }

    isDeviceOrPlace(value, key) {
        return value == 'DEVICE' || value == 'DEVICE_PLACE';
    }

    haveParameters() {
        if (!this.entry.step.parameters || !this.entry.step.process) {
            return false;
        }
        if (this.selectedDeviceModel.deviceTemplate.abstractDevice.dispenserType == 'INGREDIENT') {
            return true;
        }
        for (const param of this.entry.step.process.parameters) {
            if (param.length > 0) {
                return true;
            }
        }
        return false;
    }

    save() {
        if (this.isProcess) {
            this.entry.step.deviceId = this.selectedDeviceModel.id;
            this.entry.step.deviceName = this.selectedDeviceModel.deviceName;
            this.entry.sequence = null;
        } else {
            this.entry.sequence = this.selectedSequence;
            this.entry.step = null;
        }

        this.onResult.emit({process: this.entry, origProcess: this.origEntry});
    }

    getDeviceModel(deviceModelId: number) {
        for (const device of this.deviceModels) {
            if (device.id == deviceModelId) {
                return device;
            }
        }
        return null;
    }

    onProcessChecked() {
        this.isProcess = true;
    }

    onSequnceChecked() {
        this.isProcess = false;
    }
}
