import {Component, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../components/base.modal.component';
import {CompanyService} from '../../services/company.service';
import {DataService} from '../../services/data.service';
import {Helper} from '../../common/helper';
import {CompanyBean} from '../../model/model';

@Component({
    selector: 'companies-select-component',
    templateUrl: 'companies.select.component.html'
})

export class CompaniesSelectComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;

    selectedCompany: CompanyBean;
    companies: CompanyBean[] = [];
    compareBeans = Helper.compareBeans;

    constructor(private companyService: CompanyService,
        private dataService: DataService) {

        const companyId = this.dataService.getCompanyId();
        if (companyId == 0) {
            this.companyService.getCompanies().subscribe(companies => {
                this.companies = companies;

                if (this.companies.length > 1) {
                    this.show();
                } else {
                    this.selectedCompany = this.companies[0];
                    this.setUserCompany(this.selectedCompany);
                }
            }
            );
        }
    }

    selectCompany() {
        const companyId = this.dataService.getCompanyId();

        if (this.companies.length > 0) {
            for (const company of this.companies) {
                if (company.id == companyId) {
                    this.selectedCompany = company;
                }
            }
        }
    }

    open() {
        if (this.companies.length == 0) {
            this.companyService.getCompanies().subscribe(companies => {
                this.companies = companies;
            }
            );
        }

        this.show();
        this.selectCompany();
    }

    show() {
        this.base.show('Select Company');
    }

    setUserCompany(company: CompanyBean) {
        const user = this.dataService.getUserInfo();
        user.company = company;
        this.dataService.setUserInfo(user);
    }

    save() {
        this.setUserCompany(this.selectedCompany);
    }

}
