<base-modal-component [confirmButtonHidden]="true" [large]="true"
                      class="base-modal">

    <div class="row">
        <div class="col">
            <button (click)="loadMessages()" [style.float]="'right' " class="btn btn-default m-1"
                    type="button">Reload
            </button>
        </div>
    </div>

    <table-component></table-component>

    <div class="row">
        <div class="col col-md-8">
            <select [(ngModel)]="message.message"
                    autocomplete="off"
                    class="form-control form-control-low selectx"
                    size="1">
                <option *ngFor="let option of userMessages" value="{{option}}">{{option}}</option>
            </select>

        </div>
        <div class="col col-md-4">
            <button (click)="sendSMS()" [disabled]="!message.message" class="btn btn-primary m-1"
                    style="float: right; width: 200px"
                    type="button">Send SMS
            </button>
            <button (click)="sendToJt(false)" [disabled]="!message.message" class="btn btn-primary m-1"
                    style="float: right; width: 200px"
                    type="button">Show on JT
            </button>
            <button (click)="sendToJt(true)" [disabled]="!message.message" class="btn btn-primary m-1"
                    style="float: right; width: 200px"
                    type="button">Play audio on JT
            </button>
        </div>
    </div>

    <div class="row" style="margin-top: 20px">
        <div class="col col-md-4">
            <label style="width: 100%">
                <input [(ngModel)]="discount" class="form-control form-control-low"
                       placeholder="Discount (0-1)"
                       style="height: 41px;"
                       type="number"/>
            </label>

        </div>
        <div class="col col-md-4">
            <label style="width: 100%">
                <input (bsValueChange)="saveBsDate($event)" bsDatepicker
                       class="form-control" container=""
                       placeholder="Expiration Date"
                       type="text">
            </label>


        </div>

        <div class="col col-md-4">
            <button (click)="sendCoupon()" *ngIf="order"
                    class="btn btn-primary m-1"
                    style="float: right; width: 200px"
                    type="button">Send Coupon
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col col-md-8">
            <label style="width: 100%">
                <input [(ngModel)]="customMessage" class="form-control form-control-low"
                       placeholder="Message"
                       style="height: 41px;"
                       type="text"/>
            </label>

        </div>
    </div>

</base-modal-component>

<ng-template #dateTemplate let-i="index" let-row="row" let-value="value">
    {{ formatDate(value) }}
</ng-template>
