
<label class="col-form-label">Run Steps Sequence:</label>
<div class="row">
    <div class="col-md-7">
        <select hidden disabled
                id="select_sequence"
                data-style="select-btn"
                title="Select Sequence"
                data-size="7"
                [(ngModel)]="selectedDiagnosticsStepSequence">
            <option [ngValue]="seq" selected="selected"
                    *ngFor="let seq of diagnosticsStepSequence">{{seq.description}}
            </option>
        </select>
    </div>
    <div class="col-md-2">
        <input
                type="number"
                class="btn btn-block" [(ngModel)]="repeatCount"
        />
    </div>
    <div class="col-md-3">
        <button type="button" class="btn btn-outline-primary" style="width: 100%"
                (click)="executeSequence()">Execute Sequence
        </button>

    </div>
</div>
<br/>

<label class="col-form-label">Run Step:</label>
<div class="row">
    <div class="col-md-5">
        <select hidden disabled
                id="select_device"
                data-style="select-btn"
                title="Select Device"
                data-size="7"
                [(ngModel)]="selectedDevice"
                (change)="onDeviceChange()">
            <option [ngValue]="device" selected="selected"
                    *ngFor="let device of devices">{{device.name}}
            </option>
        </select>
    </div>
    <div class="col-md-4">
        <select hidden disabled
                id="select_process"
                data-style="select-btn"
                title="Select Process"
                data-size="7"
                [(ngModel)]="selectedProcess">
            <option [ngValue]="process" selected="selected"
                    *ngFor="let process of processes">{{process.name}}
            </option>
        </select>
    </div>
    <div class="col-md-3">
        <button type="button" class="btn btn-outline-primary pull-right" style="margin-bottom: 20px"
                style="width: 100%"
                (click)="execute()">Execute Step
        </button>
    </div>
</div>


<div *ngIf="haveParameters()">
    <label class="col-form-label">Parameters:</label>


    <div class="form-group row"
         *ngIf="selectedDevice && selectedDevice.dispenserType == 'INGREDIENT'">
        <div class="col-md-2">
            <label class="col-form-label">ingredient</label>
        </div>
        <div class="col-md-4">
            <label for="edit_value_sub_id" class="col-form-label">Value
                <select
                        id="edit_value_sub_id"
                        class="form-control form-control-low selectx"
                        [compareWith]="compareBeans"
                        [(ngModel)]="selectedIngredient"
                        size="1">
                    <option [ngValue]="ing.value"
                            *ngFor="let ing of selectedDevice.canDispenseIngredients | dictionaryValues">
                        {{ing.value.name}}
                    </option>
                </select>

            </label>
        </div>
    </div>


    <div class="form-group row" *ngFor="let entry of selectedProcess.parameters | dictionaryValues; let i = index;">
        <div class="col-md-2">
            <label class="col-form-label">{{entry.value}}</label>
        </div>
        <div class="col-md-4" *ngIf="entry.value != ''">
            <label for="edit_type_{{i}}" class="col-form-label">Value Type
                <select
                        id="edit_type_{{i}}"
                        name="edit_type_{{i}}"
                        class="form-control form-control-low selectx"
                        [(ngModel)]="selectedValueType[entry.value]"
                        size="1">
                    <option [ngValue]="'INGREDIENT_QUANTITY'">INGREDIENT_QUANTITY</option>
                    <option [ngValue]="'INGREDIENT_QUANTITY_SECONDS'">INGREDIENT_QUANTITY_SECONDS</option>
                    <option [ngValue]="'NUMBER'">NUMBER</option>
                    <option [ngValue]="'DEVICE'">DEVICE</option>
                    <option [ngValue]="'DISPENSER'">DISPENSER</option>
                    <option [ngValue]="'DEVICE_PLACE'">DEVICE_PLACE</option>
                    <option [ngValue]="'DELIVERY_PLACE'">DELIVERY_PLACE</option>
                </select>
            </label>
        </div>
        <div class="col-md-4" [hidden]="!isNumberType(entry.value)" *ngIf="entry.value != ''">
            <label for="edit_value_{{i}}" class="col-form-label">Value
                <input type="text"
                       [(ngModel)]="selectedNumber[entry.value]"
                       class="form-control form-control-low"
                       id="edit_value_{{i}}"
                       name="edit_value_{{i}}"/>
            </label>
        </div>
        <div class="col-md-4" [hidden]="!isDeviceOrPlace(entry.value)" *ngIf="entry.value != ''">
            <label for="edit_device_{{i}}" class="col-form-label">Value
                <select
                        id="edit_device_{{i}}"
                        name="edit_device_{{i}}"
                        class="form-control form-control-low selectx"
                        [(ngModel)]="selectedDeviceOrPlace[entry.value]"
                        size="1">
                    <option [ngValue]="device" *ngFor="let device of devices">{{device.name}}</option>
                </select>
            </label>
        </div>
    </div>
</div>

<br/> <br/>
<textarea disabled class="form-control" rows="30">{{log}}</textarea>
<br/>

<button type="button" class="btn btn-outline-primary pull-right"
        (click)="clearLog()">Clear log
</button>
<br/>
<br/>