import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AnalyticsBean, DeviceMessageBean, EntityNameBean} from '../../model/model';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {Helper} from '../../common/helper';
import {OperatorsService} from '../../services/operators.service';
import {KioskService} from '../../services/kiosk.service';
import {LineChartData} from '../../common/line.chart';
import {Location} from '@angular/common';
import * as moment from 'moment';
import {Moment} from 'moment';

@Component({
    templateUrl: 'home.component.html'
})
export class HomeComponent implements AfterViewInit {

    earningsYesterday = 0;

    public lineChartOptions: any = {
        responsive: true,
        animation: {
            duration: 0 // general animation time
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        legend: {
            display: true,
            labels: {
                fontSize: 12,
                padding: 10
            }
        }
    };

    public ordersChart: LineChartData;
    public errorsChart: LineChartData;
    public earningsChart: LineChartData;

    public monthlyOrdersChart: LineChartData;
    public monthlyErrorsChart: LineChartData;
    public monthlyEarningsChart: LineChartData;

    public todayOrdersChart: LineChartData;
    public todayErrorsChart: LineChartData;
    public todayEarningsChart: LineChartData;

    messages: DeviceMessageBean[];
    ordersYesterday = 0;
    errorsYesterday = 0;
    earningsToday = 0;
    ordersToday = 0;
    errorsToday = 0;
    allKiosks: EntityNameBean = {id: 0} as EntityNameBean;

    analytics: AnalyticsBean[];

    kiosks: EntityNameBean[] = [];
    selectedKiosk: EntityNameBean = {id: 0} as EntityNameBean;

    constructor(private dashboardService: DashboardService,
        private operatorsService: OperatorsService,
        private kioskService: KioskService,
        @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location) {
    }
    selectedKioskId: number;

    compareBeans = Helper.compareBeans;
    totalEarningsThisWeek: number;
    totalEarningsLastWeek: number;
    totalOrdersThisWeek: number;
    totalErrorsThisWeek: number;
    totalOrdersLastWeek: number;
    totalErrorsLastWeek: number;

    totalEarningsThisMonth: number;
    totalEarningsLastMonth: number;
    totalOrdersThisMonth: number;
    totalErrorsThisMonth: number;
    totalOrdersLastMonth: number;
    totalErrorsLastMonth: number;

    todaysAnalytics: AnalyticsBean[];
    lastWeekAnalytics: AnalyticsBean[];

    startMoment: Moment;
    endMoment: Moment;

    static daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    ngAfterViewInit() {
        this.startMoment = moment().subtract(1, 'month').startOf('month');
        this.endMoment = moment().endOf('day');

        Helper.selectPicker('select_kiosk');

        $('#from_date').datetimepicker({
            icons: Helper.faIcons,
            date: new Date()
        }).on('dp.change', (e) => {

            this.endMoment = moment(e.date._d).endOf('day');
            this.startMoment = moment(e.date._d).subtract(1, 'month').startOf('month');

            this.getDashboard();
        });

        this.route.queryParams.subscribe(params => {
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;
            this.loadKiosks();
        });
    }

    openOrdersByDayChartModal() {
        this.router.navigate(['/analytics']);
    }

    openEarningsByDayChartModal() {
        this.router.navigate(['/analytics']);
    }

    openErrors() {
        this.router.navigate(['/analytics']);
    }

    loadKiosks() {
        this.kioskService.getActiveKiosksNames(true).subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks: EntityNameBean[]) {

        this.kiosks = kiosks;
        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKioskId) {
            this.selectedKiosk = this.allKiosks;
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.id == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }

        console.log(this.selectedKiosk);

        Helper.selectPicker('select_kiosk');
        this.getDashboard();
    }

    private getDashboard() {

        this.dashboardService.getAnalytics([this.selectedKiosk], this.startMoment.unix() * 1000,
            this.endMoment.unix() * 1000, 'daily').subscribe(response => {
                if (response && response.success) {
                    this.analytics = response.value;
                    this.updateCharts();
                }
            });

        this.requestTodayData();
    }

    private requestTodayData() {

        const start = moment(this.endMoment).startOf('day');
        const end = moment(this.endMoment).endOf('day');

        this.dashboardService.getAnalytics([this.selectedKiosk], start.unix() * 1000, end.unix() * 1000, 'hourly').subscribe(response => {
            if (response && response.success) {
                this.todaysAnalytics = response.value;
                console.log(this.todaysAnalytics);
                this.updateTodayChart();
            }
        });

        start.subtract(7, 'd');
        end.subtract(7, 'd');

        this.dashboardService.getAnalytics([this.selectedKiosk], start.unix() * 1000, end.unix() * 1000, 'hourly').subscribe(response => {
            if (response && response.success) {
                this.lastWeekAnalytics = response.value;
                this.updateTodayChart();
            }
        });
    }

    onKioskChange() {
        this.getDashboard();
        this.updateLocation();
    }

    updateLocation() {
        let url = '/home';
        if (this.selectedKiosk != this.allKiosks && this.selectedKiosk.id) {
            url += ('?kiosk=' + this.selectedKiosk.id);
        }

        this.location.replaceState(url);
    }

    private updateTodayChart() {

        if (!this.todaysAnalytics || !this.lastWeekAnalytics) {
            return;
        }

        this.todayOrdersChart = new LineChartData(['Last', 'Today']);
        this.todayErrorsChart = new LineChartData(['Last', 'Today']);
        this.todayEarningsChart = new LineChartData(['Last', 'Today']);

        this.ordersToday = 0;
        this.earningsToday = 0;
        this.errorsToday = 0;

        this.ordersYesterday = 0;
        this.earningsYesterday = 0;
        this.errorsYesterday = 0;

        for (let i = 0; i < this.lastWeekAnalytics.length; i++) {

            const date = moment(this.lastWeekAnalytics[i].ts).tz('America/Los_Angeles');

            this.todayEarningsChart.labels.push(date.format('hh A'));
            this.todayOrdersChart.labels.push(date.format('hh A'));
            this.todayErrorsChart.labels.push(date.format('hh A'));

            if (i < this.todaysAnalytics.length) {

                // let errorsThisDay = 0;
                // for (const key in this.todaysAnalytics[i].errorsComputed) {
                //     errorsThisDay += this.todaysAnalytics[i].errorsComputed[key];
                // }
                this.ordersToday += this.todaysAnalytics[i].ordersTotal;
                this.earningsToday += this.todaysAnalytics[i].revenueTotal;
                this.errorsToday += this.todaysAnalytics[i].maintenanceTotal; // errorsThisDay;

                this.todayEarningsChart.data[1].data.push(this.earningsToday);
                this.todayOrdersChart.data[1].data.push(this.ordersToday);
                this.todayErrorsChart.data[1].data.push(this.errorsToday);
            }

            // let errorsLastDay = 0;
            // for (const key in this.lastWeekAnalytics[i].errorsComputed) {
            //     errorsLastDay += this.lastWeekAnalytics[i].errorsComputed[key];
            // }

            this.ordersYesterday += this.lastWeekAnalytics[i].ordersTotal;
            this.earningsYesterday += this.lastWeekAnalytics[i].revenueTotal;
            this.errorsYesterday += this.lastWeekAnalytics[i].maintenanceTotal; // errorsLastDay;

            this.todayEarningsChart.data[0].data.push(this.earningsYesterday);
            this.todayOrdersChart.data[0].data.push(this.ordersYesterday);
            this.todayErrorsChart.data[0].data.push(this.errorsYesterday);
        }

        this.todayOrdersChart.data[0].label = 'Last ' + this.endMoment.format('ddd') + ' ' + this.ordersYesterday.toFixed();
        this.todayOrdersChart.data[1].label = 'Today ' + this.ordersToday.toFixed();
        this.todayEarningsChart.data[0].label = 'Last ' + this.endMoment.format('ddd') + ' $' + this.earningsYesterday.toFixed();
        this.todayEarningsChart.data[1].label = 'Today $' + this.earningsToday;
        this.todayErrorsChart.data[0].label = 'Last ' + this.endMoment.format('ddd') + ' ' + this.errorsYesterday.toFixed();
        this.todayErrorsChart.data[1].label = 'Today ' + this.errorsToday.toFixed();
    }

    private updateCharts() {

        if (!this.analytics) {
            return;
        }

        this.messages = [];
        // if (this.dashboard.deviceMessages) for (const message of this.dashboard.deviceMessages) {
        //     if (message.date) this.messages.push(message);
        // }
        // this.layout.navbarComponent.setMessages(this.messages);

        /*
        WEEKLY
         */

        this.totalEarningsThisWeek = this.totalEarningsLastWeek = 0;
        this.totalOrdersThisWeek = this.totalOrdersLastWeek = 0;
        this.totalErrorsThisWeek = this.totalErrorsLastWeek = 0;

        this.ordersChart = new LineChartData(['Last Week', 'This Week']);
        this.errorsChart = new LineChartData(['Last Week', 'This Week']);
        this.earningsChart = new LineChartData(['Last Week', 'This Week']);

        for (let i = this.analytics.length - this.endMoment.day() - 1; i < this.analytics.length - this.endMoment.day() - 1 + 7; i++) {

            const date = moment(this.analytics[i - 7].ts).tz('America/Los_Angeles');
            const daysInWeek = 7;

            this.earningsChart.labels.push(date.format('ddd'));
            this.ordersChart.labels.push(date.format('ddd'));
            this.errorsChart.labels.push(date.format('ddd'));

            if (i < this.analytics.length) {
                this.totalEarningsThisWeek += this.analytics[i].revenueTotal;
                this.totalOrdersThisWeek += this.analytics[i].ordersTotal;
                // let errorsThisDay = 0;
                // for (const key in this.analytics[i].errorsComputed) {
                //     errorsThisDay += this.analytics[i].errorsComputed[key];
                // }
                this.totalErrorsThisWeek += this.analytics[i].maintenanceTotal; // errorsThisDay;
                this.earningsChart.data[1].data.push(this.totalEarningsThisWeek);
                this.ordersChart.data[1].data.push(this.totalOrdersThisWeek);
                this.errorsChart.data[1].data.push(this.totalErrorsThisWeek);
            }

            this.totalEarningsLastWeek += this.analytics[i - daysInWeek].revenueTotal;
            this.totalOrdersLastWeek += this.analytics[i - daysInWeek].ordersTotal;
            // let errorsLastDay = 0;
            // for (const key in this.analytics[i - daysInWeek].errorsComputed) {
            //     errorsLastDay += this.analytics[i - daysInWeek].errorsComputed[key];
            // }
            this.totalErrorsLastWeek += this.analytics[i - daysInWeek].maintenanceTotal; // errorsLastDay;

            this.earningsChart.data[0].data.push(this.totalEarningsLastWeek);
            this.ordersChart.data[0].data.push(this.totalOrdersLastWeek);
            this.errorsChart.data[0].data.push(this.totalErrorsLastWeek);
        }

        this.ordersChart.data[0].label = 'Last Week ' + this.totalOrdersLastWeek.toFixed();
        this.ordersChart.data[1].label = 'This Week ' + this.totalOrdersThisWeek.toFixed();
        this.earningsChart.data[0].label = 'Last Week $' + this.totalEarningsLastWeek.toFixed();
        this.earningsChart.data[1].label = 'This Week $' + this.totalEarningsThisWeek.toFixed();
        this.errorsChart.data[0].label = 'Last Week ' + this.totalErrorsLastWeek.toFixed();
        this.errorsChart.data[1].label = 'This Week ' + this.totalErrorsThisWeek.toFixed();

        /*
        MONTHLY
         */

        this.monthlyOrdersChart = new LineChartData(['Last Month', 'This Month']);
        this.monthlyErrorsChart = new LineChartData(['Last Month', 'This Month']);
        this.monthlyEarningsChart = new LineChartData(['Last Month', 'This Month']);

        this.totalEarningsThisMonth = this.totalEarningsLastMonth = 0;
        this.totalOrdersThisMonth = this.totalOrdersLastMonth = 0;
        this.totalErrorsThisMonth = this.totalErrorsLastMonth = 0;

        const daysInMonth = HomeComponent.daysInMonth(this.endMoment.month(), this.endMoment.year());
        console.log(this.endMoment.month() + ' ' + this.endMoment.year() + ' ' + daysInMonth);

        for (let i = this.analytics.length - this.endMoment.date(); i < this.analytics.length - this.endMoment.date() + daysInMonth; i++) {
            if (i - daysInMonth >= 0) {
                const date = new Date(this.analytics[i - daysInMonth].ts);
                date.setHours(date.getHours() - 7);
                date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

                this.monthlyEarningsChart.labels.push(date.getDate());
                this.monthlyOrdersChart.labels.push(date.getDate());
                this.monthlyErrorsChart.labels.push(date.getDate());

                if (i < this.analytics.length) {
                    this.totalEarningsThisMonth += this.analytics[i].revenueTotal;
                    this.totalOrdersThisMonth += this.analytics[i].ordersTotal;
                    // let errorsThisDay = 0;
                    // for (const key in this.analytics[i].errorsComputed) {
                    //     errorsThisDay += this.analytics[i].errorsComputed[key];
                    // }
                    this.totalErrorsThisMonth += this.analytics[i].maintenanceTotal; // errorsThisDay;

                    this.monthlyEarningsChart.data[1].data.push(this.totalEarningsThisMonth);
                    this.monthlyOrdersChart.data[1].data.push(this.totalOrdersThisMonth);
                    this.monthlyErrorsChart.data[1].data.push(this.totalErrorsThisMonth);
                }

                this.totalEarningsLastMonth += this.analytics[i - daysInMonth].revenueTotal;
                this.totalOrdersLastMonth += this.analytics[i - daysInMonth].ordersTotal;
                // let errorsLastDay = 0;
                // for (const key in this.analytics[i - daysInMonth].errorsComputed) {
                //     errorsLastDay += this.analytics[i - daysInMonth].errorsComputed[key];
                // }
                this.totalErrorsLastMonth += this.analytics[i - daysInMonth].maintenanceTotal; // errorsLastDay;

                this.monthlyEarningsChart.data[0].data.push(this.totalEarningsLastMonth);
                this.monthlyOrdersChart.data[0].data.push(this.totalOrdersLastMonth);
                this.monthlyErrorsChart.data[0].data.push(this.totalErrorsLastMonth);
            }
        }

        this.monthlyOrdersChart.data[0].label = 'Last Month ' + this.totalOrdersLastMonth.toFixed();
        this.monthlyOrdersChart.data[1].label = 'This Month ' + this.totalOrdersThisMonth.toFixed();
        this.monthlyEarningsChart.data[0].label = 'Last Month $' + this.totalEarningsLastMonth.toFixed();
        this.monthlyEarningsChart.data[1].label = 'This Month $' + this.totalEarningsThisMonth.toFixed();
        this.monthlyErrorsChart.data[0].label = 'Last Month ' + this.totalErrorsLastMonth.toFixed();
        this.monthlyErrorsChart.data[1].label = 'This Month ' + this.totalErrorsThisMonth.toFixed();

    }

}
