<label class="uploader" ondragover="return false;"
       [class.loaded]="loaded"
       [style.height]="height + 'px'"
       [style.outlineColor]="dragging ? activeColor : baseColor"
       (dragenter)="handleDragEnter()"
       (dragleave)="handleDragLeave()"
       (drop)="handleDrop($event)">

    <i class="icon icon-upload"
       [style.color]="dragging
            ? ((imageSrc && imageSrc.length > 0) ? overlayColor : activeColor)
            : ((imageSrc && imageSrc.length > 0) ? overlayColor : baseColor)"></i>

    <img
            [src]="imageSrc"
            (load)="handleImageLoad()"
            [class.loaded]="imageLoaded"/>

    <input type="file" name="file" accept="image/*"
           (change)="handleInputChange($event)">

</label>
<div class="progress" [hidden]="!uploading">
    <div #progress class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
         style="width: 0;">
    </div>
</div>
