import {publishReplay, refCount} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {
    AnalyticsBean,
    ChartOrdersCountByRecipeBean,
    DashboardBean,
    DashboardDynamicsBean,
    EntityNameBean,
    OrderErrorsStatsBean,
    OrdersCountBean,
    ResponseBean,
    ResponseItemBean,
    StatusBean
} from '../model/model';

@Injectable()
export class DashboardService extends BaseRequestService {

    private operationsDynamic: Observable<any>;
    private dashboard: Observable<any>[] = [];
    private status: Observable<any>;
    private businessOps: Observable<any>[] = [];

    getDashboard(kioskId: number): Observable<ResponseItemBean<DashboardBean>> {
        if (!this.dashboard[kioskId]) {
            const p = {};
            if (kioskId != 0) {
                p['kioskId'] = kioskId;
            }
            this.dashboard[kioskId] = this.get('admin/dashboard', p).pipe(
                publishReplay(1),
                refCount());
        }
        return this.dashboard[kioskId];
    }

    getAnalytics(kiosks: EntityNameBean[], startTime: number, endTime: number, period: string)
        : Observable<ResponseItemBean<AnalyticsBean[]>> {
        const p = {};
        if (startTime != null) {
            p['startTime'] = startTime;
        }
        if (endTime != null) {
            p['endTime'] = endTime;
        }
        if (period != null) {
            p['period'] = period;
        }

        let url = 'admin/analytics?';

        if (kiosks && kiosks.length > 0) {
            for (const kiosk of kiosks) {
                if (kiosk.id != 0) {
                    url += ('kioskId=' + kiosk.id + '&');
                }
            }
        }
        url = url.replace(/&\s*$/, '');
        return this.get(url, p);
    }

    getOrders(operatorId?: number, kioskId?: number, categoryId?: number, recipeId?: number): Observable<ResponseItemBean<{
        [key: number]: {[key: number]: OrdersCountBean[]}
    }>> {
        const p = {};
        if (operatorId != null) {
            p['operatorId'] = operatorId;
        }
        if (kioskId != null) {
            p['kioskId'] = kioskId;
        }
        if (categoryId != null) {
            p['categoryId'] = categoryId;
        }
        if (recipeId != null) {
            p['recipeId'] = recipeId;
        }

        return this.get('admin/dashboard/orders', p);
    }

    getRecipes(kioskId?: number, startTime?: number, endTime?: number): Observable<ResponseItemBean<ChartOrdersCountByRecipeBean[]>> {
        const p = {};
        if (kioskId != null) {
            p['kioskId'] = kioskId;
        }
        if (startTime != null) {
            p['startTime'] = startTime;
        }
        if (endTime != null) {
            p['endTime'] = endTime;
        }
        return this.get('admin/dashboard/recipes', p);
    }

    getOperationsDynamic(): Observable<ResponseItemBean<DashboardDynamicsBean[]>> {
        if (!this.operationsDynamic) {
            this.operationsDynamic = this.get('admin/dashboard/operations/dynamic').pipe(
                publishReplay(1),
                refCount());
        }
        return this.operationsDynamic;
    }

    getStatus(production: boolean = false): Observable<ResponseItemBean<StatusBean>> {
        if (!this.status) {
            const p = {};
            p['production'] = production;
            this.status = this.get('admin/dashboard/status', p).pipe(
                publishReplay(1),
                refCount());
        }
        return this.status;
    }

    getErrorStats(kioskId: number): Observable<ResponseItemBean<OrderErrorsStatsBean[]>> {
        return this.get('admin/dashboard/kiosk/' + kioskId + '/orders/error_stats');
    }

    getMaintenanceChartsCSV(kioskId: number, startTime: number, endTime: number): Observable<Blob> {
        const p = {};

        if (kioskId != 0) {
            p['kioskId'] = kioskId;
        }
        if (startTime != null) {
            p['startTime'] = startTime;
        }
        if (endTime != null) {
            p['endTime'] = endTime;
        }

        return this.blob('admin/analytics/maintenance_csv', p);
    }

    clear() {
        this.dashboard = null;
        this.operationsDynamic = null;
    }

    clearAnalyticsCache(operationalSelected: boolean, consumerSelected: boolean, financialSelected: boolean, startTime: number): Observable<ResponseBean> {
        const p = {};

        if (startTime != null) {
            p['start_date'] = startTime;
        }

        if (operationalSelected) {
            p['operational'] = true;
        }
        if (consumerSelected) {
            p['consumer'] = consumerSelected;
        }
        if (financialSelected) {
            p['financial'] = financialSelected;
        }
        return this.get('admin/analytics/clear_cache', p);
    }

    rebuildAnalyticsCache(): Observable<ResponseBean> {
        return this.get('admin/analytics/rebuild_cache');
    }
}
