<div class="animated fadeIn">
    <div class="col-md-12">
        <button class="pull-right btn-link" style="cursor:pointer;" (click)="editKiosk()">Edit Kiosk</button>
        <tabset>
            <tab heading='View' (selectTab)="onLiveTabSelected($event)" [disabled]="!kiosk.id">
                <kiosk-view-component></kiosk-view-component>
            </tab>
            <tab heading='Charts' (selectTab)="onStatsTabSelected($event)" [disabled]="!kiosk.id">
                <kiosk-stats-component></kiosk-stats-component>
            </tab>
            <tab heading='Sensors' (selectTab)="onSensorsTabSelected($event)" [disabled]="!kiosk.id">
                <kiosk-sensors-component></kiosk-sensors-component>
            </tab>
            <tab heading='Steps History' (selectTab)="onStepsHistoryTabSelected($event)" [disabled]="!kiosk.id">
                <kiosk-history-component></kiosk-history-component>
            </tab>
            <tab heading='Presentation' (selectTab)="onPresentationTabSelected($event)" [disabled]="!kiosk.id">
                <kiosk-presentation-component></kiosk-presentation-component>
            </tab>
        </tabset>
        <br/>
        <br/>
    </div>
</div>
