import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import * as moment from 'moment-timezone';
import {MessageToUserBean, OrderBean, SMSBean} from '../../model/model';
import {OrderService} from '../../services/order.service';
import {DataService} from '../../services/data.service';
import {KioskManageService} from '../../services/kiosk-manage.service';
import {AppComponent} from '../../app.component';
import {BaseModalComponent} from '../../components/base.modal.component';
import {TableComponent} from '../../components/table.component';
import {Column} from '../../common/column';

@Component({
    selector: 'app-sms-modal-component',
    templateUrl: 'sms.modal.component.html'
})

export class SmsModalComponent {
    order: OrderBean = {} as OrderBean;
    sms: SMSBean[];
    kioskId: number;
    userMessages: string[];
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    discount: number;
    expirationDate: number;
    customMessage: string = 'Use coupon code: {code} for {%} off your next purchase, expires {expiry}';

    fields: Column[] = [
        new Column('date', 'Date').date(),
        new Column('text', 'Text'),
        new Column('status', 'Status'),
    ];
    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    message: MessageToUserBean = {} as MessageToUserBean;

    constructor(private orderService: OrderService,
        private dataService: DataService,
        private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(title: string, order: OrderBean, kioskId: number) {
        this.order = order;
        this.sms = null;
        this.kioskId = kioskId;
        this.base.show(title);

        this.message.userId = this.order.userId;
        this.message.orderId = this.order.id;

        this.loadUserMessages();
        this.loadMessages();
    }

    formatDate(ts: number) {
        return ts ? moment(ts).tz('America/Los_Angeles').format('MM/DD/YY hh:mm A') : '-';
    }

    sendSMS() {

        this.orderService.sendSMS(this.message).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Message sent.');
                this.message.message = null;
                this.loadMessages();

            } else {
                this.app.showError('Request failed: ' + response.errorDescription);
            }
        }, error => {
            this.app.showError('Request failed: ' + error);
        });
    }

    loadMessages() {

        const endDate = moment().unix() * 1000;
        const startDate = moment.unix(this.order.date / 1000).subtract(3, 'day').unix() * 1000;

        this.orderService.getSMS(this.order.userId, null, startDate, endDate).subscribe(response => {
            if (response.success) {
                this.sms = response.value;
                this.table.setData(this.sms, this.fields);
            }
        });
    }

    sendToJt(voice: boolean) {
        this.orderService.sendMessageToJumbotron(this.kioskId, this.message, voice).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Message sent.');
                this.message.message = null;
                this.loadMessages();

            } else {
                this.app.showError('Request failed: ' + response.errorDescription);
            }
        }, error => {
            this.app.showError('Request failed: ' + error);
        });
    }

    private loadUserMessages() {
        if (this.userMessages) {
            return;
        }

        this.kioskManageService.getUserMessages(this.kioskId).subscribe(response => {
            if (response.success) {
                this.userMessages = response.value;
            }
        });
    }

    sendCoupon() {
        if (!this.discount || this.discount <= 0 || this.discount > 1) {
            this.app.showError('Invalid discount value');
            return;
        }
        this.orderService.sendCoupon(this.order.userId, this.kioskId, this.discount, this.expirationDate, this.customMessage).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Coupon sent.');
                this.loadMessages();

            } else {
                this.app.showError('Request failed: ' + response.errorDescription);
            }
        }, error => {
            this.app.showError('Request failed: ' + error);
        });
    }

    saveBsDate($event: Date) {
        this.expirationDate = moment($event.getTime()).unix() * 1000;
    }
}
