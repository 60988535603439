<div class="animated fadeIn">
    <div class="col-md-12">
        <div class="card">
            <div class="card-content">
                <table-component (onAdd)="onAdd()"
                                 (onEdit)="onEdit($event)"
                                 (onRemove)="onRemove($event)"></table-component>
            </div>
        </div>
    </div>
</div>
<mealplan-edit-component></mealplan-edit-component>
