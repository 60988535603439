import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {KioskModelService} from '../../services/kiosk-model.service';
import {Router} from '@angular/router';
import {KioskTemplateService} from '../../services/kiosk-template.service';
import {Helper} from '../../common/helper';
import {KioskModelBean, KioskTemplateBean} from '../../model/model';

@Component({
    templateUrl: 'kiosk-models.component.html',
})

export class KioskModelsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    fields: Column[] = [
        new Column('id', 'Id'),
        new Column('name', 'Name'),
        new Column('kioskTemplate', 'Template').data(this, this.formatKioskTemplate),
        new Column('devices', 'Devices').data(this, this.formatDevices)
    ];
    kioskTemplates: KioskTemplateBean[];

    constructor(private kioskTemplateService: KioskTemplateService,
        private kioskModelService: KioskModelService,
        private router: Router,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadKioskTemplates();
    }

    loadKioskTemplates() {
        this.kioskTemplateService.getKioskTemplates().subscribe(kioskTemplates => {
            this.kioskTemplates = kioskTemplates.list;
            this.loadKioskModels();
        });
    }

    loadKioskModels() {
        this.kioskModelService.getKioskModels().subscribe(kioskModels => {
            this.table.setData(kioskModels.list, this.fields);
        });
    }

    formatKioskTemplate(model: KioskModelBean) {
        if (this.kioskTemplates && model.kioskTemplate) {
            for (const kioskTemplate of this.kioskTemplates) {
                if (kioskTemplate.id == model.kioskTemplate.id) {
                    return kioskTemplate.name;
                }
            }
        }
        return null;
    }

    formatDevices(model: KioskModelBean) {
        const counts = [];
        for (const d of model.devices) {
            if (!counts[d.deviceTemplate.name]) {
                counts[d.deviceTemplate.name] = 0;
            }
            counts[d.deviceTemplate.name]++;
        }

        return Helper.objToString(counts);
    }

    onEdit(items: KioskModelBean[]) {
        if (items && items.length > 0) {

            this.router.navigate(['/kiosk-model-details/'], {
                queryParams: {
                    modelId: items[0].id,
                }
            });
        }
        // this.modal.open(items[0], this.templates, this.devices, "Edit " + items[0].name);
    }

    onRemove(items) {
        for (const kioskModel of items) {

            this.kioskModelService.deleteKioskModel(kioskModel).subscribe(response => {
                if (response.success) {
                    if (kioskModel == items.pop()) {
                        this.loadKioskModels();
                    }

                } else {
                    this.app.showError('Can\'t delete Kiosk Model');
                }
            }, error => {
                this.app.showError('Can\'t delete Kiosk Model');
            });
        }
    }

    onAdd() {
        // this.modal.open(null, this.templates, this.devices, "New Kiosk Model");

        this.router.navigate(['/kiosk-model-details/'], {});
    }
}
