<base-modal-component class="base-modal" (onConfirm)="save()">

    <div class="form-group" *ngIf="order">
        <label for="edit_note" class="col-md-2 control-label">Note: </label>
        <div class="col-md-10 pr0">
            <input type="text"
                   [(ngModel)]="order.note"
                   class="form-control"
                   id="edit_note"/>
        </div>
    </div>
    <br/><br/>

    <div class="card grayish">
        <div class="card-content" *ngIf="order">
            <div class="va-top"><b>Location:</b> {{order.kioskName}}</div>
            <div class="va-top" *ngIf="isSuperAdmin()"><b>Customer:</b> {{order.userName}}</div>
            <div class="va-top"><b>Recipe:</b> {{order.recipeName}}</div>
            <div class="va-top"><b>Client Type:</b> {{order.clientType}}</div>
            <div class="va-top"><b>Payment Type:</b> {{order.paymentType}}</div>
            <div class="va-top"><b>Ordered at:</b>
                {{order.date | los_angeles: 'MM/dd/yyyy hh:mm a'}}
            </div>
            <div class="va-top"><b>Requested Delivery Time:</b>
                {{order.requestedDeliveryTime ? (order.requestedDeliveryTime | los_angeles: 'MM/dd/yyyy hh:mm a') : 'ASAP'}}
            </div>
            <div class="va-top"><b>Expected Waiting Time:</b>
                {{order.expectedWaitingTime ? (order.expectedWaitingTime | los_angeles: 'MM/dd/yyyy hh:mm a') : 'unknown'}}
            </div>
            <div class="va-top"><b>Actual Delivery Time:</b>
                {{order.deliveryTime | los_angeles: 'MM/dd/yyyy hh:mm a'}}
            </div>

            <div class="va-top"><b>Status:</b> {{order['stateCopy']}}</div>
            <div class="va-top"><b>Volume (oz):</b> {{order.volume}}</div>
            <div class="va-top"><b>Refunded:</b> {{order.refunded ? 'Yes' : 'No'}}</div>
            <div class="va-top" *ngIf="order.storageDevice">
                <b>Storage:</b> {{order.storageDevice + ' [' + order.storageLocation + ']'}}</div>

            <div *ngFor="let progress of order.progress" style="margin-top: 10px">
                <div><b>{{progress.progressType}}:</b> {{progress.progressStatus}}</div>
                <div>{{progress.startTime | los_angeles: 'hh:mm a'}} - {{progress.endTime |
                    los_angeles: 'hh:mm a'}}
                </div>
                <div *ngFor="let status of progress.ingredientStatus">
                    {{status.ingredientName}},
                    {{status.dispensedWeight | number : '1.0-2'}}g /
                    <ng-container
                            *ngIf="status.recipeDesiredWeight != 0">{{status.recipeDesiredWeight | number : '1.0-2'}}g
                    </ng-container>
                    ({{status.desiredWeight | number : '1.0-2'}}g),
                    {{status.dispensedAtTime | los_angeles: 'hh:mm:ss a'}},
                </div>
            </div>


            <div *ngIf="order.customized" style="margin-top: 10px">
                <b>Customized ingredients</b>
                <ng-container *ngFor="let ing of order.ingredients">
                    <div *ngIf="ing.desiredQuantity != ing.normalQuantity[order.size]">
                        {{ing.name}}: {{ing.desiredQuantity | number : '1.0-2'}}g (normal
                        {{ing.normalQuantity[order.size] | number : '1.0-2'}}g)
                    </div>
                </ng-container>
            </div>

        </div>
    </div>

    <div class="form-group" *ngIf="order">
        <button type="button" class="btn btn-outline-primary" *ngIf="isSuperAdmin()"
                (click)="refund()">Refund{{order.state}}
        </button>
        <button type="button" class="btn btn-outline-primary ml5"
                *ngIf="(isSuperAdmin() || isDeveloper()) && order['stateCopy'] == 'READY'"
                (click)="pickup()">Pickup
        </button>
    </div>

    <br/><br/>


</base-modal-component>
