import {KioskDefines} from './kiosk.defines';
import {DeviceModelViewBean} from '../model/custom-model';
import {DeviceView} from './device.view';

const resourcesPath = '../assets/img/presentation/';
const fixY = 50;

export class RobotView extends DeviceView {

    private hands: fabric.Group;
    private hand2: fabric.Image;
    private hand2Fix: fabric.Image;
    private robotX: number;
    private robotY: number;
    private canvas: fabric.Canvas;

    private angle = 0;
    private a = 150;
    private b = 150;

    constructor(place: [number, number], canvas: fabric.Canvas, callback?: (objects: fabric.Object[]) => any) {

        super();
        this.robotX = place[0];
        this.robotY = place[1];
        this.canvas = canvas;

        fabric.Image.fromURL(resourcesPath + 'Robot_Hand1.png', hand1 => {
            hand1.set({
                left: this.robotX + 62,
                top: this.robotY + fixY,
                originX: 'center',
                originY: 'bottom',
                centeredRotation: false,
                hasControls: false,
                evented: false
            });

            fabric.Image.fromURL(resourcesPath + 'Robot_Hand2.png', hand2 => {
                hand2.set({
                    left: this.robotX + 62,
                    top: this.robotY - 142 + fixY,
                    originX: 'left',
                    originY: 'center',
                    centeredRotation: false,
                    angle: -30,
                    hasControls: false,
                    evented: false
                });

                this.hand2 = hand2;

                fabric.Image.fromURL(resourcesPath + 'Robot_Hand2_Fix.png', hand2Fix => {
                    hand2Fix.set({
                        left: this.robotX + 62,
                        top: this.robotY - 142 + fixY,
                        originX: 'center',
                        originY: 'center',
                        centeredRotation: false,
                        angle: -30,
                        hasControls: false,
                        evented: false
                    });
                    this.hand2Fix = hand2Fix;

                    this.hands = new fabric.Group([hand1, hand2, hand2Fix], {
                        left: this.robotX,
                        top: this.robotY + fixY,
                        originX: 'left',
                        originY: 'bottom',
                        centeredRotation: false,
                        hasControls: false,
                        evented: false,
                        angle: -30,
                        width: 118,
                        height: 246
                    });

                    fabric.Image.fromURL(resourcesPath + 'Robot_Base.png', base => {
                        base.set({
                            left: this.robotX,
                            top: this.robotY + fixY,
                            hasControls: false,
                            originX: 'center',
                            originY: 'center',
                            evented: false
                        });

                        callback.call(this, [this.hands, this.hand2Fix, base]);
                    });

                });

            });
        });
    }

    wave(duration) {
        this.animateTo(750, 200, duration / 2, () => {
            this.animateTo(730, 200, duration / 8, () => {
                this.animateTo(770, 200, duration / 8, () => {
                    this.animateTo(730, 200, duration / 8, () => {
                        this.animateTo(750, 200, duration / 8, () => {
                        });
                    });
                });
            });
        });
    }

    home(duration) {
        this.animateTo(750, 200, duration / 2);
    }

    animateTo(x, y, duration, onCompleteFunc?) {

        const angles = this.getAngles(x, y);

        this.hands.animate({angle: angles[0]}, {
            duration,
            onChange: this.canvas.renderAll.bind(this.canvas),
            onComplete: onCompleteFunc
        });

        this.hand2.animate({angle: angles[1]}, {
            duration
        });

        this.hand2Fix.animate({angle: angles[1]}, {
            duration
        });
    }

    // public getHandledDevice(): DeviceModelViewBean {
    //     return this.handledDevice;
    // }

    moveTo(x, y) {

        const angles = this.getAngles(x, y);
        this.hands.set('angle', angles[0]);
        this.hand2.set('angle', angles[1]);
        this.hand2Fix.set('angle', angles[1]);
    }

    getAngles(x, y) {
        const dx = x - this.robotX;
        const dy = y - this.robotY;
        const c = Math.sqrt(dx * dx + dy * dy);

        const alpha = Math.acos((this.b * this.b + c * c - this.a * this.a) / (2 * this.b * c)) * (180 / 3.1416);
        const beta = Math.acos((this.a * this.a + c * c - this.b * this.b) / (2 * this.a * c)) * (180 / 3.1416);
        const theta = Math.acos(dx / c) * (180 / 3.1416);

        let aa = 90 - theta - beta;
        let bb = -aa + (alpha - theta);

        if (dy > 0) {
            aa = 180 - aa;
            bb = 180 - bb;
        }
        return [aa, bb];
    }

    pick(device: DeviceModelViewBean, animationDuration) {

        if (!device) return;
        const fixes = KioskDefines.getCoordinateFixes(device, true, false);
        this.animateTo(device.views[0].left + fixes[0], device.views[0].top + fixes[1], animationDuration);
    }

    place(deviceWhat: DeviceModelViewBean, deviceTo: DeviceModelViewBean, animationDuration) {

        const fixes = KioskDefines.getCoordinateFixes(deviceTo, true, false);
        deviceWhat.views[0].animate({
            left: deviceTo.views[0].left + fixes[0],
            top: deviceTo.views[0].top + fixes[1]
        }, {
            duration: animationDuration,
            onChange: () => {
                const fixesRobot = KioskDefines.getCoordinateFixes(deviceWhat, false, false);
                this.moveTo(deviceWhat.views[0].left + fixesRobot[0], deviceWhat.views[0].top + fixesRobot[1]);
                this.canvas.renderAll();
            },
            onComplete: () => {
                if (deviceTo.deviceTemplate && deviceTo.deviceTemplate.abstractDevice.name == 'Blender') {
                    deviceWhat.views[0].sendToBack();
                }
            }
        });
    }

    placeToCoordinate(deviceWhat: DeviceModelViewBean, left: number, top: number, animationDuration) {
        deviceWhat.views[0].animate({
            left,
            top
        }, {
            duration: animationDuration,
            onChange: () => {
                const fixesRobot = KioskDefines.getCoordinateFixes(deviceWhat, false, false);
                this.moveTo(deviceWhat.views[0].left + fixesRobot[0], deviceWhat.views[0].top + fixesRobot[1]);
                this.canvas.renderAll();
            }
        });
    }

    // let fixesPlace = [0, 0];
    // if (toDevice) {
    //     toDevice.handledDevice = whatDevice;
    //     fixesPlace = KioskDefines.getCoordinateFixes(toDevice, true, false);
    // }

    // if (this.robot == undefined) {
    //
    //     if (moveWithoutRobot) {
    //         whatDevice.group.animate({left: toCoordinateX + fixesPlace[0], top: toCoordinateY + fixesPlace[1]}, {
    //             duration: animationDuration,
    //             onChange: function () {
    //                 this.canvas.renderAll();
    //             }
    //         });
    //     }
    //     return;
    // }

    // if (this.handledDevice) {
    //     device.handledDevice = this.handledDevice;
    //
    //     this.handledDevice.views[0].animate({
    //         left: device.views[0].left + fixesPlace[0],
    //         top: device.views[0].top + fixesPlace[1]
    //     }, {
    //         duration: animationDuration,
    //         onChange: () => {
    //             let fixesRobot = KioskDefines.getCoordinateFixes(this.handledDevice, false, false);
    //             this.moveTo(this.handledDevice.views[0].get('left') + fixesRobot[0],
    //             this.handledDevice.views[0].get('top') + fixesRobot[1]);
    //             this.canvas.renderAll();
    //         }
    //     });
    // }
    // };
}
