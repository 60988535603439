import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {CategoryService} from '../../services/category.service';
import {RecipeService} from '../../services/recipe.service';
import {Helper} from '../../common/helper';
import {CategoryBean, RecipeBean} from '../../model/model';
import {CustomRecipesEditComponent} from './custom-recipes.edit.component';
import {CustomRecipeState} from '../../model/custom-model';

@Component({
    templateUrl: 'custom-recipes.component.html',
})

export class CustomRecipesComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(CustomRecipesEditComponent, {static: false}) modal: CustomRecipesEditComponent;

    columns: Column[] = [
        new Column('creationDate', 'Date').date(),
        new Column('title', 'Title'),
        new Column('imageUrl', 'Image').thumbnail(75),
        new Column('rateAverage', 'Rate Average'),
        new Column('ingredients', 'Ingredients').data(this, this.getIngredientsList)
    ];

    categories: CategoryBean[] = [];
    selectedCategory: CategoryBean = {} as CategoryBean;
    selectedCategoryId = 0;
    compareBeans = Helper.compareBeans;
    customRecipeState: CustomRecipeState = 'ALL';

    constructor(private customRecipeService: RecipeService,
        private categoryService: CategoryService,
        private route: ActivatedRoute,
        private location: Location,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadCategories();

        this.route.queryParams.subscribe(params => {
            this.selectedCategoryId = params['category'] != null ? params['category'] : 0;
        });
    }

    public loadCategories() {
        this.categoryService.getCategories().subscribe(categories => {

            this.categories = categories;
            if (this.selectedCategoryId == 0) {
                this.selectedCategory = categories[0];
            } else {
                for (const category of categories) {
                    if (category.id == this.selectedCategoryId) {
                        this.selectedCategory = category;
                    }
                }
            }
            Helper.selectPicker('selectpicker');
            this.loadCustomRecipes();
        });
    }

    loadCustomRecipes() {
        this.table.setData([], this.columns);
    }

    onCategoryChange() {

        this.loadCustomRecipes();
        this.location.replaceState('/custom-recipes?category=' + this.selectedCategory.id);
    }

    onEdit(items: RecipeBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], this.customRecipeState == 'PUBLISHED', 'Edit ' + items[0].title);
        }
    }

    onRemove(items) {
        for (const customRecipe of items) {

            this.customRecipeService.deleteCustomRecipe(customRecipe).subscribe(response => {
                if (response.success) {
                    this.loadCustomRecipes();

                } else {
                    this.app.showError('Can\'t delete Recipe');
                }
            }, error => {
                this.app.showError('Can\'t delete Recipe');
            });
        }
    }

    getData(params) {

        this.customRecipeService.getCustomRecipes(
            this.selectedCategory ? this.selectedCategory.id : 0,
            this.customRecipeState == 'REVIEW',
            this.customRecipeState == 'PUBLISHED',
            params.data.limit, params.data.offset).subscribe(customRecipes => {

                console.log(customRecipes);
                params.success({
                    total: customRecipes.pagesCount * params.data.limit,
                    rows: customRecipes.list
                });
                params.complete();
            });
    }

    reviewChange($event: Event) {
        this.loadCustomRecipes();
    }

    private getIngredientsList(item: RecipeBean) {
        let list: string = '';
        for (let ing of item.ingredients) {
            list += (ing.name + ', ');
        }
        return list;
    }
}
