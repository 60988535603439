<div class="form-horizontal">
    <div class="card-content" *ngIf="kiosk">

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="card grayish">
                    <div class="card-content">
                        <h5>Orders</h5>
                        <ul class="columnList">
                            <li *ngFor="let state of kiosk.orderStateCount | dictionaryValues">
                                <a [routerLink]="['/orders']"
                                   [queryParams]="{location: kiosk.operatorId, kiosk: kiosk.id, states: state.ke}">
                                    {{state.key}}: {{state.value}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="card grayish">
                    <div class="card-content">
                        <h5>Ingredients</h5>
                        <ul class="columnList">
                            <li *ngFor="let state of kiosk.dispenserStateCount | dictionaryValues">
                                <a [routerLink]="['/kiosk-details']"
                                   [queryParams]="{operatorId: kiosk.operatorId, kioskId: kiosk.id, tab: 'ingredients'}">
                                    {{state.key}}: {{state.value}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="card grayish">
                    <div class="card-content">
                        <h5>Devices</h5>
                        <ul class="columnList">
                            <li *ngFor="let state of kiosk.deviceStateCount | dictionaryValues">
                                <a [routerLink]="['/kiosk-details']"
                                   [queryParams]="{operatorId: kiosk.operatorId, kioskId: kiosk.id, tab: 'devices'}">
                                    {{state.key}}: {{state.value}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row p5" *ngIf="!weeklyAnalytics">Loading Report...</div>
        <div class="row" *ngIf="weeklyAnalytics" style=" overflow-x: auto;    white-space: nowrap;">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th class="text-center">Week</th>
                    <th class="text-center">Orders</th>
                    <th class="text-center">Kiosk</th>
                    <th class="text-center">Meal Plan</th>
                    <th class="text-center">IOS</th>
                    <th class="text-center">Android</th>
                    <th class="text-center">Reorder</th>
                    <th class="text-center">Cancel</th>

                    <th class="text-center">User<br/>Maint</th>
                    <th class="text-center">Computed<br/>Maint</th>

                    <th class="text-center">User<br/>Errors</th>
                    <th class="text-center">Computed<br/>Errors</th>
                    <th class="text-center">System<br/>Errors</th>
                    <th class="text-center">Reliability<br/>%</th>
                    <th class="text-center">Uptime<br/>%</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let state of weeklyAnalytics">
                    <td class="text-center">{{state.ts | date: 'MM/dd'}} - {{weekEnd(state.ts) | date: 'MM/dd'}}</td>
                    <td class="text-center">{{state.ordersTotal}}</td>
                    <td class="text-center">{{state.ordersKiosk}}</td>
                    <td class="text-center">{{state.ordersMealPlan}}</td>
                    <td class="text-center">{{state.ordersIOS}}</td>
                    <td class="text-center">{{state.ordersAndroid}}</td>
                    <td class="text-center">{{state.ordersReordered}}</td>
                    <td class="text-center">{{state.ordersCancelled}}</td>

                    <td class="text-center">{{state.maintenanceUserScheduled}}</td>
                    <td class="text-center">{{state.maintenanceUserCritical}}</td>

                    <td class="text-center">{{sum(state.errorsUser)}}</td>
                    <td class="text-center">{{sum(state.errorsComputed)}}</td>
                    <td class="text-center">{{sum(state.systemErrors)}}</td>
                    <td class="text-center">{{((1 - sum(state.errorsComputed) / state.ordersTotal)) | percent:'1.2'}}</td>
                    <td class="text-center">{{((1 - (state.maintenanceUserCritical) / (10 * 60 * 5))) | percent:'1.2'}}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="row">
            <div *ngIf="kiosk.videoStreamId" class="col-lg-6 col-sm-6">
                <iframe allowfullscreen webkitallowfullscreen mozallowfullscreen
                        [src]="url1" frameborder="0" width="720"
                        height="576"></iframe>
            </div>

            <div *ngIf="kiosk.videoStreamId2" class="col-lg-6 col-sm-6">
                <iframe allowfullscreen webkitallowfullscreen mozallowfullscreen
                        [src]="url2" frameborder="0" width="720"
                        height="576"></iframe>
            </div>
        </div>

    </div>
</div>

<kiosk-error-ids-component></kiosk-error-ids-component>
