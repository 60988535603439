<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <fields-list-component [fields]="fields" name="recipes_list_edit"
                           [value]="recipesList"></fields-list-component>

    <ng-template #itemTemplate let-item="item" let-index="index"><span>{{item["value"]["categoryName"]}}
        : {{item["value"]["title"]}}</span></ng-template>

    <div class="row">
        <div class="col-sm-6">
            <div class="panel panel-warning">
                <div class="panel-heading">
                    Available recipes
                </div>
                <!--                <div class="panel-body" dnd-sortable-container [dropZones]="['boxers-zone']"-->
                <!--                     [sortableData]="recipes">-->
                <!--                    <ul class="list-group">-->
                <!--                        <li *ngFor="let item of recipes; let i = index" class="list-group-item" dnd-sortable-->
                <!--                            [sortableIndex]="i">{{item.categoryName}} - {{item.title}}-->
                <!--                        </li>-->
                <!--                    </ul>-->
                <!--                </div>-->

                <div class="panel-body">

                    <bs-sortable
                            [(ngModel)]="recipes"
                            [itemTemplate]="itemTemplate"
                            itemClass="list-group-item"
                            itemActiveClass="list-group-item"
                            placeholderItem="Loading Recipes..."
                            placeholderClass="placeholderStyle"
                            wrapperClass="list-group">
                    </bs-sortable>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="panel panel-success">
                <div class="panel-heading">
                    Selected Recipes
                </div>
                <!--                <div class="panel-body" dnd-sortable-container [dropZones]="['boxers-zone']"-->
                <!--                     [sortableData]="selectedRecipes">-->
                <!--                    <ul class="list-group">-->
                <!--                        <li *ngFor="let item of selectedRecipes; let i = index" class="list-group-item" dnd-sortable-->
                <!--                            [sortableIndex]="i">{{item.categoryName}} - {{item.title}}-->
                <!--                        </li>-->
                <!--                    </ul>-->
                <!--                </div>-->

                <div class="panel-body">
                    <bs-sortable
                            [(ngModel)]="selectedRecipes"
                            [itemTemplate]="itemTemplate"
                            itemClass="list-group-item"
                            itemActiveClass="list-group-item"
                            placeholderItem="Drop recipes here"
                            placeholderClass="callout sortable placeholder"
                            wrapperClass="list-group">
                    </bs-sortable>
                </div>
            </div>
        </div>
    </div>


</base-modal-component>
