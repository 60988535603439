<div class="animated fadeIn">
    <div class="col-md-12">

        <div *ngIf="docs">

            <div class="row">
                <div class="col-md-6">
                    <textarea style="width: 100%; height: 100vh;" [(ngModel)]="docs.introduction"></textarea>

                    <br/>
                    <br/>
                    <button type="button" class="btn btn-outline-primary pull-right"
                            (click)="save()">Save
                    </button>

                </div>
                <div class="col-md-6">

                    <markdown ngPreserveWhitespaces [data]="docs.introduction"></markdown>
                </div>

            </div>


        </div>
    </div>
</div>
