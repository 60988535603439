<div class="animated fadeIn">

    <div class="col-md-12">

        <div class="form-horizontal" *ngIf="!tabMode">
            <div class="form-group">
                <label class="col-md-2 control-label" for="select_kiosk">Location</label>
                <div class="col-md-10">
                    <select (change)="onKioskChange()"
                            [(ngModel)]="selectedKiosk"
                            [compareWith]="compareBeans" [disabled]="kiosks.length==0"
                            class="form-control"
                            data-size="7"
                            data-style="select-btn"
                            id="select_kiosk"
                            title="Select Location">
                        <option *ngFor="let kiosk of kiosks" [ngValue]="kiosk">{{kiosk.name}}
                        </option>
                    </select>
                </div>
            </div>

        </div>

        <div class="form-horizontal">
            <div class="form-group">
                <label class="col-md-2 control-label" for="date_range">Date Range</label>
                <div class="col-md-10">
                    <div class="row">
                        <input (bsValueChange)="loadEvents()" [(ngModel)]="selectedRange"
                               class="form-control"
                               id="date_range" bsDaterangepicker type="text"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="form-horizontal">
            <div class="form-group">
                <label class="col-md-2 control-label">Actions</label>
                <div class="col-md-10">
                    <button type="button" class="btn btn-outline-primary" [disabled]="!serviceEvents"
                            (click)="downloadMaintenance()">Download Data
                    </button>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-content">
                <tabset>
                    <tab heading="Data">

                        <div class="card">
                            <div class="card-content">
                                <table-component (onEdit)="onEdit($event)" (onAdd)="onEdit(null)"></table-component>
                                <service-events-edit-component></service-events-edit-component>
                            </div>
                        </div>


                    </tab>
                    <tab heading="Analytics">
                        <service-events-analytics-component></service-events-analytics-component>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>

