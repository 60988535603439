<div class="animated fadeIn" [hidden]="!blacklist">

    <table-component (onAdd)="onAdd()"
                     (onEdit)="onEdit($event)"
                     (onRemove)="onRemove($event)"></table-component>

    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
    <br/>
</div>

<kiosk-blacklist-edit-component></kiosk-blacklist-edit-component>
