import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {KioskBean} from '../../../model/model';
import * as moment from 'moment-timezone';
import {Helper} from '../../../common/helper';

@Component({
    selector: 'kiosk-logs-component',
    templateUrl: 'kiosk-logs.component.html'
})
export class KioskLogsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    fields: Column[] = [
        new Column('date', 'Date').date(),
        new Column('state', 'State'),
        new Column('trigger', 'Trigger'),
        new Column('message', 'Message'),
    ];

    // private params: any;
    private query: string;

    selectedRange = [
        moment().startOf('day').subtract(1, 'day').toDate(),
        moment().endOf('day').toDate()
    ];
    private kioskId: number;
    private operatorId: number;

    constructor(private kioskService: KioskService) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;
        this.operatorId = operatorId;
        this.getData(null);
    }

    onDateRangeChanged(range) {
        this.getData(null);
    }

    getData(query: string) {
        this.query = query;

        if (!this.kioskId) {
            return;
        }

        let start = Helper.startOf(this.selectedRange[0]);
        let end = Helper.endOf(this.selectedRange[1]);

        this.kioskService.getStateHistory(this.kioskId, this.query, start, end).subscribe(response => {

            if (response.success) {
                this.table.setData(response.value, this.fields);
            } else {
            }
        }, error => {
        });
    }
}
