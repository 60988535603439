<tabset>
    <tab heading="Tensor Flow">
        <button type="button" class="btn btn-outline-primary"
                (click)="startTensorFlowTraining()">Start Training
        </button>&nbsp;
        <button type="button" class="btn btn-outline-primary"
                (click)="stopTensorFlowTraining()">Stop Training
        </button>

        <div class="pull-right">Progress: {{tensorFlowProgress}}%</div>

        <br/>
        <br/>
        <div style="font-family:'Lucida Console', monospace; font-size: 10px"
             [innerHTML]="tensorFlowLog"></div>
    </tab>
</tabset>