export const environment = {
    production: true,
    SERVER_URL: 'https://sixddev.appspot.com/v1/',

    FIREBASE_CONFIG: {
        apiKey: 'AIzaSyCB9FGuZeIoOZ0DuD3ZCA7Hk_vsqBYC8Vk',
        authDomain: 'sixddev.firebaseapp.com',
        databaseURL: 'https://sixddev.firebaseio.com',
        projectId: 'sixddev',
        storageBucket: 'sixddev.appspot.com',
        messagingSenderId: '700643271637'
    },

    FILE_STORAGE_UPLOAD_URL: 'https://www.googleapis.com/upload/storage/v1/b/6dbytes_images_dev/o?uploadType=media&name=',
    FILE_STORAGE_URL: 'https://storage.googleapis.com/6dbytes_images_dev/',
    VISION_SERVER: 'https://visiondev.6dbytes.com/api/'
};
