export class AppConstants {
    public static TYPE_LIQUID = 'Liquid';
    public static TYPE_SOLID = 'Solid';
    public static TYPE_POWDER = 'Powder';
    public static TYPE_BULK_POWDER = 'BulkPowder';
    public static TYPE_FINE_POWDER = 'FinePowder';
    public static TYPE_TOPPING = 'Topping';
    public static TYPE_DRESSING = 'Dressing';
}

export type UserType = 'USER' | 'CUSTOMER' | 'ADMIN';
