import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {AnalyticsBean, EntityNameBean} from '../../model/model';
import {Helper} from '../../common/helper';
import * as moment from 'moment-timezone';
import {KioskService} from '../../services/kiosk.service';
import {LineChartData} from '../../common/line.chart';
import {RecipesComponent} from '../recipes/recipes.component';
import {ServiceEventsComponent} from './service-events.component';

@Component({
    selector: 'service-events-analytics-component',
    templateUrl: 'service-events-analytics.component.html',
})

export class ServiceEventsAnalyticsComponent implements AfterViewInit {

    lineChartOptions = LineChartData.lineChartOptions;
    stackedChartOptions = LineChartData.stackedChartOptions;

    range = 'daily';
    compareBeans = Helper.compareBeans;

    analytics: AnalyticsBean[];

    eventsCountChart: LineChartData;
    eventsDurationChart: LineChartData;
    eventsCountByCategoryChart: LineChartData;
    eventsDurationByCategoryChart: LineChartData;
    eventsDurationBySubComponentTrendChart: LineChartData;
    eventsDurationByCategoryTrendChart: LineChartData;
    eventsCountByCategoryTrendChart: LineChartData;

    colorsArray = [LineChartData.colorRed, LineChartData.colorGreen];
    totalEventsCount: number;
    totalEventsDuration: number;
    isShown: boolean;

    selectedKiosks: EntityNameBean[];
    selectedCategory: string;

    constructor(private kioskService: KioskService,
        @Inject(forwardRef(() => ServiceEventsComponent)) private parent: ServiceEventsComponent) {
    }

    ngAfterViewInit(): void {
    }

    rangeChange($event: Event) {
        if (this.isShown) {
            this.getAnalytics();
        }
    }

    private getAnalytics() {

        let start = Helper.startOf(this.parent.selectedRange[0]);
        let end = Helper.endOf(this.parent.selectedRange[1]);

        this.kioskService.getServiceEventCount(this.selectedKiosks, start, end, this.range, this.selectedCategory == 'all' ? null : this.selectedCategory).subscribe(response => {

            this.totalEventsCount = 0;
            if (response && response.success) {
                this.eventsCountChart = new LineChartData(['Count of Service Events']);

                for (let ts in response.value) {
                    this.totalEventsCount += response.value[ts];
                    this.eventsCountChart.data[0].data.push(response.value[ts]);
                    const label = moment(ts).tz('America/Los_Angeles').format(this.range == 'hourly' ? 'MM/DD h A' : 'MM/DD');
                    this.eventsCountChart.labels.push(label);
                }
            }
        }, error => {
            console.log(error);
        });

        this.kioskService.getServiceEventDuration(this.selectedKiosks, start, end, this.range, this.selectedCategory == 'all' ? null : this.selectedCategory).subscribe(response => {

            this.totalEventsDuration = 0;
            if (response && response.success) {
                this.eventsDurationChart = new LineChartData(['Sum of Service Duration (minutes)']);

                for (let ts in response.value) {
                    this.totalEventsDuration += response.value[ts];
                    this.eventsDurationChart.data[0].data.push(response.value[ts]);
                    const label = moment(ts).tz('America/Los_Angeles').format(this.range == 'hourly' ? 'MM/DD h A' : 'MM/DD');
                    this.eventsDurationChart.labels.push(label);
                }
            }
        }, error => {
            console.log(error);
        });

        this.kioskService.getServiceEventsCountByCategory(this.selectedKiosks, start, end, this.range, this.selectedCategory == 'all' ? null : this.selectedCategory).subscribe(response => {

            if (response && response.success) {
                this.eventsCountByCategoryChart = new LineChartData(['Top Service Events by Category']);

                for (let key in response.value) {
                    this.eventsCountByCategoryChart.data[0].data.push(response.value[key]);
                    this.eventsCountByCategoryChart.labels.push(key);
                }
            }
        }, error => {
            console.log(error);
        });

        this.kioskService.getServiceEventsDurationByCategory(this.selectedKiosks, start, end, this.range,
            this.selectedCategory == 'all' ? null : this.selectedCategory).subscribe(response => {

                if (response && response.success) {
                    this.eventsDurationByCategoryChart = new LineChartData(['Top Service Duration by Category (minutes)']);

                    for (let key in response.value) {
                        this.eventsDurationByCategoryChart.data[0].data.push(response.value[key]);
                        this.eventsDurationByCategoryChart.labels.push(key);
                    }
                }
            }, error => {
                console.log(error);
            });

        this.kioskService.getServiceEventsCountByCategoryTrend(this.selectedKiosks, start, end, this.range).subscribe(response => {

            if (response && response.success) {

                const labels = ServiceEventsAnalyticsComponent.getLabels(response.value);
                this.eventsCountByCategoryTrendChart = new LineChartData(labels);
                this.fillTrendChart(response.value, this.eventsCountByCategoryTrendChart);
            }
        }, error => {
            console.log(error);
        });

        this.kioskService.getServiceEventsDurationByCategoryTrend(this.selectedKiosks, start, end, this.range).subscribe(response => {

            if (response && response.success) {

                const labels = ServiceEventsAnalyticsComponent.getLabels(response.value);
                this.eventsDurationByCategoryTrendChart = new LineChartData(labels);
                this.fillTrendChart(response.value, this.eventsDurationByCategoryTrendChart);
            }
        }, error => {
            console.log(error);
        });

        this.kioskService.getServiceEventsDurationBySubComponentTrend(this.selectedKiosks, start, end, this.range,
            this.selectedCategory == 'all' ? null : this.selectedCategory).subscribe(response => {

                if (response && response.success) {

                    const labels = ServiceEventsAnalyticsComponent.getLabels(response.value);
                    this.eventsDurationBySubComponentTrendChart = new LineChartData(labels);
                    this.fillTrendChart(response.value, this.eventsDurationBySubComponentTrendChart);

                    console.log(this.eventsDurationBySubComponentTrendChart)
                }
            }, error => {
                console.log(error);
            });
    }

    private static getLabels(value: {[p: number]: {[p: string]: number}}) {
        let categories = new Map();
        for (let ts in value) {
            for (let key in value[ts]) {
                categories.get(key);
                categories.set(key, value[ts][key] + (categories.get(key) ? categories.get(key) : 0));
            }
        }

        categories = new Map([...categories.entries()].sort((a, b) => b[1] - a[1]));

        let labels = [];
        categories.forEach((value, key) => {
            if (value > 0) {
                labels.push(key);
            }
        });
        return labels.slice(0, 20);
    }

    private fillTrendChart(map: {[p: number]: {[p: string]: number}}, chart: LineChartData) {

        for (let ts in map) {

            const label = moment(ts).tz('America/Los_Angeles').format(this.range == 'hourly' ? 'MM/DD h A' : 'MM/DD');
            for (let line of chart.data) {

                let value = 0;
                for (let key in map[ts]) {
                    if (line.label == key) {
                        value = map[ts][key];
                    }
                }
                line.data.push(value);
            }
            chart.labels.push(label);
        }
    }

    onShow(kiosk: EntityNameBean, category: string) {
        this.selectedCategory = category;
        if (kiosk) {
            this.selectedKiosks = [kiosk];
        }
        this.getAnalytics();
        this.isShown = true;
    }

}
