import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {CategoryService} from '../../services/category.service';
import {CategoriesComponent} from './categories.component';
import {AppComponent} from '../../app.component';
import {StepsListComponent} from '../../components/steps-list.component';
import {CategoryBean, RecipeStepBean} from '../../model/model';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {CategoryNotificationEmailsComponent} from './category-notification-emails/category-notification-emails.component';

@Component({
    selector: 'categories-edit-component',
    templateUrl: 'categories.edit.component.html'
})

export class CategoriesEditComponent {

    category: CategoryBean = {} as CategoryBean;
    steps: RecipeStepBean[];
    selectedStep: RecipeStepBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    @ViewChild(CategoryNotificationEmailsComponent, {static: false}) changeUsersComponent: CategoryNotificationEmailsComponent;

    @ViewChild('steps1', {static: false}) steps1: StepsListComponent;
    @ViewChild('steps2', {static: false}) steps2: StepsListComponent;
    @ViewChild('steps3', {static: false}) steps3: StepsListComponent;

    fields: Field[] = [
        new Field('id', 'Category ID').disable(),
        new Field('name', 'Name').require(),
        new Field('description', 'Description', FieldType.TEXT_AREA),
        new Field('imageUrl', 'Image', FieldType.FILE),
        new Field('noCleaning', 'No Cleaning', FieldType.CHECKBOX),
        new Field('forceCategorySteps', 'Force Category Steps', FieldType.CHECKBOX),
    ];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private categoryService: CategoryService,
        @Inject(forwardRef(() => CategoriesComponent)) private parent: CategoriesComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {

    }

    open(category: CategoryBean, title: string) {
        this.category = (category ? Helper.copyObject(category) : {} as CategoryBean);
        this.base.show(title);

        this.steps1.init();
        this.steps2.init();
        this.steps3.init();

        this.onChangeTabSelected(null);
    }

    save() {

        if (this.category.stepPhases) {
            let ind;
            ind = 0;
            for (const step of this.category.stepPhases.preparationSteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            for (const step of this.category.stepPhases.cookingSteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            for (const step of this.category.stepPhases.servingSteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            for (const step of this.category.stepPhases.deliverySteps) {
                step.priorityNum = ind++;
                delete step['orderReady'];
            }
            ind = 0;
            if (this.category.stepPhases.cleaningSteps) {
                for (const step of this.category.stepPhases.cleaningSteps) {
                    step.priorityNum = ind++;
                    delete step['orderReady'];
                }
            }
            ind = 0;
            if (this.category.stepPhases.wrapupSteps) {
                for (const step of this.category.stepPhases.wrapupSteps) {
                    step.priorityNum = ind++;
                    delete step['orderReady'];
                }
            }
        }

        console.log(this.category);

        if (!this.category.id) {

            this.categoryService.addCategory(this.category).subscribe(response => {

                if (response.success) {
                    this.parent.loadCategories();
                } else {
                    this.app.showError('Can\'t create category');
                }
            }, error => {
                this.app.showError('Can\'t create category');
            });

        } else {

            this.categoryService.updateCategory(this.category).subscribe(response => {

                if (response.success) {
                    this.parent.loadCategories();
                } else {
                    this.app.showError('Can\'t update category');
                }
            }, error => {
                this.app.showError('Can\'t update category');
            });
        }
    }

    editDependsOn(ev) {
        this.parent.openDependsOnComponent(ev.recipeSteps, ev.step);
    }

    createStep(ev) {
        this.steps = ev.recipeSteps;
        this.parent.openCreateStepModal(ev.step);

        this.selectedStep = ev.step;
    }

    addProcess(ev, steps) {
        const step: RecipeStepBean = this.selectedStep ? Helper.copyObject(this.selectedStep) : {} as RecipeStepBean;
        step.step = ev.process;

        console.log(ev);
        console.log(step);

        if (!ev.origProcess) {
            if (this.steps.length > 0) {
                step.dependsOn = [this.steps.length - 1];
            }
            this.steps.push(step);

        } else {

            let index = -1;

            for (let i = 0; i < this.steps.length; i++) {
                if (this.steps[i].step && this.steps[i].step == ev.origProcess) {

                    step.dependsOn = this.steps[i].dependsOn;
                    index = i;
                    break;
                }
            }

            if (index >= 0) {
                this.steps.splice(index, 1);
                this.steps.splice(index, 0, step);
            }
        }
    }

    onChangeTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.changeUsersComponent.onShow(this.category);
        }
    }
}
