<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()" (onShown)="onShown()" (onHidden)="onHidden()">
    <fields-list-component #fieldsListComponent [fields]="fields" name="coupons_edit"
                           [value]="coupon"></fields-list-component>

    <div class="form-horizontal" *ngIf="coupon.workingHours">
        <div class="card-content">

            <div class="form-group" *ngFor="let i of [0, 1, 2, 3, 4, 5, 6]">

                <label for="wh1{{i}}" class="col-md-3 control-label">{{days[coupon.workingHours[i].dayOfWeek]}}</label>
                <div class="col-md-9 custom-slider">

                    <ng5-slider [(value)]="coupon.workingHours[i].hourFrom" [manualRefresh]="manualRefresh"
                                [(highValue)]="coupon.workingHours[i].hourTo" [options]="options"
                                id="wh1{{i}}"></ng5-slider>

                </div>

            </div>
        </div>
    </div>


</base-modal-component>
