<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <tabset #tabset>
        <tab heading='Profile'>
            <fields-list-component #fieldsListComponent [fields]="fields" name="profiles_edit" [value]="userProfile"></fields-list-component>
        </tab>
        <tab heading='Credentials'>

            <div class="form-horizontal">
                <div class="card-content">

                    <div class="form-group">
                        <label for="edit_email" class="col-md-3 control-label">Email <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" [disabled]="userProfile.userId"
                                   [(ngModel)]="userProfile.email"
                                   class="form-control"
                                   id="edit_email"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="edit_password1" class="col-md-3 control-label">Password</label>
                        <div class="col-md-9">
                            <input type="password"
                                   [(ngModel)]="password1"
                                   class="form-control"
                                   id="edit_password1"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="edit_password2" class="col-md-3 control-label">Repeat password</label>
                        <div class="col-md-9">
                            <input type="password"
                                   [(ngModel)]="password2"
                                   class="form-control"
                                   id="edit_password2"/>
                        </div>
                    </div>

                </div>
            </div>

            <div [hidden]="admin">

                <div class="form-horizontal">
                    <div class="card-content">
                        <div class="form-group">
                            <label for="edit_email" class="col-md-3 control-label"></label>

                            <div class="col-md-9">

                                <div *ngFor="let profileType of profileTypes" class="radio">
                                    <input [(ngModel)]="this.user.rights"
                                           [value]="profileType.right"
                                           id="radio_{{profileType.right}}"
                                           name="radio"
                                           type="radio">
                                    <label for="radio_{{profileType.right}}">{{profileType.title}}</label>
                                </div>

                                <br/>


                                <!--                                <div class="radio">-->
                                <!--                                    <input type="radio" name="radio1" id="radio2" value="0"-->
                                <!--                                           (change)="setCustomizeRights($event)"-->
                                <!--                                           [checked]="!isCompanyAdmin()">-->
                                <!--                                    <label for="radio2">Customize rights</label>-->
                                <!--                                </div>-->
                                <!--                                <br/>-->

                                <div *ngFor="let right of rights; let i = index" class="checkbox">

                                    <input
                                            type="checkbox"
                                            [checked]="isRightChecked(right.right)"
                                            (change)="setRightChecked($event, right.right)"
                                            [disabled]="true"
                                            class="custom-control-input" id="rights{{i}}"/>
                                    <label for="rights{{i}}">Manage {{right.title}}</label>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
        <tab heading='Client Config' *ngIf="isRootAdmin">
            <fields-list-component #fieldsListComponentClient [fields]="clientFields" name="profiles_edit" [value]="user"></fields-list-component>

            <button class="btn btn-primary pull-right" (click)="generateSecret()">
                Generate
            </button>
            <br />
            <br />
        </tab>
    </tabset>
</base-modal-component>
