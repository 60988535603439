import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {OperatorsService} from '../../services/operators.service';
import {TableComponent} from '../../components/table.component';
import {OperatorsEditComponent} from './operators.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {OperatorBean, OperatorStatus} from '../../model/model';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {OperatorsQuickViewComponent} from './operators.quickview.component';
import {Helper} from '../../common/helper';

@Component({
    templateUrl: 'operators.component.html',
})

export class OperatorsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(OperatorsEditComponent, {static: false}) operatorsEditComponent: OperatorsEditComponent;
    @ViewChild(OperatorsQuickViewComponent, {static: false}) operatorsViewComponent: OperatorsQuickViewComponent;

    fields: Column[] = [
        new Column('name', 'Name').button(this, this.showKiosks),
        new Column('corporate', 'Corporate').data(this, this.isCorporate),
        new Column('operations', 'Operations').data(this, this.isOperations),
        new Column('status', 'Status'),
        new Column('activeSince', 'Active Since').date(),
    ];
    operators: OperatorBean[];
    compareBeans = Helper.compareBeans;

    // selectedOperators: number[] = [];
    selectedStates: OperatorStatus[] = [];
    defaultStates: OperatorStatus[] = [
        'ACTIVE', 'PROVISIONING', 'WAITING'
    ];

    constructor(private operatorsService: OperatorsService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            if (params['states']) {
                for (const param of params['states'].split(',')) {
                    this.selectedStates.push(param);
                }
            } else {
                this.selectedStates = this.defaultStates;
            }
            this.loadOperators();
        });
    }

    showKiosks(operator: OperatorBean) {
        this.router.navigate(['/kiosks/'], {
            queryParams: {
                location: operator.id,
            }
        });
    }

    onStateChange() {
        this.showOperators();
        this.location.replaceState('/operators?states=' + this.selectedStates);
    }

    isCorporate(operator: OperatorBean) {
        const checked: boolean = !!operator.operationContactEmail && !!operator.operationContactPhone && !!operator.operationsContactName;
        return '<input type="checkbox" disabled ' + (checked ? 'checked' : '') + '/>';
    }

    isOperations(operator: OperatorBean) {
        const checked: boolean = !!operator.corporateAddress && !!operator.corporateContactEmail && !!operator.corporateContactName &&
            !!operator.corporateContactPhone;
        return '<input type="checkbox" disabled ' + (checked ? 'checked' : '') + '/>';
    }

    loadOperators() {
        this.operatorsService.getOperators().subscribe(operators => {
            this.operators = operators;
            // this.selectedOperators = [];
            // for (const operator of operators) {
            //     this.selectedOperators.push(operator.id);
            // }
            this.showOperators();
        });
    }

    onEdit(items: OperatorBean[]) {
        if (items && items.length > 0) {
            this.operatorsEditComponent.open(items[0], 'Edit ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const location of items) {

            this.operatorsService.deleteOperator(location).subscribe(response => {
                if (response.success) {
                    if (location == items.pop()) {
                        this.loadOperators();
                    }

                } else {
                    this.app.showError('Can\'t delete operator');
                }
            }, error => {
                this.app.showError('Can\'t delete operator');
            });
        }
    }

    onAdd() {
        this.operatorsEditComponent.open(null, 'New Operator');
    }

    onClickRow(item: OperatorBean) {
        this.operatorsViewComponent.open(item, item.name);
    }

    private showOperators() {

        const filteredOperators: OperatorBean[] = [];
        for (const operator of this.operators) {

            // for (const id of this.selectedOperators) {
            //     if (operator.id == id) {

            for (const state of this.selectedStates) {
                if (state == operator.status) {
                    filteredOperators.push(operator);
                }
            }

            // break;
            //     }
            // }
        }
        this.table.setData(filteredOperators, this.fields);
        Helper.selectPicker('select_operator', 'select_state');
    }

    editOperator(location: OperatorBean) {
        this.onEdit([location]);
    }

    onOperatorChange() {

        this.showOperators();
    }
}
