<div class="animated fadeIn">
    <div class="col-md-12">

        <tabset #tabset>
            <tab heading="Detect" (selectTab)="onDetectTabSelected($event)">
                <vision-detect-component></vision-detect-component>
            </tab>
            <tab heading="Learn" (selectTab)="onLearnTabSelected($event)">
                <vision-learn-component></vision-learn-component>
            </tab>
            <tab heading="Training Logs" (selectTab)="onLogsTabSelected($event)">
                <vision-log-component></vision-log-component>
            </tab>
        </tabset>
    </div>
</div>
