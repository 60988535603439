import {publishReplay, refCount} from 'rxjs/operators';
import {BaseRequestService} from './base-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomRecipeBean, EntityNameBean, PageBean, RecipeBean, RecipeInfoBean, ResponseBean, ResponseItemBean} from '../model/model';

@Injectable()
export class RecipeService extends BaseRequestService {

    private allRecipes: Observable<any>;
    private allRecipeNames: Observable<any>;
    private recipesInfo: Observable<any>;

    getAllRecipes(limit?: number, offset?: number): Observable<PageBean<RecipeBean>> {
        if (!this.allRecipes) {
            this.allRecipes = this.get('admin/recipe/list', BaseRequestService.buildOffsetParams(limit, offset)).pipe(
                publishReplay(1),
                refCount());
        }
        return this.allRecipes;
    }

    getAllRecipeNames(): Observable<ResponseItemBean<EntityNameBean[]>> {
        if (!this.allRecipeNames) {
            this.allRecipeNames = this.get('admin/recipe/list/names').pipe(
                publishReplay(1),
                refCount());
        }
        return this.allRecipeNames;
    }

    getRecipesInfo(): Observable<ResponseItemBean<RecipeInfoBean[]>> {
        if (!this.recipesInfo) {
            this.recipesInfo = this.get('admin/recipe/list_info').pipe(
                publishReplay(1),
                refCount());
        }
        return this.recipesInfo;
    }

    getRecipes(categoryId: number, limit?: number, offset?: number): Observable<PageBean<RecipeBean>> {
        return this.get('admin/recipe/category/' + categoryId + '/list', BaseRequestService.buildOffsetParams(limit, offset));
    }

    moveRecipe(categoryId: number, src: RecipeBean, dst: RecipeBean): Observable<ResponseBean> {
        return this.get('admin/recipe/category/' + categoryId + '/move_recipe/' + src.id + '/after/' + dst.id);
    }

    addRecipe(recipe: RecipeBean): Observable<ResponseBean> {
        return this.post('admin/recipe/add', recipe);
    }

    deleteRecipe(recipe: RecipeBean): Observable<ResponseBean> {
        return this.del('admin/recipe/' + recipe.id + '/delete');
    }

    updateRecipe(recipe: RecipeBean): Observable<ResponseBean> {
        return this.put('admin/recipe/update', recipe);
    }

    exportRecipes(recipes: RecipeBean[]): Observable<Blob> {
        let url = 'admin/recipe/export?';
        for (const recipe of recipes) {
            url += ('id=' + recipe.id + '&');
        }
        return this.blob(url);
    }

    publishCustomRecipe(recipe: RecipeBean): Observable<ResponseBean> {
        return this.get('admin/recipe/custom/' + recipe.id + '/publish');
    }

    deleteCustomRecipe(recipe: CustomRecipeBean): Observable<ResponseBean> {
        return this.del('admin/recipe/' + recipe.id + '/delete');
    }

    getCustomRecipes(categoryId: number, review: boolean, published: boolean, limit?: number, offset?: number)
        : Observable<PageBean<RecipeBean>> {
        const p = BaseRequestService.buildOffsetParams(limit, offset);
        p['custom'] = true;
        p['review'] = review;
        p['published'] = published;
        return this.get('admin/recipe/category/' + categoryId + '/list', p);
    }

    clear() {
        this.allRecipes = null;
    }

}
