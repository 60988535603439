<base-modal-component class="base-modal" (onConfirm)="save()" [large]="false">

    <div class="form-horizontal">
        <div class="card-content">
            <div class="checkbox" *ngFor="let step of recipeSteps; let i = index">
                <input type="checkbox" class="custom-control-input" id="checkbox_{{i}}"
                       name="checkbox_{{i}}" [checked]="isChecked(step, i)" (change)="setChecked(step, i)"
                />
                <label for="checkbox_{{i}}">{{getStepDescription(step)}}</label>
            </div>
        </div>
    </div>

</base-modal-component>