import {AfterViewInit, Component} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {DeviceBean, ManageKioskDiagnosticStatesBean, SimDeviceProcessBean} from '../../../model/model';
import {KioskManageService} from '../../../services/kiosk-manage.service';

@Component({
    selector: 'kiosk-simulator-component',
    templateUrl: 'kiosk-simulator.component.html'
})
export class KioskSimulatorComponent implements AfterViewInit {

    simDeviceProcesses: SimDeviceProcessBean[] = [];
    devices: DeviceBean[] = [];
    kioskId: number;
    operatorId: number;
    diagnosticsStates: ManageKioskDiagnosticStatesBean;

    constructor(private kioskManageService: KioskManageService,
        private kioskService: KioskService) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;
        this.operatorId = operatorId;

        this.kioskManageService.getDiagnosticStates(this.kioskId).subscribe(response => {
            if (response.success) {
                this.diagnosticsStates = response.value;
            }
        });

        this.getDeviceProcessTime();
    }

    getDeviceProcessTime() {
        this.kioskService.getDeviceProcessTime(this.kioskId, this.operatorId).subscribe(simDeviceProcesses => {
            this.simDeviceProcesses = simDeviceProcesses.value;
            this.kioskService.getDevices(this.kioskId).subscribe(devices => {
                this.devices = devices.list;
            });
        });
    }

    public setSimulatorMode(enabled) {

        this.kioskManageService.setDiagnosticStates(this.kioskId, {simulation: enabled} as ManageKioskDiagnosticStatesBean).subscribe(response => {
            if (response.success) {
                this.diagnosticsStates.simulation = enabled;
            }
        });
    }

    saveTimes() {
        this.kioskService.updateDeviceProcessTime(this.kioskId, this.operatorId, this.simDeviceProcesses).subscribe(response => {
            this.getDeviceProcessTime();
        });
    }

    isSimulator() {
        return this.diagnosticsStates && this.diagnosticsStates.simulation;
    }

    getDeviceName(deviceId: number) {
        for (const device of this.devices) {
            if (device.id == deviceId) {
                return device.name;
            }
        }
        return '';
    }

}
