<div class="animated fadeIn">
    <div class="content">

        <div class="row">

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="select_kiosk">Location</label>
                        <div class="col-md-10">
                            <select (change)="onKioskChange()"
                                    [(ngModel)]="selectedKiosk"
                                    [compareWith]="compareBeans" [disabled]="kiosks.length==0"
                                    class="form-control"
                                    data-size="7"
                                    data-style="select-btn"
                                    id="select_kiosk"
                                    title="Select Location">
                                <option [ngValue]="allKiosks" selected="selected">All Locations</option>
                                <option *ngFor="let kiosk of kiosks" [ngValue]="kiosk"
                                        selected="selected">{{kiosk.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="from_date">Date Range</label>
                        <div class="col-md-10">
                            <input (bsValueChange)="onDateRangeChanged($event)" [(ngModel)]="selectedRange"
                                   class="form-control"
                                   id="from_date" bsDaterangepicker type="text"/>
                        </div>
                    </div>

                </div>
            </div>


            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="select_mode">Frequency</label>
                        <div class="col-md-10">

                            <select (change)="rangeChange(null)"
                                    [(ngModel)]="range"
                                    class="form-control"
                                    data-size="7"
                                    data-style="select-btn"
                                    id="select_mode">
                                <option value="monthly">Monthly</option>
                                <option value="weekly">Weekly</option>
                                <option [selected]="true" value="daily">Daily</option>
                                <option value="hourly">Hourly</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>


        </div>

        <div class="container-fluid">

            <tabset style="margin: 20px;">
                <tab heading="Customer Metrics">
                    <div *ngIf="revenueChart">
                        <h3 class="text-center">Revenue</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="revenueChart.data"
                                    [labels]="revenueChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="revenueChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="ordersChart">
                        <h3 class="text-center">Unit Sales</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="ordersChart.data"
                                    [labels]="ordersChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="ordersChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="recurringLoyalistsChart">
                        <h3 class="text-center">Engagement Recurring and Loyalists</h3>
                        <div [style.height]="'400px'">
                            <canvas [chartType]="'line'" [colors]="recurringLoyalistsChart.colors"
                                    [datasets]="recurringLoyalistsChart.data"
                                    [labels]="recurringLoyalistsChart.labels"
                                    [legend]="true"
                                    [options]="lineChartOptions"
                                    [style.height]="'400px'"
                                    baseChart>
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="uniqueCustomersChart">
                        <h3 class="text-center">Unique Customers</h3>
                        <div [style.height]="'400px'">
                            <canvas [chartType]="'line'" [colors]="uniqueCustomersChart.colors"
                                    [datasets]="uniqueCustomersChart.data"
                                    [labels]="uniqueCustomersChart.labels"
                                    [legend]="true"
                                    [options]="lineChartOptions"
                                    [style.height]="'400px'"
                                    baseChart>
                            </canvas>
                        </div>
                    </div>
                </tab>

                <tab heading="Operational Metrics">


                    <button type="button" class="btn btn-outline-primary right pull-right"
                            (click)="downloadMaintenance()">Download Maintenance Data
                    </button>

                    <div *ngIf="maintenanceChart">
                        <h3 class="text-center">Maintenance Time</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="maintenanceChart.data"
                                    [labels]="maintenanceChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="maintenanceChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="uptimeChart">
                        <h3 class="text-center">Uptime (%)</h3>
                        <div [style.height]="'400px'">
                            <canvas [chartType]="'line'" [colors]="uptimeChart.colors"
                                    [datasets]="uptimeChart.data"
                                    [labels]="uptimeChart.labels"
                                    [legend]="true"
                                    [options]="lineChartOptionsNoZero"
                                    [style.height]="'400px'"
                                    baseChart>
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="orderSuccessChart">
                        <h3 class="text-center">Order Success Rate (%)</h3>
                        <div [style.height]="'400px'">
                            <canvas [chartType]="'line'" [colors]="orderSuccessChart.colors"
                                    [datasets]="orderSuccessChart.data"
                                    [labels]="orderSuccessChart.labels"
                                    [legend]="true"
                                    [options]="lineChartOptionsNoZero"
                                    [style.height]="'400px'"
                                    baseChart>
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="waitTimeChart">
                        <h3 class="text-center">Delivery Time</h3>
                        <div [style.height]="'400px'">
                            <canvas [chartType]="'line'" [colors]="waitTimeChart.colors"
                                    [datasets]="waitTimeChart.data"
                                    [labels]="waitTimeChart.labels"
                                    [legend]="true"
                                    [options]="lineChartOptions"
                                    [style.height]="'400px'"
                                    baseChart>
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="userErrorsChart">
                        <h3 class="text-center">User Initiated Maintenance</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="userErrorsChart.data"
                                    [labels]="userErrorsChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="userErrorsChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="computedErrorsChart">
                        <h3 class="text-center">Errors causing Maintenance</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="computedErrorsChart.data"
                                    [labels]="computedErrorsChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="computedErrorsChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>


                    <div *ngIf="systemErrorsChart">
                        <h3 class="text-center">System Errors</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="systemErrorsChart.data"
                                    [labels]="systemErrorsChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="systemErrorsChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="actualIngredientsChart">
                        <h3 class="text-center">Actual Ingredients</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="actualIngredientsChart.data"
                                    [labels]="actualIngredientsChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="actualIngredientsChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="desiredIngredientsChart">
                        <h3 class="text-center">Desired Ingredients</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="desiredIngredientsChart.data"
                                    [labels]="desiredIngredientsChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="desiredIngredientsChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="cogsPerUnitChart">
                        <h3 class="text-center">COGS per Unit</h3>
                        <div [style.height]="'400px'">
                            <canvas [chartType]="'line'" [colors]="cogsPerUnitChart.colors"
                                    [datasets]="cogsPerUnitChart.data"
                                    [labels]="cogsPerUnitChart.labels"
                                    [legend]="true"
                                    [options]="lineChartOptions"
                                    [style.height]="'400px'"
                                    baseChart>
                            </canvas>
                        </div>
                    </div>

                </tab>

                <tab heading="Other Metrics">
                    <div *ngIf="recipesChart">
                        <h3 class="text-center">Recipes</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="recipesChart.data"
                                    [labels]="recipesChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="recipesChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="customizedChart">
                        <h3 class="text-center">Customized Orders</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="customizedChart.data"
                                    [labels]="customizedChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="customizedChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>

                    <div *ngIf="reviewsChart">
                        <h3 class="text-center">Reviews</h3>
                        <div [style.height]="'400px'">
                            <canvas baseChart [style.height]="'400px'"
                                    [datasets]="reviewsChart.data"
                                    [labels]="reviewsChart.labels"
                                    [options]="lineChartOptions"
                                    [colors]="reviewsChart.colors"
                                    [legend]="true"
                                    [chartType]="'line'">
                            </canvas>
                        </div>
                    </div>
                </tab>

                <tab heading="Delivery Metrics">


                    <ng-container *ngFor="let analytic of analytics">

                        <h4>{{analytic.ts | date}}</h4>

                        <table class="table" *ngIf="!isEmpty(analytic.waitTimeMedianByTotalOrders)">
                            <thead>
                            <tr>
                                <th class="col-md-4">Total Orders</th>
                                <th>Median Wait Time</th>
                                <th>Median Processing Time</th>
                                <th>Median Delivery Time</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let entry of analytic.waitTimeMedianByTotalOrders | dictionaryValues">
                                <th>{{entry.key}}</th>
                                <th>{{analytic.waitTimeMedianByTotalOrders[entry.key] | time}}</th>
                                <th>{{analytic.processingTimeMedianByTotalOrders[entry.key] | time}}</th>
                                <th>{{analytic.customerDeliveryTimeMedianByTotalOrders[entry.key] | time}}</th>
                            </tr>
                            </tbody>
                        </table>

                        <h6 *ngIf="isEmpty(analytic.waitTimeMedianByTotalOrders)">No Data</h6>

                    </ng-container>
                </tab>

                <tab heading="Actions">

                    <div class="row">
                        <div class="form-group">
                            <label class="col-md-2 control-label" for="from_reset_date">Clear Cache From</label>
                            <div class="col-md-3">
                                <input [(ngModel)]="selectedResetFrom"
                                       bsDatepicker
                                       class="form-control" id="from_reset_date" type="text"/>
                            </div>
                        </div>

                    </div>

                    <div class="checkbox">
                        <input
                                [(ngModel)]="operationalSelected"
                                autocomplete="off" id="cb_operational"
                                type="checkbox"/>
                        <label for="cb_operational">Operational</label>
                    </div>

                    <div class="checkbox">
                        <input
                                [(ngModel)]="financialSelected"
                                autocomplete="off" id="cb_financial"
                                type="checkbox"/>
                        <label for="cb_financial">Financial</label>
                    </div>

                    <div class="checkbox">
                        <input
                                [(ngModel)]="consumerSelected"
                                autocomplete="off" id="cb_consumer"
                                type="checkbox"/>
                        <label for="cb_consumer">Consumer</label>
                    </div>


                    <button (click)="clearAnalyticsCache()" class="btn btn-primary" type="button">Clear Analytics Cache
                    </button>&nbsp;

                    <br />
                    <br />
                    <br />
                    <br />

                    <button (click)="rebuildAnalyticsCache()" class="btn btn-primary" type="button">Rebuild Analytics Cache
                    </button>&nbsp;
                </tab>

            </tabset>
        </div>
    </div>

</div>
