import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {CategoryService} from '../../services/category.service';
import {TableComponent} from '../../components/table.component';
import {CategoriesEditComponent} from './categories.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ProcessEditComponent} from '../../components/process.edit.component';
import {DependsOnEditComponent} from '../../components/depends-on.edit.component';
import {KioskDataService} from '../../services/kiosk-data.service';
import {CategoryBean, RecipeStepBean} from '../../model/model';

@Component({
    templateUrl: 'categories.component.html',
})

export class CategoriesComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(CategoriesEditComponent, {static: false}) categoriesEditComponent: CategoriesEditComponent;
    @ViewChild('processEditComponent', {static: false}) processEditComponent: ProcessEditComponent;
    @ViewChild('dependsOnEditComponent', {static: false}) dependsOnEditComponent: DependsOnEditComponent;

    fields: Column[] = [
        new Column('imageUrl', 'Image').thumbnail(150),
        new Column('name', 'Name'),
        new Column('description', 'Description'),
    ];
    steps: RecipeStepBean[];

    constructor(private categoryService: CategoryService,
        private kioskDataService: KioskDataService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadData();
    }

    loadData() {

        this.kioskDataService.getData().subscribe(kioskData => {
            this.loadCategories();
        });
    }

    loadCategories() {
        this.categoryService.getCategories().subscribe(categories => {
            this.table.setData(categories, this.fields);

        }, error => {
            console.log('error');
        });
    }

    onEdit(items: CategoryBean[]) {
        if (items && items.length > 0) {
            this.categoriesEditComponent.open(items[0], 'Edit ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const category of items) {

            this.categoryService.deleteCategory(category).subscribe(response => {
                if (response.success) {
                    if (category == items.pop()) {
                        this.loadCategories();
                    }

                } else {
                    this.app.showError('Can\'t delete category');
                }
            }, error => {
                this.app.showError('Can\'t delete category');
            });
        }
    }

    onAdd() {
        this.categoriesEditComponent.open(null, 'New Category');
    }

    openCreateStepModal(ev) {

        if (ev) {
            this.steps = ev.recipeSteps;
            this.processEditComponent.open(ev.step);
        } else {
            this.processEditComponent.open(null);
        }
    }

    openDependsOnComponent(recipeSteps: RecipeStepBean[], step: RecipeStepBean) {
        this.dependsOnEditComponent.open(recipeSteps, step);
    }

    addProcess(ev) {
        console.log(ev, this.steps)
        this.categoriesEditComponent.addProcess(ev, this.steps);
    }
}
