<div *ngIf="kioskNOC">
    <div class="row {{kioskState=='OPERATIONAL' ? 'greenish' : (kioskState=='MAINTENANCE' ? 'yellowish' : 'redish')}}">

        <div class="col-md-2 p5" style="padding-top: 10px; padding-left: 20px">
            Powered by foodOS™
        </div>

        <div *ngIf="kioskNOC.kiosk_name" class="col-md-8 text-center p5">
            <h4 class="m0">{{kioskNOC.kiosk_name.value}}: {{kioskState}}
                {{kioskState == 'OPERATIONAL' ? (isRunning() ? "(RUNNING)" : "(IDLE)") : ""}} </h4>
        </div>

        <div class="col-md-2">
            <div class="checkbox right">
                <input
                        (change)="setDiagnosticsMode($event.target.checked)"
                        [checked]="kioskState!='OPERATIONAL'"
                        class="custom-control-input"
                        id="diagnostics_enable"
                        type="checkbox"/>
                <label for="diagnostics_enable">Enable Diagnostics</label>
            </div>
        </div>
    </div>

    <div class="row redish" *ngIf="kioskState != 'OPERATIONAL' && kioskNOC.failed_devices_msg.value">
        <h4 class="m5 text-center"> {{kioskNOC.failed_devices_msg.value}}</h4>
    </div>


    <div class="row">
        <div class="col-lg-3 pl5 pr5">

            <h5>Orders</h5>

            <div class="card grayish">
                <div class="card-content">

                    <ul *ngIf="kioskNOC.order_state_count" class="columnList">
                        <li *ngFor="let state of kioskNOC.order_state_count.value | dictionaryValues">
                            <a [routerLink]="['/orders']"
                               [queryParams]="{location: operatorId, kiosk: kioskId, states: state.key}">
                                {{state.key}}: {{state.value}}
                            </a>
                        </li>
                    </ul>

                </div>
            </div>

            <div class="card-content table-responsive" *ngIf="kioskNOC.orders">
                <table class="table table-striped table-compact">
                    <thead>
                    <tr>
                        <th class="col-lg-7">Order</th>
                        <th class="col-lg-5">Details</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr *ngFor="let item of kioskNOC.orders | keyvalue">
                        <ng-container *ngIf="item.value.value as order">
                            <td [style.backgroundColor]="order.warning ? '#eeaebc': 'white'">
                                {{order.recipe}}<br/>
                                {{order.customer_name}}<br/>
                                <br/>
                                <ng-container *ngIf="order.jar_name">
                                    {{order.jar_name ? (order.jar_name + ": " + (order.jar_place ? order.jar_place
                                    : "Home")) : "-"}}
                                </ng-container>
                                <br/>
                                {{getLastOrderStep(order)}}

                                <ng-container *ngIf="order.state=='READY'">
                                    <br/>Pick up at {{order.storage_device}}
                                    [{{order.storage_location}}]
                                    <br/><a (click)="pickup(order.id)">Pick up</a>

                                </ng-container>

                                <ng-container *ngIf="order.state=='CANCELLED' && order.expired && order.discard_date==null">
                                    <br/>DISCARDED
                                    <br/>Pick up at {{order.storage_device}}
                                    [{{order.storage_location}}]
                                    <br/><a (click)="pickup(order.id)">Pick up</a>

                                </ng-container>

                            </td>
                            <td *ngIf="item.value.value as order">
                                Id: {{order.id}}<br/>
                                Ready: {{order.ready_pct}}%<br/>
                                Complete: {{order.complete_pct}}%<br/>
                                <ng-container *ngIf="order.start_date">
                                    Start Time: {{order.start_date | los_angeles: 'MM/dd/yyyy hh:mm:ss a'}}<br/>
                                    Processing Time: {{((now - order.start_date) / 1000) | time}}<br/>
                                </ng-container>
                                <ng-container *ngIf="order.wait_time">
                                    Wait Time: {{order.wait_time / 1000 | time}}<br/>
                                </ng-container>
                                State: {{order.state}}<br/>
                                State Change: {{order.state_change_date | los_angeles: 'hh:mm:ss a'}}<br/>
                                Delivery Time: {{order.delivery_time | los_angeles: 'hh:mm:ss a'}}<br/>
                                <span *ngIf="order.requested_delivery_time" style="background-color: red">
                                Requested Delivery Time: {{order.requested_delivery_time | los_angeles: 'MM/dd/yyyy hh:mm:ss a'}}
                                    <br/>
                            </span>
                            </td>
                        </ng-container>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-lg-9 pl5 pr5">
            <div class="row">

                <div class="col-lg-6 pl5 pr5">
                    <h5>Devices</h5>

                    <div class="card grayish">
                        <div class="card-content">
                            <ul *ngIf="kioskNOC.device_state_count" class="columnList">
                                <li *ngFor="let state of kioskNOC.device_state_count.value | dictionaryValues">
                                    <a [routerLink]="['/kiosk-details']"
                                       [queryParams]="{operatorId: operatorId, kioskId: kioskId, tab: 'devices'}">
                                        {{state.key}}: {{state.value}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div *ngIf="kioskNOC.devices_snapshot">
                        <!--<h6 class="pt5">Active:</h6>-->
                        <div class="card-content table-responsive ">
                            <table class="table table-striped table-compact">
                                <thead>
                                <tr>
                                    <th class="col-lg-5">Name</th>
                                    <th class="col-lg-3">State</th>
                                    <th class="col-lg-4">Details/Error/Invalid</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let device of kioskNOC.devices_snapshot.value.devices">
                                    <tr *ngIf="!device.disabled && device.state=='ACTIVE' && (device.operation_type!='SIMULATION' || device.abstract_name=='Jar') && !device.ingredients"
                                        tooltip="Status: {{device.fuse_status}}">
                                        <td>{{device.name}} {{device.ip}}
                                            <ng-container *ngIf="device.operation_type!='SIMULATION'">
                                                <a (click)="restart(device)">↻</a>
                                            </ng-container>
                                        </td>
                                        <td>{{device.invalid ? 'INVALID' : device.state}} {{device.locked ? ' (locked) '
                                            : ''}}
                                        </td>
                                        <td>
                                            <ng-container *ngIf="device.locked && device.locked_order_id != 0">
                                                #{{device.locked_order_id}}
                                            </ng-container>

                                            <ng-container *ngIf="device.error_code">
                                                {{device.error_code}} {{device.error_msg}}
                                            </ng-container>
                                            <ng-container *ngIf="device.abstract_name=='Jar'">
                                                {{getOrderForJar(device)}}
                                            </ng-container>

                                            {{getRunningStep(device)}}
                                            {{device.robot_safety_mode}}

                                            <ng-container *ngIf="device.invalid">
                                                <a (click)="unlock(device.id)">Unlock</a>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <h6>Out of Service:</h6>
                        <div class="card-content table-responsive ">
                            <table class="table table-striped table-compact">
                                <tbody>
                                <ng-container *ngFor="let device of kioskNOC.devices_snapshot.value.devices">
                                    <tr *ngIf="!device.disabled && device.state!='ACTIVE'">
                                        <td class="col-lg-5">{{device.name}} {{device.ip}} <a (click)="restart(device)">↻</a>
                                        </td>
                                        <td class="col-lg-3"><span
                                                class="redish">{{device.invalid ? 'INVALID' : device.state}}</span>
                                        </td>
                                        <td class="col-lg-4">
                                            {{device.error_code}} {{device.error_msg}}
                                            {{device.robot_safety_mode}}

                                            <ng-container *ngIf="device.invalid">
                                                <a (click)="unlock(device.id)">Unlock</a>
                                            </ng-container>
                                            <ng-container *ngIf="device.error_code==107">
                                                <a (click)="refill(device.id)">Refill</a>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="device.name.startsWith('Blender') && device.state=='FAILED'">
                                                <a (click)="refill(device.id)">Retry</a>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>

                        <h6>Delivery Times</h6>
                        <div class="card-content table-responsive ">
                            <table class="table table-striped table-compact">
                                <tbody>
                                <tr>
                                    <td>Median</td>
                                    <td>Max</td>
                                    <td>Min</td>
                                </tr>
                                <tr>
                                    <td>{{kioskNOC.stats.delivery_time.value.median}}</td>
                                    <td>{{kioskNOC.stats.delivery_time.value.max}}</td>
                                    <td>{{kioskNOC.stats.delivery_time.value.min}}</td>

                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <h6>Uptime</h6>
                        <div class="card-content table-responsive ">
                            <table class="table table-striped table-compact">
                                <tbody>
                                <tr>
                                    <td>Service Availability</td>
                                    <td>Uptime</td>
                                </tr>
                                <tr>
                                    <td>{{kioskNOC.stats.uptime.value.service_availability}}</td>
                                    <td>{{kioskNOC.stats.uptime.value.uptime}}</td>

                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 pl5 pr5">

                    <h5>Ingredients</h5>
                    <div class="card grayish">
                        <div class="card-content">
                            <ul *ngIf=" kioskNOC.dispenser_state_count" class="columnList">
                                <li *ngFor="let state of kioskNOC.dispenser_state_count.value | dictionaryValues">
                                    <a [routerLink]="['/kiosk-details']"
                                       [queryParams]="{operatorId: operatorId, kioskId: kioskId, tab: 'ingredients'}">
                                        {{state.key}}: {{state.value}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="card-content table-responsive" *ngIf="kioskNOC.devices_snapshot">
                        <table class="table table-striped table-compact">
                            <thead>
                            <tr>
                                <th class="col-lg-4">Device</th>
                                <th class="col-lg-1">%</th>
                                <th class="col-lg-1">F</th>
                                <th class="col-lg-4">State</th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let device of kioskNOC.devices_snapshot.value.devices">
                                <ng-container *ngIf="!device.disabled">
                                    <tr *ngFor="let ingredient of device.ingredients"
                                        tooltip="Status: {{device.fuse_status}}">
                                        <td>{{device.name}} - {{ingredient.name}} {{device.ip}}
                                            <ng-container *ngIf="device.operation_type!='SIMULATION'">
                                                <a (click)="restart(device)">↻</a>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <ng-container *ngFor="let pct of [getIngredientPercent(ingredient)]">
                                                <span class="{{pct < 40 ? (pct < 20 ? (pct==0 ? 'red': 'redish') : 'yellowish') : ''}}">
                                                    {{pct | number : '1.0-2'}}%
                                                </span>
                                            </ng-container>
                                            <a (click)="setIngredientQuantity(device, ingredient)">↻</a>
                                        </td>
                                        <td>
                                            {{device.today_errors_count}}
                                        </td>
                                        <td>
                                            {{getLastIngredientStep(device, ingredient)}}
                                            <ng-container *ngIf="device.error_code!=0">
                                                <br/>
                                                {{device.error_msg}}
                                                <ng-container *ngIf="device.error_code==101">
                                                    <a (click)="refill(device.id)">Refill</a>
                                                </ng-container>

                                            </ng-container>
                                            <br/>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!kioskNOC" class="center-block text-center"><br/>Loading NOC...</div>
