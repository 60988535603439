<base-modal-component class="base-modal" (onConfirm)="save()">

    <select style="margin-bottom: 20px"
            class="form-control form-control-low selectx"
            size="1" autocomplete="off" (change)="onTagChanged()"
            [(ngModel)]="selectedTag">
        <option value="">All Tags</option>
        <option value="{{tag}}" *ngFor="let tag of tags">{{tag}}</option>
    </select>


    <div class="row">
        <div class="col-md-2 col-sm-3" *ngFor="let image of filteredImages" (click)="select(image)">
            <img class="img-thumbnail"
                 [ngClass]="(selectedImage==image)?'thumb-img-selected':'thumb-img'"
                 [src]="getThumbnail(image)" alt="" width="128px" height="128px"/>
        </div>
    </div>
</base-modal-component>
