<div class="animated fadeIn">
    <div class="col-md-12">
        <button class="pull-right btn-link" style="cursor:pointer;" (click)="viewKiosk()">View Kiosk</button>
        <tabset [hidden]="!kioskId">

            <tab heading='Description'>
                <tabset>
                    <tab heading='Info' (selectTab)="onDescriptionTabSelected($event)">
                        <kiosk-description-component [adminMode]="isAdmin"></kiosk-description-component>
                    </tab>
                    <tab (selectTab)="onMangersTabSelected($event)" *ngIf="isAdmin" [disabled]="kioskId == 0"
                         heading='Managers'>
                        <kiosk-managers-component></kiosk-managers-component>
                    </tab>
                    <tab (selectTab)="onTestersTabSelected($event)" *ngIf="isAdmin" [disabled]="kioskId == 0"
                         heading='Users'>
                        <kiosk-testers-component></kiosk-testers-component>
                    </tab>
                    <tab (selectTab)="onBlacklistTabSelected($event)" *ngIf="isAdmin" [disabled]="kioskId == 0"
                         heading='Blacklist'>
                        <kiosk-blacklist-component></kiosk-blacklist-component>
                    </tab>
                    <tab (selectTab)="onHoursTabSelected($event)" [disabled]="kioskId == 0" heading='Working Hours'
                         *ngIf="manageKiosksRights() || isBusinessOps()">
                        <kiosk-hours-component></kiosk-hours-component>
                    </tab>
                    <tab (selectTab)="onCouponsTabSelected($event)" [disabled]="kioskId == 0" heading='Coupons'
                         *ngIf="manageKiosksRights() || isBusinessOps()">
                        <kiosk-coupons-component></kiosk-coupons-component>
                    </tab>
                    <tab (selectTab)="onMaintenanceOptionsTabSelected($event)" [disabled]="kioskId == 0"
                         heading='Maintenance Options'
                         *ngIf="manageKiosksRights() || isBusinessOps()">
                        <kiosk-maintenance-options-component></kiosk-maintenance-options-component>
                    </tab>
                    <tab (selectTab)="onRewardsTabSelected($event)" [disabled]="kioskId == 0" heading='Rewards'
                         *ngIf="manageKiosksRights()">
                        <kiosk-rewards-component></kiosk-rewards-component>
                    </tab>
                    <tab (selectTab)="onTabletsTabSelected($event)" *ngIf="manageKiosksRights()" [disabled]="kioskId == 0"
                         heading='Tablets'>
                        <kiosk-tablets-component></kiosk-tablets-component>
                    </tab>
                    <tab (selectTab)="onNotificationEmailsTabSelected($event)" [disabled]="kioskId == 0"
                         heading="Notification Emails">
                        <kiosk-notification-emails-component></kiosk-notification-emails-component>
                    </tab>
                    <tab (selectTab)="onUserMessagesTabSelected($event)" [disabled]="kioskId == 0"
                         heading="User Messages">
                        <user-messages-component></user-messages-component>
                    </tab>
                </tabset>
            </tab>
            <tab (selectTab)="onRecipesListsTabSelected($event)" [disabled]="kioskId == 0" heading='Recipes'>
                <tabset>
                    <tab heading='Menus' (selectTab)="onRecipesListsTabSelected($event)">
                        <kiosk-recipes-lists-component></kiosk-recipes-lists-component>
                    </tab>
                    <tab heading='Recipes' (selectTab)="onRecipesTabSelected($event)">
                        <kiosk-recipes-component></kiosk-recipes-component>
                    </tab>
                    <tab heading='Custom Recipes' (selectTab)="onCustomRecipesTabSelected($event)"
                         *ngIf="manageKiosksRights()">
                        <kiosk-custom-recipes-component></kiosk-custom-recipes-component>
                    </tab>
                    <tab heading='Ingredients' (selectTab)="onIngredientsTabSelected($event)"
                         *ngIf="manageKiosksRights()" [active]="tab=='ingredients'">
                        <kiosk-ingredients-component></kiosk-ingredients-component>
                    </tab>
                </tabset>
            </tab>
            <tab (selectTab)="onDevicesTabSelected($event)" *ngIf="manageKiosksRights()" [disabled]="kioskId == 0"
                 heading='Devices'
                 [active]="tab=='devices'">
                <kiosk-devices-component></kiosk-devices-component>
            </tab>
            <tab (selectTab)="onDiagnosticsTabSelected($event)" [disabled]="kioskId == 0" heading='Diagnostics'
                 *ngIf="manageKiosksRights()">
                <kiosk-diagnostics-component></kiosk-diagnostics-component>
            </tab>
            <tab (selectTab)="onSimulatorTabSelected($event)" [disabled]="kioskId == 0" heading='Simulator'
                 *ngIf="manageKiosksRights()">
                <kiosk-simulator-component></kiosk-simulator-component>
            </tab>
            <tab (selectTab)="onTestsTabSelected($event)" [disabled]="kioskId == 0" heading='Tests'
                 *ngIf="manageKiosksRights()">
                <kiosk-tests-component></kiosk-tests-component>
            </tab>
            <tab (selectTab)="onLogsTabSelected($event)" [disabled]="kioskId == 0" heading='Device Logs'
                 *ngIf="manageKiosksRights()">
                <device-logs-component></device-logs-component>
            </tab>
            <tab (selectTab)="onKioskLogsTabSelected($event)" [disabled]="kioskId == 0" heading='Kiosk Logs'
                 *ngIf="manageKiosksRights()">
                <kiosk-logs-component></kiosk-logs-component>
            </tab>
            <tab (selectTab)="onActionsTabSelected($event)" *ngIf="isAdmin" [disabled]="kioskId == 0" heading='Actions'>
                <kiosk-actions-component></kiosk-actions-component>
            </tab>
        </tabset>
    </div>
</div>
