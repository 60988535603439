import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {OperatorsService} from '../../services/operators.service';
import {OperatorsComponent} from './operators.component';
import {AppComponent} from '../../app.component';
import {HttpClient} from '@angular/common/http';
import {KioskBean, OperatorBean} from '../../model/model';
import {Router} from '@angular/router';
import {KioskService} from '../../services/kiosk.service';

@Component({
    selector: 'operators-quickview-component',
    templateUrl: 'operators.quickview.component.html'
})

export class OperatorsQuickViewComponent {

    location: OperatorBean = {} as OperatorBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('name', 'Name').disable(),
        new Field('corporateAddress', 'Corporate Address').disable(),
        new Field('corporateContactName', 'Corporate Contact').disable(),
        new Field('corporateContactEmail', 'Corporate Contact Email').disable(),
        new Field('corporateContactPhone', 'Corporate Contact Phone').disable(),
        new Field('operationsContactName', 'Operations Contact Name').disable(),
        new Field('operationContactEmail', 'Operation Contact Email').disable(),
        new Field('operationContactPhone', 'Operation Contact Phone').disable(),
        new Field('status', 'Status').disable()
    ];
    kiosks: KioskBean[] = [];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private operatorsService: OperatorsService,
        private kioskService: KioskService,
        private http: HttpClient,
        private router: Router,
        @Inject(forwardRef(() => OperatorsComponent)) private parent: OperatorsComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(location: OperatorBean, title: string) {

        this.location = (location ? Helper.copyObject(location) : {} as OperatorBean);
        this.base.show(title);

        this.kioskService.getKiosks(this.location.id).subscribe(response => {
            this.kiosks = response.list;
        });
    }

    edit() {
        this.parent.editOperator(this.location);
    }

    showLocations(state) {
        if (state == '') {
            state = null;
        }
        this.router.navigate(['/kiosks'], {queryParams: {states: state, location: this.location.id}});
    }

    showLocation(kioskId) {
        this.router.navigate(['/kiosk-details'], {queryParams: {operatorId: this.location.id, kioskId}});
    }

    getKiosksCount(state) {
        let result = 0;
        for (const kiosk of this.kiosks) {
            if (kiosk.state == state) {
                result++;
            }
        }
        return result;
    }
}
