import {Route} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {DashboardLayoutComponent} from '../layouts/dashboard-layout.component';
import {FullscreenLayoutComponent} from '../layouts/fullscreen-layout.component';
import {CompaniesComponent} from './companies/companies.component';
import {CategoriesComponent} from './categories/categories.component';
import {CompaniesEditComponent} from './companies/companies.edit.component';
import {CategoriesEditComponent} from './categories/categories.edit.component';
import {RecipesEditComponent} from './recipes/recipes.edit.component';
import {RecipesComponent} from './recipes/recipes.component';
import {StepsListComponent} from '../components/steps-list.component';
import {DependsOnEditComponent} from '../components/depends-on.edit.component';
import {ProcessEditComponent} from '../components/process.edit.component';
import {IngredientsComponent} from './ingredients/ingredients.component';
import {IngredientsEditComponent} from './ingredients/ingredients.edit.component';
import {OrdersComponent} from './orders/orders.component';
import {OrderDetailsComponent} from './order-details/order-details.component';
import {ReviewsComponent} from './reviews/reviews.component';
import {CustomRecipesComponent} from './custom-recipes/custom-recipes.component';
import {EventLogComponent} from './event-log/eveng-log.component';
import {ProfilesComponent} from './profiles/profiles.component';
import {ProfilesEditComponent} from './profiles/profiles.edit.component';
import {DevicesComponent} from './devices/devices.component';
import {DevicesEditComponent} from './devices/devices.edit.component';
import {MacroStepsComponent} from './macro-steps/macro-steps.component';
import {CanDeactivateMacroStepEdit, MacroStepEditComponent} from './macro-step-edit/macro-step-edit.component';
import {KiosksComponent} from './kiosks/kiosks.component';
import {VisionComponent} from './vision/vision.component';
import {KioskDetailsComponent} from './kiosk-details/kiosk-details.component';
import {KioskDevicesComponent} from './kiosk-details/devices/kiosk-devices.component';
import {KioskDevicesEditComponent} from './kiosk-details/devices/kiosk-devices.edit.component';
import {KioskDiagnosticsComponent} from './kiosk-details/diagnostics/kiosk-diagnostics.component';
import {KioskSimulatorComponent} from './kiosk-details/simulator/kiosk-simulator.component';
import {DeviceTemplatesComponent} from './device-templates/device-templates.component';
import {DeviceTemplatesEditComponent} from './device-templates/device-templates.edit.component';
import {KioskTemplatesEditComponent} from './kiosk-templates/kiosk-templates.edit.component';
import {KioskTemplatesComponent} from './kiosk-templates/kiosk-templates.component';
import {KioskModelsComponent} from './kiosk-models/kiosk-models.component';
import {KioskPresentationComponent} from './kiosk-information/presentation/kiosk-presentation.component';
import {KioskHistoryComponent} from './kiosk-information/history/kiosk-history.component';
import {CanDeactivateKioskModelDetails, KioskModelDetailsComponent} from './kiosk-model-details/kiosk-model-details.component';
import {Rights} from '../common/rights';
import {AuthGuard, RightsGuard} from '../common/auth.guard';
import {DeviceModelEditComponent} from './kiosk-model-details/device-model.edit.component';
import {ModelPresentationComponent} from './kiosk-model-details/presentation/model-presentation.component';
import {DiagnosticsStepSequenceEditComponent} from './kiosk-model-details/diagnostics-steps-sequence.edit.component';
import {DiagnosticsStepEditComponent} from '../components/diagnostics-step.edit.component';
import {TagSelectComponent} from './vision/detect/tag.select.component';
import {VisionLearnComponent} from './vision/learn/vision-learn.component';
import {VisionDetectComponent} from './vision/detect/vision-detect.component';
import {VisionLogComponent} from './vision/log/vision-log.component';
import {DeviceProvisioningEditComponent} from './device-provisioning/device-provisioning.edit.component';
import {DeviceProvisioningComponent} from './device-provisioning/device-provisioning.component';
import {KioskTestsComponent} from './kiosk-details/tests/kiosk-tests.component';
import {DeviceProcessEditComponent} from './devices/device-process.edit.component';
import {KioskTestDetailsComponent} from './kiosk-details/tests/kiosk-test-details.component';
import {RobotCommandEditComponent} from './kiosk-model-details/robot-command.edit.component';
import {KioskTestCycleDetailsComponent} from './kiosk-details/tests/kiosk-test-cycle-details.component';
import {ProgressLoaderComponent} from '../components/progress-loader.component';
import {ValueEditorComponent} from '../components/value-editor.component';
import {DeviceLogsComponent} from './kiosk-details/device-logs/device-logs.component';
import {RecipesListsComponent} from './recipes-lists/recipes-lists.component';
import {RecipesListsEditComponent} from './recipes-lists/recipes-lists.edit.component';
import {KioskActionsComponent} from './kiosk-details/actions/kiosk-actions.component';
import {KioskRecipesListsComponent} from './kiosk-details/description/recipes-lists/kiosk-recipes-lists.component';
import {OperatorsComponent} from './operators/operators.component';
import {OperatorsEditComponent} from './operators/operators.edit.component';
import {KioskViewComponent} from './kiosk-information/view/kiosk-view.component';
import {KioskRecipesComponent} from './kiosk-details/description/recipes/kiosk-recipes.component';
import {KioskIngredientsComponent} from './kiosk-details/description/ingredients/kiosk-ingredients.component';
import {OperatorsQuickViewComponent} from './operators/operators.quickview.component';
import {KiosksQuickviewComponent} from './kiosks/kiosks.quickview.component';
import {OrdersQuickViewComponent} from './orders/orders.quickview.component';
import {KioskNocComponent} from './noc/kiosk-noc.component';
import {KioskDiagnosticsRunStepComponent} from './kiosk-details/diagnostics/kiosk-diagnostics-run-step.component';
import {KioskDiagnosticsOrdersComponent} from './kiosk-details/diagnostics/kiosk-diagnostics-orders.component';
import {KioskInformationComponent} from './kiosk-information/kiosk-information.component';
import {KioskStatsComponent} from './kiosk-information/stats/kiosk-stats.component';
import {KioskErrorIdsComponent} from './kiosk-information/view/kiosk-error-ids.component';
import {KioskManagersComponent} from './kiosk-details/description/managers/kiosk-managers.component';
import {KioskHoursComponent} from './kiosk-details/description/hours/kiosk-hours.component';
import {DeviceProvisioningTestComponent} from './device-provisioning/device-provisioning.test.component';
import {PaymentCredentialsComponent} from './payment-credentials/payment-credentials.component';
import {KioskCouponsComponent} from './kiosk-details/description/coupons/kiosk-coupons.component';
import {KioskCouponsEditComponent} from './kiosk-details/description/coupons/kiosk-coupons.edit.component';
import {StoreManagerKioskViewComponent} from './store-manager-kiosks/store-manager-kiosk-view.component';
import {StoreManagerKioskDevicesEditComponent} from './store-manager-kiosks/store-manager-kiosk-devices.edit.component';
import {RectSelectComponent} from './kiosk-details/devices/rect.select.component';
import {AppConfigurationComponent} from './app-configuration/app-configuration.component';
import {ImageSelectComponent} from './vision/learn/image.select.component';
import {KioskSensorsComponent} from './kiosk-information/sensors/kiosk-sensors.component';
import {ReviewQuickviewComponent} from './reviews/review.quickview.component';
import {KioskTestersComponent} from './kiosk-details/description/testers/kiosk-testers.component';
import {KioskTestersEditComponent} from './kiosk-details/description/testers/kiosk-testers.edit.component';
import {KioskLogsComponent} from './kiosk-details/kiosk-logs/kiosk-logs.component';
import {DocumentationComponent} from './documentation/documentation.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {ProfilesNotificationComponent} from './profiles/profiles.notification.component';
import {AnalyticsComponent} from './analytics/analytics.component';
import {KioskMaintenanceOptionsComponent} from './kiosk-details/description/maintenance-options/kiosk-maintenance-options.component';
import {KioskMaintenanceOptionsEditComponent} from './kiosk-details/description/maintenance-options/kiosk-maintenance-options.edit.component';
import {BusinessOpsComponent} from './business-ops/business-ops.component';
import {KioskDevicesViewComponent} from './kiosk-details/devices/kiosk-devices.view.component';
import {KioskCustomRecipesComponent} from './kiosk-details/description/custom-recipes/kiosk-custom-recipes.component';
import {KioskRewardsComponent} from './kiosk-details/description/rewards/kiosk-rewards.component';
import {CustomerEditComponent} from './profiles/customer.edit.component';
import {ImageManagerComponent} from './image-manager/image-manager.component';
import {ImageManagerEditComponent} from './image-manager/image-manager.edit.component';
import {ImageManagerUploadComponent} from './image-manager/image-manager.upload.component';
import {KioskModelQuestionsComponent} from './kiosk-model-details/questions/kiosk-model-questions.component';
import {KioskModelQuestionsEditComponent} from './kiosk-model-details/questions/kiosk-model-questions.edit.component';
import {CustomRecipesEditComponent} from './custom-recipes/custom-recipes.edit.component';
import {KioskHoursEditComponent} from './kiosk-details/description/hours/kiosk-hours.edit.component';
import {KioskBlacklistComponent} from './kiosk-details/description/blacklist/kiosk-blacklist.component';
import {KioskBlacklistEditComponent} from './kiosk-details/description/blacklist/kiosk-blacklist.edit.component';
import {MealplansComponent} from './mealplans/mealplans.component';
import {MealplanEditComponent} from './mealplans/mealplan.edit.component';
import {SystemConfigurationComponent} from './system-configuration/system-configuration.component';
import {ServiceEventsComponent} from './service-events/service-events.component';
import {ServiceEventsEditComponent} from './service-events/service-events.edit.component';
import {PromotionsComponent} from './promotions/promotions.component';
import {PromotionsEditComponent} from './promotions/promotions.edit.component';
import {ServiceEventsAnalyticsComponent} from './service-events/service-events-analytics.component';
import {CategoryNotificationEmailsComponent} from './categories/category-notification-emails/category-notification-emails.component';
import {CategoryNotificationEmailsEditComponent} from './categories/category-notification-emails/category-notification-emails.edit.component';
import {ModelNotificationEmailsComponent} from './kiosk-model-details/model-notification-emails/model-notification-emails.component';
import {ModelNotificationEmailsEditComponent} from './kiosk-model-details/model-notification-emails/model-notification-emails.edit.component';
import {KioskNotificationEmailsComponent} from './kiosk-details/description/notification-emails/kiosk-notification-emails.component';
import {KioskNotificationEmailsEditComponent} from './kiosk-details/description/notification-emails/kiosk-notification-emails.edit.component';
import {KioskInfoComponent} from './kiosk-details/description/info/kiosk-info.component';
import {UserMessagesComponent} from './kiosk-details/description/user-messages/user-messages.component';
import {UserMessagesEditComponent} from './kiosk-details/description/user-messages/user-messages.edit.component';
import {SmsModalComponent} from './orders/sms.modal.component';
import {KioskTabletsComponent} from './kiosk-details/description/tablets/kiosk-tablets.component';
import {KioskTabletsEditComponent} from './kiosk-details/description/tablets/kiosk-tablets.edit.component';

export const DASHBOARD_MODULE_ROUTES: Route[] = [
    {
        canActivate: [AuthGuard],
        path: '', component: DashboardLayoutComponent,
        children: [
            {
                path: 'home',
                component: HomeComponent,
                data: {rights: [Rights.MANAGE_DASHBOARD]},
                canActivate: [RightsGuard]
            },
            {
                path: 'analytics',
                component: AnalyticsComponent,
                data: {rights: [Rights.MANAGE_DASHBOARD]},
                canActivate: [RightsGuard]
            },
            {
                path: 'business-ops',
                component: BusinessOpsComponent,
                data: {rights: [Rights.MANAGE_DASHBOARD]},
                canActivate: [RightsGuard]
            },
            {
                path: 'image-manager',
                component: ImageManagerComponent,
                data: {rights: [Rights.MANAGE_IMAGES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'companies',
                component: CompaniesComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard]
            },
            {
                path: 'payment-credentials',
                component: PaymentCredentialsComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard]
            },
            {
                path: 'mealplans',
                component: MealplansComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard]
            },
            {
                path: 'app-configuration',
                component: AppConfigurationComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard]
            },
            {
                path: 'system-configuration',
                component: SystemConfigurationComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard]
            },
            {
                path: 'documentation',
                component: DocumentationComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard]
            },
            {
                path: 'notifications',
                component: NotificationsComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard]
            },
            {
                path: 'categories',
                component: CategoriesComponent,
                data: {rights: [Rights.MANAGE_CATEGORIES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'operators',
                component: OperatorsComponent,
                data: {rights: [Rights.MANAGE_OPERATORS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'recipes',
                component: RecipesComponent,
                data: {rights: [Rights.MANAGE_RECIPES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'lists',
                component: RecipesListsComponent,
                data: {rights: [Rights.MANAGE_RECIPES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'ingredients',
                component: IngredientsComponent,
                data: {rights: [Rights.MANAGE_INGREDIENTS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'orders',
                component: OrdersComponent,
                data: {rights: [Rights.MANAGE_ORDERS, Rights.MANAGE_ALL_STORES, Rights.MANAGE_KIOSKS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'order-details',
                component: OrderDetailsComponent,
                data: {rights: [Rights.MANAGE_ORDERS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'custom-recipes',
                component: CustomRecipesComponent,
                data: {rights: [Rights.MANAGE_RECIPES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'service-events',
                component: ServiceEventsComponent,
                data: {rights: [Rights.MANAGE_KIOSKS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'promotions',
                component: PromotionsComponent,
                data: {rights: [Rights.MANAGE_KIOSKS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'event-log',
                component: EventLogComponent,
                data: {rights: [Rights.MANAGE_KIOSKS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'reviews',
                component: ReviewsComponent,
                data: {rights: [Rights.MANAGE_REVIEWS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'devices',
                component: DevicesComponent,
                data: {rights: [Rights.MANAGE_DEVICES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'device-templates',
                component: DeviceTemplatesComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard]
            },
            {
                path: 'device-provisioning',
                component: DeviceProvisioningComponent,
                data: {rights: [Rights.MANAGE_DEVICES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'macro-steps',
                component: MacroStepsComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard]
            },
            {
                path: 'macro-step-edit',
                component: MacroStepEditComponent,
                data: {rights: [Rights.SUPER_ADMIN]},
                canActivate: [RightsGuard],
                canDeactivate: [CanDeactivateMacroStepEdit]
            },
            {
                path: 'kiosks',
                component: KiosksComponent,
                data: {rights: [Rights.MANAGE_KIOSKS, Rights.MANAGE_ALL_STORES, Rights.MANAGE_SELECTED_STORES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'kiosk-details',
                component: KioskDetailsComponent,
                data: {rights: [Rights.MANAGE_KIOSKS, Rights.MANAGE_ALL_STORES, Rights.MANAGE_SELECTED_STORES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'kiosk-information',
                component: KioskInformationComponent,
                data: {rights: [Rights.MANAGE_KIOSKS, Rights.MANAGE_ALL_STORES, Rights.MANAGE_SELECTED_STORES]},
                canActivate: [RightsGuard]
            },
            {
                path: 'store-manager-kiosk-view',
                component: StoreManagerKioskViewComponent,
                data: {rights: [Rights.MANAGE_SELECTED_STORES, Rights.MANAGE_ALL_STORES, Rights.MANAGE_KIOSKS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'kiosk-templates',
                component: KioskTemplatesComponent,
                data: {rights: [Rights.MANAGE_KIOSKS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'kiosk-models',
                component: KioskModelsComponent,
                data: {rights: [Rights.MANAGE_KIOSKS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'kiosk-model-details',
                component: KioskModelDetailsComponent,
                data: {rights: [Rights.MANAGE_KIOSKS]},
                canActivate: [RightsGuard],
                canDeactivate: [CanDeactivateKioskModelDetails]
            },
            {
                path: 'vision',
                component: VisionComponent,
                data: {rights: [Rights.SUPER_ADMIN, Rights.MANAGE_KIOSKS]},
                canActivate: [RightsGuard]
            },
            {
                path: 'profiles',
                children: [
                    {
                        path: 'users',
                        component: ProfilesComponent,
                        data: {rights: [Rights.SUPER_ADMIN], title: 'Users', userType: 'USER'},
                        canActivate: [RightsGuard]
                    },
                    {
                        path: 'admins',
                        component: ProfilesComponent,
                        data: {rights: [Rights.SUPER_ADMIN], title: 'Admins', userType: 'ADMIN'},
                        canActivate: [RightsGuard]
                    },
                    {
                        path: 'customers',
                        component: ProfilesComponent,
                        data: {rights: [Rights.MANAGE_CUSTOMERS], title: 'Customers', userType: 'CUSTOMER'},
                        canActivate: [RightsGuard]
                    }
                ],
            },
            {path: '', redirectTo: 'home', pathMatch: 'full'}
        ]
    },
    {
        canActivate: [AuthGuard],
        path: '', component: FullscreenLayoutComponent,
        children: [
            {
                path: 'kiosk-view',
                component: KioskNocComponent,
                data: {rights: [Rights.MANAGER]},
                canActivate: [RightsGuard]
            },
        ]
    },
];

export const DASHBOARD_MODULE_COMPONENTS = [
    DashboardLayoutComponent,
    FullscreenLayoutComponent,
    ProgressLoaderComponent,
    HomeComponent,
    AnalyticsComponent,
    CompaniesComponent,
    CompaniesEditComponent,
    CategoriesComponent,
    CategoriesEditComponent,
    OperatorsComponent,
    OperatorsEditComponent,
    OperatorsQuickViewComponent,
    RecipesComponent,
    RecipesEditComponent,
    StepsListComponent,
    ProcessEditComponent,
    DependsOnEditComponent,
    IngredientsComponent,
    IngredientsEditComponent,
    OrdersComponent,
    OrderDetailsComponent,
    OrdersQuickViewComponent,
    ReviewsComponent,
    CustomRecipesComponent,
    EventLogComponent,
    ProfilesComponent,
    ProfilesEditComponent,
    DevicesComponent,
    DevicesEditComponent,
    DeviceProcessEditComponent,
    MacroStepsComponent,
    MacroStepEditComponent,
    KiosksComponent,
    VisionComponent,
    KioskInfoComponent,
    KioskInformationComponent,
    KioskStatsComponent,
    KioskDetailsComponent,
    KioskDevicesComponent,
    KioskManagersComponent,
    KioskHoursComponent,
    KiosksQuickviewComponent,
    KioskDevicesEditComponent,
    KioskRewardsComponent,
    KioskPresentationComponent,
    KioskDiagnosticsComponent,
    KioskDiagnosticsRunStepComponent,
    KioskDiagnosticsOrdersComponent,
    KioskSimulatorComponent,
    KioskTestsComponent,
    KioskTestDetailsComponent,
    KioskTestCycleDetailsComponent,
    KioskHistoryComponent,
    DeviceLogsComponent,
    KioskRecipesListsComponent,
    KioskRecipesComponent,
    KioskIngredientsComponent,
    KioskActionsComponent,
    KioskViewComponent,
    KioskNocComponent,
    KioskCouponsComponent,
    KioskCouponsEditComponent,
    KioskErrorIdsComponent,
    KioskSensorsComponent,
    DevicesComponent,
    DeviceTemplatesComponent,
    DeviceTemplatesEditComponent,
    DeviceProvisioningComponent,
    DeviceProvisioningEditComponent,
    DeviceProvisioningTestComponent,
    KioskTemplatesComponent,
    KioskTemplatesEditComponent,
    KioskModelsComponent,
    KioskModelDetailsComponent,
    KioskCustomRecipesComponent,
    RobotCommandEditComponent,
    DeviceModelEditComponent,
    ModelPresentationComponent,
    DiagnosticsStepSequenceEditComponent,
    DiagnosticsStepEditComponent,
    ValueEditorComponent,
    TagSelectComponent,
    ImageSelectComponent,
    VisionLearnComponent,
    VisionDetectComponent,
    VisionLogComponent,
    RecipesListsComponent,
    RecipesListsEditComponent,
    PaymentCredentialsComponent,
    RectSelectComponent,
    AppConfigurationComponent,
    ReviewQuickviewComponent,
    KioskTestersComponent,
    KioskTestersEditComponent,
    KioskBlacklistComponent,
    KioskBlacklistEditComponent,
    KioskMaintenanceOptionsComponent,
    KioskMaintenanceOptionsEditComponent,
    KioskDevicesViewComponent,
    BusinessOpsComponent,
    KioskLogsComponent,
    KioskHoursEditComponent,
    DocumentationComponent,
    NotificationsComponent,
    ProfilesNotificationComponent,
    StoreManagerKioskViewComponent,
    StoreManagerKioskDevicesEditComponent,
    CustomerEditComponent,
    ImageManagerComponent,
    ImageManagerEditComponent,
    ImageManagerUploadComponent,
    KioskModelQuestionsComponent,
    KioskModelQuestionsEditComponent,
    CustomRecipesEditComponent,
    MealplansComponent,
    MealplanEditComponent,
    SystemConfigurationComponent,
    ServiceEventsComponent,
    ServiceEventsEditComponent,
    ServiceEventsAnalyticsComponent,
    PromotionsComponent,
    PromotionsEditComponent,
    CategoryNotificationEmailsComponent,
    CategoryNotificationEmailsEditComponent,
    ModelNotificationEmailsComponent,
    ModelNotificationEmailsEditComponent,
    KioskNotificationEmailsComponent,
    KioskNotificationEmailsEditComponent,
    UserMessagesComponent,
    UserMessagesEditComponent,
    SmsModalComponent,
    KioskTabletsComponent,
    KioskTabletsEditComponent,
];
