<div class="animated fadeIn">
    <div class="col-md-12">
        <tabset>
            <tab heading='Description'>
                <fields-list-component #fieldsListComponent name="macro_step_edit" [fields]="fields" [value]="step"></fields-list-component>

                <div class="form-horizontal">
                    <div class="card-content">
                        <div class="form-group">
                            <label for="edit_2" class="col-md-3 control-label">Type</label>
                            <div class="col-md-9">
                                <select
                                        id="edit_2"
                                        name="edit_2"
                                        class="form-control form-control-low selectx"
                                        size="1"
                                        [(ngModel)]="step.template">
                                    <option [value]="'NONE'">NONE</option>
                                    <option [value]="'INGREDIENT'">INGREDIENT</option>
                                    <option [value]="'SOLID_INGREDIENTS'">SOLID_INGREDIENTS</option>
                                    <option [value]="'LIQUID_INGREDIENTS'">LIQUID_INGREDIENTS</option>
                                    <option [value]="'POWDER_INGREDIENTS'">POWDER_INGREDIENTS</option>
                                    <option [value]="'TOPPINGS'">TOPPINGS</option>
                                    <option [value]="'DRESSINGS'">DRESSINGS</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="isIngredient()">
                            <label for="edit_3" class="col-md-3 control-label">Use for Ingredient</label>
                            <div class="col-md-9">
                                <select id="edit_3"
                                        name="edit_3"
                                        class="form-control form-control-low selectx"
                                        size="1"
                                        [compareWith]="compareBeans"
                                        [(ngModel)]="step.templateIngredient">
                                    <option [ngValue]="ingredient" *ngFor="let ingredient of ingredients">
                                        {{ingredient.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading='Processes'>

                <table-component (onEdit)="onEdit($event)"
                                 (onAdd)="onAdd()"
                                 (onRemove)="onRemove($event)"></table-component>

            </tab>
        </tabset>
        <br/>
        <button type="button" class="btn btn-primary pull-right" (click)="saveMacroStep()">Save</button>
    </div>
</div>

<process-edit-component (onResult)="addProcess($event)"></process-edit-component>


