/*!

 =========================================================
 * Paper Dashboard PRO - V1.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-dashboard-pro
 * Available with purchase of license from https://www.creative-tim.com/product/paper-dashboard-pro
 * Copyright 2017 Creative Tim (https://www.creative-tim.com)
 * License Creative Tim (https://www.creative-tim.com/license)

 =========================================================

 */

var fixedTop = false;

var mobile_menu_visible = 0,
    mobile_menu_initialized = false,
    toggle_initialized = false,
    bootstrap_nav_initialized = false,
    $sidebar,
    $sidebar_wrapper,
    isWindows;

(function () {
    // isWindows = true;// navigator.platform.indexOf('Win') > -1 ? true : false;
    //
    // if (isWindows && !$('body').hasClass('sidebar-mini')) {
    //     // if we are on windows OS we activate the perfectScrollbar function
    //     // $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar();
    //
    //     // $('html').addClass('perfect-scrollbar-on');
    // } else {
    $('html').addClass('perfect-scrollbar-off');
    // }
})();

$(document).ready(function () {

    var window_width = $(window).width();
    $sidebar = $('.sidebar');

    if ($('body').hasClass('sidebar-mini')) {
        pdp.misc.sidebar_mini_active = true;
    }

    pdp.initSidebarsCheck();
    pdp.initMinimizeSidebar();

    $('.form-control').on("focus", function () {
        $(this).parent('.input-group').addClass("input-group-focus");
    }).on("blur", function () {
        $(this).parent(".input-group").removeClass("input-group-focus");
    });

    //  Activate the tooltips
    $('[rel="tooltip"]').tooltip();


    $('.switch').bootstrapSwitch({
        onColor: 'primary'
    });

    $('.switch-plain').bootstrapSwitch({
        onColor: '',
        onText: '',
        offText: ''
    });

    $('.switch-icon').bootstrapSwitch({
        onColor: '',
        onText: '<i class="fa fa-check"></i>',
        offText: '<i class="fa fa-times"></i>'
    });


    //Activate tags
    //removed class label and label-color from tag span and replaced with data-color
    if ($(".tagsinput").length != 0) {
        var tagClass = $('.tagsinput').data('color');

        $('.tagsinput').tagsinput({
            tagClass: ' tag-' + tagClass + ' '
        });
    }


});


// activate collapse right menu when the windows is resized
$(window).resize(function () {
    pdp.initSidebarsCheck();

});

var pdp = {
    misc: {
        navbar_menu_visible: 0,
        active_collapse: true,
        disabled_collapse_init: 0

    },
    initSidebarsCheck: function () {

        if (!$sidebar) return;
        // Init navigation toggle for small screens
        if ($(window).width() <= 991) {
            if ($sidebar.length != 0) {
                pdp.initSidebarMenu();
            } else {
                pdp.initBootstrapNavbarMenu();
            }
        } else if (mobile_menu_initialized == true) {
            // clear all the additions that we made for the sidebar wrapper only if the screen is bigger than 991px
            $sidebar_wrapper.find('.navbar-form').remove();
            $sidebar_wrapper.find('.nav-mobile-menu').remove();

            mobile_menu_initialized = false;
        }
    },

    initMinimizeSidebar: function () {
        $('#minimizeSidebar').click(function () {
            var $btn = $(this);

            if (pdp.misc.sidebar_mini_active == true) {
                $('body').removeClass('sidebar-mini');
                $btn.html('<i class="ti-more-alt"></i>');
                pdp.misc.sidebar_mini_active = false;

            } else {
                $('body').addClass('sidebar-mini');
                $btn.html('<i class="ti-menu-alt"></i>');
                pdp.misc.sidebar_mini_active = true;
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            var simulateWindowResize = setInterval(function () {
                window.dispatchEvent(new Event('resize'));
            }, 180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function () {
                clearInterval(simulateWindowResize);
            }, 1000);
        });
    },

    initSidebarMenu: function () {
        $sidebar_wrapper = $('.sidebar-wrapper');

        if (!mobile_menu_initialized) {

            var $navbar = $('nav').find('.navbar-collapse').first().clone(true);

            var nav_content = '';
            var mobile_menu_content = '';

            $navbar.children('ul').each(function () {

                var content_buff = $(this).html();
                nav_content = nav_content + content_buff;
            });

            nav_content = '<ul class="nav nav-mobile-menu">' + nav_content + '</ul>';

            var $navbar_form = $('nav').find('.navbar-form').clone(true);

            var $sidebar_nav = $sidebar_wrapper.find(' > .nav');

            // insert the navbar form before the sidebar list
            var $nav_content = $(nav_content);
            $nav_content.insertBefore($sidebar_nav);
            $navbar_form.insertBefore($nav_content);

            $(".sidebar-wrapper .dropdown .dropdown-menu > li > a").click(function (event) {
                event.stopPropagation();

            });

            mobile_menu_initialized = true;
        } else {
            if ($(window).width() > 991) {
                // clear all the additions that we made for the sidebar wrapper only if the screen is bigger than 991px
                $sidebar_wrapper.find('.navbar-form').remove();
                $sidebar_wrapper.find('.nav-mobile-menu').remove();

                mobile_menu_initialized = false;
            }
        }

        if (!toggle_initialized) {
            var $toggle = $('.navbar-toggle');

            $toggle.click(function () {

                if (mobile_menu_visible == 1) {
                    $('html').removeClass('nav-open');

                    $('.close-layer').remove();
                    setTimeout(function () {
                        $toggle.removeClass('toggled');
                    }, 400);

                    mobile_menu_visible = 0;
                } else {
                    setTimeout(function () {
                        $toggle.addClass('toggled');
                    }, 430);

                    var main_panel_height = $('.main-panel')[0].scrollHeight;
                    var $layer = $('<div class="close-layer"></div>');
                    $layer.css('height', main_panel_height + 'px');
                    $layer.appendTo(".main-panel");


                    setTimeout(function () {
                        $layer.addClass('visible');
                    }, 100);

                    $layer.click(function () {
                        $('html').removeClass('nav-open');
                        mobile_menu_visible = 0;

                        $layer.removeClass('visible');

                        setTimeout(function () {
                            $layer.remove();
                            $toggle.removeClass('toggled');

                        }, 400);
                    });

                    $('html').addClass('nav-open');
                    mobile_menu_visible = 1;

                }
            });

            toggle_initialized = true;
        }

    },

    initBootstrapNavbarMenu: debounce(function () {

        if (!bootstrap_nav_initialized) {
            var $navbar = $('nav').find('.navbar-collapse').first().clone(true);

            var nav_content = '';
            var mobile_menu_content = '';

            //add the content from the regular header to the mobile menu
            $navbar.children('ul').each(function () {
                var content_buff = $(this).html();
                nav_content = nav_content + content_buff;
            });

            nav_content = '<ul class="nav nav-mobile-menu">' + nav_content + '</ul>';

            $navbar.html(nav_content);
            $navbar.addClass('off-canvas-sidebar');

            // append it to the body, so it will come from the right side of the screen
            $('body').append($navbar);

            var $toggle = $('.navbar-toggle');

            $navbar.find('a').removeClass('btn btn-round btn-default');
            $navbar.find('button').removeClass('btn-round btn-fill btn-info btn-primary btn-success btn-danger btn-warning btn-neutral');
            $navbar.find('button').addClass('btn-simple btn-block');

            $toggle.click(function () {
                if (mobile_menu_visible == 1) {
                    $('html').removeClass('nav-open');

                    $('.close-layer').remove();
                    setTimeout(function () {
                        $toggle.removeClass('toggled');
                    }, 400);

                    mobile_menu_visible = 0;
                } else {
                    setTimeout(function () {
                        $toggle.addClass('toggled');
                    }, 430);

                    $layer = $('<div class="close-layer"></div>');
                    $layer.appendTo(".wrapper-full-page");

                    setTimeout(function () {
                        $layer.addClass('visible');
                    }, 100);


                    $layer.click(function () {
                        $('html').removeClass('nav-open');
                        mobile_menu_visible = 0;

                        $layer.removeClass('visible');

                        setTimeout(function () {
                            $layer.remove();
                            $toggle.removeClass('toggled');

                        }, 400);
                    });

                    $('html').addClass('nav-open');
                    mobile_menu_visible = 1;

                }

            });
            bootstrap_nav_initialized = true;
        }
    }, 500),
}


// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
};


var md = {
    misc: {
        navbar_menu_visible: 0,
        active_collapse: true,
        disabled_collapse_init: 0,
    },


    checkScrollForTransparentNavbar: debounce(function () {
        if ($(document).scrollTop() > 381) {
            if (transparent) {
                transparent = false;
                $('.navbar-color-on-scroll').removeClass('navbar-transparent');
                $('.navbar-title').removeClass('hidden');
            }
        } else {
            if (!transparent) {
                transparent = true;
                $('.navbar-color-on-scroll').addClass('navbar-transparent');
                $('.navbar-title').addClass('hidden');
            }
        }
    }, 17),


    startAnimationForLineChart: function (chart) {

        chart.on('draw', function (data) {
            if (data.type === 'line' || data.type === 'area') {
                data.element.animate({
                    d: {
                        begin: 600,
                        dur: 700,
                        from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint
                    }
                });
            } else if (data.type === 'point') {
                seq++;
                data.element.animate({
                    opacity: {
                        begin: seq * delays,
                        dur: durations,
                        from: 0,
                        to: 1,
                        easing: 'ease'
                    }
                });
            }
        });

        seq = 0;
    },
    startAnimationForBarChart: function (chart) {

        chart.on('draw', function (data) {
            if (data.type === 'bar') {
                seq2++;
                data.element.animate({
                    opacity: {
                        begin: seq2 * delays2,
                        dur: durations2,
                        from: 0,
                        to: 1,
                        easing: 'ease'
                    }
                });
            }
        });

        seq2 = 0;
    }
};
