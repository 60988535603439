<div class="animated fadeIn">

    <div class="card-content table-responsive table-full-width" style="margin: 1px">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Recipe List</th>
                <th>State</th>
                <th>Enabled for Custom Recipes</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let ingredientState of this.ingredients">
                <td>{{ingredientState.name}}</td>
                <td>

                    <select
                            data-style="select-btn"
                            title="Select State"
                            data-size="7"
                            [(ngModel)]="ingredientState.state">
                        <option [ngValue]="'AVAILABLE'">AVAILABLE</option>
                        <option [ngValue]="'PROVISIONED'">PROVISIONED</option>
                        <option disabled [ngValue]="'READY'">READY</option>
                        <option [ngValue]="'OUT_OF_SERVICE'">OUT OF SERVICE</option>
                        <option [ngValue]="'INACTIVE'">INACTIVE</option>
                    </select>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="ingredientState.enabledForCustomRecipes"/>

                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
    <br/>
</div>
