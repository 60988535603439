import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {IngredientService} from '../../services/ingredient.service';
import {TableComponent} from '../../components/table.component';
import {IngredientsEditComponent} from './ingredients.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {IngredientBean} from '../../model/model';

@Component({
    templateUrl: 'ingredients.component.html',
})

export class IngredientsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(IngredientsEditComponent, {static: false}) modal: IngredientsEditComponent;

    fields: Column[] = [
        new Column('imageUrl', 'Image').thumbnail(),
        new Column('name', 'Name'),
        new Column('type', 'Type'),
        new Column('density', 'Density').decimal(),
        new Column('costPerGram', 'Cost (per g)').decimal(3),
        new Column('calories', 'Cals'),
        new Column('protein', 'Pro'),
        new Column('totalCarb', 'Carb'),
        new Column('dietaryFiber', 'Fiber'),
        new Column('sugar', 'Sugar'),
        new Column('addedSugar', 'Added Sugar'),
        new Column('totalFat', 'Fat'),
        new Column('saturatedFat', 'Sat. fat').hide(),
        new Column('cholestrol', 'Cho').hide(),
        new Column('vitaminA', 'Vit A').hide(),
        new Column('vitaminC', 'Vit C').hide(),
        new Column('vitaminD', 'Vit D').hide(),
        new Column('calcium', 'Calc.').hide(),
        new Column('iron', 'Iron').hide(),
        new Column('pottasium', 'Pot.').hide(),
        new Column('sodium', 'Sodium').hide(),
        // new Column('polyunsaturatedFat', 'PUFA'),
        // new Column('monounsaturatedFat', 'MUFA'),
        // new Column('vitaminE', 'Vit E'),
        // new Column('magnesium', 'Mag.'),
    ];

    constructor(private ingredientService: IngredientService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadIngredients();
    }

    loadIngredients() {
        this.ingredientService.getIngredients().subscribe(ingredients => {
            for (const ingredient of ingredients.list) {
                ingredient['stateCopy'] = ingredient.state;
            }

            this.table.setData(ingredients.list, this.fields);
        });
    }

    onEdit(items: IngredientBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const ingredient of items) {

            this.ingredientService.deleteIngredient(ingredient).subscribe(response => {
                if (response.success) {
                    if (ingredient == items.pop()) {
                        this.loadIngredients();
                    }

                } else {
                    this.app.showError('Can\'t delete ingredient');
                }
            }, error => {
                this.app.showError('Can\'t delete ingredient');
            });
        }
    }

    onAdd() {
        this.modal.open(null, 'New Ingredient');
    }
}
