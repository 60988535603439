import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../../../common/field';
import {ManageKioskRewardsBean} from '../../../../model/model';
import {FieldsListComponent} from '../../../../components/fields-list.component';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-rewards-component',
    templateUrl: 'kiosk-rewards.component.html'
})
export class KioskRewardsComponent {

    @ViewChild('kioskFieldsListComponent', {static: false}) fieldsListComponent: FieldsListComponent;

    fields = [
        new Field('pointsPerOrder', 'Points per order', FieldType.NUMBER),
        new Field('pointsPerFreeDrink', 'Points per free drink', FieldType.NUMBER),
        new Field('pointsPerReferral', 'Points per referral', FieldType.NUMBER),
        new Field('pointsPerReferralConversion', 'Points per referral conversion', FieldType.NUMBER)
    ];
    rewards: ManageKioskRewardsBean;
    kioskId: number;

    constructor(private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    onShow(kioskId: number, operatorId: number) {

        this.kioskId = kioskId;
        this.kioskManageService.getRewards(kioskId).subscribe(response => {
            if (response.success) {
                this.rewards = response.value;
            }
        });
    }

    save() {

        this.kioskManageService.setRewards(this.kioskId, this.rewards).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
