<div class="animated fadeIn" [hidden]="!kioskManagers">

    <select hidden multiple
            id="select_manager"
            data-style="select-btn"
            title="Select Managers"
            data-size="7"
            [compareWith]="compareBeans"
            [(ngModel)]="kioskManagers">
        <option [ngValue]="user"
                *ngFor="let user of managers">{{user.displayName}}
        </option>
    </select>


    <button type="button" class="btn btn-primary pull-right" id="button_save" (click)="save()">Save</button>
    <br/>
    <br/>
    <br/>
</div>
