import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {AppComponent} from '../../app.component';
import {ClientConfigBean} from '../../model/model';
import {Field, FieldType} from '../../common/field';
import {FieldsListComponent} from '../../components/fields-list.component';
import {ConfigurationService} from '../../services/configuration.service';

@Component({
    templateUrl: 'app-configuration.component.html',
})

export class AppConfigurationComponent implements AfterViewInit {

    clientConfig: ClientConfigBean;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields = [
        new Field('androidVersion', 'Android App Version'),
        // new Field('androidVersionCode', 'Android App Version Code', FieldType.NUMBER),
        new Field('iosVersion', 'IOS App Version'),
        // new Field('iosVersionCode', 'IOS App Version Code', FieldType.NUMBER),
        new Field('urlWebsite', 'Website URL'),
        new Field('urlOurStory', 'Our Story URL'),
        new Field('urlTerms', 'Terms & Services URL'),
        new Field('urlHelp', 'Help URL'),
    ];

    constructor(private clientService: ConfigurationService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.getPaymentCredentials();
    }

    getPaymentCredentials() {
        this.clientService.getClientConfig().subscribe(response => {
            if (response) {
                this.clientConfig = response.value;
            }
        });
    }

    save() {
        this.clientService.updateClientConfig(this.clientConfig).subscribe(response => {

            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update config');
            }
        }, error => {
            this.app.showError('Can\'t update config');
        });

    }
}
