<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">
    <fields-list-component #fieldsListComponent [fields]="fields" name="devices_edit"
                           [value]="device"></fields-list-component>

    <div class="form-horizontal">
        <div class="card-content">

            <div *ngIf="isIngredient()">
                <div class="form-group" *ngFor="let i of range(device.numberOfIngredients)">
                    <label for="select_ingredient{{i}}" class="col-md-3 control-label">Sub Ingredient #{{i}}</label>
                    <div class="col-md-9">
                        <select
                                hidden
                                id="select_ingredient{{i}}"
                                data-style="select-btn" data-size="7"
                                [compareWith]="compareBeans"
                                [(ngModel)]="device.canDispenseIngredients[i]">

                            <option [ngValue]="null">
                                Select Ingredient
                            </option>

                            <option [ngValue]="ingredient"
                                    *ngFor="let ingredient of getIngredients(device.ingredientType)">
                                {{ingredient.name}}
                            </option>
                        </select>

                        <div class="form-group">
                            <label for="edit_max_amount{{i}}" class="col-md-3 control-label">Max amount</label>
                            <div class="col-md-9">
                                <input type="number"
                                       [(ngModel)]="device.maxAmount[i]"
                                       class="form-control"
                                       id="edit_max_amount{{i}}"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="edit_current_amount{{i}}" class="col-md-3 control-label">Current amount</label>
                            <div class="col-md-9">
                                <input type="number"
                                       [(ngModel)]="device.currentAmount[i]"
                                       class="form-control"
                                       id="edit_current_amount{{i}}"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</base-modal-component>