<div class="form-horizontal">
    <div class="card-content">


        <div class="form-group">
            <label class="col-md-3 control-label" for="working_hours_range">Date Range</label>
            <div class="col-md-9">
                <input (bsValueChange)="onDateRangeChanged($event)" [(ngModel)]="selectedRange"
                       class="form-control"
                       id="working_hours_range" bsDaterangepicker type="text"/>
            </div>
        </div>


        <table-component  ></table-component>
    </div>
</div>

