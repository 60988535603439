import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';
import {ActivatedRoute} from '@angular/router';
import {AnalyticsBean, EntityNameBean, RecipeInfoBean} from '../../model/model';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {Helper} from '../../common/helper';
import {OperatorsService} from '../../services/operators.service';
import {KioskService} from '../../services/kiosk.service';
import {Location} from '@angular/common';
import {LineChartData} from '../../common/line.chart';
import * as FileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import * as math from 'mathjs';
import {RecipeService} from '../../services/recipe.service';
import {AppComponent} from '../../app.component';

@Component({
    templateUrl: 'analytics.component.html'
})
export class AnalyticsComponent implements AfterViewInit {

    allKiosks: EntityNameBean = {id: 0} as EntityNameBean;

    lineChartOptions = LineChartData.lineChartOptions;
    lineChartOptionsNoZero = LineChartData.lineChartOptionsNoZero;

    ordersChart: LineChartData;
    maintenanceChart: LineChartData;
    uptimeChart: LineChartData;
    orderSuccessChart: LineChartData;
    waitTimeChart: LineChartData;
    revenueChart: LineChartData;
    userErrorsChart: LineChartData;
    computedErrorsChart: LineChartData;
    systemErrorsChart: LineChartData;
    recipesChart: LineChartData;
    actualIngredientsChart: LineChartData;
    desiredIngredientsChart: LineChartData;
    cogsPerUnitChart: LineChartData;
    customizedChart: LineChartData;
    reviewsChart: LineChartData;
    recurringLoyalistsChart: LineChartData;
    uniqueCustomersChart: LineChartData;

    kiosks: EntityNameBean[] = [];
    selectedKiosk: EntityNameBean = {id: 0} as EntityNameBean;
    range = 'daily';
    selectedKioskId: number;

    compareBeans = Helper.compareBeans;

    analytics: AnalyticsBean[];
    selectedRange = [
        moment().startOf('day').subtract(1, 'month').toDate(),
        moment().endOf('day').toDate()
    ];
    selectedResetFrom = moment().startOf('day').subtract(1, 'month').toDate();
    recipes: RecipeInfoBean[];
    private rangeChanged = false;

    operationalSelected = true;
    financialSelected = true;
    consumerSelected = true;

    constructor(private dashboardService: DashboardService,
        private operatorsService: OperatorsService,
        private kioskService: KioskService,
        private recipeService: RecipeService,
        @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
        private route: ActivatedRoute,
        private location: Location,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    private static fillChart(chart: LineChartData, keys: {[index: string]: number}, label: string, total: boolean) {
        let sum = 0;
        let totalIndex = 0;
        for (let i = 0; i < chart.data.length; i++) {
            if (chart.data[i].label == 'Total') {
                totalIndex = i;
            }
        }
        for (let i = 0; i < chart.data.length; i++) {
            let found = false;
            for (const key in keys) {
                if (chart.data[i].label == key) {
                    chart.data[i].data.push(keys[key]);
                    sum += keys[key];
                    found = true;
                }
            }
            if (!found) {
                if (!total || (total && i != totalIndex)) {
                    chart.data[i].data.push(0);
                }
            }
        }
        if (total) {
            chart.data[totalIndex].data.push(sum);
        }
        chart.labels.push(label);
    }

    onKioskChange() {
        this.getAnalytics();
        this.updateLocation();
    }

    loadKiosks() {
        this.kioskService.getActiveKiosksNames(true).subscribe(kiosks => {
            this.setKiosks(kiosks.value);
            this.getAnalytics();
        });
    }

    setKiosks(kiosks: EntityNameBean[]) {

        this.kiosks = kiosks;
        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKioskId) {
            this.selectedKiosk = this.allKiosks;
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.id == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }

        Helper.selectPicker('select_kiosk');
    }

    updateLocation() {
        let url = '/analytics';
        if (this.selectedKiosk != this.allKiosks && this.selectedKiosk.id) {
            url += ('?kiosk=' + this.selectedKiosk.id);
        }

        this.location.replaceState(url);
    }

    ngAfterViewInit() {
        Helper.selectPicker('select_kiosk', 'select_mode');

        const date = new Date();
        const dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - 27);

        const quarter = new Date(date.getFullYear(), Math.floor((date.getMonth() / 3)) * 3, 1);
        this.route.queryParams.subscribe(params => {
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;

            this.recipeService.getRecipesInfo().subscribe(response => {
                this.recipes = response.value;
                this.loadKiosks();
            });

        });
    }

    downloadMaintenance() {

        let start = Helper.startOf(this.selectedRange[0]);
        let end = Helper.endOf(this.selectedRange[1]);

        this.dashboardService.getMaintenanceChartsCSV(this.selectedKiosk.id, start, end).subscribe(response => {
            FileSaver.saveAs(response, 'Maintenance.csv');
        });
    }

    onDateRangeChanged(event) {
        if (!this.rangeChanged) {
            if (event) {
                this.getAnalytics();
            }
        }
        this.rangeChanged = true;
    }

    private getAnalytics() {

        let start = Helper.startOf(this.selectedRange[0]);
        let end = Helper.endOf(this.selectedRange[1]);

        this.dashboardService.getAnalytics([this.selectedKiosk], start, end, this.range).subscribe(response => {
            if (response && response.success) {
                this.analytics = response.value;
                this.updateCharts();
            }
        });
    }

    rangeChange($event: Event) {
        this.getAnalytics();
    }

    private updateCharts() {

        if (!this.analytics) {
            return;
        }

        this.revenueChart = new LineChartData(['Gross', 'Net', 'IOS', 'Android', 'Kiosk', 'Web', 'Meal Plan', 'Stripe', 'Square']);
        this.customizedChart = new LineChartData(['IOS, %', 'Android, %', 'Kiosk, %', 'Web, %', 'Meal Plan, %']);
        this.ordersChart = new LineChartData(['Total', 'Customer', 'Total Paid', 'IOS', 'Android', 'Kiosk', 'Web', 'Meal Plan',
            'Reordered', 'Canceled', 'Canceled Before Start', 'Promo', 'Free', 'It\'s on Us', 'Employee', 'Test', 'Stripe', 'Square']);
        this.maintenanceChart = new LineChartData(['Total', /*'Critical', 'Regular',*/ 'User Critical', 'User Scheduled']);
        this.uptimeChart = new LineChartData(['Uptime', 'Service Availability']);
        this.orderSuccessChart = new LineChartData(['Order Success Rate']);
        this.cogsPerUnitChart = new LineChartData(['COGS per Unit', 'COGS per Unit (paid)']);

        this.waitTimeChart = new LineChartData(['Wait Time Maximum', 'Wait Time Median', 'Processing Time Maximum',
            'Processing Time Median', 'Customer Delivery Time Maximum', 'Customer Delivery Time Median']);
        this.recurringLoyalistsChart = new LineChartData(['Recurring', 'Loyalists']);
        this.uniqueCustomersChart = new LineChartData(['Unique Purchasers', 'App Unique Customers']);

        this.reviewsChart = new LineChartData(['Freshness', 'Overall', 'Wait Time', 'Rate']);

        const userErrorNames: Set<string> = new Set();
        const computedErrorNames: Set<string> = new Set();
        const systemErrorNames: Set<string> = new Set();

        const recipeNames: Set<string> = new Set();
        const actualIngredientNames: Set<string> = new Set();
        const desiredIngredientNames: Set<string> = new Set();

        for (const item of this.analytics) {
            for (const key in item.errorsUser) {
                userErrorNames.add(key);
            }
            for (const key in item.errorsComputed) {
                computedErrorNames.add(key);
            }
            for (const key in item.systemErrors) {
                systemErrorNames.add(key);
            }
            for (const key in item.recipes) {
                recipeNames.add(key);
            }
            for (const key in item.actualIngredients) {
                actualIngredientNames.add(key);
            }
            for (const key in item.desiredIngredients) {
                desiredIngredientNames.add(key);
            }
        }

        const userErrorNamesArray = userErrorNames.size > 0 ? Array.from(userErrorNames.values()).sort() : ['No Data'];
        userErrorNamesArray.unshift('Total');

        const computedErrorNamesArray = computedErrorNames.size > 0 ? Array.from(computedErrorNames.values()).sort() : ['No Data'];
        computedErrorNamesArray.unshift('Total');

        const systemErrorNamesArray = systemErrorNames.size > 0 ? Array.from(systemErrorNames.values()).sort() : ['No Data'];
        systemErrorNamesArray.unshift('Total');

        this.userErrorsChart = new LineChartData(userErrorNamesArray);
        this.computedErrorsChart = new LineChartData(computedErrorNamesArray);
        this.systemErrorsChart = new LineChartData(systemErrorNamesArray);

        this.recipesChart = new LineChartData(recipeNames.size > 0 ?
            Array.from(recipeNames.values()).sort() : ['No Data']);
        this.actualIngredientsChart = new LineChartData(recipeNames.size > 0 ?
            Array.from(actualIngredientNames.values()).sort() : ['No Data']);
        this.desiredIngredientsChart = new LineChartData(recipeNames.size > 0 ?
            Array.from(desiredIngredientNames.values()).sort() : ['No Data']);

        for (const item of this.analytics) {

            const date = new Date(item.ts);
            const label = moment(date).tz('America/Los_Angeles').format(this.range == 'hourly' ? 'MM/DD h A' : 'MM/DD');

            this.revenueChart.data[0].data.push(item.revenueTotal + item.revenueOnUs);
            this.revenueChart.data[1].data.push(item.revenueTotal);
            this.revenueChart.data[2].data.push(item.revenueIOS);
            this.revenueChart.data[3].data.push(item.revenueAndroid);
            this.revenueChart.data[4].data.push(item.revenueKiosk);
            this.revenueChart.data[5].data.push(item.revenueWeb);
            this.revenueChart.data[6].data.push(item.revenueMealPlan);
            this.revenueChart.data[7].data.push(item.revenueStripe);
            this.revenueChart.data[8].data.push(item.revenueSquare);
            this.revenueChart.labels.push(label);

            this.ordersChart.data[0].data.push(item.ordersTotal);
            this.ordersChart.data[1].data.push(item.paidOrderNumber + item.ordersOnUs);
            this.ordersChart.data[2].data.push(item.paidOrderNumber);
            this.ordersChart.data[3].data.push(item.ordersIOS);
            this.ordersChart.data[4].data.push(item.ordersAndroid);
            this.ordersChart.data[5].data.push(item.ordersKiosk);
            this.ordersChart.data[6].data.push(item.ordersWeb);
            this.ordersChart.data[7].data.push(item.ordersMealPlan);
            this.ordersChart.data[8].data.push(item.ordersReordered);
            this.ordersChart.data[9].data.push(item.ordersCancelled);
            this.ordersChart.data[10].data.push(item.ordersCancelledBeforeStart);
            this.ordersChart.data[11].data.push(item.ordersPromo);
            this.ordersChart.data[12].data.push(item.ordersFree);
            this.ordersChart.data[13].data.push(item.ordersOnUs);
            this.ordersChart.data[14].data.push(item.ordersEmployee);
            this.ordersChart.data[15].data.push(item.ordersTest);
            this.ordersChart.data[16].data.push(item.ordersStripe);
            this.ordersChart.data[17].data.push(item.ordersSquare);

            this.ordersChart.labels.push(label);

            if (this.range == 'hourly' || this.range == 'daily') {
                this.recurringLoyalistsChart = null;
            } else {
                this.recurringLoyalistsChart.data[0].data.push(item.engagementRecurring);
                this.recurringLoyalistsChart.data[1].data.push(item.engagementLoyalists);
                this.recurringLoyalistsChart.labels.push(label);
            }

            this.uniqueCustomersChart.data[0].data.push(item.totalUniqueCustomers);
            this.uniqueCustomersChart.data[1].data.push(item.totalUniqueCustomersApp);
            this.uniqueCustomersChart.labels.push(label);

            this.customizedChart.data[0].data.push(item.ordersIOS > 0 ? (item.customizedIOS / item.ordersIOS * 100) : 0);
            this.customizedChart.data[1].data.push(item.ordersAndroid > 0 ? (item.customizedAndroid / item.ordersAndroid * 100) : 0);
            this.customizedChart.data[2].data.push(item.ordersKiosk > 0 ? (item.customizedKiosk / item.ordersKiosk * 100) : 0);
            this.customizedChart.data[3].data.push(item.ordersKiosk > 0 ? (item.customizedWeb / item.ordersWeb * 100) : 0);
            this.customizedChart.data[4].data.push(item.ordersMealPlan > 0 ? (item.customizedMealPlan / item.ordersMealPlan * 100) : 0);
            this.customizedChart.labels.push(label);

            this.maintenanceChart.data[0].data.push(item.maintenanceTotal);
            // this.maintenanceChart.data[1].data.push(item.maintenanceCritical);
            // this.maintenanceChart.data[2].data.push(item.maintenanceRegular);
            this.maintenanceChart.data[1].data.push(item.maintenanceUserCritical);
            this.maintenanceChart.data[2].data.push(item.maintenanceUserScheduled);
            this.maintenanceChart.labels.push(label);

            this.uptimeChart.data[0].data.push((item.scheduledUptimeMinutes - item.maintenanceTotal) / (item.scheduledUptimeMinutes - item.maintenanceUserScheduled) * 100);
            this.uptimeChart.data[1].data.push((item.scheduledUptimeMinutes - item.maintenanceTotal) / (item.scheduledUptimeMinutes) * 100);
            this.uptimeChart.labels.push(label);

            this.orderSuccessChart.data[0].data.push((item.ordersTotal == 0 ? 1 : ((item.ordersTotal - item.ordersCancelled) / item.ordersTotal)) * 100);
            this.orderSuccessChart.labels.push(label);

            this.cogsPerUnitChart.data[0].data.push(item.ordersTotal == 0 ? 0 : (item.totalIngredientsCost / item.ordersTotal));
            this.cogsPerUnitChart.data[1].data.push(item.paidOrderNumber == 0 ? 0 : (item.totalIngredientsCost / item.paidOrderNumber));
            this.cogsPerUnitChart.labels.push(label);

            let customerDeliveryTimeSeconds = [];
            for (let key in item.customerDeliveryTimeSeconds) {
                customerDeliveryTimeSeconds.push(item.customerDeliveryTimeSeconds[key]);
            }

            this.waitTimeChart.data[0].data.push(item.waitTimeMaximum);
            this.waitTimeChart.data[1].data.push(item.waitTimeMedian);
            this.waitTimeChart.data[2].data.push(item.processingTimeMaximum);
            this.waitTimeChart.data[3].data.push(item.processingTimeMedian);
            this.waitTimeChart.data[4].data.push(customerDeliveryTimeSeconds.length > 0 ? math.max(customerDeliveryTimeSeconds) : 0);
            this.waitTimeChart.data[5].data.push(item.customerDeliveryTimeMedian);
            this.waitTimeChart.labels.push(label);

            this.reviewsChart.data[0].data.push(item.reviewFreshness / item.reviewFreshnessCount);
            this.reviewsChart.data[1].data.push(item.reviewOverall / item.reviewOverallCount);
            this.reviewsChart.data[2].data.push(item.reviewWaitTime / item.reviewWaitTimeCount);
            this.reviewsChart.data[3].data.push(item.reviewRating / item.reviewRatingCount);
            this.reviewsChart.labels.push(label);

            AnalyticsComponent.fillChart(this.userErrorsChart, item.errorsUser, label, true);
            AnalyticsComponent.fillChart(this.computedErrorsChart, item.errorsComputed, label, true);
            AnalyticsComponent.fillChart(this.systemErrorsChart, item.systemErrors, label, true);
            AnalyticsComponent.fillChart(this.recipesChart, item.recipes, label, false);
            AnalyticsComponent.fillChart(this.actualIngredientsChart, item.actualIngredients, label, false);
            AnalyticsComponent.fillChart(this.desiredIngredientsChart, item.desiredIngredients, label, false);

            date.setDate(date.getDate() + 1);
        }

        let ind = 0;
        for (let item of this.recipesChart.data) {

            if (this.recipesChart.colors[ind]) {
                this.recipesChart.colors[ind].borderColor = this.getIngredientColor(item.label);
            }
            ind++;
        }
    }

    isEmpty(obj: any) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    private getIngredientColor(label: string) {

        if (this.recipes) {
            for (let recipe of this.recipes) {
                if (recipe.title == label && recipe.colorChart && recipe.colorChart.length > 6) {
                    return recipe.colorChart;
                }
            }
        }
        return '#ff3f2d';
    }

    clearAnalyticsCache() {
        this.dashboardService.clearAnalyticsCache(this.operationalSelected, this.consumerSelected, this.financialSelected, this.selectedResetFrom.getTime()).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Clear Scheduled');
            } else {
                this.app.showError('Failed to reset analytics');
            }
        });
    }

    rebuildAnalyticsCache() {
        this.dashboardService.rebuildAnalyticsCache().subscribe(response => {
            if (response.success) {
                this.app.showNotification('Rebuild Scheduled');
            } else {
                this.app.showError('Failed to reset analytics');
            }
        });
    }
}
