<tabset *ngIf="orders; else loading">

    <tab *ngFor="let order of orders | dictionaryValues" heading="{{order.key}}">
        <h6>{{order.value.recipe}} - {{order.value.user}}</h6>

        <table class="table table-striped table-compact">
            <thead>
            <tr>
                <th>Device</th>
                <th>Process</th>
                <th>State</th>
                <th>Dispensed</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let step of order.value.steps | dictionaryValues">
                <td>{{step.value.device_name}}</td>
                <td>{{step.value.process}}</td>
                <td>{{step.value.state}}</td>
                <td>{{step.value.dispensed_weight}}</td>
                <td>

                    <ng-container *ngIf="diagnostics">
                        <ng-container *ngIf="step.value.state=='FINISHED'">
                            <button class="btn btn-simple" (click)="restart(order.key, step.key)">Restart</button>
                        </ng-container>

                        <ng-container
                                *ngIf="step.value.state=='STARTED' || step.value.state=='RUNNING' || step.value.state=='PAUSED'">
                            <button class="btn btn-simple" (click)="restart(order.key, step.key)">Restart</button>
                            <button class="btn btn-simple" (click)="skip(order.key, step.key)">Skip</button>
                        </ng-container>

                        <ng-container *ngIf="step.value.state=='WAITING'">
                            <button class="btn btn-simple" (click)="restart(order.key, step.key)">Start</button>
                            <button class="btn btn-simple" (click)="skip(order.key, step.key)">Skip</button>
                        </ng-container>
                    </ng-container>

                </td>
            </tr>
        </table>
    </tab>
</tabset>
<ng-template #loading>{{loaded ? "No orders" : "Loading..."}}</ng-template>