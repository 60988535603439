import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../../common/field';
import {KioskService} from '../../../services/kiosk.service';
import {KioskDevicesComponent} from './kiosk-devices.component';
import {AppComponent} from '../../../app.component';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {FieldsListComponent} from '../../../components/fields-list.component';
import {Helper} from '../../../common/helper';
import {KioskDataService} from '../../../services/kiosk-data.service';
import {DeviceTemplateService} from '../../../services/device-template.service';
import {DeviceBean, DeviceTemplateBean, EntityNameBean, IngredientBean, VisionObjectAreaBean} from '../../../model/model';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'kiosk-devices-edit-component',
    templateUrl: 'kiosk-devices.edit.component.html'
})

export class KioskDevicesEditComponent implements AfterViewInit {

    device: DeviceBean = {} as DeviceBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    fields: Field[] = [
        new Field('id', 'Device ID').disable(),
        new Field('name', 'Name').require(),
        new Field('disabled', 'Disabled', FieldType.CHECKBOX),
        new Field('operationType', 'Operation Type', FieldType.SELECT).setOptions(['NORMAL', 'SIMULATION', 'PSEUDO_SIM']),
    ];
    deviceTemplate: DeviceTemplateBean;
    ingredients: IngredientBean[];
    vendingMachineRecipes: EntityNameBean[];

    compareBeans = Helper.compareBeans;
    devices: DeviceBean[];
    disabledStorageLocations: boolean[] = [false, false, false, false];
    private kioskId: number;
    private operatorId: number;

    canDispenseIngredientsOrRecipe: {[index: string]: IngredientBean | EntityNameBean};

    constructor(private kioskService: KioskService,
        private kioskDataService: KioskDataService,
        private sanitizer: DomSanitizer,
        private deviceTemplateService: DeviceTemplateService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent,
        @Inject(forwardRef(() => KioskDevicesComponent)) private parent: KioskDevicesComponent) {
    }

    range = (value) => {
        const a = [];
        for (let i = 0; i < value; ++i) {
            a.push(i);
        }
        return a;
    };

    ngAfterViewInit(): void {
        this.kioskDataService.getData().subscribe(kioskData => {
            this.ingredients = [];
            for (const ingredient of kioskData.value.ingredients) {
                this.ingredients.push(Helper.copyObject(ingredient));
            }
            this.vendingMachineRecipes = [];
            for (const recipe of kioskData.value.vendingMachineRecipes) {
                this.vendingMachineRecipes.push(Helper.copyObject(recipe));
            }

            console.log(this.vendingMachineRecipes);
        });

    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    open(kioskId: number, operatorId: number, device: DeviceBean, devices: DeviceBean[]) {
        this.kioskId = kioskId;
        this.operatorId = operatorId;

        this.devices = devices;
        this.device = (device ? Helper.copyObject(device) : {} as DeviceBean);
        if (!this.device.canDispenseIngredients) {
            this.device.canDispenseIngredients = {};
        }
        if (!this.device.canDispenseRecipes) {
            this.device.canDispenseRecipes = {};
        }

        this.base.show(device == null ? 'Create new Device' : 'Edit ' + device.name);
        this.onDeviceChanged();

        this.deviceTemplateService.getDeviceTemplates().subscribe(deviceTemplates => {

            for (const deviceTemplate of deviceTemplates.list) {
                if (deviceTemplate.id == this.device.deviceTemplateId) {
                    this.deviceTemplate = deviceTemplate;
                }
            }

            Helper.selectPicker('select_hw', 'select_dqt', 'edit_type');
        });

        this.disabledStorageLocations = [false, false, false, false];
        for (let id of this.device.disabledStorageLocations) {
            this.disabledStorageLocations[id - 1] = true;
        }

        this.canDispenseIngredientsOrRecipe = {};
        for (const key in this.device.canDispenseIngredients) {
            this.canDispenseIngredientsOrRecipe[key] = this.device.canDispenseIngredients[key];
        }
        for (const key in this.device.canDispenseRecipes) {
            this.canDispenseIngredientsOrRecipe[key] = this.device.canDispenseRecipes[key];
        }
    }

    getIngredients(ingredientType: string) {

        const result = [];
        for (const ingredient of this.ingredients) {
            if (!ingredientType || ingredient.type == ingredientType) {
                result.push(ingredient);
            }
        }
        return result;
    }

    onDeviceChanged() {
        setTimeout(() => {
            for (const i of this.range(this.device.numberOfIngredients)) {
                Helper.selectPicker('select_ingredient' + i);
            }
            Helper.selectPicker('select_boosts');

        }, 1);
        if (!this.isIngredient()) {
            this.device.canDispenseIngredients = {};
        }
        if (!this.isVendingMachine()) {
            this.device.canDispenseRecipes = {};
        }
    }

    isIngredient() {
        return this.device.dispenserType == 'INGREDIENT';
    }

    isVendingMachine() {
        return this.device.dispenserType == 'VENDING_MACHINE';
    }

    isVision() {
        return this.device.abstractDeviceName == 'Vision';
    }

    save() {

        // if (Object.keys(this.device.canDispenseIngredients).length == 0) {
        //     this.device.canDispenseIngredients = null;
        // }
        //
        // for (const key in this.device.canDispenseIngredients) {
        //     if (!this.device.canDispenseIngredients[key]) {
        //         delete this.device.canDispenseIngredients[key];
        //     }
        // }
        this.device.canDispenseIngredients = {};
        this.device.canDispenseRecipes = {};

        for (const key in this.canDispenseIngredientsOrRecipe) {

            if (this.canDispenseIngredientsOrRecipe[key]) {
                if (this.canDispenseIngredientsOrRecipe[key]['type']) {
                    // @ts-ignore
                    this.device.canDispenseIngredients[key] = this.canDispenseIngredientsOrRecipe[key];

                } else {
                    this.device.canDispenseRecipes[key] = this.canDispenseIngredientsOrRecipe[key];
                }
            } else {
                delete this.device.canDispenseIngredients[key];
                delete this.device.canDispenseRecipes[key];
            }
        }

        console.log(this.device.canDispenseRecipes);
        console.log(this.device.canDispenseIngredients);

        this.device.disabledStorageLocations = [];

        let ind = 1;
        for (let id of this.disabledStorageLocations) {
            if (id) {
                this.device.disabledStorageLocations.push(ind);
            }
            ind++;
        }

        this.kioskService.updateDevice(this.kioskId, this.operatorId, this.device).subscribe(response => {

            if (response.success) {
                this.parent.loadDevices();
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update device');
            }
        }, error => {
            this.app.showError('Can\'t update device');
        });
    }

    addRect() {
        this.parent.openRectEditDialog(this.device, null);
    }

    editRect(rect: VisionObjectAreaBean) {
        this.parent.openRectEditDialog(this.device, rect);
    }

    removeRect(obj: VisionObjectAreaBean) {

        const index = this.device.visionObjectAreas.indexOf(obj);
        if (index != -1) {
            this.device.visionObjectAreas.splice(index, 1);
        }
    }

    isStorageAndDelivery() {
        return this.device.abstractDeviceName == 'Storage And Delivery System';
    }

    isTemperatureSensor() {
        return this.device.abstractDeviceName == 'Temperature Sensor';
    }
}
