<div class="animated fadeIn">
    <div class="col-md-12">
        <select hidden
                id="selectpicker"
                data-style="select-btn"
                title="Select Category"
                data-size="7"
                [compareWith]="compareBeans"
                [(ngModel)]="selectedCategory"
                (change)="onCategoryChange()">
            <option [ngValue]="category" selected="selected"
                    *ngFor="let category of categories">{{category.name}}
            </option>
        </select>

        <div class="card">
            <div class="card-content">
                <table-component (onEdit)="onEdit($event)" extraText="Export" (onExtra)="onExport($event)"
                                 (onRemove)="onRemove($event)"
                                 (onAdd)="onAdd()"></table-component>
            </div>
        </div>
    </div>


</div>

<angular-file-uploader (ApiResponse)="onFileUploaded($event)"
        [config]="afuConfig">
</angular-file-uploader>

<recipes-edit-component #recipesEditComponent></recipes-edit-component>
<depends-on-edit-component #dependsOnEditComponent></depends-on-edit-component>
<process-edit-component #processEditComponent (onResult)="addProcess($event)"></process-edit-component>