<div class="animated fadeIn">

    <div class="content">

        <div class="row">

            <div class="form-horizontal">
                <div class="form-group">
                    <label class="col-md-2 control-label" for="select_mode">Frequency</label>
                    <div class="col-md-10">

                        <select (change)="rangeChange(null)"
                                [(ngModel)]="range"
                                class="form-control"
                                data-size="7"
                                data-style="select-btn"
                                id="select_mode">
                            <option value="monthly">Monthly</option>
                            <option value="weekly">Weekly</option>
                            <option [selected]="true" value="daily">Daily</option>
                        </select>
                    </div>
                </div>
            </div>

        </div>


        <div class="row pt15">
            <div class="com-md-12 col-lg-6">
                <div *ngIf="eventsCountChart && eventsCountChart.data.length > 0">
                    <h6 class="text-center">Total Events Count: {{totalEventsCount}}</h6>
                    <div [style.height]="'400px'">
                        <canvas baseChart [style.height]="'400px'"
                                [datasets]="eventsCountChart.data"
                                [labels]="eventsCountChart.labels"
                                [options]="lineChartOptions"
                                [colors]="eventsCountChart.colors"
                                [legend]="true"
                                [chartType]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>

            <div class="com-md-12 col-lg-6">
                <div *ngIf="eventsDurationChart && eventsDurationChart.data.length > 0">
                    <h6 class="text-center">Total Events Duration: {{totalEventsDuration}} minutes</h6>
                    <div [style.height]="'400px'">
                        <canvas baseChart [style.height]="'400px'"
                                [datasets]="eventsDurationChart.data"
                                [labels]="eventsDurationChart.labels"
                                [options]="lineChartOptions"
                                [colors]="eventsDurationChart.colors"
                                [legend]="true"
                                [chartType]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>

        </div>
        <div class="row pt15">
            <div class="com-md-12 col-lg-6">
                <div *ngIf="eventsCountByCategoryChart && eventsCountByCategoryChart.data.length > 0">
                    <h6 class="text-center">Top Service Events by Sub Category</h6>
                    <div [style.height]="'400px'">
                        <canvas baseChart [style.height]="'400px'"
                                [datasets]="eventsCountByCategoryChart.data"
                                [labels]="eventsCountByCategoryChart.labels"
                                [options]="stackedChartOptions"
                                [colors]="colorsArray"
                                [legend]="false"
                                [chartType]="'bar'">
                        </canvas>
                    </div>
                </div>
            </div>

            <div class="com-md-12 col-lg-6">
                <div *ngIf="eventsDurationByCategoryChart && eventsDurationByCategoryChart.data.length > 0">
                    <h6 class="text-center">Top Service Duration by Category (minutes)</h6>
                    <div [style.height]="'400px'">
                        <canvas baseChart [style.height]="'400px'"
                                [datasets]="eventsDurationByCategoryChart.data"
                                [labels]="eventsDurationByCategoryChart.labels"
                                [options]="stackedChartOptions"
                                [colors]="colorsArray"
                                [legend]="false"
                                [chartType]="'bar'">
                        </canvas>
                    </div>
                </div>
            </div>

            <div class="com-12 pt15">
                <div *ngIf="eventsCountByCategoryTrendChart && eventsCountByCategoryTrendChart.data.length > 0">
                    <h6 class="text-center">Service Duration by Category Trend</h6>
                    <div [style.height]="'400px'">
                        <canvas baseChart [style.height]="'400px'"
                                [datasets]="eventsCountByCategoryTrendChart.data"
                                [labels]="eventsCountByCategoryTrendChart.labels"
                                [options]="lineChartOptions"
                                [colors]="colorsArray"
                                [legend]="true"
                                [chartType]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>

            <div class="com-12 pt15">
                <div *ngIf="eventsDurationByCategoryTrendChart && eventsDurationByCategoryTrendChart.data.length > 0">
                    <h6 class="text-center">Service Duration by Category Trend (minutes)</h6>
                    <div [style.height]="'400px'">
                        <canvas baseChart [style.height]="'400px'"
                                [datasets]="eventsDurationByCategoryTrendChart.data"
                                [labels]="eventsDurationByCategoryTrendChart.labels"
                                [options]="lineChartOptions"
                                [colors]="colorsArray"
                                [legend]="true"
                                [chartType]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>

            <div class="com-12 pt15">
                <div *ngIf="eventsDurationBySubComponentTrendChart && eventsDurationBySubComponentTrendChart.data.length > 0">
                    <h6 class="text-center">Top Service Duration by Component Trend (minutes)</h6>
                    <div [style.height]="'600px'">
                        <canvas baseChart [style.height]="'600px'"
                                [datasets]="eventsDurationBySubComponentTrendChart.data"
                                [labels]="eventsDurationBySubComponentTrendChart.labels"
                                [options]="lineChartOptions"
                                [colors]="colorsArray"
                                [legend]="true"
                                [chartType]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

