import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {RecipeListStateBean} from '../../../../model/model';
import {KioskManageService} from '../../../../services/kiosk-manage.service';
import {AppComponent} from '../../../../app.component';

@Component({
    selector: 'kiosk-recipes-lists-component',
    templateUrl: 'kiosk-recipes-lists.component.html'
})
export class KioskRecipesListsComponent implements AfterViewInit {

    recipesList: RecipeListStateBean[];
    kioskId: number;

    constructor(private kioskManageService: KioskManageService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskId: number, operatorId: number) {
        this.kioskId = kioskId;
        this.kioskManageService.getRecipeLists(kioskId).subscribe(response => {
            if (response.success) {
                this.recipesList = response.value;
            }
        });
    }

    save() {
        this.kioskManageService.setRecipeLists(this.kioskId, this.recipesList).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update kiosk');
            }
        }, error => {
            this.app.showError('Can\'t update kiosk');
        });
    }
}
