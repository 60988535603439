import {AbstractDeviceBean, ProcessParameterValueBean, StepBean} from '../model/model';
import {UserType} from '../app.constants';
import {Rights, RightsGroup, RightsManager} from './rights';
import * as moment from 'moment-timezone';

const sha1 = require('sha1');

function isPrimitive(test) {
    return (test !== Object(test));
}

function isString(val) {
    return (typeof val === 'string' || val instanceof String);
}

export class Helper {

    static faIcons = {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
    };

    static copyObject(value: object): any {
        return JSON.parse(JSON.stringify(value));
    }

    static getStepParametersString(step: StepBean, devices: AbstractDeviceBean[]): string {
        return this.getStepParametersStringByParams(step.parameters, devices);
    }

    static getStepParametersStringByParams(params, devices): string {
        let result: string = '';
        for (const key in params) {

            if (params.hasOwnProperty(key)) {

                if (key.trim().length == 0) {
                    continue;
                }
                const value: ProcessParameterValueBean = params[key];
                const device = Helper.getDeviceById(value.value, devices);

                switch (value.type) {
                    case 'INGREDIENT_QUANTITY':
                        result += (key + ': ' + 'INGREDIENT_QUANTITY(?); ');
                        break;
                    case 'INGREDIENT_QUANTITY_SECONDS':
                        result += (key + ': ' + 'INGREDIENT_QUANTITY_SECONDS(?); ');
                        break;
                    case 'NUMBER':
                        result += (key + ': ' + 'NUMBER(' + (value.value ? value.value : '?') + '); ');
                        break;
                    case 'TEXT':
                        result += (key + ': ' + 'TEXT(' + (value.stringValue ? value.stringValue : '?') + '); ');
                        break;
                    case 'DEVICE':
                        result += (key + ': ' + (device ? device.name : '') + '; ');
                        break;
                    case 'DISPENSER':
                        result += (key + ': ' + 'Dispenser; ');
                        break;
                    case 'DEVICE_PLACE':
                        result += (key + ': ' + (device ? device.name : '') + ' place; ');
                        break;
                    case 'DELIVERY_PLACE':
                        result += (key + ': ' + 'DELIVERY_PLACE(?); ');
                        break;
                    case 'BLEND_TIME':
                        result += (key + ': ' + 'BLEND_TIME(?); ');
                        break;
                }
            }
        }
        return result.trim();
    }

    static getDeviceById(id: number, devices) {
        for (const device of devices) {
            if (device.id == id) {
                return device;
            }
        }
        return null;
    }

    static getStepDescription(step: StepBean, devices: AbstractDeviceBean[]) {
        if (!step) {
            return '';
        }

        for (const key2 in step.parameters) {
            const value2 = step.parameters[key2];
            if (parseInt('' + value2.type, 10) >= 0) {
            } else {
                // value2.type = ValueType['' + value2.type];
            }
        }

        return (step.device ? step.device.name : 'NULL') + ' -> ' + (step.process ? step.process.name : 'NULL') +
            ' (' + Helper.getStepParametersString(step, devices) + ')';
    }

    public static encodePassword(password: string) {
        const key: string = '6LeFdhYTAAAAAO5QDWN62fP0zY-dtdqtQAvQKdfI';
        let result: string = '0x';
        const bytes: number[] = Helper.hex2bin(sha1(password + key));

        for (let char of bytes) {
            if (char > 127) {
                char = -256 + char;
            }
            const num: number = (char & 0xff) + 0x100;
            result += num.toString(16).substr(1).toUpperCase();
        }
        return result;
    }

    static hex2bin(hexString: string): number[] {
        const arr: number[] = [];
        for (let i = 0; i < hexString.length; i += 2) {
            arr.push(+('0x' + hexString.substr(i, 2)));
        }
        return arr;
    }

    static generateGUID() {
        const S4 = () => {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
    }

    static objToString(obj) {
        let str = '[';
        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                str += p + ': ' + obj[p] + ', ';
            }
        }
        if (str.length > 2) {
            str = str.slice(0, -2);
        }
        return str + ']';
    }

    static hashCode(str: string) {
        let hash = 0;
        if (str.length == 0) {
            return hash;
        }
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    static hexToRgb(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    static componentToHex(c) {
        const hex = c.toString(16);
        return hex.length == 1 ? '0' + hex : hex;
    }

    static rgbToHex(r, g, b) {
        return '#' + Helper.componentToHex(r) + Helper.componentToHex(g) + Helper.componentToHex(b);
    }

    static compareBeans(b1, b2): boolean {

        return b1 && b2 ? ((b1.id != null && b1.id == b2.id) || (b1.kioskId != null && b1.kioskId == b2.kioskId)) : b1 == b2;
    }

    static getUserTypeString(userType: UserType): string {
        switch (userType) {
            case 'ADMIN':
                return 'admins';
            case 'CUSTOMER':
                return 'customers';
            case 'USER':
                return 'users';
        }
        return '';
    }

    static selectPicker(...ids) {
        setTimeout(() => {
            for (let id of ids) {
                $('#' + id).selectpicker('destroy').selectpicker();
            }
        }, 100);
    }

    static hashNum(str): number {
        return str.split('').reduce((prevHash, currVal) =>
            (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
    }

    static mulberry32(a): number {
        let t = a += 0x6D2B79F5;
        t = Math.imul(t ^ t >>> 15, t | 1);
        t ^= t + Math.imul(t ^ t >>> 7, t | 61);
        return ((t ^ t >>> 14) >>> 0) / 4294967296;
    }

    static profileTypes: RightsManager[] = [
        new RightsManager('Admin', RightsGroup.ADMIN),
        new RightsManager('Developer', RightsGroup.DEVELOPER),
        new RightsManager('Chef', RightsGroup.CHEF),
        new RightsManager('Business Ops', RightsGroup.BUSINESS_OPS),
        new RightsManager('Store Manager', RightsGroup.STORE_MANAGER),
        new RightsManager('Customer', Rights.MANAGE_DASHBOARD),
    ];

    static startOf(ts: Date): number {
        return moment.tz(moment(ts).format('YYYY-MM-DDT00:00:00'), 'America/Los_Angeles').unix() * 1000;
    }

    static endOf(ts: Date): number {
        return moment.tz(moment(ts).format('YYYY-MM-DDT23:59:59'), 'America/Los_Angeles').unix() * 1000;
    }

    static merge(initValue: any, currentValue: any) {
        const result = {};

        for (const key in initValue) {
            if (initValue.hasOwnProperty(key)) {

                if (isPrimitive(currentValue[key])) {
                    if (initValue[key] != currentValue[key]) {
                        result[key] = currentValue[key];
                        if (isString(initValue[key]) && !currentValue[key]) {
                            result[key] = '';
                        }
                    }
                } else {
                    if (Helper.objToString(initValue[key]) != Helper.objToString(currentValue[key])) {
                        result[key] = currentValue[key];
                    }
                }
            }
        }
        return result;
    }

    static co

}
