`
<div class="animated fadeIn">
    <div class="col-md-12">

        <agm-map [latitude]="averageLatitude" *ngIf="kiosks"
                 [longitude]="averageLongitude"
                 [zoom]="5"
                 [disableDefaultUI]="false"
                 [zoomControl]="true">
            <agm-marker *ngFor="let kiosk of filteredKiosk"
                        (markerClick)="clickedMarker(kiosk)"
                        [latitude]="kiosk.latitude"
                        [longitude]="kiosk.longitude">

                <agm-info-window>
                    <a (click)="onClickRow(kiosk)">
                        {{kiosk.name}}
                    </a>
                </agm-info-window>

            </agm-marker>
        </agm-map>

        <br/><br/>

        <div>
            <select
                    multiple hidden
                    id="select_state"
                    data-style="select-btn" data-size="7" (change)="onStateChange()"
                    [(ngModel)]="selectedStates">
                <option [ngValue]="'OUT_OF_SERVICE'">OUT OF SERVICE</option>
                <option [ngValue]="'WAITING'">WAITING</option>
                <option [ngValue]="'OPERATIONAL'">OPERATIONAL</option>
                <option [ngValue]="'MAINTENANCE'">MAINTENANCE</option>
                <option [ngValue]="'PROVISIONING'">PROVISIONING</option>
                <option [ngValue]="'DECOMMISSIONED'">DECOMMISSIONED</option>
            </select>
        </div>

        <div class="card">
            <div class="card-content">
                <table-component (onStats)="onStats($event)" (onView)="onView($event)"
                                 [rowClick]="canEditKiosk()"
                                 [statsButton]="canEditKiosk()"
                                 (onQuickView)="onQuickView($event)"
                                 (onEdit)="onEdit($event)" (onClickRow)="onClickRow($event)"
                                 (onAdd)="onAdd()"></table-component>
            </div>
        </div>

    </div>


</div>
<kiosks-quickview-component #kiosksQuickviewComponent></kiosks-quickview-component>
<map-modal-component></map-modal-component>
