<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()">

    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">

                <label for="select_device" class="col-md-3 control-label">Abstract Device <span
                        class="text-danger">*</span></label>
                <div class="col-md-9">

                    <select class="form-control form-control-low" id="select_device"
                            [compareWith]="compareBeans"
                            [(ngModel)]="selectedAbstractDeviceBean">
                        <option *ngFor="let device of devices" [ngValue]="device">
                            {{device.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <fields-list-component [fields]="fields" name="device_templates_edit"
                           [value]="deviceTemplate"></fields-list-component>


    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">

                <label class="col-md-3 control-label">Hardware Versions</label>

                <div class="col-md-4">

                    <label for="hw_version" class="control-label">Version</label>
                    <input
                            type="text" id="hw_version" [(ngModel)]="hwVersion"
                            class="form-control form-control-low"/>

                </div>
                <div class="col-md-4">

                    <label for="hw_description" class="control-label">Description</label>
                    <input
                            type="text" id="hw_description" [(ngModel)]="hwDescription"
                            class="form-control form-control-low"/>

                </div>

                <div class="col-md-1">

                    <label for="add_hw" class="control-label">&nbsp;</label>
                    <button type="button"
                            id="add_hw"
                            class="form-control form-control-low btn btn-link btn-sm black"
                            (click)="addHardwareVersion()">
                        <i class="fa fa-plus"></i></button>

                </div>

            </div>


            <div class="form-group" *ngFor="let hw of this.deviceTemplate.hwVersions">

                <label class="col-md-3 control-label"></label>

                <div class="col-md-4">
                    <input type="text" [(ngModel)]="hw.version" class="form-control form-control-low"/>
                </div>
                <div class="col-md-4">
                    <input type="text" [(ngModel)]="hw.description" class="form-control form-control-low"/>
                </div>

                <div class="col-md-1">
                    <button type="button" id="add_hw"
                            class="form-control form-control-low btn btn-link btn-sm black"
                            (click)="removeHardwareVersion(hw)">
                        <i class="fa fa-minus"></i></button>
                </div>

            </div>
        </div>
    </div>

</base-modal-component>
