<div class="animated fadeIn">
    <div class="col-md-12">
        <div class="card">
            <div class="card-content">

                <table-component (onEdit)="onEdit($event)"
                                 (onRemove)="onRemove($event)" extraText="Batch Upload" [extraAutoDisabled]="false" (onExtra)="onBatchUpload()"
                                 (onAdd)="onAdd()"></table-component>
            </div>
        </div>
    </div>
</div>
<image-manager-edit-component></image-manager-edit-component>
<image-manager-upload-component></image-manager-upload-component>