<div class="animated fadeIn">


    <div class="col-md-12">

        <div class="row">

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="select_kiosk">Locations</label>
                        <div class="col-md-10">
                            <select (change)="onKioskChange()" [(ngModel)]="selectedKiosks"
                                    multiple
                                    [compareWith]="compareBeans" [disabled]="kiosks.length==0"
                                    class="form-control"
                                    data-size="7"
                                    data-style="select-btn"
                                    id="select_kiosk"
                                    title="All Locations">
                                <option *ngFor="let kiosk of kiosks" [ngValue]="kiosk">{{kiosk.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="from_date">Date Range</label>
                        <div class="col-md-10">
                            <input (bsValueChange)="onDateRangeChanged()" [(ngModel)]="selectedRange"
                                   class="form-control"
                                   id="from_date" bsDaterangepicker type="text"/>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-horizontal">
                <div class="card-content">
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="select_mode">Frequency</label>
                        <div class="col-md-10">

                            <select (change)="rangeChange(null)"
                                    [(ngModel)]="range"
                                    class="form-control"
                                    data-size="7"
                                    data-style="select-btn"
                                    id="select_mode">
                                <option [selected]="true" value="current">Current Week</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div class="content">


            <div *ngIf="data" class="card">
                <div class="card-block">
                    <table class="table">
                        <thead>
                        <tr>
                            <th class="col-md-4">Financial Metrics</th>
                            <th *ngFor="let item of data">{{item['title']}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="col-md-4">Number of Transactions (#)</td>
                            <td *ngFor="let item of data">{{item.ordersTotal | number}}</td>
                        </tr>
                        <tr>
                            <td class="col-md-4">Customer Transactions (#) <span
                                    tooltip="Includes appeasement orders">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.paidOrderNumber + item.ordersOnUs | number}}</td>
                        </tr>
                        <tr>
                            <td>Paid Transactions (#) <span
                                    tooltip="Includes free promotional transactions, but not appeasement orders">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.paidOrderNumber | number}}</td>
                        </tr>
                        <tr>
                            <td>Promotional Transactions (#)</td>
                            <td *ngFor="let item of data">{{item.ordersPromo | number}}</td>
                        </tr>
                        <tr>
                            <td>Free Transactions (#) <span
                                    tooltip="Employee Meals, Test Drinks, It’s On Us customer redemptions, and free promotional drinks">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.ordersFree | number}}</td>
                        </tr>
                        <tr>
                            <td>App Transactions (#)</td>
                            <td *ngFor="let item of data">{{item.ordersIOS + item.ordersAndroid | number}}</td>
                        </tr>
                        <tr>
                            <td>Web Transactions (#)</td>
                            <td *ngFor="let item of data">{{item.ordersWeb | number}}</td>
                        </tr>
                        <tr>
                            <td>Customer Transactions per Hour (#)</td>
                            <td *ngFor="let item of data">{{(item.paidOrderNumber + item.ordersOnUs) / (item.scheduledUptimeMinutes / 60) | number}}</td>
                        </tr>
                        <tr>
                            <td>Paid Transactions (%)</td>
                            <td *ngFor="let item of data">{{item.paidOrderNumber / item.ordersTotal | percent:'1.2'}}</td>
                        </tr>
                        <tr>
                            <td>Promotional Transactions (%) <span
                                    tooltip="Percentage of Paid Transactions that are Promotional Transactions">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.ordersPromo / item.paidOrderNumber | percent:'1.2'}}</td>
                        </tr>
                        <tr>
                            <td>Free Transactions (%)<span
                                    tooltip="Percentage of all Transactions that are Free Transactions">�</span></td>
                            <td *ngFor="let item of data">{{item.ordersFree / item.ordersTotal | percent:'1.2'}}</td>
                        </tr>
                        <tr>
                            <td>App Transactions (%) <span
                                    tooltip="Percentage of Paid Transactions ordered via the Blendid mobile app">�</span>
                            </td>
                            <td *ngFor="let item of data">{{(item.ordersIOS + item.ordersAndroid) / item.paidOrderNumber | percent:'1.2'}}</td>
                        </tr>
                        <tr>
                            <td>Gross Sales ($)</td>
                            <td *ngFor="let item of data">{{((item.paidOrderNumber + item.ordersOnUs) * 6) | currency}}</td>
                        </tr>
                        <tr>
                            <td>Net Sales ($)</td>
                            <td *ngFor="let item of data">{{item.revenueTotal | currency}}</td>
                        </tr>
                        <tr>
                            <td>Average Order Value ($) <span tooltip="For Paid Transactions">�</span></td>
                            <td *ngFor="let item of data">{{(item.revenueTotal / item.paidOrderNumber) | currency}}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div *ngIf="data" class="card">
                <div class="card-block">
                    <table class="table">
                        <thead>
                        <tr>
                            <th class="col-md-4">Engagement Metrics</th>
                            <th *ngFor="let item of data">{{item['title']}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="col-md-4">Total Unique Customers (#) <span
                                    tooltip="For Paid Transactions only">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.totalUniqueCustomers | number}}</td>
                        </tr>
                        <tr>
                            <td>Engagement - Loyalists (#) <span
                                    tooltip="Customers who purchased more than once in the current week">�</span></td>
                            <td *ngFor="let item of data">{{item.engagementLoyalists | number}}</td>
                        </tr>
                        <tr>
                            <td>Engagement - Recurring (#) <span
                                    tooltip="Customers who purchased in the previous week and the current week, not including Loyalists">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.engagementRecurring | number}}</td>
                        </tr>
                        <tr>
                            <td>Engagement - Loyalists (%)</td>
                            <td *ngFor="let item of data">{{item.engagementLoyalists / item.totalUniqueCustomers | percent:'1.2'}}</td>
                        </tr>
                        <tr>
                            <td>Engagement - Recurring (%)</td>
                            <td *ngFor="let item of data">{{item.engagementRecurring / item.totalUniqueCustomers | percent:'1.2'}}</td>
                        </tr>
                        <tr>
                            <td>Orders count - Loyalists (#)</td>
                            <td *ngFor="let item of data">{{item.loyalistsOrdersNumber  | number}}</td>
                        </tr>
                        <tr>
                            <td>Orders count - Recurring (#)</td>
                            <td *ngFor="let item of data">{{item.recurringOrdersNumber  | number}}</td>
                        </tr>
                        <tr>
                            <td>Orders per Customer - Loyalists (#)</td>
                            <td *ngFor="let item of data">{{item.loyalistsOrdersNumber / item.engagementLoyalists | number: '1.1-1'}}</td>
                        </tr>
                        <tr>
                            <td>Orders per Customer - Recurring (#)</td>
                            <td *ngFor="let item of data">{{item.recurringOrdersNumber / item.engagementRecurring | number: '1.1-1'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div *ngIf="data" class="card">
                <div class="card-block">
                    <table class="table">
                        <thead>
                        <tr>
                            <th class="col-md-4">Customer Experience Metrics</th>
                            <th *ngFor="let item of data">{{item['title']}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Reviews - Total</td>
                            <td *ngFor="let item of data">{{ item.reviewOverallCount  | number}}</td>
                        </tr>
                        <tr>
                            <td>Reviews - Freshness</td>
                            <td *ngFor="let item of data">{{(item.reviewFreshnessCount > 0 ? (item.reviewFreshness / item.reviewFreshnessCount) : 0) | number}}</td>
                        </tr>
                        <tr>
                            <td>Reviews - Wait Time</td>
                            <td *ngFor="let item of data">{{(item.reviewWaitTimeCount > 0 ? (item.reviewWaitTime / item.reviewWaitTimeCount) : 0) | number}}</td>
                        </tr>
                        <tr>
                            <td>Reviews - Overall</td>
                            <td *ngFor="let item of data">{{(item.reviewOverallCount > 0 ? (item.reviewOverall / item.reviewOverallCount) : 0) | number}}</td>
                        </tr>
                        <tr>
                            <td>Wait Time - Median (Hrs:Mins:Secs) <span
                                    tooltip="Time from customer placing an order to when the kiosk starts processing the order.">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.waitTimeMedian | time }}</td>
                        </tr>
                        <tr>
                            <td>Wait Time - Worst (Hrs:Mins:Secs) <span
                                    tooltip="4-Week Average is the maximum wait time during the 4 week period.">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.waitTimeMaximum | time }}</td>
                        </tr>
                        <tr>
                            <td>Processing Time - Min (Hrs:Mins:Secs) <span
                                    tooltip="4-Week Average is the minimum processing time during the 4 week period.">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.processingTimeMinimum | time }}</td>
                        </tr>
                        <tr>
                            <td>Processing Time - Median (Hrs:Mins:Secs) <span
                                    tooltip="Time from when the kiosk starts processing an order to when the drink is available for the customer.">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.processingTimeMedian | time }}</td>
                        </tr>
                        <tr>
                            <td>Processing Time - Worst (Hrs:Mins:Secs) <span
                                    tooltip="4-Week Average is the maximum processing time during the 4 week period.">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.processingTimeMaximum | time }}</td>
                        </tr>
                        <tr>
                            <td>Customer Delivery Time - Median (Hrs:Mins:Secs) <span
                                    tooltip="Total time from order to delivery of drink.  4-Week Average is the median delivery time during the 4 week period.">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.customerDeliveryTimeMedian | time }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div *ngIf="data" class="card">
                <div class="card-block">
                    <table class="table">
                        <thead>
                        <tr>
                            <th class="col-md-4">Operational Metrics</th>
                            <th *ngFor="let item of data">{{item['title']}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="col-md-4">Hours of Operation (Hrs)</td>
                            <td *ngFor="let item of data">{{item.scheduledUptimeMinutes / 60 | number}}</td>
                        </tr>
                        <tr>
                            <td>Maintenance Count (#)</td>
                            <td *ngFor="let item of data">{{sum(item.errorsUser) + sum(item.errorsComputed) | number}}</td>
                        </tr>
                        <tr>
                            <td>Scheduled Service Count (#) <span
                                    tooltip="Scheduled Service is for restocking of ingredients">�</span></td>
                            <td *ngFor="let item of data">{{sum(item.errorsUser) | number}}</td>
                        </tr>
                        <tr>
                            <td>Unscheduled Service Count (#) <span
                                    tooltip="Unscheduled Service is any maintenance required due to errors">�</span>
                            </td>
                            <td *ngFor="let item of data">{{sum(item.errorsComputed) | number}}</td>
                        </tr>
                        <tr>
                            <td>Total Downtime (Hrs)</td>
                            <td *ngFor="let item of data">{{item.maintenanceUserScheduled + item.maintenanceUserCritical | minute}}</td>
                        </tr>
                        <tr>
                            <td>Scheduled Servicing Time (Hrs:Mins:Secs) <span
                                    tooltip="Scheduled Service is for restocking of ingredients">�</span></td>
                            <td *ngFor="let item of data">{{item.maintenanceUserScheduled | minute}}</td>
                        </tr>
                        <tr>
                            <td>Unscheduled Servicing Time (Hrs:Mins:Secs) <span
                                    tooltip="Unscheduled Service is any maintenance required due to errors">�</span>
                            </td>
                            <td *ngFor="let item of data">{{item.maintenanceUserCritical | minute}}</td>
                        </tr>
                        <tr>
                            <td class="col-md-4">Service Availability (%) <span
                                    tooltip="Percentage of advertised operating hours that the kiosk is operational">�</span>
                            </td>
                            <td *ngFor="let item of data">{{(item.scheduledUptimeMinutes - item.maintenanceTotal) / (item.scheduledUptimeMinutes) | percent:'1.2'}}</td>
                        </tr>
                        <tr>
                            <td class="col-md-4">Uptime (%) <span
                                    tooltip="Percentage of operating hours not including scheduled maintenance time that the kiosk is operational">�</span>
                            </td>
                            <td *ngFor="let item of data">{{(item.scheduledUptimeMinutes - item.maintenanceTotal) / (item.scheduledUptimeMinutes - item.maintenanceUserScheduled) | percent:'1.2'}}</td>
                        </tr>
                        <tr>
                            <td>Order Success Rate (%) <span
                                    tooltip="Percentage of orders where the customer successfully receives their drink">�</span>
                            </td>
                            <td *ngFor="let item of data">{{(item.ordersTotal - item.ordersCancelled + item.ordersCancelledBeforeStart) / item.ordersTotal | percent:'1.2'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div *ngIf="status" class="card">
                <div class="card-block">
                    <table class="table">
                        <thead>
                        <tr>
                            <th class="col-md-2">Kiosk</th>
                            <th class="col-md-2">Status</th>
                            <th>Orders in queue</th>
                            <th>Orders processed</th>
                            <th>Average wait time</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let stat of status.kioskStats">
                            <td>{{stat.kioskName}}</td>
                            <td>{{stat.state}}</td>
                            <td>{{stat.ordersInQueue}}</td>
                            <td>{{stat.ordersProcessed}}</td>
                            <td>{{stat.avgWaitTime | date: 'hh:mm:ss'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card">
                <div class="card-content">

                    <div *ngFor="let kiosk of operationsDynamicOrders; let ind=index">
                        <ng-container *ngIf="isSelected(kiosk)">

                            <div><h4 class="header-primary">{{kiosk.kioskName}} ({{kiosk.operatorName}})</h4></div>

                            <div class="row">
                                <div class="col-sm-3">
                                    <b>Devices</b><br/>
                                    {{kiosk.devicesActive}} active<br/>
                                    {{kiosk.devicesProvisioned}} provisioned.<br/>
                                </div>
                                <div class="col-sm-3">
                                    <b>Ingredients</b><br/>
                                    {{kiosk.ingredientsReady}} active / ready<br/>
                                    {{kiosk.ingredientsProvisioned}} provisioned.<br/>
                                    <br/>
                                </div>
                                <div class="col-sm-3">
                                    <b>Orders</b><br/>
                                    {{kiosk.orderStatesCount["READY"]}} ready<br/>
                                    {{kiosk.orderStatesCount["PREPARING"]}} preparing<br/>
                                    {{kiosk.orderStatesCount["WAITING"]}} waiting<br/>
                                    {{kiosk.orderStatesCount["COMPLETED"]}} completed<br/>
                                    {{kiosk.orderStatesCount["CANCELLED"]}} cancelled.<br/>
                                </div>
                                <div class="col-sm-2">
                                    <a class="btn btn-primary btn-danger collapsed" data-toggle="collapse"
                                       href="#details{{ind}}">
                                        <span class="if-collapsed">View Details</span>
                                        <span class="if-not-collapsed">Hide Details</span>
                                    </a>
                                </div>
                            </div>

                            <div class="collapse" id="details{{ind}}">
                                <div *ngIf="kiosk.ordersProcessing.length > 0">
                                    <h5 class="text-center">Processing: {{kiosk.ordersProcessing.length}} orders</h5>
                                    <div class="row">
                                        <div *ngFor="let order of (kiosk.ordersProcessing ? kiosk.ordersProcessing.slice(0, 20) : [])"
                                             class="col-lg-4 col-sm-6">
                                            <div class="card grayish">
                                                <div class="card-content">
                                                    <div class="va-top"><b>Customer:</b> {{order.userName}}</div>
                                                    <div class="va-top"><b>Recipe:</b> {{order.recipe}}</div>
                                                    <div class="va-top"><b>Ordered at:</b>
                                                        {{order.date | los_angeles: 'MM/dd/yyyy hh:mm a'}}
                                                    </div>
                                                    <div class="va-top"><b>Status:</b> {{order.state}}</div>
                                                    <div *ngFor="let progress of order.progress"
                                                         style="margin-top: 10px">
                                                        <div><b>{{progress.progressType}}
                                                            :</b> {{progress.progressStatus}}
                                                        </div>
                                                        <div>{{progress.startTime | los_angeles: 'hh:mm a' }}
                                                            - {{progress.endTime |
                                                                los_angeles: 'hh:mm a'}}
                                                        </div>
                                                        <div *ngFor="let status of progress.ingredientStatus">
                                                            {{status.ingredientName}},
                                                            {{status.dispensedWeight | number : '1.0-2'}}g /
                                                            {{status.desiredWeight | number : '1.0-2'}}g,
                                                            {{status.dispensedAtTime | los_angeles: 'hh:mm:ss a'}},
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="kiosk.ordersWaiting.length > 0">
                                    <h5 class="text-center">Waiting: {{kiosk.ordersWaiting.length}} orders</h5>
                                    <div class="row">
                                        <div *ngFor="let order of (kiosk.ordersWaiting ? kiosk.ordersWaiting.slice(0, 20) : [])"
                                             class="col-lg-4 col-sm-6">
                                            <div class="card grayish">
                                                <div class="card-content">
                                                    <div class="va-top"><b>Customer:</b> {{order.userName}}</div>
                                                    <div class="va-top"><b>Recipe:</b> {{order.recipe}}</div>
                                                    <div class="va-top"><b>Ordered at:</b>
                                                        {{order.date | los_angeles: 'MM/dd/yyyy hh:mm a'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="kiosk.ordersCompleted.length > 0">
                                    <h5 class="text-center">Completed: {{kiosk.ordersCompleted.length}} orders</h5>
                                    <div class="row">
                                        <div *ngFor="let order of (kiosk.ordersCompleted ? kiosk.ordersCompleted.slice(0, 20) : [])"
                                             class="col-lg-4 col-sm-6">
                                            <div class="card grayish">
                                                <div class="card-content">
                                                    <div class="va-top"><b>Customer:</b> {{order.userName}}</div>
                                                    <div class="va-top"><b>Recipe:</b> {{order.recipe}}</div>
                                                    <div class="va-top"><b>Ordered at:</b>
                                                        {{order.date | los_angeles: 'MM/dd/yyyy hh:mm a'}}
                                                        <div class="va-top"><b>Picked up at:</b>
                                                            {{order.stateChangeDate | los_angeles: 'MM/dd/yyyy hh:mm a'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="kiosk.ordersCancelled.length > 0">
                                    <h5 class="text-center">Cancelled: {{kiosk.ordersCancelled.length}} orders</h5>
                                    <div class="row">
                                        <div *ngFor="let order of (kiosk.ordersCancelled ? kiosk.ordersCancelled.slice(0, 20) : [])"
                                             class="col-lg-4 col-sm-6">
                                            <div class="card grayish">
                                                <div class="card-content">
                                                    <div class="va-top"><b>Customer:</b> {{order.userName}}</div>
                                                    <div class="va-top"><b>Recipe:</b> {{order.recipe}}</div>
                                                    <div class="va-top"><b>Ordered at:</b>
                                                        {{order.date | los_angeles: 'MM/dd/yyyy hh:mm a'}}
                                                        <div class="va-top"><b>Cancelled
                                                            at:</b> {{order.stateChangeDate |
                                                            los_angeles:'MM/dd/yyyy hh:mm a'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
