<base-modal-component class="base-modal" [validation]="isValid()" (onConfirm)="save()" [large]="false"
                      confirmButtonText="Submit" [autoHide]="false">
    <div class="form-horizontal">
        <div class="card-content">

            <div class="form-group">
                <label for="input_email" class="col-md-3 control-label">email
                    <span class="text-danger">*</span>
                </label>
                <div class="col-md-9">

                    <input
                            [type]="email"
                            [(ngModel)]="email"
                            class="form-control form-control-low"
                            id="input_email"
                            [required]="true"/>

                </div>
            </div>
        </div>
    </div>
</base-modal-component>
