import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DASHBOARD_MODULE_COMPONENTS, DASHBOARD_MODULE_ROUTES} from './dashboard.routes';
import {FooterModule} from '../shared/footer/footer.module';
import {NavbarModule} from '../shared/navbar/navbar.module';
import {SidebarModule} from '../shared/sidebar/sidebar.module';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {SharedModule} from '../shared/shared.module';
import {VisModule} from 'ngx-vis';
import {AgmCoreModule} from '@agm/core';
import 'hammerjs';
import 'chartjs-plugin-zoom';
import {ImageCropperModule} from 'ngx-image-cropper';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {Ng5SliderModule} from 'ng5-slider';
import {MarkdownModule, MarkedOptions} from 'ngx-markdown';
import {AngularFileUploaderModule} from 'angular-file-uploader';
import {LightboxModule} from 'ngx-lightbox';
import {FileUploadModule} from '@iplab/ngx-file-upload';
import {NgxCoolDialogsModule} from 'ngx-cool-dialogs';
import {TooltipModule} from 'ngx-bootstrap';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ChartsModule} from 'ng2-charts';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {SortableModule} from 'ngx-bootstrap/sortable';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
    imports: [
        RouterModule.forChild(DASHBOARD_MODULE_ROUTES),
        CommonModule,
        ChartsModule,
        FormsModule,
        SharedModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        VisModule,
        Ng5SliderModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        ImageCropperModule,
        AngularFileUploaderModule,
        LightboxModule,
        FileUploadModule,
        NgSelectModule,
        TooltipModule.forRoot(),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useValue: {
                    gfm: true,
                    tables: true,
                    breaks: true,
                    pedantic: false,
                    sanitize: false,
                    smartLists: true,
                    smartypants: false,
                },
            },
        }),
        NgxCoolDialogsModule.forRoot({
            theme: 'default', // available themes: 'default' | 'material' | 'dark'
            okButtonText: 'OK',
            cancelButtonText: 'Cancel',
            color: '#7f5cff'
        }),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        SortableModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBC01nYn_X0t-uT04ixACVNDao7kCInDQs'
        })
    ],
    entryComponents: [],
    declarations: [DASHBOARD_MODULE_COMPONENTS]
})

export class DashboardModule {
}
